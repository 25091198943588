import React, { useState, useEffect } from 'react';
import {
  Box, VStack, HStack, Text, Heading, Button,
  Input, Textarea, IconButton, Divider,
  UnorderedList, ListItem
} from '@chakra-ui/react';
import { FaEdit, FaSave, FaTimes, FaEnvelope } from 'react-icons/fa';
import { EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { formatTitleWithDate } from '../../../../utils/transcriptionUtils';
import SpeakerStatisticsComponent from './SpeakerStatisticsComponent';

/**
 * Component for displaying and editing call summary
 */
const SummaryPanel = ({
  data,
  title,
  summary,
  type,
  isEditingSummary,
  handleEditSummary,
  handleCancelEditSummary,
  handleSaveSummary,
}) => {
  const [newTitle, setNewTitle] = useState(title || '');
  const [newSummary, setNewSummary] = useState(summary || '');

  // Update state when props change
  useEffect(() => {
    setNewTitle(title || '');
    setNewSummary(summary || '');
  }, [title, summary]);

  // Handle title input change
  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  // Handle summary input change
  const handleSummaryChange = (e) => {
    setNewSummary(e.target.value);
  };

  // Parse timestamp string (MM:SS) to seconds
  const parseTimestamp = (timestamp) => {
    if (!timestamp) return 0;

    const parts = timestamp.split(':');
    if (parts.length === 2) {
      const minutes = parseInt(parts[0], 10);
      const seconds = parseInt(parts[1], 10);
      return minutes * 60 + seconds;
    }

    return 0;
  };

  // Jump to specific timestamp in audio player
  const jumpToTimestamp = (seconds) => {
    // This would typically interact with an audio player component
    console.log(`Jumping to timestamp: ${seconds} seconds`);
    // Implementation would depend on how audio playback is handled
  };

  // Format the summary as a list
  const summaryItems = summary
    ? summary.split('\n').filter(item => item.trim() !== '')
    : [];

  return (
    <VStack spacing={4} align="stretch" overflowY="auto" height="100%" p={2}>
      {/* Summary Section */}
      <Box bg="gray.50" p={6} borderRadius="lg">
        <HStack justify="space-between" mb={4}>
          <Heading size="md">Summary</Heading>
          {isEditingSummary ? (
            <HStack>
              <IconButton
                icon={<CheckIcon />}
                colorScheme="green"
                size="sm"
                onClick={() => handleSaveSummary(newSummary)}
                aria-label="Save summary"
              />
              <IconButton
                icon={<CloseIcon />}
                colorScheme="red"
                size="sm"
                onClick={handleCancelEditSummary}
                aria-label="Cancel edit"
              />
            </HStack>
          ) : (
            <IconButton
              icon={<EditIcon />}
              size="sm"
              onClick={handleEditSummary}
              aria-label="Edit summary"
            />
          )}
        </HStack>
        {isEditingSummary ? (
          <Textarea
            value={newSummary}
            onChange={handleSummaryChange}
            minHeight="200px"
            placeholder="Enter summary..."
          />
        ) : (
          <Text whiteSpace="pre-wrap">
            {summary || 'No summary available'}
          </Text>
        )}
      </Box>

      {/* Topics Section */}
      {data?.topics?.topics?.length > 0 && (
        <Box bg="white" p={6} borderRadius="lg" borderWidth="1px" borderColor="gray.200">
          <Heading size="md" mb={4}>Topics</Heading>
          <VStack align="stretch" spacing={3}>
            {data?.topics?.topics?.map((topic, index) => {
              const timestampSeconds = parseTimestamp(topic.timestamp);
              return (
                <HStack
                  key={index}
                  p={3}
                  bg="gray.50"
                  borderRadius="md"
                  spacing={3}
                  _hover={{ bg: "gray.100" }}
                >
                  <Button
                    variant="link"
                    colorScheme="blue"
                    fontWeight="medium"
                    // onClick={() => jumpToTimestamp(timestampSeconds)} // To be implemented
                  >
                    {topic.timestamp}
                  </Button>
                  <Text>{topic.topic}</Text>
                </HStack>
              );
            })}
          </VStack>
        </Box>
      )}

      {/* Speaker Statistics Section */}
      {type !== 'email' && (
        <SpeakerStatisticsComponent
          transcription={data?.transcription}
          title={title}
          audioUrl={data?.audioUrl}
        />
      )}
    </VStack>
  );
};

export default SummaryPanel; 