import axios from 'axios';
import { SimpleGrid, Flex, FormControl, FormLabel, Input, Button, useToast } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const serverUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const res = await axios.post(`${serverUrl}/forgot-password`, { email });
            toast({
                title: "Success",
                description: "If an account exists with this email, you will receive password reset instructions. If you don't see the email in your inbox, please check your spam folder.",
                status: "success",
                duration: 8000,
                isClosable: true,
            });
            navigate('/login');
        } catch (error) {
            toast({
                title: "Error",
                description: "An error occurred. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <SimpleGrid columns={2} spacing={0} width={"100%"} height={"100%"} data-simple className="ma-0 pa-0">
            <Flex justifyContent={"center"} alignItems={"center"}
                flexDirection={"column"} width={"100%"} height={"100%"}
            >
                <div className="h-100" style={{ maxWidth: "450px" }}>
                    <div style={{color: '#2B3674', fontSize: 36, fontWeight: '700', wordWrap: 'break-word'}}>Reset Password</div>
                    <div style={{color: '#A3AED0', marginBottom: "4px", fontSize: 16, fontWeight: '400', wordWrap: 'break-word'}}>
                        Enter your email address and we'll send you instructions to reset your password.
                    </div>
                    <form onSubmit={handleSubmit}>
                        <FormControl className="mt-8" width={"100%"} isRequired={true}>
                            <FormLabel>Email</FormLabel>
                            <Input 
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                            />
                        </FormControl>
                        <Button 
                            width={"100%"} 
                            className="mt-10" 
                            background={"#00417D"} 
                            color={"white"} 
                            borderRadius={"16px"} 
                            isLoading={isSubmitting}
                            type="submit"
                        >
                            Send Reset Instructions
                        </Button>
                    </form>
                    <Button 
                        width={"100%"} 
                        className="mt-4" 
                        variant={"none"}
                        onClick={() => navigate('/login')}
                    >
                        Back to Login
                    </Button>
                </div>
            </Flex>
            <Flex>
                <Image src="/images/login_bg.jpeg" alt="LOGO JPEG" width={"100%"} height={"100%"}/>
            </Flex>
        </SimpleGrid>
    );
};

export default ForgotPassword; 