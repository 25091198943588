import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, VStack, Button } from '@chakra-ui/react';
import { formatPersonName } from '../utils/formatters';

const AssignClientModal = ({
  isClientAssignModalOpen,
  setIsClientAssignModalOpen,
  clients,
  setClientName
}) => {
  return (
    <Modal
      isOpen={isClientAssignModalOpen}
      onClose={() => setIsClientAssignModalOpen(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Assign Client to Call</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack align="stretch" spacing={4}>
            {clients.map(client => (
              <Button
                key={client.id}
                onClick={() => {
                  setClientName(formatPersonName(client));
                  setIsClientAssignModalOpen(false);
                }}
                variant="outline"
                justifyContent="start"
              >
                {formatPersonName(client)}
              </Button>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
    );
};

export default AssignClientModal;