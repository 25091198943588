import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box, Heading, Table, Tbody, Td, Th, Thead, Tr,
  Input, InputGroup, InputLeftElement, Link, Skeleton, Text,
  HStack, Button, useToast, Icon, Tooltip, Avatar, Tabs, TabList, TabPanels, TabPanel, Select, Menu, MenuButton, MenuList, MenuItem,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Link as ChakraLink,
  Alert,
  AlertIcon,
  Progress,
  Code,
  Spinner,
  Badge,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { ExternalLinkIcon, ChevronDownIcon, AddIcon, EditIcon } from '@chakra-ui/icons';
import { FaSearch, FaSync, FaUsers, FaBuilding } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FeatureStats } from '../../../enums/UsageStats';
import { useUser } from '../../../contexts/UserContext';
import { useOrganizationClients } from '../../../contexts/OrganizationClientsContext';

const Clients = () => {
  const toast = useToast();
  // Use the OrganizationClientsContext
  const { clients: contextClients, entities: contextEntities, loading: contextLoading, fetchClients } = useOrganizationClients();
  
  const [clients, setClients] = useState([]);
  const [entities, setEntities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const serverUrl = process.env.REACT_APP_API_URL;
  const wbUrl = 'https://app.crmworkspace.com/contacts/'
  const rtUrl = 'https://crm.redtailtechnology.com/contacts/'
  const [resyncLoading, setResyncLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [sortOrder, setSortOrder] = useState('recent');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadResults, setUploadResults] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useUser(); // Use the global user context
  const [userCrms, setUserCrms] = useState(user?.organization?.crms || []); // State to hold user CRMs
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [currentSearchRequest, setCurrentSearchRequest] = useState(null);
  const [lastResync, setLastResync] = useState(null);
  const [showType, setShowType] = useState('clients'); // 'clients', 'entities'
  const [expandedEntities, setExpandedEntities] = useState({});

  const handlePageChange = (value) => {
    setPage(value);
  };

  const openInCrm = async (id, crmSource) => {
    if (crmSource === 'Wealthbox') {
      window.open(`${wbUrl}${id}`, '_blank');
    }
    if (crmSource === 'RedTail') {
      window.open(`${rtUrl}${id}/`, '_blank');
    }
    if (crmSource === 'Salesforce') {
      if(userCrms.length > 0 && userCrms[0].connection?.instanceUrl) {
        window.open(`${userCrms[0].connection.instanceUrl}/lightning/r/Contact/${id}/view`, '_blank');
      } else {
        toast({
          title: 'No Salesforce CRM found',
          description: 'Please connect a Salesforce CRM to your account',
        });
      }
    }
  };

  // Toggle expanded state for an entity
  const toggleEntityExpanded = (entityId) => {
    setExpandedEntities(prev => ({
      ...prev,
      [entityId]: !prev[entityId]
    }));
  };

  useEffect(() => {
    // If context data is available, use it
    if (contextClients.length > 0) {
      setClients(contextClients);
    }
    
    if (contextEntities.length > 0) {
      setEntities(contextEntities);
    }
    
    if (!contextLoading) {
      setIsInitialLoading(false);
    }
  }, [contextClients, contextEntities, contextLoading]);

  useEffect(() => {
    // If search term is empty, we're not searching
    if (!debouncedSearchTerm) {
      setIsSearching(false);
    }
    
    const fetchData = async () => {
      // Only set initial loading on first load or when not searching
      if (!isSearching) {
        setIsInitialLoading(true);
      }
      
      // Cancel any in-flight request
      if (currentSearchRequest) {
        currentSearchRequest.cancel("Operation canceled due to new request");
      }
      
      // Create a new cancellation token
      const cancelTokenSource = axios.CancelToken.source();
      setCurrentSearchRequest(cancelTokenSource);
      
      try {
        // Always include entities in the request
        const response = await axios.get(
          `${serverUrl}/api/clients`, {
            params: {
              page,
              pageSize,
              sortOrder,
              search: debouncedSearchTerm,
              includeEntities: 'true' // Always request entities
            },
            cancelToken: cancelTokenSource.token,
            headers: {
              'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
          }
        );
        
        console.log("API response:", {
          clients: response.data.clients?.length || 0,
          entities: response.data.entities?.length || 0
        });
        
        // Store the full data
        setClients(response.data.clients || []);
        setEntities(response.data.entities || []);
        
        // Now filter based on showType
        let filteredResults = [];
        
        if (showType === 'all' || showType === 'clients') {
          filteredResults = [...filteredResults, ...(response.data.clients || [])];
        }
        
        if (showType === 'all' || showType === 'entities') {
          // Mark entities for easier identification
          const markedEntities = (response.data.entities || []).map(entity => ({
            ...entity,
            isEntity: true
          }));
          filteredResults = [...filteredResults, ...markedEntities];
        }
        
        console.log(`Filtered results (${showType}):`, filteredResults.length);
        setSearchResults(filteredResults);
        setTotalPages(response.data.totalPages || 1);
        
        // Log search usage only when search is actually performed
        if (debouncedSearchTerm) {
          try {
            await axios.post(`${serverUrl}/api/usage/log`, {
              statPath: FeatureStats.CLIENTS_SEARCHED,
              value: 1
            }, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
              }
            });
          } catch (error) {
            console.error("Error logging search usage:", error);
          }
        }
      } catch (error) {
        // Don't show error if request was cancelled
        if (!axios.isCancel(error)) {
          console.error("Error fetching clients:", error);
          toast({
            title: "Error fetching clients.",
            description: `There was an error fetching clients from the server: ${error.response ? error.response.data.details : error.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } finally {
        // Always update loading states
        setIsInitialLoading(false);
        
        // Only turn off search loading if this is the current search term
        // This prevents older requests from affecting newer ones
        if (searchTerm === debouncedSearchTerm) {
          setIsSearching(false);
        }
      }
    };
    
    fetchData();
    
    // Cleanup function to cancel request when component unmounts or dependencies change
    return () => {
      if (currentSearchRequest) {
        currentSearchRequest.cancel("Operation canceled due to component unmount or dependency change");
      }
    };
  }, [page, pageSize, debouncedSearchTerm, sortOrder, showType]);

  const handleChange = event => {
    setSearchTerm(event.target.value);
    // Remove the automatic search trigger
    // setIsSearching(true);
  };

  // Add a new function to handle search button click
  const handleSearch = () => {
    setIsSearching(true);
    setDebouncedSearchTerm(searchTerm);
  };

  // Add a function to handle pressing Enter in the search field
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleResync = async () => {
    setResyncLoading(true);
    console.log('handleResync - Initiating resync process');
    try {
      console.warn('handleResync - Resyncing all clients for the organization');
      const response = await axios.post(`${serverUrl}/api/clients/resync-all`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      if (response.data.message) {
        // Log the CRM resync
        await axios.post(`${serverUrl}/api/usage/log`, {
          statPath: FeatureStats.CRM_RESYNCS,
          value: 1
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });

        toast({
          title: "Resync Initiated",
          description: "All client data resync has been initiated. This may take a few moments.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        setResyncLoading(true);
        // wait 10 seconds before fetching jobs
        setTimeout(fetchJobs, 10000);
      } else {
        throw new Error(response.data.message || "Failed to initiate resync");
      }
    } catch (error) {
      console.error("Error initiating resync:", error);
      toast({
        title: "Resync Failed",
        description: `Failed to initiate resync: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      console.log('Clients Component: handleResync - Resync process completed');
    }
  };

  const fetchJobs = async (bypassToast = false) => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await axios.get(`${serverUrl}/api/jobs?type=Resync&status=active`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
      console.log('Jobs:', response.data);
      setResyncLoading(response.data.length > 0);
      if (response.data.length > 0) {
        setTimeout(fetchJobs, 10000); // Poll every 10 seconds until the length is 0
      } else {
        setResyncLoading(false);
        if (!bypassToast) {
          toast({
            title: "Resync Completed",
            description: "All client data resync has been completed. Refresh the page to see the latest data.",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
          
          // Refresh client and entity data from context
          fetchClients();
        }
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const fetchLastResync = async () => {
    const token = Cookies.get('jwtToken');
    const response = await axios.get(`${serverUrl}/api/last-resync`, { headers: { Authorization: `Bearer ${token}` } });
    console.log('Last Resync:', response.data);
    setLastResync(response.data.lastResyncJobDate);
  };

  useEffect(() => {
    fetchJobs(true);
    fetchLastResync();
  }, []);

  const navigate = useNavigate();
  const handleAddClient = () => {
    navigate('/clients/new');
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    setUploadResults(null);

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const csvData = e.target.result;
          const response = await axios.post(
            `${serverUrl}/api/clients/upload-csv`,
            { csvData },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('jwtToken')}`
              }
            }
          );

          setUploadResults(response.data);
          
          // Refresh client list if any clients were successfully added
          if (response.data.successCount > 0) {
            fetchClients();
          }

        } catch (error) {
          toast({
            title: 'Error uploading CSV',
            description: error.response?.data?.error || 'Failed to upload CSV file',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      };
      reader.readAsText(file);
    } catch (error) {
      toast({
        title: 'Error reading file',
        description: 'Failed to read the CSV file',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const CSV_TEMPLATE = `firstName,lastName,email,phone,prefix,middleName,suffix,nickname,maritalStatus,jobTitle,company,phoneSecondary,tags,background,householdRole,householdName,streetLine1,streetLine2,city,state,country,gender,preferredName,emailSecondary,citizenship,preferredMethodOfContact,preferredMeetingFrequency,investmentObjectives,financialGoals,annualIncome,wealthRange,netWorth,spouseName,spouseDetails,familyDetails
John,Doe,john@example.com,123-456-7890,Mr.,James,Jr.,Johnny,Married,CEO,Acme Inc,987-654-3210,"VIP,High Priority",Long-term client,Head,Doe Family,123 Main St,Suite 100,Anytown,CA,USA,Male,Johnny,john.doe@work.com,US,Email,Quarterly,"Wealth Growth,Preservation","Retirement,College Planning",150000,High Net Worth,2000000,Jane Doe,Works as doctor,2 children in college`;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  // Custom Icon component for entity types
  const EntityTypeIcon = ({ entityType }) => {
    switch (entityType?.toLowerCase()) {
      case 'family':
        return <Icon as={FaUsers} color="blue.500" />;
      case 'business':
        return <Icon as={FaBuilding} color="purple.500" />;
      default:
        return <Icon as={FaUsers} color="gray.500" />;
    }
  };
  
  // Render a table row for an entity
  const renderEntityRow = (entity) => {
    const isExpanded = expandedEntities[entity.id] || false;
    const clientCount = entity.clients?.length || 0;
    
    return (
      <React.Fragment key={`entity-${entity.id}`}>
        <Tr 
          onClick={() => toggleEntityExpanded(entity.id)}
          cursor="pointer"
          _hover={{ bg: "gray.50" }}
        >
          <Td>
            <HStack spacing={3}>
              <Box position="relative">
                <Avatar 
                  icon={<EntityTypeIcon entityType={entity.entityType} />}
                  size="sm"
                  bg="gray.100"
                  fontSize="16px"
                />
              </Box>
              <VStack align="start" spacing={0}>
                <HStack>
                  <Text fontWeight="bold">{entity.name}</Text>
                  <Badge colorScheme={entity.entityType === 'Family' ? 'blue' : 'purple'}>
                    {entity.entityType}
                  </Badge>
                </HStack>
                <Text fontSize="xs" color="gray.500">
                  {clientCount} {clientCount === 1 ? 'client' : 'clients'}
                </Text>
              </VStack>
            </HStack>
          </Td>
          <Td>
            {entity.updatedAt ? formatDate(entity.updatedAt) : 'N/A'}
          </Td>
          <Td textAlign="right">
            <HStack justify="flex-end" spacing={2}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleEntityExpanded(entity.id);
                }}
                colorScheme="blue"
                size="sm"
                variant="outline"
              >
                {isExpanded ? 'Collapse' : 'Expand'}
              </Button>
            </HStack>
          </Td>
        </Tr>
        {isExpanded && entity.clients?.map(client => (
          <Tr key={`entity-${entity.id}-client-${client.id}`} bg="gray.50">
            <Td pl={10}>
              <HStack spacing={3}>
                <Avatar 
                  name={`${client.firstName} ${client.lastName}`}
                  size="sm"
                  bg="#00417D"
                  color="white"
                  fontSize="16px"
                  fontWeight="700"
                />
                <Text>{client.firstName} {client.metadata?.preferredName ? `(${client.metadata?.preferredName})` : ''} {client.lastName}</Text>
              </HStack>
            </Td>
            <Td>
              {client.emails?.length > 0 ?
                formatDate(client.emails[0].receivedAt) :
                client.calls?.length > 0 ?
                  formatDate(client.calls[0].startTime) :
                  'No correspondence'
              }
            </Td>
            <Td textAlign="right">
              <Button
                as={RouterLink}
                to={`/clients/${client.id}`}
                colorScheme="blue"
                size="sm"
                onClick={(e) => e.stopPropagation()}
              >
                View
              </Button>
            </Td>
          </Tr>
        ))}
      </React.Fragment>
    );
  };

  // Render a table row for a client
  const renderClientRow = (client) => (
    <Tr key={client.id}>
      <Td>
        <HStack spacing={3}>
          <Avatar 
            name={`${client.firstName} ${client.lastName}`}
            size="sm"
            bg="#00417D"
            color="white"
            fontSize="16px"
            fontWeight="700"
          />
          <Text>{client.firstName} {client.metadata?.preferredName ? `(${client.metadata?.preferredName})` : ''} {client.lastName}</Text>
        </HStack>
      </Td>
      <Td>
        {client.emails?.length > 0 ?
          formatDate(client.emails[0].receivedAt) :
          client.calls?.length > 0 ?
            formatDate(client.calls[0].startTime) :
            'No correspondence'
        }
      </Td>
      <Td textAlign="right">
        <HStack justify="flex-end" spacing={2}>
          <Button
            as={RouterLink}
            to={`/clients/${client.id}`}
            colorScheme="blue"
            size="sm"
          >
            View
          </Button>
          {(!client.crmSource && !client.crmId) && (  // Only show edit button for non-CRM clients
            <Button
              as={RouterLink}
              to={`/clients/${client.id}/edit`}
              leftIcon={<EditIcon />}
              size="sm"
              variant="outline"
              colorScheme="blue"
            >
              Edit
            </Button>
          )}
          {(client.crmId && client.crmSource) && (
            <Tooltip label={`Open in ${client.crmSource}`}>
              <Button
                leftIcon={<ExternalLinkIcon />}
                onClick={() => openInCrm(client.crmId, client.crmSource)}
                size="sm"
                variant="outline"
                colorScheme="blue"
              >
                {client.crmSource.charAt(0).toUpperCase() + client.crmSource.slice(1).toLowerCase()}
              </Button>
            </Tooltip>
          )}
        </HStack>
      </Td>
    </Tr>
  );

  return (
    <Box bg="white" p={8} overflowY="auto">
      <VStack spacing={4} align="stretch">
        {/* First row with heading and search */}
        <HStack justify="space-between">
          <Heading color={'#00417D'} fontSize={'32px'}>Clients</Heading>
          <HStack spacing={4}>
            <InputGroup w={'auto'}>
              <InputLeftElement pointerEvents="none">
                {isSearching ? (
                  <Spinner size="xs" color="#A1A1A1" />
                ) : (
                  <Icon as={FaSearch} color={'#A1A1A1'} w={3} mb={'6px'} />
                )}
              </InputLeftElement>
              <Input
                placeholder="Search for a client"
                _placeholder={{
                  color: '#A1A1A1',
                  fontWeight: 700,
                  textAlign: 'center'
                }}
                borderRadius={'6px'}
                size="sm"
                w='270px'
                value={searchTerm}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <Button 
                ml={2} 
                size="sm" 
                colorScheme="blue" 
                onClick={handleSearch}
                isLoading={isSearching}
              >
                Search
              </Button>
            </InputGroup>

            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme="blue" size="sm">
                Add Client
              </MenuButton>
              <MenuList>
                <MenuItem icon={<AddIcon />} onClick={handleAddClient}>
                  Add Single Client
                </MenuItem>
                <MenuItem icon={<ExternalLinkIcon />} onClick={onOpen}>
                  Upload CSV
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>

        {/* Second row with filters and controls */}
        <HStack spacing={4} justify="flex-end">
          {/* Client/Entity filter */}
          <HStack spacing={2} border="1px solid" borderColor="gray.200" borderRadius="md" p={1}>
            <Button 
              size="sm" 
              variant={showType === 'clients' ? "solid" : "ghost"}
              colorScheme={showType === 'clients' ? "blue" : "gray"}
              onClick={() => setShowType('clients')}
            >
              Clients
            </Button>
            <Button 
              size="sm" 
              variant={showType === 'entities' ? "solid" : "ghost"}
              colorScheme={showType === 'entities' ? "blue" : "gray"}
              onClick={() => setShowType('entities')}
            >
              Entities
            </Button>
          </HStack>

          {/* Sort order select */}
          <Select
            size="sm"
            w="200px"
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="recent">Most Recent</option>
            <option value="lastCorrespondence">Last Correspondence</option>
            <option value="az">A-Z</option>
            <option value="za">Z-A</option>
          </Select>

          {/* Resync CRM button */}
          <Tooltip label={lastResync ? `Last Resync: ${lastResync}` : ''}>
            <Button
              onClick={handleResync}
              isLoading={resyncLoading}
              loadingText="Resyncing"
              size="sm"
              variant="outline"
              colorScheme="blue"
              leftIcon={
                <motion.div
                  animate={{ rotate: resyncLoading ? 360 : 0 }}
                  transition={{ duration: 1, repeat: resyncLoading ? Infinity : 0, ease: "linear" }}
                >
                  <Icon as={FaSync} />
                </motion.div>
              }
            >
              Resync CRM
            </Button>
          </Tooltip>
        </HStack>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Clients CSV</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text>
                Upload a CSV file containing client information. Download the template below for the correct format.
              </Text>
              
              <HStack>
                <Button
                  as="label"
                  htmlFor="csv-upload"
                  colorScheme="blue"
                  leftIcon={<ExternalLinkIcon />}
                  isLoading={isUploading}
                  cursor="pointer"
                >
                  Choose CSV File
                  <input
                    id="csv-upload"
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                    style={{ display: 'none' }}
                  />
                </Button>

                <Button
                  leftIcon={<ExternalLinkIcon />}
                  onClick={() => {
                    const blob = new Blob([CSV_TEMPLATE], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'client-template.csv';
                    a.click();
                  }}
                >
                  Download Template
                </Button>
              </HStack>

              {uploadResults && (
                <VStack spacing={4} align="stretch">
                  <Progress
                    value={(uploadResults.successCount / uploadResults.totalProcessed) * 100}
                    colorScheme="blue"
                    size="sm"
                  />
                  
                  <Alert status={uploadResults.errorCount > 0 ? 'warning' : 'success'}>
                    <AlertIcon />
                    Processed {uploadResults.totalProcessed} records:
                    {uploadResults.successCount} successful,
                    {uploadResults.errorCount} failed
                  </Alert>

                </VStack>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isInitialLoading || isSearching ? (
        <Box mt={12}>
          <Skeleton height="32px" width="200px" mb={4} />
          <Skeleton height="32px" width="200px" mb={4} />
          <Skeleton height="32px" width="200px" mb={4} />
        </Box>
      ) : (
        <Table variant="simple" mt={12}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Last Correspondence</Th>
              <Th textAlign="right">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {searchResults.length === 0 ? (
              <Tr>
                <Td colSpan={3} textAlign="center">
                  <Text color="gray.500">
                    {debouncedSearchTerm ? 'No clients found matching your search' : 'No clients available'}
                  </Text>
                </Td>
              </Tr>
            ) : (
              // Filter and render clients and entities based on showType
              searchResults.map(item => {
                // Detect entity using the explicit marker we added earlier
                const isEntity = item.isEntity === true || (item.name && item.entityType);
                
                if (isEntity) {
                  if (showType === 'clients') return null; // Skip entities when viewing only clients
                  return renderEntityRow(item);
                } else {
                  if (showType === 'entities') return null; // Skip clients when viewing only entities
                  return renderClientRow(item);
                }
              })
            )}
          </Tbody>
        </Table>
      )}

      <HStack justify="flex-end" mt={4}>
        {page > 1 && <Button onClick={() => handlePageChange(page - 1)}>Previous</Button>}
        {page < totalPages && <Button onClick={() => handlePageChange(page + 1)}>Next</Button>}
        <Text>Page {page} of {totalPages}</Text>
      </HStack>
    </Box>
  );
};

export default Clients;