import React, { useState, useMemo, memo, useCallback, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  VStack,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Portal,
  IconButton,
  CloseButton
} from '@chakra-ui/react';
import { SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { FaEdit } from 'react-icons/fa';
import { formatPersonName } from '../utils/formatters';

// Memoized SpeakerSelectionItem component
const SpeakerSelectionItem = memo(({ 
  person, 
  speaker, 
  groupIndex, 
  handleSpeakerUpdate, 
  setActiveSpeakerEdit 
}) => {
  // Use callbacks to prevent unnecessary re-renders
  const handleClick = useCallback(() => {
    handleSpeakerUpdate(person, speaker, groupIndex, false);
    setActiveSpeakerEdit(null);
  }, [person, speaker, groupIndex, handleSpeakerUpdate, setActiveSpeakerEdit]);

  const handleReplaceAll = useCallback((e) => {
    e.stopPropagation();
    handleSpeakerUpdate(person, speaker, null, true);
    setActiveSpeakerEdit(null);
  }, [person, speaker, handleSpeakerUpdate, setActiveSpeakerEdit]);

  return (
    <Box
      key={person.id}
      position="relative"
      transition="all 0.2s"
      borderRadius="md"
      width="100%"
      _hover={{
        bg: 'gray.50',
        transform: 'translateX(2px)'
      }}
      data-speaker-selection="true"
    >
      <Flex
        p={3}
        align="center"
        justify="space-between"
        cursor="pointer"
        onClick={handleClick}
        minWidth="0"
      >
        <HStack spacing={3} flex="1" minWidth="0">
          <Box
            bg={person.type === 'client' ? 'blue.100' : 'green.100'}
            p={2}
            borderRadius="full"
            color={person.type === 'client' ? 'blue.700' : 'green.700'}
            flexShrink={0}
          >
            {person.type === 'client' ? '👤' : '👔'}
          </Box>
          <VStack align="start" spacing={0} minWidth="0">
            <Text fontWeight="medium" noOfLines={1}>{person.name}</Text>
            <Text fontSize="xs" color="gray.500" textTransform="capitalize">
              {person.type}
            </Text>
          </VStack>
        </HStack>
        <Button
          size="sm"
          variant="ghost"
          colorScheme={person.type === 'client' ? 'blue' : 'green'}
          onClick={handleReplaceAll}
          ml={2}
          flexShrink={0}
        >
          Replace All
        </Button>
      </Flex>
    </Box>
  );
});

// Memoized SpeakerSelectionContent component
const SpeakerSelectionContent = memo(({ 
  speaker, 
  groupIndex, 
  availableSpeakers, 
  speakerSearchTerm, 
  setSpeakerSearchTerm, 
  handleSpeakerUpdate, 
  setActiveSpeakerEdit,
  colors,
  onClose
}) => {
  // Create a ref for the search input
  const searchInputRef = useRef(null);
  
  // Focus the search input when the modal opens
  useEffect(() => {
    if (searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 100);
    }
    
    // Log that the modal content is being rendered
    console.log('SpeakerSelectionContent rendered for:', { speaker, groupIndex });
  }, [speaker, groupIndex]);

  // Filter speakers based on search term
  const filteredSpeakers = useMemo(() => {
    return availableSpeakers.filter(person =>
      person.name.toLowerCase().includes(speakerSearchTerm.toLowerCase())
    );
  }, [availableSpeakers, speakerSearchTerm]);

  // Handle search input change
  const handleSearchChange = useCallback((e) => {
    setSpeakerSearchTerm(e.target.value);
  }, [setSpeakerSearchTerm]);

  return (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      width="350px"
      bg="white"
      boxShadow="xl"
      borderRadius="xl"
      zIndex={1500}
      data-speaker-selection="true"
      border="1px solid"
      borderColor="gray.200"
    >
      <Flex justifyContent="space-between" alignItems="center" p={3} borderBottom="1px solid" borderColor="gray.100">
        <Text fontWeight="bold">Select Speaker: {speaker}</Text>
        <CloseButton onClick={onClose} />
      </Flex>
      
      <VStack 
        align="stretch" 
        width="100%" 
        spacing={2} 
        p={3}
      >
        <InputGroup size="md">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.400" />
          </InputLeftElement>
          <Input
            ref={searchInputRef}
            placeholder="Search speakers..."
            value={speakerSearchTerm}
            onChange={handleSearchChange}
            bg="white"
            borderWidth="2px"
            _focus={{
              borderColor: colors.secondary,
              boxShadow: 'none'
            }}
          />
        </InputGroup>
        
        {filteredSpeakers.length > 0 ? (
          <VStack 
            align="stretch" 
            spacing={1} 
            maxH="300px" 
            overflowY="auto"
            overflowX="hidden"
            width="100%"
            sx={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: colors.primary,
                borderRadius: '24px',
              },
            }}
          >
            {filteredSpeakers.map((person) => (
              <SpeakerSelectionItem
                key={person.id}
                person={person}
                speaker={speaker}
                groupIndex={groupIndex}
                handleSpeakerUpdate={handleSpeakerUpdate}
                setActiveSpeakerEdit={setActiveSpeakerEdit}
              />
            ))}
          </VStack>
        ) : (
          <Box textAlign="center" py={4}>
            <Text color="gray.500">No speakers found</Text>
          </Box>
        )}
        
        <Button 
          size="sm" 
          variant="outline" 
          onClick={onClose} 
          mt={2}
          colorScheme="gray"
        >
          Cancel
        </Button>
      </VStack>
    </Box>
  );
});

// Simple speaker button component
const SpeakerButton = memo(({ 
  speaker, 
  isOpen, 
  onToggle, 
  colors 
}) => {
  // Handle click with proper event passing
  const handleClick = useCallback((e) => {
    // Pass the event to the onToggle handler
    onToggle(e);
  }, [onToggle]);

  return (
    <Button
      variant="ghost"
      size="sm"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      fontWeight="bold"
      color={colors.primary}
      onClick={handleClick}
      leftIcon={<FaEdit size="12px" />}
      rightIcon={<ChevronDownIcon />}
      _hover={{
        bg: 'blue.50'
      }}
      position="relative"
      width="100%"
      minWidth="120px"
      data-speaker-selection="true"
    >
      {speaker}
    </Button>
  );
});

/**
 * Custom hook for selecting and updating speakers in transcription
 */
const useSpeakerSelection = ({ 
  users, 
  clients, 
  colors = { primary: 'blue.500', secondary: 'blue.300' },
  handleSpeakerUpdate 
}) => {
  // All hooks must be at the top level of the component
  const [activeSpeakerEdit, setActiveSpeakerEdit] = useState(null);
  const [speakerSearchTerm, setSpeakerSearchTerm] = useState('');
  
  // Create a ref to store the current value of activeSpeakerEdit
  // This helps avoid closure issues in callbacks
  const activeSpeakerEditRef = useRef(activeSpeakerEdit);
  
  // Update the ref whenever activeSpeakerEdit changes
  useEffect(() => {
    activeSpeakerEditRef.current = activeSpeakerEdit;
    console.log('activeSpeakerEdit changed:', activeSpeakerEdit);
  }, [activeSpeakerEdit]);
  
  // We can't use a single ref for all speaker buttons, so we'll use a different approach
  // for handling clicks outside

  // Handle click outside to close the dropdown
  useEffect(() => {
    // Only add the event listener if a speaker edit is active
    if (!activeSpeakerEdit) return;
    
    function handleClickOutside(event) {
      // Check if the click is outside any speaker selection component
      // by checking if it has a parent with data-speaker-selection attribute
      let targetElement = event.target;
      let isInsideSpeakerSelection = false;
      
      while (targetElement) {
        if (targetElement.getAttribute && targetElement.getAttribute('data-speaker-selection')) {
          isInsideSpeakerSelection = true;
          break;
        }
        targetElement = targetElement.parentElement;
      }
      
      if (!isInsideSpeakerSelection && activeSpeakerEdit) {
        setActiveSpeakerEdit(null);
      }
    }

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeSpeakerEdit]);

  // Memoized lists and content
  const availableSpeakers = useMemo(() => {
    const formattedUsers = users.map(user => ({
      id: user.id,
      name: formatPersonName(user),
      type: 'user'
    }));

    const formattedClients = clients.map(client => ({
      id: client.id,
      name: formatPersonName(client),
      type: 'client'
    }));

    return [...formattedUsers, ...formattedClients]
    .filter(person => person.name)
    .sort((a, b) => a.name.localeCompare(b.name));
  }, [users, clients]);

  // Function to directly open the speaker modal
  const openSpeakerModal = useCallback((speaker, groupIndex) => {
    const speakerKey = `${speaker}-${groupIndex}`;
    console.log('Directly opening speaker modal:', { 
      speaker, 
      groupIndex, 
      speakerKey,
      currentActiveSpeakerEdit: activeSpeakerEditRef.current 
    });
    
    // Use a function to set the state to ensure we're using the latest state
    setActiveSpeakerEdit((current) => {
      console.log('Setting activeSpeakerEdit from:', current, 'to:', speakerKey);
      return speakerKey;
    });
    setSpeakerSearchTerm('');
  }, []);

  // Render speaker name with edit functionality
  const renderSpeakerName = useCallback((speaker, groupIndex) => {
    const speakerKey = `${speaker}-${groupIndex}`;
    // Use the ref to get the current value but also check the actual state
    // This ensures we're using the most up-to-date value
    const isOpen = activeSpeakerEdit === speakerKey;
    
    console.log('renderSpeakerName called:', { 
      speaker, 
      groupIndex, 
      speakerKey, 
      activeSpeakerEdit, 
      refValue: activeSpeakerEditRef.current,
      isOpen 
    });
    
    const handleToggle = (e) => {
      // Stop propagation to prevent immediate closing
      e.stopPropagation();
      console.log('Speaker button clicked:', { 
        speaker, 
        groupIndex, 
        currentActive: activeSpeakerEdit, 
        refValue: activeSpeakerEditRef.current,
        willSetTo: isOpen ? null : speakerKey 
      });
      
      setActiveSpeakerEdit(isOpen ? null : speakerKey);
      if (!isOpen) {
        setSpeakerSearchTerm('');
      }
    };
    
    const handleClose = () => {
      console.log('Speaker selection closing');
      setActiveSpeakerEdit(null);
      setSpeakerSearchTerm('');
    };
    
    console.log('Rendering speaker name:', { speaker, groupIndex, isOpen });
    
    return (
      <Box 
        position="relative" 
        display="inline-block"
        data-speaker-selection="true"
      >
        <SpeakerButton 
          speaker={speaker} 
          isOpen={isOpen} 
          onToggle={handleToggle} 
          colors={colors} 
        />
        
        {isOpen && (
          <Portal>
            <SpeakerSelectionContent
              speaker={speaker}
              groupIndex={groupIndex}
              availableSpeakers={availableSpeakers}
              speakerSearchTerm={speakerSearchTerm}
              setSpeakerSearchTerm={setSpeakerSearchTerm}
              handleSpeakerUpdate={handleSpeakerUpdate}
              setActiveSpeakerEdit={setActiveSpeakerEdit}
              colors={colors}
              onClose={handleClose}
            />
          </Portal>
        )}
      </Box>
    );
  }, [availableSpeakers, speakerSearchTerm, colors, handleSpeakerUpdate, activeSpeakerEdit]);

  // Return the renderSpeakerName function to be used by parent components
  return { renderSpeakerName, openSpeakerModal };
};

export default useSpeakerSelection;