import React, { useState, useEffect } from 'react';
import { 
  Box, VStack, HStack, IconButton, Flex, Text, 
  Button, Heading, Divider, Badge, Link, 
  Accordion, AccordionItem, AccordionButton, 
  AccordionPanel, AccordionIcon, Avatar,
  Collapse, Spinner, Icon,
  Menu, MenuButton, MenuList, MenuItem, useToast
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon, ChevronUpIcon, ChevronDownIcon, ArrowBackIcon, TimeIcon, WarningIcon } from '@chakra-ui/icons';
import { MdFullscreen, MdFullscreenExit, MdReply, MdReplyAll, MdForward } from 'react-icons/md';
import { FaEnvelope, FaPaperclip, FaEdit } from 'react-icons/fa';
import { VscReply } from 'react-icons/vsc';
import { format } from 'date-fns';
import axios from 'axios';
import Cookies from 'js-cookie';

// Import AssignClientModal and formatPersonName
import AssignClientModal from '../../../AssignClientModal';
import { useOrganizationClients } from '../../../../contexts/OrganizationClientsContext';
import { formatPersonName } from '../../../../utils/formatters';

/**
 * Component for displaying email content
 */
const EmailContentPanel = ({
  data,
  id,
  title,
  content,
  sender,
  recipients,
  date,
  attachments = [],
  replies = [],
  isLeftExpanded,
  setIsLeftExpanded,
  isRightExpanded = false,
  setIsRightExpanded
}) => {
  const [showFullHeaders, setShowFullHeaders] = useState(false);
  const [isRepliesExpanded, setIsRepliesExpanded] = useState(false);
  const [isLoadingReplies, setIsLoadingReplies] = useState(false);
  const [isClientAssignModalOpen, setIsClientAssignModalOpen] = useState(false);
  const [clientName, setClientName] = useState(data?.call?.client ? formatPersonName(data.call.client) : '');
  const [detailedReplies, setDetailedReplies] = useState([]);
  const { clients } = useOrganizationClients();
  const toast = useToast();

  // Format date
  const formatDate = (date) => {
    if (!date) return '';
    try {
      return format(new Date(date), 'MMM d, yyyy h:mm a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return date;
    }
  };

  // Format email address
  const formatEmailAddress = (address) => {
    if (!address) return '';
    if (typeof address === 'string') {
      return address;
    }
    if (address.name && address.email) {
      return `${address.name} <${address.email}>`;
    }
    return address.email || '';
  };

  // Format title with date
  const formatTitleWithDate = (title) => {
    if (!title) return 'Untitled Email';

    // Remove date markers if present
    return title.replace(/\|\|.*?\|\|/, '').trim();
  };

  // Add this function to fetch replies
  const fetchReplies = async (emailId) => {
    setIsLoadingReplies(true);
    try {
      const serverUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.get(
        `${serverUrl}/api/emails/${emailId}/replies`,
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );
      setDetailedReplies(response.data);
    } catch (error) {
      console.error('Error fetching replies:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch email replies',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingReplies(false);
    }
  };

  // Fetch detailed replies when expanding the replies section
  useEffect(() => {
    if (isRepliesExpanded && id && replies.length > 0 && detailedReplies.length === 0) {
      fetchReplies(id);
    }
  }, [isRepliesExpanded, id, replies.length, detailedReplies.length]);

  // Update client for the call
  const updateCallClient = async (clientId) => {
    try {
      const serverUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.put(
        `${serverUrl}/update-call-client/${id}`,
        { clientId },
        { headers: { Authorization: `Bearer ${Cookies.get("jwtToken")}` } }
      );

      toast({
        title: "Client updated",
        description: "The email has been assigned to the selected client.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error updating call client:', error);
      toast({
        title: "Error updating client",
        description: "There was an error assigning the client to this email.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  // Handle client selection from modal
  const handleClientSelect = async (selectedClient) => {
    try {
      await updateCallClient(selectedClient.id);
      setClientName(formatPersonName(selectedClient));

      // Update the data object to reflect the new client
      if (data && data.call) {
        data.call.client = selectedClient;
      }

      toast({
        title: "Client assigned",
        description: `This email has been assigned to ${formatPersonName(selectedClient)}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error in handleClientSelect:', error);
    }
  };

  return (
    <Box 
      bg="white" 
      borderRadius="md" 
      shadow="sm" 
      p={4} 
      h="100%" 
      overflowY="auto"
      position="relative"
      transition="width 0.1s ease"
      display="flex"
      flexDirection="column"
    >
      <VStack 
        spacing={0} 
        h="100%"
      >
        {/* Header with title and expand button */}
        <HStack w="100%" justifyContent="space-between">
          <VStack spacing={0} width="100%">
            {/* Title Section */}
            <Box
              w="100%"
              bg="gray.50"
              borderBottomWidth="1px"
              borderColor="gray.200"
            >
              <VStack spacing={2} p={4}>
                <HStack justify="space-between" width="100%">
                  <HStack spacing={3}>
                    <IconButton
                      aria-label="Go back"
                      icon={<ArrowBackIcon />}
                      onClick={() => window.history.back()}
                      variant="ghost"
                      color="gray.600"
                      _hover={{ bg: "gray.100" }}
                    />
                    <Heading size="md" color="#00417D">
                      {formatTitleWithDate(data?.call?.title || title)}
                    </Heading>
                  </HStack>
                  <IconButton
                    icon={isLeftExpanded ? <MdFullscreenExit /> : <MdFullscreen />}
                    onClick={() => {
                      setIsLeftExpanded(!isLeftExpanded);
                      if (setIsRightExpanded && !isLeftExpanded) {
                        setIsRightExpanded(false);
                      }
                    }}
                    aria-label="Toggle fullscreen"
                  />
                </HStack>

                <HStack spacing={6} pl={12} color="gray.600" width="100%">
                  {/* Client assignment button - hidden for now
                  <HStack spacing={2}>
                    {clientName ? (
                      <Menu>
                        <MenuButton
                          as={Button}
                          size="sm"
                          variant="ghost"
                          _hover={{ bg: 'gray.100' }}
                        >
                          <HStack spacing={2}>
                            <Avatar
                              name={clientName}
                              size="xs"
                              bg="#00417D"
                              color="white"
                              fontSize="12px"
                              fontWeight="700"
                            />
                            <Text fontWeight="medium">{clientName}</Text>
                            <ChevronDownIcon />
                          </HStack>
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            icon={<Icon as={FaEdit} />}
                            onClick={() => setIsClientAssignModalOpen(true)}
                          >
                            Reassign Client
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    ) : (
                      <Button
                        leftIcon={<Icon as={WarningIcon} color="red.500" />}
                        size="sm"
                        variant="outline"
                        colorScheme="red"
                        onClick={() => setIsClientAssignModalOpen(true)}
                      >
                        Assign Client
                      </Button>
                    )}
                  </HStack>
                  */}

                  <HStack spacing={2}>
                    <Icon as={TimeIcon} />
                    <Text>
                      {data?.call?.title ?
                        (() => {
                          // First try to parse date from title format "Meeting M/D/YYYY HH:MM AM/PM"
                          const titleMatch = data.call.title.match(/Meeting (\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2} [AP]M)/);
                          if (titleMatch) {
                            const date = new Date(titleMatch[1]);
                            return date.toLocaleString(undefined, {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            });
                          }

                          // Then try timestamp between || markers
                          const markerMatches = data.call.title.match(/\|\|(.*?)\|\|/);
                          if (markerMatches) {
                            const date = new Date(markerMatches[1]);
                            return date.toLocaleString(undefined, {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            });
                          }

                          // Finally fallback to date prop or startTime
                          if (date) {
                            return formatDate(date);
                          } else if (data.call.startTime) {
                            return new Date(data.call.startTime).toLocaleString(undefined, {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            });
                          }
                          return 'Time not available';
                        })()
                        : date ? formatDate(date) : 'Time not available'
                      }
                    </Text>
                  </HStack>
                </HStack>
              </VStack>
            </Box>
          </VStack>
        </HStack>

        <Divider />

        <VStack align="stretch" spacing={4} mt={4}>
          {/* Original Email */}
          <Box 
            p={6} 
            bg="white" 
            borderRadius="lg"
            borderWidth="1px"
            borderColor="gray.200"
            position="relative"
          >
            {/* Email Subject */}
            <Box mb={4}>
              <Text fontWeight="bold" color="gray.700">Subject:</Text>
              <Text mt={1}>{title || data?.call?.title || 'No Subject'}</Text>
            </Box>
            
            {/* Email Metadata */}
            <Box mb={4}>
              <HStack spacing={4} alignItems="flex-start">
                <Box>
                  <Text fontWeight="bold" color="gray.700">From:</Text>
                  <Text>
                    {sender}
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.700">To:</Text>
                  <Text>
                    {recipients}
                  </Text>
                </Box>
              </HStack>
            </Box>

            {/* Email Body */}
            <Box>
              <Text fontWeight="bold" color="gray.700" mb={2}>Body:</Text>
              <Box 
                p={4} 
                bg="gray.50" 
                borderRadius="md"
                whiteSpace="pre-wrap"
              >
                {data?.call?.contentType === 'text/html' || data?.call?.body ? (
                  <Text dangerouslySetInnerHTML={{ __html: data?.call?.body || content }} />
                ) : content ? (
                  <Text>{content}</Text>
                ) : (
                  <Text color="gray.500">No content available</Text>
                )}
              </Box>
            </Box>
          </Box>

          {/* Reply Thread Section */}
          {(replies.length > 0 || detailedReplies.length > 0) && (
            <Box>
              {/* Replies Header */}
              <Button
                onClick={() => {
                  const newExpandedState = !isRepliesExpanded;
                  setIsRepliesExpanded(newExpandedState);
                  
                  // If expanding and we have replies but no detailed replies, fetch them
                  if (newExpandedState && id && replies.length > 0 && detailedReplies.length === 0) {
                    fetchReplies(id);
                  }
                }}
                width="100%"
                variant="outline"
                size="md"
                borderRadius="md"
                borderColor="gray.200"
                bg="white"
                _hover={{ bg: 'gray.50' }}
                rightIcon={
                  <Icon 
                    as={isRepliesExpanded ? ChevronUpIcon : ChevronDownIcon} 
                    transition="transform 0.2s"
                  />
                }
                position="relative"
                height="48px"
                mb={4}
              >
                <HStack width="100%" justify="space-between">
                  <HStack spacing={3}>
                    <Icon as={VscReply} color="blue.500" />
                    <Text 
                      fontSize="sm" 
                      fontWeight="semibold"
                      color="gray.700"
                      letterSpacing="wide"
                    >
                      {isRepliesExpanded ? 'Hide Replies' : 'View Replies'}
                    </Text>
                    <Badge
                      colorScheme="blue"
                      variant="solid"
                      borderRadius="full"
                      fontSize="xs"
                    >
                      {detailedReplies.length > 0 ? detailedReplies.length : replies.length}
                    </Badge>
                  </HStack>
                </HStack>
              </Button>

              <Collapse in={isRepliesExpanded} animateOpacity>
                <VStack align="stretch" spacing={4} pl={12} position="relative">
                  {isLoadingReplies ? (
                    <Flex justify="center" py={4}>
                      <Spinner color="blue.500" />
                    </Flex>
                  ) : detailedReplies.length > 0 ? (
                    <>
                      {/* Thread line */}
                      <Box 
                        position="absolute" 
                        left="24px" 
                        top="0" 
                        bottom="0" 
                        width="2px" 
                        bg="blue.100"
                        zIndex={0}
                      />
                      
                      {detailedReplies.map((reply, index) => (
                        <Box
                          key={reply.id || index}
                          position="relative"
                          pl={8}
                        >
                          {/* Reply connector */}
                          <Box
                            position="absolute"
                            left="-4px"
                            top="24px"
                            width="28px"
                            height="2px"
                            bg="blue.100"
                          />
                          
                          {/* Reply indicator */}
                          <Box
                            position="absolute"
                            left="-8px"
                            top="20px"
                            bg="white"
                            borderRadius="full"
                            p={1}
                            zIndex={1}
                          >
                            <Icon 
                              as={VscReply} 
                              boxSize={4} 
                              color="blue.400"
                              transform="rotate(180deg)"
                            />
                          </Box>

                          {/* Reply content */}
                          <Box
                            bg="white"
                            borderRadius="lg"
                            borderWidth="1px"
                            borderColor="gray.200"
                            p={6}
                            _hover={{ 
                              boxShadow: 'sm',
                              borderColor: 'blue.200' 
                            }}
                            transition="all 0.2s"
                          >
                            {/* Reply metadata */}
                            <HStack 
                              spacing={6} 
                              mb={4} 
                              pb={4}
                              borderBottomWidth="1px"
                              borderColor="gray.100"
                            >
                              <Box>
                                <Text 
                                  fontSize="sm" 
                                  color="gray.500" 
                                  mb={1}
                                >
                                  From
                                </Text>
                                <Text fontWeight="medium">
                                  {reply.from?.emailAddress?.name && reply.from?.emailAddress?.address ? 
                                    `${reply.from.emailAddress.name} (${reply.from.emailAddress.address})` : 
                                    reply.metadata?.from?.emailAddress?.address && reply.metadata?.from?.emailAddress?.name ? 
                                      `${reply.metadata.from.emailAddress.name} (${reply.metadata.from.emailAddress.address})` : 
                                      reply.sender?.name && reply.sender?.email ?
                                        `${reply.sender.name} (${reply.sender.email})` :
                                        'Unknown sender'}
                                </Text>
                              </Box>
                              <Box>
                                <Text 
                                  fontSize="sm" 
                                  color="gray.500" 
                                  mb={1}
                                >
                                  Date
                                </Text>
                                <Text fontWeight="medium">
                                  {formatDate(reply.receivedAt || reply.date)}
                                </Text>
                              </Box>
                            </HStack>

                            {/* Reply body */}
                            <Box 
                              p={4} 
                              bg="gray.50" 
                              borderRadius="md"
                              whiteSpace="pre-wrap"
                              fontSize="sm"
                              color="gray.700"
                            >
                              {reply?.metadata?.body?.contentType === 'text/html' || reply?.metadata?.body?.content ? (
                                <Text dangerouslySetInnerHTML={{ __html: reply?.metadata?.body?.content || reply?.metadata?.content }} />
                              ) : (
                                <Text>{reply?.metadata?.content || 'No content available'}</Text>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </>
                  ) : replies.length > 0 ? (
                    <>
                      {/* Thread line */}
                      <Box 
                        position="absolute" 
                        left="24px" 
                        top="0" 
                        bottom="0" 
                        width="2px" 
                        bg="blue.100"
                        zIndex={0}
                      />
                      
                      {replies.map((reply, index) => (
                        <Box
                          key={reply.id || index}
                          position="relative"
                          pl={8}
                        >
                          {/* Reply connector */}
                          <Box
                            position="absolute"
                            left="-4px"
                            top="24px"
                            width="28px"
                            height="2px"
                            bg="blue.100"
                          />
                          
                          {/* Reply indicator */}
                          <Box
                            position="absolute"
                            left="-8px"
                            top="20px"
                            bg="white"
                            borderRadius="full"
                            p={1}
                            zIndex={1}
                          >
                            <Icon 
                              as={VscReply} 
                              boxSize={4} 
                              color="blue.400"
                              transform="rotate(180deg)"
                            />
                          </Box>

                          {/* Reply content */}
                          <Box
                            bg="white"
                            borderRadius="lg"
                            borderWidth="1px"
                            borderColor="gray.200"
                            p={6}
                            _hover={{ 
                              boxShadow: 'sm',
                              borderColor: 'blue.200' 
                            }}
                            transition="all 0.2s"
                          >
                            {/* Reply metadata */}
                            <HStack 
                              spacing={6} 
                              mb={4} 
                              pb={4}
                              borderBottomWidth="1px"
                              borderColor="gray.100"
                            >
                              <Box>
                                <Text 
                                  fontSize="sm" 
                                  color="gray.500" 
                                  mb={1}
                                >
                                  From
                                </Text>
                                <Text fontWeight="medium">
                                  {reply.from?.emailAddress?.name && reply.from?.emailAddress?.address ? 
                                    `${reply.from.emailAddress.name} (${reply.from.emailAddress.address})` : 
                                    reply.metadata?.from?.emailAddress?.address && reply.metadata?.from?.emailAddress?.name ? 
                                      `${reply.metadata.from.emailAddress.name} (${reply.metadata.from.emailAddress.address})` : 
                                      reply.sender?.name && reply.sender?.email ?
                                        `${reply.sender.name} (${reply.sender.email})` :
                                        'Unknown sender'}
                                </Text>
                              </Box>
                              <Box>
                                <Text 
                                  fontSize="sm" 
                                  color="gray.500" 
                                  mb={1}
                                >
                                  Date
                                </Text>
                                <Text fontWeight="medium">
                                  {formatDate(reply.receivedAt || reply.date)}
                                </Text>
                              </Box>
                            </HStack>

                            {/* Reply body */}
                            <Box 
                              p={4} 
                              bg="gray.50" 
                              borderRadius="md"
                              whiteSpace="pre-wrap"
                              fontSize="sm"
                              color="gray.700"
                            >
                              {reply.contentType === 'text/html' || reply.body ? (
                                <Text dangerouslySetInnerHTML={{ __html: reply.body || reply.content }} />
                              ) : (
                                <Text>{reply.content || 'No content available'}</Text>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Text 
                      color="gray.500" 
                      textAlign="center" 
                      py={6}
                      bg="gray.50"
                      borderRadius="md"
                    >
                      No replies in this thread
                    </Text>
                  )}
                </VStack>
              </Collapse>
            </Box>
          )}
        </VStack>
      </VStack>

      {/* Add AssignClientModal */}
      <AssignClientModal
        isClientAssignModalOpen={isClientAssignModalOpen}
        setIsClientAssignModalOpen={setIsClientAssignModalOpen}
        clients={clients}
        setClientName={(name) => {
          // Find the client by formatted name
          const selectedClient = clients.find(client => formatPersonName(client) === name);
          if (selectedClient) {
            handleClientSelect(selectedClient);
          }
        }}
      />
    </Box>
  );
};

export default EmailContentPanel; 