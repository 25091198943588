import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import {
  Box,
  Text,
  HStack,
  Button,
  Highlight,
  Tooltip
} from '@chakra-ui/react';
import { formatTime } from '../utils/audioUtils';

/**
 * Component for displaying a transcription entry
 * Implemented as a functional component with hooks
 */
const TranscriptionEntry = (props) => {
  const {
    entry,
    isCurrentEntry,
    onTimestampClick,
    isEditing,
    searchTerm,
    groupIndex,
    entryIndex,
    onTextChange
  } = props;

  // State hooks
  const [editedText, setEditedText] = useState(entry.text || '');
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  
  // Refs
  const textRef = useRef(null);
  const resizeObserverRef = useRef(null);

  // Check if text is truncated and needs "Show More" button
  const checkTextTruncation = useCallback(() => {
    if (textRef.current) {
      const isTruncated = textRef.current.scrollHeight > textRef.current.clientHeight;
      setIsTruncated(isTruncated);
    }
  }, []);

  // Setup ResizeObserver on mount
  useEffect(() => {
    checkTextTruncation();
    
    // Use ResizeObserver instead of checking on every update
    if (window.ResizeObserver) {
      resizeObserverRef.current = new ResizeObserver(checkTextTruncation);
      if (textRef.current) {
        resizeObserverRef.current.observe(textRef.current);
      }
    }
    
    // Cleanup on unmount
    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [checkTextTruncation]);

  // Update local state when entry changes
  useEffect(() => {
    setEditedText(entry.text || '');
    
    // Only check truncation if ResizeObserver is not available
    if (!resizeObserverRef.current) {
      checkTextTruncation();
    }
  }, [entry.text, checkTextTruncation]);

  // Toggle expanded state for long text
  const toggleExpanded = useCallback((e) => {
    e.stopPropagation();
    setIsExpanded(prev => !prev);
  }, []);

  // Handle text changes in edit mode
  const handleTextChange = useCallback((e) => {
    const newText = e.target.value;
    setEditedText(newText);
    
    onTextChange(groupIndex, entryIndex, {
      ...entry,
      text: newText
    });
  }, [entry, groupIndex, entryIndex, onTextChange]);

  // Memoize highlighted text to prevent unnecessary re-renders
  const getHighlightedAndLineBreakedText = useCallback(() => {
    if (!entry.text) return null;
    
    // If no search term, just add line breaks
    if (!searchTerm) {
      return entry.text.split('\n').map((line, i) => (
        <React.Fragment key={i}>
          {line}
          {i < entry.text.split('\n').length - 1 && <br />}
        </React.Fragment>
      ));
    }
    
    // If there's a search term, use Chakra UI's Highlight component
    return entry.text.split('\n').map((line, i) => (
      <React.Fragment key={i}>
        <Highlight
          query={searchTerm}
          styles={{ bg: 'blue.50', px: '1', py: '0.5', rounded: 'sm' }}
        >
          {line}
        </Highlight>
        {i < entry.text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  }, [entry.text, searchTerm]);

  // Use static colors instead of hooks
  const highlightBg = 'blue.50';
  const currentEntryBg = 'yellow.50';
  const timestampColor = 'blue.600';
  
  // Max height for collapsed text
  const maxHeight = isExpanded ? 'none' : '120px';
  
  return (
    <Box
      p={2}
      borderRadius="md"
      bg={isCurrentEntry ? currentEntryBg : 'transparent'}
      mb={2}
      position="relative"
    >
      <HStack spacing={3} align="flex-start">
        <Tooltip label="Jump to this timestamp" placement="top">
          <Button
            size="xs"
            variant="ghost"
            color={'#00417D'}
            onClick={() => onTimestampClick(entry.start)}
            fontFamily="monospace"
            p={1}
            minW="60px"

          >
            {formatTime(entry.start)}
          </Button>
        </Tooltip>
        
        <Box flex="1">
          {isEditing ? (
            <textarea
              value={editedText}
              onChange={handleTextChange}
              style={{
                width: '100%',
                minHeight: '80px',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #E2E8F0'
              }}
            />
          ) : (
            <Box>
              <Text 
                lineHeight="1.6"
                overflow={'visible'}
                position="relative"
                ref={textRef}
                sx={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap'
                }}
              >
                {getHighlightedAndLineBreakedText()}
              </Text>
              
            </Box>
          )}
        </Box>
      </HStack>
    </Box>
  );
};

export default TranscriptionEntry; 