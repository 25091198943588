import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Text,
    VStack,
    HStack,
    IconButton,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import { FaDownload, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { getLatestRecording } from '../../../utils/indexedDB';
import { useUser } from '../../../contexts/UserContext';

const RecordingDownloadPanel = ({ recordingInfo, onDismiss }) => {
    const [isMinimized, setIsMinimized] = useState(false);
    const [sizeWarning, setSizeWarning] = useState(null);
    const [downloadError, setDownloadError] = useState(null);
    const { user } = useUser();

    useEffect(() => {
        if (recordingInfo?.sizeMB) {
            // Warning if file is larger than 1MB (change to 1000MB in production)
            if (recordingInfo.sizeMB > 1000) {
                setSizeWarning("Large file size might cause download issues");
            }
        }
    }, [recordingInfo]);

    if (!recordingInfo || !user) return null;

    const handleDownload = async () => {
        try {
            setDownloadError(null);
            if (!recordingInfo.url) {
                // If URL is not available, try to get the recording from IndexedDB
                const recording = await getLatestRecording(user.id);
                if (recording) {
                    const url = URL.createObjectURL(recording.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = `${recording.name}.mp3`;
                    link.click();
                    URL.revokeObjectURL(url);
                } else {
                    setDownloadError('Recording not found in storage');
                }
            } else {
                const link = document.createElement('a');
                link.href = recordingInfo.url;
                link.download = `${recordingInfo.name}.mp3`;
                link.click();
            }
        } catch (err) {
            console.error("Download error:", err);
            setDownloadError("Failed to download recording. Please try again.");
            // Don't throw, just show error and continue
        }
    };

    const formatFileSize = (sizeMB) => {
        if (sizeMB < 1) {
            return `${(sizeMB * 1024).toFixed(1)} KB`;
        }
        return `${sizeMB.toFixed(1)} MB`;
    };

    return (
        <Box
            position="fixed"
            bottom="4"
            right="4"
            bg="white"
            boxShadow="lg"
            borderRadius="md"
            borderWidth="1px"
            maxW="sm"
            zIndex={1000}
            transition="all 0.2s"
        >
            <HStack 
                justify="space-between" 
                p={2} 
                borderBottomWidth={isMinimized ? "0" : "1px"}
                cursor="pointer"
                onClick={() => setIsMinimized(!isMinimized)}
                _hover={{ bg: "gray.50" }}
            >
                <Text fontWeight="bold" ml={2}> Last Recording </Text>
                <IconButton
                    icon={isMinimized ? <FaChevronUp /> : <FaChevronDown />}
                    size="sm"
                    variant="ghost"
                    aria-label={isMinimized ? "Expand" : "Minimize"}
                />
            </HStack>

            {!isMinimized && (
                <VStack align="stretch" spacing={3} p={4}>
                    <Text fontSize="sm">
                        Title: {recordingInfo.name}
                        <br />
                        Date: {new Date(recordingInfo.timestamp).toLocaleString()}
                        {recordingInfo.sizeMB && (
                            <>
                                <br />
                                Size: {formatFileSize(recordingInfo.sizeMB)}
                            </>
                        )}
                    </Text>
                    
                    {sizeWarning && (
                        <Alert status="warning" size="sm">
                            <AlertIcon />
                            {sizeWarning}
                        </Alert>
                    )}

                    {downloadError && (
                        <Alert status="error" size="sm">
                            <AlertIcon />
                            {downloadError}
                        </Alert>
                    )}

                    <Button
                        leftIcon={<FaDownload />}
                        colorScheme="blue"
                        size="sm"
                        onClick={handleDownload}
                        isDisabled={!!downloadError}
                    >
                        Download Recording
                    </Button>
                </VStack>
            )}
        </Box>
    );
};

export default RecordingDownloadPanel; 