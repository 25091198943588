import React from 'react';
import { 
  Badge, Box, HStack, Link, Text, VStack, Icon, Spinner 
} from '@chakra-ui/react';
import { FaRobot } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import MessageFeedback from '../../components/MessageFeedback';


const determineLink = (citation) => {
  if (citation.sourceId && citation.type) {
    if (citation.type === 'call' || citation.type === 'email') {
      return `/transcripts/${citation.sourceId}`;
    } else if (citation.type === 'client') {
      return `/clients/${citation.sourceId}`;
    }
  }
  
  if (citation.description) {
    const match = citation.description.match(/(call|email|client):\s*([a-f0-9-]+)/i);
    if (match) {
      const [, type, id] = match;
      
      if (type.toLowerCase() === 'call' || type.toLowerCase() === 'email') {
        return `/transcripts/${id}`;
      } else if (type.toLowerCase() === 'client') {
        return `/clients/${id}`;
      }
    }
  }
  
  return '#';
};

const MessageDisplay = ({ 
  messages, 
  isInitialLoading, 
  messagesEndRef,
  emptyStateMessage 
}) => {
  if (isInitialLoading) {
    return (
      <VStack spacing={4} justify="center" height="100%">
        <Spinner size="xl" color="blue.500" />
        <Text color="gray.500">Loading conversation...</Text>
      </VStack>
    );
  }
  
  if (messages.length === 0) {
    return (
      <VStack spacing={4} justify="center" height="100%">
        <Icon as={FaRobot} boxSize={12} color="blue.200" />
        <Text color="gray.500" textAlign="center">
          {emptyStateMessage || (
            <>
              Start a conversation with DataDasher.<br />
              Ask any question about your organization, clients, or meetings.
            </>
          )}
        </Text>
      </VStack>
    );
  }
  
  return (
    <VStack spacing={4} align="stretch">
      {messages.map((message, index) => (
        <HStack 
          key={index} 
          alignSelf={message.type === 'user' ? 'flex-end' : 'flex-start'}
          bg={message.type === 'user' ? 'blue.50' : 'transparent'}
          p={3}
          borderRadius="md"
          maxW="80%"
          borderWidth={message.type === 'assistant' ? '0px' : '1px'}
          borderColor={message.type === 'user' ? 'blue.100' : 'transparent'}
        >
          <VStack align="start" spacing={1}>
            <Text fontWeight="bold">
              {message.type === 'user' ? 'You' : 'DataDasher'}
            </Text>
            <Box
              dangerouslySetInnerHTML={{ __html: message.content }}
              sx={{
                'p': { mb: 2 },
                'ul, ol': { mb: 2, pl: 6 },
                'li': { mb: 1 },
              }}
            />
            {message.citations && message.citations.length > 0 && (
              <Box fontSize="sm" color="gray.600" mt={2}>
                <Text fontWeight="medium">Sources:</Text>
                {message.citations.map((citation, citIndex) => (
                  <HStack key={citIndex} spacing={2} mt={1}>
                    <Text>[{citIndex + 1}]</Text>
                    <Link
                      as={RouterLink}
                      to={determineLink(citation)}
                      color="blue.500"
                      _hover={{ textDecoration: 'underline' }}
                    >
                      {citation.description || citation.text || `Citation ${citIndex + 1}`}
                    </Link>
                    {citation.type && (
                      <Badge
                        size="sm"
                        colorScheme={
                          citation.type.toLowerCase() === 'email' ? 'blue' :
                          citation.type.toLowerCase() === 'call' ? 'green' :
                          citation.type.toLowerCase() === 'client' ? 'purple' : 'gray'
                        }
                      >
                        {citation.type}
                      </Badge>
                    )}
                  </HStack>
                ))}
              </Box>
            )}
            
            {message.type === 'assistant' && message.messageId && (
              <MessageFeedback messageId={message.messageId} />
            )}
          </VStack>
        </HStack>
      ))}
      <div ref={messagesEndRef} />
    </VStack>
  );
};

export default MessageDisplay;
