import React from 'react';
import {
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Container,
  useColorModeValue,
  Link
} from '@chakra-ui/react';
import { useUser } from '../contexts/UserContext';
import axios from 'axios';
import Cookies from 'js-cookie';
import { format } from 'date-fns';

const SubscriptionPausedOverlay = () => {
  const { user } = useUser();
  const serverUrl = process.env.REACT_APP_API_URL;
  
  const pausedUntil = user?.organization?.subscriptionDetails?.pausedUntil
    ? new Date(user.organization.subscriptionDetails.pausedUntil)
    : null;
    
  const formattedDate = pausedUntil 
    ? format(pausedUntil, 'MMMM d, yyyy') 
    : 'Unknown date';
    
  const handleReactivateSubscription = async () => {
    try {
      const response = await axios.post(
        `${serverUrl}/stripe/reactivate-subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`,
          },
        }
      );
      
      if (response.data && response.data.success) {
        // Refresh the page to update the user context
        window.location.reload();
      }
    } catch (error) {
      console.error('Error reactivating subscription:', error);
      // Redirect to billing portal as fallback
      window.location.href = '/settings';
    }
  };

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="rgba(0, 0, 0, 0.85)"
      zIndex="9999"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Container maxW="md">
        <Box
          bg={useColorModeValue('white', 'gray.800')}
          p={8}
          borderRadius="lg"
          boxShadow="xl"
          textAlign="center"
        >
          <VStack spacing={6}>
            <Heading size="xl" color="red.500">
              Subscription Paused
            </Heading>
            
            <Text fontSize="lg">
              Your subscription is currently paused and will resume on <strong>{formattedDate}</strong>.
            </Text>
            
            <Text>
              During this time, you won't have access to the platform's features.
              You can reactivate your subscription at any time.
            </Text>
            
            <Button
              colorScheme="blue"
              size="lg"
              onClick={handleReactivateSubscription}
              width="full"
            >
              Reactivate My Subscription
            </Button>
            
            <Text fontSize="sm" color="gray.500">
              Need help? <Link color="blue.500" href="mailto:support@datadasher.com">Contact support</Link>
            </Text>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
};

export default SubscriptionPausedOverlay; 