import React, { useRef, useEffect } from 'react';
import {
  Box, VStack, Input, Button, Flex, Spinner, 
  InputGroup, InputRightElement, IconButton, Text,
  Heading, HStack, Tooltip
} from '@chakra-ui/react';
import { ArrowUpIcon, DeleteIcon } from '@chakra-ui/icons';
import MessageDisplay from '../../../../pages/AskDatadasher/MessageDisplay';

const ChatPanel = ({
  chatHistory,
  inputValue,
  setInputValue,
  handleAskQuestion,
  loadingChat,
  isLoadingHistory,
  clearConversation
}) => {
  const messagesEndRef = useRef(null);
  
  // Scroll to bottom when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAskQuestion();
    }
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <HStack justify="space-between" mb={4}>
        <Heading size="md">Ask about this client</Heading>
        {chatHistory.length > 0 && (
          <Tooltip label="Clear conversation">
            <IconButton
              icon={<DeleteIcon />}
              variant="ghost"
              colorScheme="red"
              onClick={clearConversation}
              aria-label="Clear conversation"
              size="sm"
            />
          </Tooltip>
        )}
      </HStack>
      
      <Box flex="1" overflowY="auto" mb={4}>
        <MessageDisplay 
          messages={chatHistory}
          isInitialLoading={isLoadingHistory}
          messagesEndRef={messagesEndRef}
          emptyStateMessage={
            <>
              Start a conversation about this client.
              Ask questions about their portfolio, preferences, or previous interactions.
            </>
          }
        />
      </Box>

      <Box mt="auto" pt={4} pb={2} borderTopWidth="1px" borderColor="gray.200">
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            placeholder="Ask about this client..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyPress}
            disabled={loadingChat}
          />
          <InputRightElement width="4.5rem">
            <IconButton
              h="1.75rem"
              size="sm"
              icon={<ArrowUpIcon />}
              colorScheme="blue"
              onClick={handleAskQuestion}
              isLoading={loadingChat}
              disabled={!inputValue.trim()}
              aria-label="Send message"
            />
          </InputRightElement>
        </InputGroup>
      </Box>
    </Box>
  );
};

export default ChatPanel; 