import React, { useState, useEffect } from 'react';
import { 
  Box, Grid, useToast, Tabs, TabList, TabPanels, TabPanel, Tab,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
  Skeleton, SkeletonText, HStack, VStack, Heading, IconButton, Text,
  Button, Menu, MenuButton, MenuList, MenuItem
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useEmailCorrespondence from '../../../hooks/useEmailCorrespondence';
import { useUser } from '../../../contexts/UserContext';
import { useOrganizationUsers } from '../../../contexts/OrganizationUsersContext';
import { useOrganizationClients } from '../../../contexts/OrganizationClientsContext';
import { MdFullscreen, MdFullscreenExit, MdArrowBack, MdPerson } from 'react-icons/md';
import { ChevronDownIcon } from '@chakra-ui/icons';

import EmailContentPanel from './correspondence/EmailContentPanel';
import SummaryPanel from './correspondence/SummaryPanel';
import ActionItemsPanel from './correspondence/ActionItemsPanel';
import InsightsPanel from './correspondence/InsightsPanel';
import ChatPanel from './correspondence/ChatPanel';
import FollowUpEmail from './components/FollowUpEmail';

/**
 * Container component for the email correspondence page
 */
const EmailCorrespondenceContainer = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useUser();
  const { users } = useOrganizationUsers();
  const { clients } = useOrganizationClients();
  
  // State from useEmailCorrespondence hook
  const {
    emailData,
    actionItems,
    summary,
    aiInsights,
    isLoading,
    isLoadingActionItems,
    isLoadingInsights,
    isLoadingQuestions,
    isEditingTitle,
    isEditingSummary,
    handleEditTitle,
    handleCancelEditTitle,
    handleSaveTitle,
    handleEditSummary,
    handleCancelEditSummary,
    handleSaveSummary,
    handleApproveActionItem,
    handleRejectActionItem,
    handleAssignActionItem,
    handleDateChange,
    handleSendMessage,
    handleAskAboutSubmit,
    fetchData,
    questions,
    crm,
    replies,
    isLoadingReplies
  } = useEmailCorrespondence();

  // Extract specific data from emailData for better organization
  const id = emailData?.id;
  const title = emailData?.subject;
  const content = emailData?.body;
  const topics = emailData?.topics;
  const clientTodos = emailData?.clientTodos;
  const advisorTodos = emailData?.advisorTodos;
  const date = emailData?.receivedAt;
  const sender = emailData?.metadata?.from ? `${emailData?.metadata?.from?.emailAddress?.name} (${emailData?.metadata?.from?.emailAddress?.address})` : 'Unknown sender';
  const recipients = emailData?.metadata?.toRecipients ? emailData?.metadata?.toRecipients.map(recipient => recipient.emailAddress.address) : 'Unknown recipients';
  const attachments = emailData?.attachments || [];

  // Extract more specific data from emailData for better organization
  const stats = emailData?.call?.stats;
  const type = emailData?.call?.type || 'email';
  const metadata = emailData?.call?.metadata;

  // UI state
  const [activeTab, setActiveTab] = useState(0);
  const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [isLeftExpanded, setIsLeftExpanded] = useState(false);
  const [isRightExpanded, setIsRightExpanded] = useState(false);
  const [leftPanelWidth, setLeftPanelWidth] = useState(50);
  const [isDragging, setIsDragging] = useState(false);

  // Handle tab change
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  // Navigate back
  const handleNavigateBack = () => {
    navigate(-1);
  };

  // Handle client assignment
  const handleAssignClient = (clientId) => {
    // Implement client assignment logic here
    toast({
      title: "Client assigned",
      description: `Email assigned to client ID: ${clientId}`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  // Toggle follow-up modal
  const handleToggleFollowUpModal = () => {
    setIsFollowUpModalOpen(!isFollowUpModalOpen);
  };

  // Toggle tasks modal
  const handleToggleTasksModal = () => {
    setIsTasksModalOpen(!isTasksModalOpen);
  };

  // Drag handlers for resizer
  const handleDragStart = () => {
    setIsDragging(true);
    document.body.style.cursor = 'col-resize';
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    document.body.style.cursor = 'default';
  };

  const handleDrag = (e) => {
    if (!isDragging) return;

    const container = e.currentTarget.parentElement;
    const containerRect = container.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const offsetX = e.clientX - containerRect.left;
    const newWidth = (offsetX / containerWidth) * 100;

    // Limit the resize range (20% to 80%)
    if (newWidth >= 20 && newWidth <= 80) {
      setLeftPanelWidth(newWidth);
    }
  };

  // Skeleton components for loading states
  const EmailContentSkeleton = () => (
    <Box
      gridColumn="1 / 2"
      bg="white"
      position="relative"
      transition="width 0.1s ease"
      overflow="hidden"
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box
        p={4}
        bg="gray.50"
        borderColor="gray.200"
      >
        <HStack justify="space-between" align="center">
          <HStack spacing={4} align="center">
            <Skeleton height="32px" width="32px" borderRadius="md" />
            <VStack align="start" spacing={1}>
              <Skeleton height="24px" width="200px" />
              <HStack spacing={4}>
                <Skeleton height="16px" width="150px" />
                <Skeleton height="16px" width="120px" />
              </HStack>
            </VStack>
          </HStack>
          <Skeleton height="32px" width="32px" borderRadius="md" />
        </HStack>
      </Box>
      <Box flex="1" overflow="auto" p={4}>
        <Skeleton height="40px" width="70%" mb={4} />
        <SkeletonText mt={2} noOfLines={1} spacing="4" width="30%" />
        <Box mt={6}>
          <Skeleton height="20px" width="100%" mb={2} />
          <Skeleton height="20px" width="90%" mb={2} />
          <Skeleton height="20px" width="95%" mb={2} />
          <Skeleton height="20px" width="85%" mb={2} />
          <Skeleton height="20px" width="92%" mb={2} />
        </Box>
      </Box>
    </Box>
  );

  const SummarySkeleton = () => (
    <Box>
      <Skeleton height="40px" width="60%" mb={4} />
      <SkeletonText mt={4} noOfLines={6} spacing="4" />
      <Box mt={8}>
        <Skeleton height="30px" width="40%" mb={4} />
        <Box mt={4}>
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} height="30px" width="90%" mb={3} />
          ))}
        </Box>
      </Box>
    </Box>
  );

  const ActionItemsSkeleton = () => (
    <Box>
      <Skeleton height="30px" width="40%" mb={4} />
      {[...Array(3)].map((_, i) => (
        <Box key={i} p={4} mb={4} borderWidth="1px" borderRadius="md">
          <Skeleton height="24px" width="80%" mb={3} />
          <HStack spacing={4} mt={3}>
            <Skeleton height="36px" width="120px" />
            <Skeleton height="36px" width="120px" />
            <Skeleton height="36px" width="100px" />
          </HStack>
        </Box>
      ))}
    </Box>
  );

  const InsightsSkeleton = () => (
    <Box>
      <Skeleton height="30px" width="40%" mb={4} />
      {[...Array(4)].map((_, i) => (
        <Box key={i} p={4} mb={4} borderWidth="1px" borderRadius="md">
          <Skeleton height="24px" width="60%" mb={3} />
          <SkeletonText mt={2} noOfLines={2} spacing="4" />
        </Box>
      ))}
    </Box>
  );

  const ChatSkeleton = () => (
    <Box>
      <Skeleton height="30px" width="40%" mb={4} />
      <Box mt={4} mb={8}>
        {[...Array(3)].map((_, i) => (
          <Box 
            key={i} 
            p={4} 
            mb={4} 
            borderWidth="1px" 
            borderRadius="md"
            alignSelf={i % 2 === 0 ? "flex-end" : "flex-start"}
            maxW="80%"
            ml={i % 2 === 0 ? "auto" : 0}
          >
            <Skeleton height="20px" width="100px" mb={2} />
            <SkeletonText mt={2} noOfLines={2} spacing="4" />
          </Box>
        ))}
      </Box>
      <Skeleton height="50px" width="100%" mt={4} />
    </Box>
  );

  // If the entire page is loading, show a full-page skeleton
  if (isLoading && !emailData) {
    return (
      <Box position="relative" bg="gray.50" minH="100vh">
        <Box p={0} height="100vh" overflow="hidden">
          <Grid
            templateColumns="1fr 8px 1fr"
            gap={0}
            height="100%"
            maxHeight="calc(100vh - 82px)"
            overflowY="hidden"
            bg="#F5F5F5"
          >
            <EmailContentSkeleton />
            
            <Box
              gridColumn="2 / 3"
              bg="gray.100"
              position="relative"
            >
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="4px"
                height="32px"
                borderRadius="full"
                bg="gray.300"
              />
            </Box>
            
            <Box
              gridColumn="3 / -1"
              bg="white"
              position="relative"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              <Box
                p={4}
                bg="gray.50"
                borderColor="gray.200"
              >
                <HStack justify="space-between">
                  <Heading size="md">Email Details</Heading>
                  <Skeleton height="32px" width="32px" borderRadius="md" />
                </HStack>
              </Box>
              <Box p={4}>
                <SummarySkeleton />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  return (
    <Box position="relative" bg="gray.50" height="100vh" overflow="hidden" p={0}>
      <Box p={0} height="100vh" overflow="hidden">
        <Grid
          templateColumns={
            isLeftExpanded
              ? "1fr"
              : isRightExpanded
                ? "1fr"
                : `${leftPanelWidth}% 8px calc(${100 - leftPanelWidth}% - 8px)`
          }
          gap={0}
          height="100%"
          maxHeight="calc(100vh - 42px)"
          overflowY="hidden"
          bg="#F5F5F5"
          onMouseMove={handleDrag}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
        >
          {/* Left panel - Email Content */}
          {isLoading ? (
            <EmailContentSkeleton />
          ) : (
            <Box
              gridColumn={isLeftExpanded ? "1 / -1" : "1 / 2"}
              bg="white"
              position="relative"
              transition="width 0.1s ease"
              overflow="hidden"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              <Box flex="1" overflow="auto">
                <EmailContentPanel 
                  data={emailData}
                  id={id}
                  title={title}
                  content={content}
                  sender={sender}
                  recipients={recipients}
                  date={date}
                  attachments={attachments}
                  replies={replies}
                  isLeftExpanded={isLeftExpanded}
                  setIsLeftExpanded={setIsLeftExpanded}
                  isRightExpanded={isRightExpanded}
                />
              </Box>
            </Box>
          )}

          {/* Resizer */}
          {!isLeftExpanded && !isRightExpanded && (
            <Box
              gridColumn="2 / 3"
              bg="gray.100"
              cursor="col-resize"
              onMouseDown={handleDragStart}
              position="relative"
              _hover={{ bg: "blue.100" }}
              transition="background 0.2s"
            >
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="4px"
                height="32px"
                borderRadius="full"
                bg={isDragging ? "blue.500" : "gray.300"}
                transition="background 0.2s"
              />
            </Box>
          )}

          {/* Right panel - Tabs */}
          {!isLeftExpanded && (
            <Box
              gridColumn={isRightExpanded ? "1 / -1" : "3 / -1"}
              bg="white"
              position="relative"
              transition="width 0.1s ease"
              overflow="hidden"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              <Box
                p={4}
                bg="gray.50"
                borderColor="gray.200"
              >
                <HStack justify="space-between">
                  <Heading size="md" color="#00417D">Email Details</Heading>
                  <IconButton
                    icon={isRightExpanded ? <MdFullscreenExit /> : <MdFullscreen />}
                    onClick={() => {
                      setIsRightExpanded(!isRightExpanded);
                      if (!isRightExpanded) {
                        setIsLeftExpanded(false);
                      }
                    }}
                    aria-label="Toggle fullscreen"
                  />
                </HStack>
              </Box>
              <Tabs 
                index={activeTab} 
                onChange={handleTabChange} 
                variant="enclosed" 
                display="flex" 
                flexDirection="column" 
                height="calc(100% - 72px)" 
                overflow="hidden"
              >
                <TabList>
                  <Tab>Overview</Tab>
                  <Tab>Action Items</Tab>
                  <Tab>Follow-Up Email</Tab>
                </TabList>

                <TabPanels flex="1" overflow="hidden">
                  {/* Summary Tab */}
                  <TabPanel height="100%" overflow="hidden">
                    {isLoading ? (
                      <SummarySkeleton />
                    ) : (
                      <SummaryPanel 
                        data={emailData}
                        id={id}
                        title={title}
                        summary={summary}
                        type={'email'}
                        isEditingTitle={isEditingTitle}
                        isEditingSummary={isEditingSummary}
                        handleEditTitle={handleEditTitle}
                        handleCancelEditTitle={handleCancelEditTitle}
                        handleSaveTitle={handleSaveTitle}
                        handleEditSummary={handleEditSummary}
                        handleCancelEditSummary={handleCancelEditSummary}
                        handleSaveSummary={handleSaveSummary}
                        handleToggleFollowUpModal={handleToggleFollowUpModal}
                      />
                    )}
                  </TabPanel>

                  {/* Action Items Tab */}
                  <TabPanel height="100%" overflow="hidden">
                    {isLoadingActionItems ? (
                      <ActionItemsSkeleton />
                    ) : (
                      <ActionItemsPanel 
                        actionItems={actionItems}
                        id={id}
                        users={users}
                        handleApproveActionItem={handleApproveActionItem}
                        handleRejectActionItem={handleRejectActionItem}
                        handleAssignActionItem={handleAssignActionItem}
                        handleDateChange={handleDateChange}
                        handleToggleTasksModal={handleToggleTasksModal}
                        crm={crm}
                        data={emailData}
                      />
                    )}
                  </TabPanel>

                  {/* Follow-Up Email Tab */}
                  <TabPanel height="100%" overflow="hidden">
                    <FollowUpEmail 
                      callData={{call: emailData}}
                      callId={id}
                      callTitle={title}
                      callSummary={summary}
                      callClientTodos={clientTodos}
                      callAdvisorTodos={advisorTodos}
                      callClients={clients}
                      onClose={() => {}}
                      type={'email'}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          )}
        </Grid>
      </Box>

      {/* Follow-up Email Modal */}
      <Modal isOpen={isFollowUpModalOpen} onClose={handleToggleFollowUpModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Follow-up Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FollowUpEmail 
              data={emailData}
              id={id}
              title={title}
              summary={summary}
              clientTodos={clientTodos}
              advisorTodos={advisorTodos}
              clients={clients}
              onClose={handleToggleFollowUpModal} 
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Tasks Modal */}
      <Modal isOpen={isTasksModalOpen} onClose={handleToggleTasksModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Tasks</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <ActionItemsPanel 
              actionItems={actionItems}
              id={id}
              users={users}
              handleApproveActionItem={handleApproveActionItem}
              handleRejectActionItem={handleRejectActionItem}
              handleAssignActionItem={handleAssignActionItem}
              handleDateChange={handleDateChange}
              isModal={true}
              data={emailData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EmailCorrespondenceContainer; 