import React from 'react';
import {
  Box,
  Flex,
  IconButton,
  Progress,
  Text,
  Tooltip,
  Spinner,
  HStack
} from '@chakra-ui/react';
import { 
  FaPlay, 
  FaPause, 
  FaStepBackward, 
  FaStepForward,
  FaVolumeUp,
  FaVolumeMute
} from 'react-icons/fa';
import { ChevronLeftIcon, ChevronRightIcon, RepeatIcon } from '@chakra-ui/icons';
import { formatTime } from '../utils/audioUtils';
import { AudioPlayerSettings } from '../constants';

/**
 * Audio player component with buffering support
 */
const AudioPlayer = ({
  isPlaying,
  currentTime,
  duration,
  progress,
  isBuffering,
  onPlayPause,
  onSkip,
  onSeek,
  skipForwardSeconds = AudioPlayerSettings.SKIP_FORWARD_SECONDS,
  skipBackwardSeconds = AudioPlayerSettings.SKIP_BACKWARD_SECONDS
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [tooltipValue, setTooltipValue] = React.useState(0);
  const [isMuted, setIsMuted] = React.useState(false);
  const [volume, setVolume] = React.useState(100);
  const [prevVolume, setPrevVolume] = React.useState(100);
  const [hoverPosition, setHoverPosition] = React.useState(null);
  const progressContainerRef = React.useRef(null);
  
  // Handle volume change
  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  };
  
  // Toggle mute
  const toggleMute = () => {
    if (isMuted) {
      setVolume(prevVolume);
      setIsMuted(false);
    } else {
      setPrevVolume(volume);
      setVolume(0);
      setIsMuted(true);
    }
  };
  
  // Handle seek click for the Progress component
  const handleSeekClick = (e) => {
    const progressBar = e.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const percentClicked = (clickPosition / rect.width) * 100;
    onSeek(percentClicked);
  };

  // Handle mouse movement over progress bar
  const handleMouseMove = (e) => {
    if (!progressContainerRef.current) return;
    
    const rect = progressContainerRef.current.getBoundingClientRect();
    const mousePosition = e.clientX - rect.left;
    const percentHovered = (mousePosition / rect.width) * 100;
    setHoverPosition(Math.min(Math.max(percentHovered, 0), 100));
  };

  // Handle mouse leaving progress bar
  const handleMouseLeave = () => {
    setHoverPosition(null);
  };
  
  // Handle restart
  const handleRestart = () => {
    onSeek(0);
  };
  
  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bg="gray.100"
      color="blue.500"
      p={4}
      boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
      zIndex={999}
    >
      <Flex align="center" justify="space-between">
        <HStack spacing={4}>
          <IconButton
            icon={isBuffering ? <Spinner size="sm" /> : isPlaying ? <FaPause /> : <FaPlay />}
            onClick={onPlayPause}
            aria-label={isPlaying ? "Pause" : "Play"}
            colorScheme="blue"
            variant="ghost"
            size="lg"
            isDisabled={isBuffering}
          />
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={() => onSkip(-skipBackwardSeconds)}
            aria-label={`Rewind ${skipBackwardSeconds} seconds`}
            variant="ghost"
            isDisabled={isBuffering}
          />
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={() => onSkip(skipForwardSeconds)}
            aria-label={`Forward ${skipForwardSeconds} seconds`}
            variant="ghost"
            isDisabled={isBuffering}
          />
          <IconButton
            icon={<RepeatIcon />}
            onClick={handleRestart}
            aria-label="Restart"
            variant="ghost"
            isDisabled={isBuffering}
          />
        </HStack>

        {/* Progress bar container with hover effects */}
        <Box 
          position="relative" 
          flex={1} 
          mx={4}
          ref={progressContainerRef}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <Progress
            value={progress}
            size="sm"
            colorScheme="blue"
            bg="gray.300"
            onClick={handleSeekClick}
            sx={{ cursor: 'pointer' }}
            borderRadius="md"
            width="100%"
          />
          
          {/* Hover effect overlay - only visible when hovering */}
          {hoverPosition !== null && hoverPosition > progress && (
            <Box
              position="absolute"
              left={`calc(${progress}% - 4px)`}
              top={0}
              height="100%"
              width={`calc(${hoverPosition - progress}% + 4px)`}
              bg="gray.500"
              opacity={0.5}
              borderRadius="0 md md 0"
              zIndex={1}
              pointerEvents="none"
              sx={{
                '&': {
                  borderTopRightRadius: 'var(--chakra-radii-md)',
                  borderBottomRightRadius: 'var(--chakra-radii-md)'
                }
              }}
            />
          )}
        </Box>

        <HStack spacing={4}>
          <Text fontSize="sm">
            {formatTime(currentTime)} / {formatTime(duration)}
          </Text>
        </HStack>
      </Flex>
    </Box>
  );
};

export default AudioPlayer;