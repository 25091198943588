import React, { useEffect, useState } from 'react';
import {
  Box, Heading, Image, Grid, VStack, HStack, Button, useToast, Input, useBreakpointValue, Tooltip, Text, Switch, Icon,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Link, Wrap, WrapItem, SimpleGrid, Skeleton, SkeletonText} from '@chakra-ui/react';
import BlueBox from '../../BlueBox';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { FeatureStats } from '../../../enums/UsageStats';

import { FaUser, FaMapMarkerAlt, FaUsers } from 'react-icons/fa';

import { TimeIcon, PhoneIcon, EditIcon, InfoIcon, RepeatIcon } from '@chakra-ui/icons';

import { useOrganizationClients } from '../../../contexts/OrganizationClientsContext';

const formatDate = (dateString, timeZone) => {
  const localDate = new Date(dateString);
  const options = { 
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true,
      timeZone: timeZone
  };
  return localDate.toLocaleString('en-US', options);
};

export const fetchEvents = async (serverUrl, filterForToday = false) => {
  console.log('Fetching events with filterForToday:', filterForToday);
  try {
    const response = await axios.get(`${serverUrl}/api/events`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
      params: {
        filter: filterForToday ? 'today' : undefined
      }
    });
    console.log('Raw API Response:', response);
    console.log('Fetched Meetings:', response.data);
    
    // Log each event's start time for debugging
    if (response.data && Array.isArray(response.data)) {
      response.data.forEach(event => {
        console.log('Event:', event.subject, 'Start Time:', event.startTime);
      });
    }
    
    return response.data; 
    
  } catch (error) {
    console.error('Error fetching meetings:', error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
    }
    return [];
  }
};

const Meetings = () => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate(); 
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [isMicrosoftConnected, setIsMicrosoftConnected] = useState(false);
  const [isResyncing, setIsResyncing] = useState(false);

  const [webConferenceUrl, setWebConferenceUrl] = useState('');
  const [botId, setBotId] = useState(null); 
  const [clients, setClients] = useState([]);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const toast = useToast();

  // Check if user has Microsoft connected
  useEffect(() => {
    const checkConnections = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/user-connections`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        setIsMicrosoftConnected(response.data.some(conn => conn.name === 'Microsoft'));
      } catch (error) {
        console.error('Error checking connections:', error);
      }
    };
    checkConnections();
  }, [serverUrl]);

  const handleResync = async () => {
    try {
      setIsResyncing(true);
      const token = Cookies.get('jwtToken');
      if (!token) {
        throw new Error('No authentication token found');
      }
      await axios.post(`${serverUrl}/outlook/force-sync`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      toast({
        title: 'Calendar sync started',
        description: 'Your calendar events will be updated shortly. Refresh the page after a few minutes.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Refresh events after a short delay to allow sync to complete
      setTimeout(() => {
        fetchEvents(serverUrl).then(data => setEvents(data));
        setIsResyncing(false);
      }, 5000);
    } catch (error) {
      console.error('Error syncing calendar:', error);
      toast({
        title: 'Error syncing calendar',
        description: error.response?.data?.error || 'Failed to sync calendar',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsResyncing(false);
    }
  };

  // Example events data for testing UI
  const exampleEvents = [
    {
      id: "event-123",
      subject: "Client Strategy Meeting",
      startTime: "2023-12-15T14:00:00Z",
      endTime: "2023-12-15T15:00:00Z",
      webConfLink: "https://teams.microsoft.com/l/meetup-join/meeting_abc123",
      location: "",
      audioBotJoinStatus: true,
      botId: "bot-456",
      attendees: [
        {
          emailAddress: {
            address: "client@example.com",
            name: "Important Client"
          }
        },
        {
          emailAddress: {
            address: "you@yourcompany.com",
            name: "You"
          }
        }
      ]
    },
    {
      id: "event-124",
      subject: "Internal Team Sync",
      startTime: "2023-12-16T10:00:00Z",
      endTime: "2023-12-16T10:30:00Z",
      webConfLink: "https://zoom.us/j/123456789",
      location: "",
      audioBotJoinStatus: false,
      botId: null,
      attendees: [
        {
          emailAddress: {
            address: "teammate1@yourcompany.com",
            name: "Teammate 1"
          }
        },
        {
          emailAddress: {
            address: "teammate2@yourcompany.com",
            name: "Teammate 2"
          }
        }
      ]
    },
    {
      id: "event-125",
      subject: "In-Person Client Meeting",
      startTime: "2023-12-17T13:00:00Z",
      endTime: "2023-12-17T14:30:00Z",
      webConfLink: "",
      location: "Conference Room A, 123 Business St",
      audioBotJoinStatus: null,
      botId: null,
      attendees: [
        {
          emailAddress: {
            address: "vip-client@clientcompany.com",
            name: "VIP Client"
          }
        },
        {
          emailAddress: {
            address: "you@yourcompany.com",
            name: "You"
          }
        }
      ]
    },
    {
      id: "event-126",
      subject: "Project Kickoff with New Client",
      startTime: "2023-12-18T09:00:00Z",
      endTime: "2023-12-18T10:00:00Z",
      webConfLink: "https://meet.google.com/abc-defg-hij",
      location: "",
      audioBotJoinStatus: true,
      botId: "bot-789",
      attendees: [
        {
          emailAddress: {
            address: "new-client@newclientcorp.com",
            name: "New Client"
          }
        },
        {
          emailAddress: {
            address: "you@yourcompany.com",
            name: "You"
          }
        },
        {
          emailAddress: {
            address: "colleague@yourcompany.com",
            name: "Colleague"
          }
        }
      ]
    }
  ];

  // Example clients data for testing UI
  const exampleClients = [
    {
      id: "client-001",
      name: "Important Client",
      email: "client@example.com",
      company: "Example Corp"
    },
    {
      id: "client-002",
      name: "VIP Client",
      email: "vip-client@clientcompany.com",
      company: "Client Company"
    },
    {
      id: "client-003",
      name: "New Client",
      email: "new-client@newclientcorp.com",
      company: "New Client Corp"
    }
  ];

  useEffect(() => {
    setEventsLoading(true);
    
    // Try to fetch real events first
    // fetchEvents(serverUrl, true)
    // .then((fetchedEvents) => {
    //   // If no events were fetched, use example data
    //   // We'll set a small timeout to simulate an API call
    //   setTimeout(() => {
    //     if (fetchedEvents.length === 0) {
    //       console.log('Using example events data');
    //       setEvents(exampleEvents);
    //     }
    //   }, 500);
    // });
    const fetchAndSetEvents = async () => {
      try {
        console.log('Fetching events from server:', serverUrl);
        const fetchedEvents = await fetchEvents(serverUrl, false); // false to get 30 days of events
        console.log('Raw fetched events:', fetchedEvents);
        
        if (fetchedEvents && fetchedEvents.length > 0) {
          console.log('Setting events state with:', fetchedEvents);
          setEvents(fetchedEvents);
        } else {
          console.log('No events were fetched or empty array returned');
          // If no events were fetched, use example data
          // setEvents(exampleEvents);
        }
      } catch (error) {
        console.error('Error fetching events:', error);
        // If error fetching, use example data
        // setEvents(exampleEvents);
      } finally {
        setEventsLoading(false);
      }
    };

    fetchAndSetEvents();

    const fetchClients = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/organization/clients`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        // console.log('API Response:', response.data.clients.clients);
        setClients(Array.isArray(response.data.clients.clients) ? response.data.clients.clients : []);
      } catch (error) {
        console.error('Error fetching clients:', error);
        // Use example clients if real clients can't be fetched
        console.log('Using example clients data');
        setClients(exampleClients);
      }
    };

    fetchClients();
  }, [serverUrl]); 

  const emailToClientIdMap = clients.reduce((map, client) => {
    map[client.email.toLowerCase()] = client.id;
    return map;
  }, {});

  const handleLiveCall = async () => {
    if (webConferenceUrl) {
      try {
        // Log web conference meeting usage first
        await axios.post(`${serverUrl}/api/usage/log`, {
          statPath: FeatureStats.WEB_CONFERENCE_MEETINGS,
          value: 1
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });

        const response = await axios.post(`${serverUrl}/api/createBot`, 
          { meetingUrl: webConferenceUrl }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            },
          }
        );
        console.log('handleLiveCall response data:', response.data);
        const createdBotId = response.data.botId;
        setBotId(createdBotId);
        console.log('Created bot with ID:', createdBotId);

        toast({ 
          title: 'DataDasher Notetaker',
          description: 'The DataDasher Notetaker will join your meeting in a few minutes.',
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error creating bot:', error.response ? error.response.data : error.message);
      }
    } else {
      console.error('Please enter a valid web conference URL.');
    }
  };

  const now = new Date();
  const filteredEvents = events
  /*
    .filter(event => {
      const eventStartTime = new Date(event.startTime);
      return eventStartTime >= now;
    });
  */
  const actionGridColumns = useBreakpointValue({ base: 1, sm: 2, md: 2, lg: 4 });

  const isClientAttendee = (attendeeEmails) => {
    console.log('Clients:', clients);
    return attendeeEmails.some(attendeeEmail => {
      console.log('Attendee Email:', attendeeEmail);
      return clients.some(client => client.email.trim().toLowerCase() === attendeeEmail.trim().toLowerCase());
    });
  };

  return (
    <Box bg="white" p={8}>
      <Heading color={'#00417D'} fontSize={{ base: '24px', md: '32px' }}>Meetings</Heading>
      
      <Grid templateColumns={`repeat(${actionGridColumns}, 1fr)`} gap={4} mt={4}>
        <ActionBox 
          image={<Image src="/icons/Microsoft_Office_Outlook.svg" alt="Outlook" boxSize={{ base: '80px', md: '100px' }} 
                objectFit="contain"
              />
          }
          description="Auto-join calendar meetings"
          description2="Supports Microsoft Teams, Google Meet, Zoom, Webex."
          buttonText="Integrations" 
          buttonAction={() => navigate('/integrations')} 
        />
        <ActionBox 
          image={<Image src="/icons/Submark-Icon-Favicon.svg" alt="DD Logo" boxSize={{ base: '80px', md: '100px' }} objectFit="contain"/>} 
          description={"Paste a web conference link"} 
          description2={
            <Input 
              value={webConferenceUrl} 
              onChange={(e) => setWebConferenceUrl(e.target.value)} 
              placeholder="Enter web-conf link" 
              mt={2} 
              size="md"
            />
          } 
          buttonText="Join Live Call" 
          buttonAction={handleLiveCall} 
        />
        <ActionBox 
          image={<Image src="/icons/Submark-Icon-Favicon.svg" alt="DD Logo" boxSize={{ base: '80px', md: '100px' }} objectFit="contain"/>} 
          description={"Record an in person meeting"}
          description2={"Use the DataDasher web application."}
          buttonText="Record" 
          buttonAction={() => navigate('/live-meeting')}
        />  
        <ActionBox 
          image={<Image src="/icons/Submark-Icon-Favicon.svg" alt="DD Logo" boxSize={{ base: '80px', md: '100px' }} objectFit="contain"/>} 
          description="Upload a recorded meeting"
          description2={"MP3, M4A, WAV, and MP4 files are supported."}
          buttonText="Upload" 
          buttonAction={() => navigate('/upload')} 
        />
      </Grid>
      <HStack justify="space-between" align="center" mt={8}>
        <Heading color={'black'} fontSize={{ base: '20px', md: '24px' }}>Upcoming Meetings</Heading>
        {isMicrosoftConnected && (
          <Button
            leftIcon={<RepeatIcon />}
            onClick={handleResync}
            isLoading={isResyncing}
            loadingText="Syncing..."
            size="sm"
            colorScheme="blue"
            variant="outline"
          >
            Resync Calendar
          </Button>
        )}
      </HStack>
      <Text color={'gray'} fontSize={{ base: '14px', md: '16px' }} mt={4}>
        If you have a calendar connected, events from the next 30 days will be displayed here.
        <br/>
        Clicking on a client name will generate a meeting prep document for that client.
      </Text>
      <Box mt={4}>
        {eventsLoading ? (
          <SimpleGrid columns={1} spacing={4}>
            {[1, 2].map((index) => (
              <Box key={index} p={4} borderWidth="1px" borderRadius="lg" bg="white">
                <SkeletonText noOfLines={2} spacing="4" />
                <HStack mt={4} spacing={4}>
                  <Skeleton height="20px" width="120px" />
                  <Skeleton height="20px" width="100px" />
                </HStack>
              </Box>
            ))}
          </SimpleGrid>
        ) : events.length > 0 ? (
          <SimpleGrid columns={1} spacing={4}>
            {events
              .sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
              .map((event) => (
                <EventBox
                  key={event.id}
                  id={event.id}
                  eventId={event.id}
                  subject={event.subject}
                  startTime={formatDate(event.startTime, userTimeZone)}
                  endTime={formatDate(event.endTime, userTimeZone)}
                  webConfLink={event.webConfLink}
                  location={event.location}
                  audioBotJoinStatus={event.audioBotJoinStatus ?? true}
                  botId={event.botId ?? null}
                  eventClients={event.clients}
                />
              ))}
          </SimpleGrid>
        ) : (
          <Box textAlign="center" py={8} bg="white" borderRadius="lg">
            <Text color="gray.500">No meetings scheduled for the next 30 days.</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ActionBox = ({ image, description, description2, buttonText, buttonAction }) => {
  return (
    <Box 
      bg="#F5F5F5" 
      pt={{ base: 2, md: 4 }} 
      pl={{ base: 4, md: 6 }} 
      pr={{ base: 4, md: 6 }} 
      pb={{ base: 2, md: 4 }} 
      w="100%" 
      minHeight={{ base: '160px', md: '210px' }} 
      borderRadius="8px"
      border="1px"
      borderColor="gray.200"
      boxShadow="sm"
      display="flex" 
      flexDirection="column" 
      justifyContent="space-between"
      _hover={{ boxShadow: 'md' }}
      transition="box-shadow 0.3s ease"
    >
      <HStack spacing={3} alignItems="center" justifyContent="flex-start" flex="1" mt={-5}>
        <Box flex="0.25">
          {image}
        </Box>
        <Text 
          flex="0.75"
          fontSize={{ base: '12px', md: '14px' }} 
          wordBreak="break-word"
          textAlign="left"
          fontWeight={500}
        >
          {description}
        </Text>
      </HStack>
      <Box flex="1" display="flex" alignItems="center" justifyContent="flex-start" mt={-4}>
        <Text 
          fontSize={{ base: '10px', md: '12px' }} 
          color="gray"
          wordBreak="break-word"
          textAlign="left"
        >
          {description2}
        </Text>
      </Box>
      <Box flex="1" display="flex" alignItems="center" justifyContent="flex-start" mt={2}>
        <Button 
          onClick={buttonAction} 
          colorScheme="blue"
          variant="outline"
          bg="#F5F5F5"
          size={{ base: 'sm', md: 'md' }}
          transition="all 0.3s ease"
          color="#00417D"
          borderColor="#00417D"
          _hover={{ 
            bg: '#00417D',
            color: 'white'
          }}
        >
          <Text fontSize={{ base: '12px', md: '14px' }}>{buttonText}</Text>
        </Button>
      </Box>
    </Box>
  );
};

export const EventBox = ({
  id,
  eventId,
  subject,
  startTime,
  endTime,
  webConfLink,
  location,
  audioBotJoinStatus,
  botId,
  eventClients: initialEventClients,
  onClientChange = () => {}
}) => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const [isChecked, setIsChecked] = useState(audioBotJoinStatus);
  const [showClientSelection, setShowClientSelection] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [assignedClient, setAssignedClient] = useState(null);
  const [clientToReplace, setClientToReplace] = useState(null);
  const [eventClients, setEventClients] = useState(initialEventClients);
  const { clients, entities, loading: clientsLoading } = useOrganizationClients();
  const toast = useToast();

  // Update eventClients when initialEventClients changes
  useEffect(() => {
    setEventClients(initialEventClients);
  }, [initialEventClients]);

  const handleToggle = async () => {
    console.warn('handleToggle called for bot id:', botId);
    const newAttendingStatus = !isChecked;
    setIsChecked(newAttendingStatus);

    try {
      await axios.patch(`${serverUrl}/api/events/${eventId}`, {
        botId: botId,
        audioBotJoinStatus: newAttendingStatus
      });
      console.warn('Attendance status updated:', newAttendingStatus);
    } catch (error) {
      console.error('Error updating attendance status:', error);
    }
  };

  const handleLiveCall = () => {
    window.location.href = `${serverUrl}/live-meeting`;
  };

  const joinCall = () => {
    // Open the web conference link in a new tab
    window.open(webConfLink.startsWith('http') ? webConfLink : `https://${webConfLink}`, '_blank');
  };

  // Extract just the time portion from the formatted date string
  const getTimeOnly = (dateString) => {
    const timeMatch = dateString.match(/(\d+:\d+\s[AP]M)/);
    return timeMatch ? timeMatch[0] : dateString;
  };

  const timeOnly = getTimeOnly(startTime);

  const handleClientSelect = async (selectedClient) => {
    try {
      // Prevent self-reassignment
      if (clientToReplace && selectedClient.id === clientToReplace.id) {
        toast({
          title: "Invalid Selection",
          description: "Cannot reassign a client to themselves.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Check if selection is an entity
      if (selectedClient.isEntity) {
        // For entities, we need to get all associated clients
        // API call to assign all entity clients to the meeting
        const response = await axios.patch(`${serverUrl}/api/events/${id}/assign-entity`, {
          entityId: selectedClient.id,
          replaceClientId: clientToReplace?.id // Include the client ID to replace if reassigning
        }, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        
        // Update with all clients returned from the API
        if (response.data && response.data.clients) {
          setEventClients(response.data.clients);
          // Notify parent component of the change if onClientChange is provided
          if (typeof onClientChange === 'function') {
            onClientChange(id, response.data.clients);
          }
        }
        
        toast({
          title: "Entity Assigned",
          description: `All clients from ${selectedClient.name} have been assigned to this meeting.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Original client assignment logic
        const response = await axios.patch(`${serverUrl}/api/events/${id}/assign-client`, {
          clientId: selectedClient.id,
          replaceClientId: clientToReplace?.id // Include the client ID to replace if reassigning
        }, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        
        setAssignedClient(selectedClient);
        
        // Update the local eventClients state with the response data
        if (response.data && response.data.clients) {
          setEventClients(response.data.clients);
          // Notify parent component of the change if onClientChange is provided
          if (typeof onClientChange === 'function') {
            onClientChange(id, response.data.clients);
          }
        } else {
          // Fallback to local state update if response data is not available
          const updatedClients = clientToReplace
            ? eventClients.map(client => 
                client.id === clientToReplace.id ? selectedClient : client
              )
            : [...eventClients, selectedClient];
          
          setEventClients(updatedClients);
          // Notify parent component of the change if onClientChange is provided
          if (typeof onClientChange === 'function') {
            onClientChange(id, updatedClients);
          }
        }
        
        toast({
          title: clientToReplace ? "Client Replaced" : "Client Assigned",
          description: `${selectedClient.firstName} ${selectedClient.lastName} has been ${clientToReplace ? 'replaced' : 'assigned'} to this meeting.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      
      setShowClientSelection(false);
      setClientToReplace(null);
    } catch (error) {
      console.error('Error assigning client:', error);
      toast({
        title: "Error",
        description: "Failed to assign client to meeting.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleReassignClick = (client) => {
    setClientToReplace(client);
    setShowClientSelection(true);
  };

  return (
    <Box
      bg="white"
      p={4}
      borderRadius="8px"
      border="1px"
      borderColor="gray.200"
      boxShadow="sm"
      transition="box-shadow 0.3s ease"
      _hover={{ boxShadow: 'md' }}
      w="100%"
      mb={0}
    >
      {/* Meeting Title and Time */}
      <HStack justifyContent="space-between" mb={3}>
        <HStack spacing={2} align="center">
          <Box 
            p={2}
            bg={webConfLink ? "blue.50" : "gray.100"}
            borderRadius="md"
            color={webConfLink ? "blue.500" : "gray.500"}
          >
            <Icon as={PhoneIcon} boxSize={4} />
          </Box>
          <Text fontWeight="bold" fontSize={{ base: 'md', md: 'lg' }}>
            {subject}
          </Text>
        </HStack>
        <HStack spacing={1} color="gray.600" fontSize="sm">
          <Icon as={TimeIcon} boxSize={3} />
          <Text fontWeight="medium">
            {startTime}
          </Text>
        </HStack>
      </HStack>
      
      {/* Location if applicable */}
      {!webConfLink && location && (
        <HStack spacing={1} color="gray.600" ml={10} mb={3}>
          <Icon as={FaMapMarkerAlt} boxSize={3} />
          <Text 
            fontSize="sm"
            color="gray.600"
            maxW={{ base: "400px", md: "600px" }}
            wordBreak="break-word"
            whiteSpace="normal"
          >
            {location}
          </Text>
        </HStack>
      )}
      
      {/* Client and Join Button Row */}
      <HStack justifyContent="space-between" alignItems="center" mt={2}>
        {/* Client Section with background styling */}
        {eventClients && eventClients.length > 0 ? (
          <Box
            p={2} 
            borderRadius="md" 
            bg="gray.50"
            borderWidth="1px"
            borderColor="gray.200"
            ml={10}
          >
            <HStack spacing={2} alignItems="center">
              <Icon as={FaUser} boxSize={3} color="gray.500" />
              <Wrap spacing={2} align="center">
                {eventClients
                  .sort((a, b) => a.firstName.localeCompare(b.firstName))
                  .map(client => (
                  <WrapItem key={client.id}>
                    <Box
                      bg="white"
                      px={2}
                      py={1}
                      borderRadius="md"
                      borderWidth="1px"
                      borderColor="gray.200"
                      display="inline-flex"
                      alignItems="center"
                    >
                      <Link 
                        as={RouterLink} 
                        to={`/clients/${client.id}?tab=4`}
                        color="blue.600"
                        _hover={{
                          textDecoration: 'underline',
                          color: 'blue.700'
                        }}
                        mr={2}
                      >
                        <Text>
                          {client.firstName} {client.lastName}
                        </Text>
                      </Link>
                      <Button
                        size="xs"
                        variant="ghost"
                        leftIcon={<EditIcon />}
                        onClick={() => handleReassignClick(client)}
                        color="gray.600"
                        _hover={{ color: 'blue.500' }}
                        p={1}
                        minW="auto"
                      />
                    </Box>
                  </WrapItem>
                ))}
              </Wrap>
            </HStack>
          </Box>
        ) : (
          <Button
            size="xs"
            colorScheme="blue"
            variant="solid"
            leftIcon={<FaUser />}
            onClick={() => {
              setClientToReplace(null);
              setShowClientSelection(true);
            }}
            isLoading={clientsLoading}
            ml={10}
          >
            Assign Client
          </Button>
        )}
        
        {/* Right Side: Join Button and DataDasher Toggle */}
        <HStack spacing={4}>
          {/* DataDasher Toggle with Text */}
          {webConfLink && (
            <HStack spacing={2} alignItems="center">
              <Text fontSize="sm" color="gray.600">Include Notetaker?</Text>
              <Switch
                colorScheme="blue"
                isChecked={isChecked}
                onChange={handleToggle}
                size="md"
                transition="all 0.2s ease"
                aria-label="Toggle DataDasher Join Status"
              />
            </HStack>
          )}
          
          {/* Join Call Button */}
          {webConfLink ? (
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={joinCall}
              bg="#00417D"
              size="sm"
              height="32px"
              fontSize="sm"
              transition="background-color 0.3s ease"
              color="white"
              leftIcon={<PhoneIcon boxSize={3} />}
            >
              Join Call
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={handleLiveCall}
              bg="#00417D"
              size="sm"
              height="32px"
              fontSize="sm"
              transition="background-color 0.3s ease"
              color="white"
              leftIcon={<PhoneIcon boxSize={3} />}
            >
              Create Live Call
            </Button>
          )}
        </HStack>
      </HStack>

      {/* Client Selection Modal */}
      {showClientSelection && (
        <Modal 
          isOpen={showClientSelection} 
          onClose={() => {
            setShowClientSelection(false);
            setClientToReplace(null);
          }}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {clientToReplace ? `Replace ${clientToReplace.firstName} ${clientToReplace.lastName}` : 'Select Client or Entity'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4}>
                <Input
                  placeholder="Search clients or entities..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <VStack align="stretch" maxH="400px" overflowY="auto" w="100%" spacing={2}>
                  {/* Entities Section */}
                  {entities && entities.length > 0 && (
                    <Box mb={4}>
                      <Text fontWeight="bold" mb={2} color="gray.600">
                        Entities (Groups)
                      </Text>
                      {entities
                        .filter(entity => 
                          entity.name.toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        .map(entity => (
                          <Button
                            key={`entity-${entity.id}`}
                            variant="ghost"
                            justifyContent="flex-start"
                            onClick={() => handleClientSelect({...entity, isEntity: true})}
                            py={4}
                            bg="blue.50"
                            _hover={{
                              bg: 'blue.100'
                            }}
                            mb={1}
                          >
                            <HStack>
                              <Icon as={FaUsers} color="blue.500" />
                              <VStack align="start" spacing={0}>
                                <Text>{entity.name}</Text>
                                <Text fontSize="xs" color="gray.500">
                                  {entity.clients.length || 0} clients
                                </Text>
                              </VStack>
                            </HStack>
                          </Button>
                        ))}
                    </Box>
                  )}
                  
                  {/* Individual Clients Section */}
                  <Box>
                    <Text fontWeight="bold" mb={2} color="gray.600">
                      Individual Clients
                    </Text>
                    {clients
                      .filter(c => 
                        `${c.firstName} ${c.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map(client => (
                        <Button
                          key={`client-${client.id}`}
                          variant="ghost"
                          justifyContent="flex-start"
                          onClick={() => handleClientSelect(client)}
                          py={4}
                          _hover={{
                            bg: 'blue.50'
                          }}
                        >
                          <HStack>
                            <Icon as={FaUser} color="gray.400" />
                            <Text>{client.firstName} {client.lastName}</Text>
                          </HStack>
                        </Button>
                      ))}
                  </Box>
                </VStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};  

export { formatDate };
export default Meetings;