import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  VStack, Box, Heading, Text, Button, useToast, HStack, Table,
  Thead, Tbody, Tr, Th, Td, Input, Alert, AlertIcon, Tooltip, Container, Divider,
  Skeleton, SkeletonText
} from '@chakra-ui/react';
import { EmailIcon, RepeatIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { createOutlookDraft } from '../../../../utils/outlookUtils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FeatureStats } from '../../../../enums/UsageStats';
import { createGmailDraft } from '../../../../utils/gmailUtils';

// Helper function to generate email body
const generateEmailBody = ({ clientName, summary, clientTodos, advisorTodos }) => {
  // Format client to-dos
  const clientTodosList = clientTodos && clientTodos.length > 0
    ? clientTodos.map(todo => `<li>${todo}</li>`).join('')
    : '<li>No action items for you at this time.</li>';

  // Format advisor to-dos
  const advisorTodosList = advisorTodos && advisorTodos.length > 0
    ? advisorTodos.map(todo => {
        const todoText = typeof todo === 'string' ? todo : todo.description || todo.text || '';
        return todoText ? `<li>${todoText}</li>` : '';
      }).filter(Boolean).join('')
    : '<li>No action items for us at this time.</li>';

  // Generate the email body
  return `
    <p>Dear ${clientName},</p>
    
    <p>Thank you for our meeting today. Here's a summary of what we discussed:</p>
    
    <h3>Summary:</h3>
    <p>${summary || 'No summary available.'}</p>
    
    <h3>Key Action Items:</h3>
    <p><strong>For You:</strong></p>
    <ul>
      ${clientTodosList}
    </ul>
    
    <p><strong>For Us:</strong></p>
    <ul>
      ${advisorTodosList}
    </ul>
    
    <h3>Next Steps:</h3>
    <ul>
      <li>Schedule follow-up meeting</li>
      <li>Review and sign updated documents</li>
      <li>Complete assigned action items</li>
    </ul>
    
    <p>Please let me know if you have any questions or need clarification on any of these items.</p>
    
    <p>Best regards,<br>
    [Advisor Name]</p>
  `;
};

// Define the same styles from FollowUpPage
const styles = `
  .quill-wrapper {
    height: 500px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .quill-wrapper .quill-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .quill-wrapper .ql-container {
    flex: 1;
    overflow-y: auto;
    height: calc(100% - 42px);
  }

  .quill-wrapper .ql-toolbar {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    height: 42px;
  }

  .email-editor-container {
    height: 500px;
    overflow: hidden;
  }
`;

const FollowUpEmail = ({
  callData,
  callId,
  callTitle,
  callSummary,
  callClientTodos,
  callAdvisorTodos,
  callClients,
  type,
  onClose
}) => {
  const [followUpEmail, setFollowUpEmail] = useState({
    subject: `Follow-up: ${callTitle || callData?.call?.title || ''}`,
    body: generateEmailBody({
      clientName: callClients?.[0]?.firstName || callData?.call?.clients?.[0]?.firstName || '[Client Name]',
      summary: callSummary || callData?.call?.summary || '',
      clientTodos: callClientTodos || callData?.call?.clientTodos || [],
      advisorTodos: callAdvisorTodos || callData?.call?.advisorTodos || []
    })
  });
  const [originalEmail, setOriginalEmail] = useState({});
  const [clientInfo, setClientInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [discrepancies, setDiscrepancies] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [templateContent, setTemplateContent] = useState('');
  const [isOutlookConnected, setIsOutlookConnected] = useState(false);
  const [isGmailConnected, setIsGmailConnected] = useState(false);
  const [emailService, setEmailService] = useState(null); // 'outlook' or 'gmail'
  const quillRef = useRef(null);
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Add styles to document head
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  // Move fetchData outside of useEffect and wrap in useCallback
  const fetchData = useCallback(async (regenerate = false) => {
    try {
      setIsLoading(true);
      // Fetch user settings
      const settingsResponse = await axios.get(`${serverUrl}/api/user-settings`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      setUserSettings(settingsResponse.data?.settings || {});
      // Fetch follow-up email - use the correct endpoint based on type
      const response = await axios.post(
        `${serverUrl}/api/follow-up/${type}/${callData.call.id}${regenerate ? '?regenerate=true' : ''}`,
        { useTemplate: settingsResponse.data?.settings?.useEmailTemplate },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          }
        }
      );

        if (settingsResponse.data?.settings?.useEmailTemplate) {
          const template = settingsResponse.data?.settings?.emailTemplate || '';
          const today = new Date().toLocaleDateString();
          const filledTemplate = template
            .replace(/{{clientName}}/g, (response.data?.clientInfo?.name || '[Client Name]'))
            .replace(/{{todaysDate}}/g, (today || '[Date]'))
            .replace(/{{body}}/g, response.data?.followUpEmail.emailBody || '[!!Email Body]')
            .replace(/{{advisorName}}/g, `${response.data?.user?.firstName} ${response.data?.user?.lastName}`)
            .replace(/{{signatureImage}}/g, response.data?.settings?.signatureImage || '');
          
          setTemplateContent(filledTemplate);
        } else {
          setTemplateContent(response.data?.followUpEmail.emailBody || '');
        }

      setFollowUpEmail({
        subject: type === 'email' ? 
          `Re: ${callData.call.title}` : 
          `Follow-up: ${callData.call.title}`,
        ...response.data?.followUpEmail
      });
      setOriginalEmail(response.data?.followUpEmail);
      setClientInfo(response.data?.clientInfo);
      setIsLoading(false);

      // Check for discrepancies - only for calls
      if (callData.call.type === 'call') {
        const discrepancyResponse = await axios.post(
          `${serverUrl}/api/call/check-discrepancies`,
          {
            id: callData.call.id,
            emailContent: response.data?.followUpEmail.emailBody
          },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
          }
        );
        setDiscrepancies(discrepancyResponse.data.discrepancies);
      }

      // Check user connections
      const connectionsResponse = await axios.get(`${serverUrl}/api/user-connections`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      
      const hasOutlookConnection = connectionsResponse.data.some(
        connection => connection.name === 'Microsoft'
      );
      const hasGmailConnection = connectionsResponse.data.some(
        connection => connection.name === 'Google'
      );
      
      setIsOutlookConnected(hasOutlookConnection);
      setIsGmailConnected(hasGmailConnection);
      
      // Set the email service based on connections
      if (hasOutlookConnection) {
        setEmailService('outlook');
      } else if (hasGmailConnection) {
        setEmailService('gmail');
      } else {
        setEmailService(null);
      }
    } catch (err) {
      setError('Failed to generate follow-up email');
      setIsLoading(false);
    }
  }, [callData, type, serverUrl, setIsLoading, setUserSettings, setTemplateContent, setFollowUpEmail, setOriginalEmail, setClientInfo, setDiscrepancies, setIsOutlookConnected, setIsGmailConnected, setEmailService, setError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTemplateChange = (content) => {
    setTemplateContent(content);
  };

  const renderEmailBodyInput = () => {
    return (
      <Box className="quill-container" width="100%">
        <ReactQuill
          ref={quillRef}
          value={templateContent}
          onChange={handleTemplateChange}
          modules={{
            toolbar: [
              [{ 'header': [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              ['link'],
              ['clean']
            ],
            clipboard: {
              matchVisual: false
            }
          }}
          formats={[
            'header',
            'bold', 'italic', 'underline', 'strike',
            'list', 'bullet',
            'link'
          ]}
          style={{ width: '100%', height: '100%', overflowY: 'auto' }}
        />
      </Box>
    );
  };

  const handleOpenInEmailService = async () => {
    try {
      // Log email generation
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.EMAILS_GENERATED,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });

      const emailContent = userSettings?.useEmailTemplate ? 
        templateContent : 
        followUpEmail.emailBody;

      // Save the email draft regardless of which service is used
      await axios.post(
        `${serverUrl}/api/follow-up/${callData.call.type}/${callData.call.id}/save`,
        {
          originalEmail,
          currentDraft: {
            ...followUpEmail,
            emailBody: emailContent
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          }
        }
      );

      // Open the email in the appropriate service
      if (emailService === 'outlook') {
        const { messageData, webLink } = await createOutlookDraft(
          followUpEmail.subject,
          emailContent,
          true
        );

        window.open(webLink, '_blank');

        toast({
          title: 'Draft created and opened in Outlook',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else if (emailService === 'gmail') {
        try {
          const { webLink } = await createGmailDraft(
            followUpEmail.subject,
            emailContent,
            true
          );

          window.open(webLink, '_blank');

          toast({
            title: 'Draft created and opened in Gmail',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } catch (error) {
          if (error.response?.data?.needsReconnect) {
            toast({
              title: 'Google connection issue',
              description: 'Please reconnect your Google account in Integrations',
              status: 'warning',
              duration: 5000,
              isClosable: true,
            });
            // Could redirect to integrations page
            // navigate('/settings/integrations');
          } else {
            throw error; // Re-throw for the outer catch block
          }
        }
      }
    } catch (error) {
      console.error(`Error creating draft in ${emailService}:`, error);
      toast({
        title: `Failed to create draft in ${emailService === 'outlook' ? 'Outlook' : 'Gmail'}`,
        description: `${error.message || `Request failed with status code ${error.response?.status || 500}`}. Gmail scope is not yet added.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Add function to handle regenerating the email
  const handleRegenerateEmail = async () => {
    try {
      await fetchData(true);
      toast({
        title: 'Email regenerated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Failed to regenerate email',
        description: error.message || 'An error occurred',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <VStack spacing={8} align="stretch">
        <Skeleton height="40px" width="400px" />
        <SkeletonText mt='4' noOfLines={3} spacing='4' skeletonHeight='4' />
        <Skeleton height="400px" />
      </VStack>
    );
  }

  if (error) {
    return (
      <Alert status="error" variant="left-accent">
        <AlertIcon />
        <Text>{error}</Text>
      </Alert>
    );
  }

  return (
    <VStack spacing={8} align="stretch" overflowY="auto" maxHeight="calc(100vh - 200px)">
      {/*discrepancies && (discrepancies.length > 0) && (
        <Alert status="warning" variant="left-accent" borderRadius="md">
          <AlertIcon />
          <Box>
            <Heading size="sm" mb={2}>Potential Discrepancies Detected</Heading>
            <Text>{discrepancies?.map(discrepancy => <li>{discrepancy}</li>)}</Text>
          </Box>
        </Alert>
      )*/}

      <Box 
        bg="white" 
        p={6} 
        borderRadius="lg" 
        boxShadow="sm"
        border="1px"
        borderColor="gray.100"
      >
        <HStack justify="space-between">
          <HStack spacing={4}>
            <Tooltip 
              label={!emailService 
                ? "Please connect your email account in Integrations" 
                : `Create and view email draft in ${emailService === 'outlook' ? 'Outlook' : 'Gmail'}`}
              placement="top"
              hasArrow
            >
              <Button
                leftIcon={<EmailIcon />}
                colorScheme="blue"
                onClick={handleOpenInEmailService}
                isDisabled={!emailService}
              >
                Open in {emailService === 'outlook' ? 'Outlook' : 'Gmail'}
              </Button>
            </Tooltip>
            
            <Tooltip 
              label="Regenerate email content"
              placement="top"
              hasArrow
            >
              <Button
                leftIcon={<RepeatIcon />}
                colorScheme="green"
                onClick={handleRegenerateEmail}
                isLoading={isLoading}
              >
                Regenerate
              </Button>
            </Tooltip>
          </HStack>
        </HStack>
        <Divider mb={4} />
        
        <VStack spacing={6} align="stretch">
          <Box>
            <Text mb={2} fontWeight="medium">Subject</Text>
            <Input
              value={followUpEmail.subject}
              onChange={(e) => setFollowUpEmail(prev => ({ ...prev, subject: e.target.value }))}
              size="md"
              bg="gray.50"
            />
          </Box>

          <Box>
            <Text mb={2} fontWeight="medium">Email Body</Text>
            <Box 
              bg="gray.50" 
              borderRadius="md" 
              className="email-editor-container"
              width="100%"
            >
              <Box className="preserve-whitespace quill-wrapper">
                {renderEmailBodyInput()}
              </Box>
            </Box>
          </Box>
        </VStack>
      </Box>
    </VStack>
  );
};

export default FollowUpEmail; 