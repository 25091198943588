import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { FeatureStats } from '../enums/UsageStats';
import { 
  fetchCorrespondence, 
  fetchCorrespondenceMetadata,
  fetchTranscription,
  fetchSummary,
  fetchAudioUrl,
  fetchActionItems,
  fetchInsights,
  fetchAggregateInsights,
  fetchKeyInfo,
  fetchEmailReplies,
  updateCallTitle,
  updateCallSummary,
  updateTranscription,
  approveActionItem,
  rejectActionItem,
  assignActionItem,
  updateActionItemDueDate,
  sendChatMessage,
  askAboutCorrespondence,
  fetchQuestions,
  logUsage,
  updateSpeaker,
  fetchCrmData
} from '../services/correspondenceService';
import { formatSpeakerName } from '../utils/transcriptionUtils';
import { getTranscriptionDuration } from '../utils/audioUtils';
import Cookies from 'js-cookie';

/**
 * Aggregates insights by category for easier display
 * @param {Array} insights - Array of insight objects
 * @returns {Object} Insights grouped by category
 */
const aggregateInsightsByCategory = (insights) => {
  if (!Array.isArray(insights)) return {};
  
  return insights.reduce((acc, insight) => {
    const category = insight.category || 'general';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(insight);
    return acc;
  }, {});
};

/**
 * Custom hook for managing correspondence data
 * @returns {Object} Correspondence data and loading states
 */
const useCorrespondence = () => {
  const { id } = useParams();
  const toast = useToast();
  
  // Data states
  const [callData, setCallData] = useState(null);
  const [groupedTranscription, setGroupedTranscription] = useState([]);
  const [actionItems, setActionItems] = useState([]);
  const [aiInsights, setAiInsights] = useState([]);
  const [aggregateInsights, setAggregateInsights] = useState([]);
  const [keyInfo, setKeyInfo] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [emailReplies, setEmailReplies] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [transcriptionDuration, setTranscriptionDuration] = useState(0);
  const [crm, setCrm] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoadingMoreTranscription, setIsLoadingMoreTranscription] = useState(false);
  const [allTranscriptionLoaded, setAllTranscriptionLoaded] = useState(false);
  const [allTranscriptionData, setAllTranscriptionData] = useState([]);

  // UI states
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [isEditingSummary, setIsEditingSummary] = useState(false);
  const [summary, setSummary] = useState('');
  const [newSummary, setNewSummary] = useState('');
  const [isEditingTranscription, setIsEditingTranscription] = useState(false);
  const [editedTranscription, setEditedTranscription] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingStates, setLoadingStates] = useState({});
  
  // Component-specific loading states
  const [isLoadingTranscription, setIsLoadingTranscription] = useState(true);
  const [isLoadingActionItems, setIsLoadingActionItems] = useState(false);
  const [isLoadingInsights, setIsLoadingInsights] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  // Tab state to track which tab is active
  const [activeTab, setActiveTab] = useState(0);
  
  // Data loaded flags to prevent duplicate fetches
  const [dataLoadedFlags, setDataLoadedFlags] = useState({
    metadata: false,
    transcription: false,
    summary: false,
    actionItems: false,
    insights: false,
    questions: false
  });

  const serverUrl = process.env.REACT_APP_API_URL;
  
  // Refs
  const callDataRef = useRef();
  
  // Set callDataRef when callData changes
  useEffect(() => {
    callDataRef.current = callData;
  }, [callData]);
  
  // Fetch essential data on mount (metadata and transcription only)
  useEffect(() => {
    if (id) {
      fetchInitialData();
    }
  }, [id]);
  
  // Fetch tab-specific data when tab changes
  useEffect(() => {
    if (!id || !callData) return;
    
    if (activeTab === 1 && !dataLoadedFlags.actionItems) {
      fetchActionItemsData();
    } else if (activeTab === 2 && !dataLoadedFlags.questions) {
      fetchQuestionsData();
    }
  }, [activeTab, id, callData, dataLoadedFlags]);
  
  // Memoize the grouped transcription to prevent unnecessary re-renders
  const memoizedGroupedTranscription = useMemo(() => {
    return groupedTranscription;
  }, [groupedTranscription]);
  
  // Load more transcription data when scrolling
  const loadMoreTranscription = useCallback(async () => {
    if (isLoadingMoreTranscription || allTranscriptionLoaded || currentPage >= totalPages) {
      return;
    }
    
    try {
      setIsLoadingMoreTranscription(true);
      const nextPage = currentPage + 1;
      
      const transcriptionResponse = await fetchTranscription(id, nextPage, itemsPerPage);
      
      if (transcriptionResponse && transcriptionResponse.groupedTranscription) {
        // Append new data to existing data
        setGroupedTranscription(prev => [...prev, ...transcriptionResponse.groupedTranscription]);
        setAllTranscriptionData(prev => [...prev, ...transcriptionResponse.transcription]);
        
        // Update pagination info
        setCurrentPage(nextPage);
        setTotalPages(transcriptionResponse.pagination.totalPages);
        setTotalItems(transcriptionResponse.pagination.total);
        
        // Check if all data is loaded
        if (nextPage >= transcriptionResponse.pagination.totalPages) {
          setAllTranscriptionLoaded(true);
        }
      }
    } catch (error) {
      console.error('Error loading more transcription data:', error);
      toast({
        title: 'Error loading more data',
        description: 'Could not load additional transcription data. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingMoreTranscription(false);
    }
  }, [id, currentPage, totalPages, isLoadingMoreTranscription, allTranscriptionLoaded, itemsPerPage]);
  
  // Initial data fetching function - only loads essential data
  const fetchInitialData = async () => {
    setIsLoading(true);
    setIsLoadingTranscription(true);
    
    try {
      // Create a call data object to store all the fetched data
      let callDataObj = { call: {} };
      
      // Determine if this is an email or call
      const isEmail = window.location.pathname.includes('/email/');
      
      // Fetch metadata first (we need this for other requests)
      const metadata = await fetchCorrespondenceMetadata(id);
      callDataObj.call = { ...callDataObj.call, ...metadata };

      // Fetch CRM data
      const crmData = await fetchCrmData();
      setCrm(crmData);
      
      // Set title and summary for editing
      setNewTitle(metadata.title || '');
      setSummary(metadata.summary || '');
      
      // Set call data with metadata to show basic info while other data loads
      setCallData(callDataObj);
      
      // Mark metadata as loaded
      setDataLoadedFlags(prev => ({ ...prev, metadata: true }));
      
      // For calls, add transcription and audio URL requests
      if (!isEmail) {
        try {
          // Reset pagination state
          setCurrentPage(1);
          setAllTranscriptionLoaded(false);
          setAllTranscriptionData([]);
          
          // Fetch first page of transcription
          const transcriptionResponse = await fetchTranscription(id, 1, itemsPerPage);
          
          // Ensure transcription data is properly set in callData
          if (transcriptionResponse && transcriptionResponse.transcription) {
            // Store the first page of transcription data
            callDataObj.call.transcription = transcriptionResponse.transcription;
            setAllTranscriptionData(transcriptionResponse.transcription);
            
            // Store transcription metadata if available (for timeline visualization)
            if (transcriptionResponse.transcriptionMetadata) {
              callDataObj.call.transcriptionMetadata = transcriptionResponse.transcriptionMetadata;
            }
            
            // Update pagination info
            if (transcriptionResponse.pagination) {
              setTotalPages(transcriptionResponse.pagination.totalPages);
              setTotalItems(transcriptionResponse.pagination.total);
              setCurrentPage(transcriptionResponse.pagination.page);
              
              // Check if all data is loaded in the first page
              if (transcriptionResponse.pagination.page >= transcriptionResponse.pagination.totalPages) {
                setAllTranscriptionLoaded(true);
              }
            }
            
            // Use server-provided grouped transcription if available
            if (transcriptionResponse.groupedTranscription && transcriptionResponse.groupedTranscription.length > 0) {
              setGroupedTranscription(transcriptionResponse.groupedTranscription);
              setEditedTranscription(JSON.parse(JSON.stringify(transcriptionResponse.groupedTranscription)));
            } else {
              // Fallback to empty array if no grouped transcription
              setGroupedTranscription([]);
              setEditedTranscription([]);
            }
            
            // Calculate transcription duration
            const duration = getTranscriptionDuration(transcriptionResponse.transcription);
            setTranscriptionDuration(duration);
          } else {
            console.error('Invalid transcription data format:', transcriptionResponse);
            callDataObj.call.transcription = [];
            setGroupedTranscription([]);
            setEditedTranscription([]);
          }
          
          // Fetch audio URL in parallel
          const audioUrl = await fetchAudioUrl(id);
          callDataObj.call.audio_url = audioUrl;
          
          // Mark transcription as loaded
          setDataLoadedFlags(prev => ({ ...prev, transcription: true }));
        } catch (error) {
          console.error('Error fetching transcription or audio:', error);
        } finally {
          setIsLoadingTranscription(false);
        }
      } else {
        setIsLoadingTranscription(false);
      }
      
      // Fetch summary (this is essential for the UI)
      try {
        const summaryData = await fetchSummary(id);
        callDataObj.call.summary = summaryData.summary;
        setSummary(summaryData.summary);
        callDataObj.call.topics = summaryData.topics;
        
        // Mark summary as loaded
        setDataLoadedFlags(prev => ({ ...prev, summary: true }));
      } catch (error) {
        console.error('Error fetching summary:', error);
      }
      
      // Update call data with all fetched data
      setCallData(callDataObj);
      setIsLoading(false);
      
      // Log usage
      try {
        await logUsage(FeatureStats.TRANSCRIPT_VIEWS);
      } catch (error) {
        console.error('Error logging usage:', error);
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
      toast({
        title: 'Error loading data',
        description: 'Could not load correspondence data. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      
      setIsLoading(false);
      setIsLoadingTranscription(false);
    }
  };
  
  // Fetch action items data (called when user navigates to Action Items tab)
  const fetchActionItemsData = async () => {
    if (dataLoadedFlags.actionItems) return;
    
    setIsLoadingActionItems(true);
    
    try {
      const items = await fetchActionItems(id);
      setActionItems(items);
      
      // Update call data
      setCallData(prev => {
        if (!prev) return prev;
        return {
          ...prev,
          call: {
            ...prev.call,
            actionItems: items
          }
        };
      });
      
      // Mark action items as loaded
      setDataLoadedFlags(prev => ({ ...prev, actionItems: true }));
    } catch (error) {
      console.error('Error loading action items:', error);
      toast({
        title: 'Error loading action items',
        description: 'Could not load action items. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingActionItems(false);
    }
  };
  
  // Fetch questions data (called when user navigates to Ask About tab)
  const fetchQuestionsData = async () => {
    if (dataLoadedFlags.questions) return;
    
    setIsLoadingQuestions(true);
    
    try {
      const questions = await fetchQuestions(id);
      setQuestions(questions);
      
      // Update call data
      setCallData(prev => {
        if (!prev) return prev;
        return {
          ...prev,
          call: {
            ...prev.call,
            questions: { questions: questions }
          }
        };
      });
      
      // Mark questions as loaded
      setDataLoadedFlags(prev => ({ ...prev, questions: true }));
    } catch (error) {
      console.error('Error fetching questions:', error);
      toast({
        title: 'Error fetching questions',
        description: 'Could not load questions. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingQuestions(false);
    }
  };
  
  // Fetch insights data (called when user navigates to Insights tab)
  const fetchInsightsData = async () => {
    if (dataLoadedFlags.insights) return;
    
    setIsLoadingInsights(true);
    
    try {
      const insights = await fetchInsights(id);
      setAiInsights(insights);
      
      // Process insights for display
      if (insights && insights.length > 0) {
        const aggregated = aggregateInsightsByCategory(insights);
        setAggregateInsights(aggregated);
      }
      
      // Update call data
      setCallData(prev => {
        if (!prev) return prev;
        return {
          ...prev,
          call: {
            ...prev.call,
            insights: insights
          }
        };
      });
      
      // Mark insights as loaded
      setDataLoadedFlags(prev => ({ ...prev, insights: true }));
    } catch (error) {
      console.error('Error fetching insights:', error);
      toast({
        title: 'Error fetching insights',
        description: 'Could not load insights. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingInsights(false);
    }
  };

  // Legacy fetchData function for backward compatibility
  const fetchData = async () => {
    await fetchInitialData();
    
    // Fetch other data if not already loaded
    if (!dataLoadedFlags.actionItems) {
      await fetchActionItemsData();
    }
    
    if (!dataLoadedFlags.questions) {
      await fetchQuestionsData();
    }
    
    if (!dataLoadedFlags.insights) {
      await fetchInsightsData();
    }
  };
  
  // Fetch key info
  const fetchKeyInfoData = async () => {
    try {
      if (callData?.call?.client_id) {
        const info = await fetchKeyInfo(callData.call.client_id);
        setKeyInfo(info);
        
        // Set client name if available
        if (info?.client?.name) {
          setClientName(info.client.name);
        }
        
        return info;
      }
      return null;
    } catch (error) {
      console.error('Error fetching key info:', error);
      toast({
        title: 'Error fetching client info',
        description: 'Could not load client information. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return null;
    }
  };
  
  // Fetch email replies
  const fetchReplies = async (emailId) => {
    try {
      const replies = await fetchEmailReplies(emailId);
      setEmailReplies(replies);
      return replies;
    } catch (error) {
      console.error('Error fetching email replies:', error);
      toast({
        title: 'Error fetching email replies',
        description: 'Could not load email replies. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return [];
    }
  };
  
  // Format person name
  const formatPersonName = (person) => {
    return formatSpeakerName(person);
  };
  
  const handleSpeakerUpdate = async (newSpeaker, currentSpeaker, groupIndex = null, applyToAll = true) => {
    try {
      const currentCallData = callDataRef.current;
      
      console.log('handleSpeakerUpdate called with:', {
        newSpeaker,
        currentSpeaker,
        groupIndex,
        applyToAll,
        callData: currentCallData
      });

      if (!currentCallData?.call) {
        console.error('Call data not properly structured:', currentCallData);
        return;
      }

      // Show loading toast
      const loadingToastId = toast({
        title: 'Updating Speaker',
        description: 'Please wait while we update the speaker name...',
        status: 'loading',
        duration: null,
        isClosable: false,
      });

      // Use the service function to update the speaker on the server
      const response = await updateSpeaker(
        currentCallData.call.id, 
        currentSpeaker, 
        newSpeaker, 
        groupIndex, 
        applyToAll
      );
      
      console.log('Response from updateSpeaker:', response);

      // Close loading toast
      toast.close(loadingToastId);

      // Update the state after successful speaker update
      if (response?.call?.transcription) {
        console.log('Updating state with new transcription:', response.call.transcription);
        
        // Update the full transcription data
        setAllTranscriptionData(response.call.transcription);
        
        // Update call data with the complete response
        setCallData(prev => ({
          ...prev,
          call: {
            ...prev.call,
            ...response.call,
          },
        }));
        
        // Reset pagination and fetch the first page with updated data
        setCurrentPage(1);
        setAllTranscriptionLoaded(false);
        
        // Fetch the updated transcription with grouped data to ensure UI is in sync
        try {
          const transcriptionResponse = await fetchTranscription(currentCallData.call.id, 1, itemsPerPage);
          console.log('Fetched updated transcription:', transcriptionResponse);
          
          if (transcriptionResponse && transcriptionResponse.groupedTranscription) {
            setGroupedTranscription(transcriptionResponse.groupedTranscription);
            
            // Update pagination info
            if (transcriptionResponse.pagination) {
              setTotalPages(transcriptionResponse.pagination.totalPages);
              setTotalItems(transcriptionResponse.pagination.total);
              
              // Check if all data is loaded in the first page
              if (transcriptionResponse.pagination.page >= transcriptionResponse.pagination.totalPages) {
                setAllTranscriptionLoaded(true);
              }
            }
          }
        } catch (fetchError) {
          console.error('Error fetching updated transcription:', fetchError);
          // Continue with the update even if the fetch fails
        }
  
        toast({
          title: 'Speaker Updated',
          description: `Speaker name has been updated to ${typeof newSpeaker === 'string' ? newSpeaker : newSpeaker.name}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        
        return true;
      } else {
        console.error('Invalid response structure:', response);
        throw new Error('Invalid response data');
      }
    } catch (error) {
      console.error('Error in handleSpeakerUpdate:', error);
      toast({
        title: 'Update Failed',
        description: 'Failed to update speaker name. Please try refreshing the page and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
  };
 
  // Handle edit title
  const handleEditTitle = () => {
    setNewTitle(callData?.call?.title || '');
    setIsEditingTitle(true);
  };
  
  // Handle cancel edit title
  const handleCancelEditTitle = () => {
    setIsEditingTitle(false);
  };
  
  // Handle save title
  const handleSaveTitle = async (title) => {
    if (!title.trim()) {
      toast({
        title: 'Title required',
        description: 'Please enter a title for the call.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    try {
      const response = await updateCallTitle(callData.call.id, title);
      
      // Log title edit
      await logUsage(FeatureStats.TITLES_EDITED);
      
      setCallData(prev => ({
        ...prev,
        call: {
          ...prev.call,
          title: response.title,
        },
      }));
      
      setIsEditingTitle(false);
      
      toast({
        title: 'Title updated',
        description: 'Call title has been updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating title:', error);
      toast({
        title: 'Error updating title',
        description: 'Could not update call title. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  // Handle edit summary
  const handleEditSummary = () => {
    setNewSummary(callData?.call?.summary || '');
    setIsEditingSummary(true);
  };
  
  // Handle cancel edit summary
  const handleCancelEditSummary = () => {
    setIsEditingSummary(false);
    setSummary(callData?.call?.summary || '');
    setNewSummary(callData?.call?.summary || '');
  };
  
  // Handle save summary
  const handleSaveSummary = async (summary) => {
    if (!summary.trim()) {
      toast({
        title: 'Summary required',
        description: 'Please enter a summary for the call.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    try {
      const formattedSummary = summary.trim();
      
      const response = await updateCallSummary(callData.call.id, formattedSummary);
      
      // Log summary edit
      await logUsage(FeatureStats.SUMMARIES_EDITED);
      
      // Split the summary into list items by newline for display
      const summaryItems = formattedSummary.split('\n').filter(item => item.trim() !== '');
      
      setCallData(prev => ({
        ...prev,
        call: {
          ...prev.call,
          summary: response.summary,
        },
      }));
      setSummary(response.summary);
      
      setIsEditingSummary(false);
      
      toast({
        title: 'Summary updated',
        description: 'Call summary has been updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating summary:', error);
      toast({
        title: 'Error updating summary',
        description: 'Could not update call summary. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  // Handle edit transcription
  const handleEditTranscription = () => {
    setEditedTranscription(JSON.parse(JSON.stringify(groupedTranscription)));
    setIsEditingTranscription(true);
  };
  
  // Handle cancel edit transcription
  const handleCancelEditTranscription = () => {
    setIsEditingTranscription(false);
  };
  
  // Handle transcription change
  const handleTranscriptionChange = (groupIndex, entryIndex, updatedEntry) => {
    setEditedTranscription(prev => {
      const updated = [...prev];
      if (updated[groupIndex] && updated[groupIndex].entries[entryIndex]) {
        // Update the entry while preserving the original structure
        updated[groupIndex].entries[entryIndex] = {
          ...updated[groupIndex].entries[entryIndex],
          text: updatedEntry.text // Make sure we're explicitly updating the text
        };
      }
      return updated;
    });
  };
  
  // Handle save transcription
  const handleSaveTranscription = async () => {
    try {
      // Prepare the updated transcription array
      const updatedTranscriptionArray = editedTranscription.flatMap((group) => {
        // Get the entries for this group
        const entries = group.entries || [];
        
        // Map each entry to preserve its structure with updated text
        return entries.map(entry => ({
          start: entry.start,
          end: entry.end,
          text: entry.text,
          confidence: entry.confidence,
          speaker: group.speaker,
          // Include any other necessary fields from the original entry
          ...entry
        }));
      });
      
      // Sort by start time
      updatedTranscriptionArray.sort((a, b) => a.start - b.start);
      
      console.log('Sending updated transcription:', updatedTranscriptionArray);

      // Use the service function to update the transcription
      const response = await updateTranscription(callData.call.id, updatedTranscriptionArray);

      // Log transcription edit
      await logUsage(FeatureStats.TRANSCRIPTIONS_EDITED);

      // Update the full transcription data
      setAllTranscriptionData(response.transcription || updatedTranscriptionArray);
      
      // Update state with new transcription
      setCallData(prev => ({
        ...prev,
        call: {
          ...prev.call,
          transcription: response.transcription || updatedTranscriptionArray,
        },
      }));

      // Reset pagination and fetch the first page with updated data
      setCurrentPage(1);
      setAllTranscriptionLoaded(false);
      
      // Fetch the updated transcription with grouped data
      const transcriptionResponse = await fetchTranscription(callData.call.id, 1, itemsPerPage);
      if (transcriptionResponse && transcriptionResponse.groupedTranscription) {
        setGroupedTranscription(transcriptionResponse.groupedTranscription);
        
        // Update pagination info
        if (transcriptionResponse.pagination) {
          setTotalPages(transcriptionResponse.pagination.totalPages);
          setTotalItems(transcriptionResponse.pagination.total);
          
          // Check if all data is loaded in the first page
          if (transcriptionResponse.pagination.page >= transcriptionResponse.pagination.totalPages) {
            setAllTranscriptionLoaded(true);
          }
        }
      }
      
      setIsEditingTranscription(false);
      
      toast({
        title: 'Transcription Updated',
        description: 'The transcription has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating transcription:', error);
      // Add more detailed error logging
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      
      toast({
        title: 'Update Failed',
        description: error.response?.data?.message || 'There was an error updating the transcription. The payload might be too large.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  // Handle approve action item
  const handleApproveActionItem = async (item, actionItemData = {}) => {
    try {
      setLoadingStates(prev => ({ ...prev, [item.id]: 'approving' }));
      
      const response = await approveActionItem(item.id, actionItemData);
      
      // Update action items
      setActionItems(prev => 
        prev.map(i => i.id === item.id ? { ...i, status: 'approved' } : i)
      );
      
      toast({
        title: 'Action item approved',
        description: 'The action item has been approved.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      
      return response;
    } catch (error) {
      console.error('Error approving action item:', error);
      toast({
        title: 'Error approving action item',
        description: 'Could not approve the action item. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw error;
    } finally {
      setLoadingStates(prev => ({ ...prev, [item.id]: null }));
    }
  };
  
  // Handle reject action item
  const handleRejectActionItem = async (item) => {
    try {
      setLoadingStates(prev => ({ ...prev, [item.id]: 'rejecting' }));
      
      const response = await rejectActionItem(item.id);
      
      // Update action items
      setActionItems(prev => 
        prev.map(i => i.id === item.id ? { ...i, status: 'rejected' } : i)
      );
      
      toast({
        title: 'Action item rejected',
        description: 'The action item has been rejected.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      
      return response;
    } catch (error) {
      console.error('Error rejecting action item:', error);
      toast({
        title: 'Error rejecting action item',
        description: 'Could not reject the action item. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw error;
    } finally {
      setLoadingStates(prev => ({ ...prev, [item.id]: null }));
    }
  };
  
  // Handle assign action item
  const handleAssignActionItem = async (itemId, userId) => {
    try {
      const response = await assignActionItem(itemId, userId);
      
      // Update action items
      setActionItems(prev => 
        prev.map(i => i.id === itemId ? { ...i, assigned_to: userId } : i)
      );
      
      toast({
        title: 'Action item assigned',
        description: 'The action item has been assigned.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      
      return response;
    } catch (error) {
      console.error('Error assigning action item:', error);
      toast({
        title: 'Error assigning action item',
        description: 'Could not assign the action item. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };
  
  // Handle date change
  const handleDateChange = async (itemId, date) => {
    try {
      //const response = await updateActionItemDueDate(itemId, date);
      
      // Update action items
      setActionItems(prev => 
        prev.map(i => i.id === itemId ? { ...i, due_date: date } : i)
      );
      
      toast({
        title: 'Due date updated',
        description: 'The due date has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      
      //return response;
    } catch (error) {
      console.error('Error updating due date:', error);
      toast({
        title: 'Error updating due date',
        description: 'Could not update the due date. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };
  
  // Handle send message
  const handleSendMessage = async (message) => {
    try {
      const response = await sendChatMessage(id, message);
      
      return response;
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Error sending message',
        description: 'Could not send the message. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };
  
  // Handle ask about submit
  const handleAskAboutSubmit = async (question, chatHistory = [], onChunk = null) => {
    try {
      
      // Call the service with streaming support
      const response = await askAboutCorrespondence(id, question, chatHistory, onChunk);
      
      return response;
    } catch (error) {
      console.error('Error asking about correspondence:', error);
      toast({
        title: 'Error asking question',
        description: 'Could not process your question. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };
  
  // Fetch CRM data
  const fetchCrmDataForUser = async () => {
    try {
      const crmData = await fetchCrmData();
      setCrm(crmData);
      return crmData;
    } catch (error) {
      console.error('Error fetching CRM data:', error);
      return null;
    }
  };

  // Include CRM data fetch in the initial data load
  useEffect(() => {
    if (id) {
      fetchCrmDataForUser();
    }
  }, [id]);

  return {
    callData,
    groupedTranscription: memoizedGroupedTranscription,
    actionItems,
    aiInsights,
    summary,
    aggregateInsights,
    keyInfo,
    clientName,
    emailReplies,
    questions,
    transcriptionDuration,
    crm,
    isLoading,
    isLoadingTranscription,
    isLoadingActionItems,
    isLoadingInsights,
    isLoadingQuestions,
    isEditingTitle,
    isEditingSummary,
    isEditingTranscription,
    // Pagination related
    currentPage,
    totalPages,
    totalItems,
    isLoadingMoreTranscription,
    loadMoreTranscription,
    allTranscriptionLoaded,
    // Tab state
    activeTab,
    setActiveTab,
    // Existing functions
    handleEditTitle,
    handleCancelEditTitle,
    handleSaveTitle,
    handleEditSummary,
    handleCancelEditSummary,
    handleSaveSummary,
    handleEditTranscription,
    handleCancelEditTranscription,
    handleSaveTranscription,
    handleTranscriptionChange,
    handleSpeakerUpdate,
    handleApproveActionItem,
    handleRejectActionItem,
    handleAssignActionItem,
    handleDateChange,
    handleSendMessage,
    handleAskAboutSubmit,
    fetchData,
    fetchCrmDataForUser
  };
};

export default useCorrespondence; 