/**
 * Groups emails by their thread ID, consolidating replies into the parent email
 * @param {Array} emails - Array of email objects
 * @returns {Array} - Array of emails with replies consolidated
 */
export const groupEmailsByThread = (emails) => {
    if (!emails || !Array.isArray(emails)) {
      return [];
    }
  
    // Create a map to store threads
    const threadMap = new Map();
    const standaloneEmails = [];
  
    // First pass: identify parent emails and create thread groups
    emails.forEach(email => {
      // Check if this email has thread information
      if (email.metadata?.threadId) {
        const threadId = email.metadata.threadId;
        
        // If this is the first email we've seen with this thread ID
        if (!threadMap.has(threadId)) {
          // Create a new thread entry with this email as parent
          threadMap.set(threadId, {
            ...email,
            replies: []
          });
        } else {
          // This is a reply to an existing thread
          const thread = threadMap.get(threadId);
          
          // Add this email as a reply
          thread.replies.push(email);
          
          // Sort replies by date (newest first)
          thread.replies.sort((a, b) => {
            const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt);
            const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt);
            return dateB - dateA;
          });
        }
      } else {
        // This is a standalone email (no thread)
        standaloneEmails.push(email);
      }
    });
  
    // Convert the thread map to an array
    const threadedEmails = Array.from(threadMap.values());
    
    // Combine threaded emails with standalone emails
    const result = [...threadedEmails, ...standaloneEmails];
    
    // Sort all emails by date (newest first)
    result.sort((a, b) => {
      const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt);
      const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt);
      return dateB - dateA;
    });
    
    return result;
  }; 

