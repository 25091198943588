export const getDateRangeParams = (timeRange, customStartDate, customEndDate) => {
  const dateParams = {};
  
  if (timeRange === 'custom' && customStartDate) {
    dateParams.startDate = customStartDate;
    if (customEndDate) {
      dateParams.endDate = customEndDate;
    }
  } else if (timeRange !== 'all') {
    const now = new Date();
    let startDate = new Date();
    
    switch (timeRange) {
      case 'today':
        startDate.setHours(0, 0, 0, 0);
        break;
      case 'week':
        startDate.setDate(now.getDate() - 7);
        break;
      case 'month':
        startDate.setMonth(now.getMonth() - 1);
        break;
      case 'quarter':
        startDate.setMonth(now.getMonth() - 3);
        break;
      case 'year':
        startDate.setFullYear(now.getFullYear() - 1);
        break;
      default:
        break;
    }
    
    dateParams.startDate = startDate.toISOString();
    dateParams.endDate = now.toISOString();
  }
  
  return dateParams;
};
