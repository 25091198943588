import React, { useState, useEffect } from 'react';
import {
  Box, HStack, Icon, IconButton, Text, 
  Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalBody, ModalFooter, ModalCloseButton, 
  Button, Radio, RadioGroup, Stack, Textarea,
  useDisclosure, useToast
} from '@chakra-ui/react';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import axios from 'axios';
import Cookies from 'js-cookie';

const FEEDBACK_OPTIONS = [
  "The answer was inaccurate",
  "The answer was not helpful",
  "The answer was incomplete",
  "The answer was not relevant to my question",
  "Something else"
];

const MessageFeedback = ({ messageId }) => {
  const serverUrl = process.env.REACT_APP_API_URL || '';
  const [rating, setRating] = useState(null);
  const [existingFeedback, setExistingFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [negativeOption, setNegativeOption] = useState("");
  const [customFeedback, setCustomFeedback] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // Helper function to get auth headers
  const getAuthHeaders = () => ({
    'Authorization': `Bearer ${Cookies.get('jwtToken')}`
  });

  // Fetch existing feedback if available
  useEffect(() => {
    if (messageId) {
      const fetchFeedback = async () => {
        try {
          console.log(`Fetching feedback for message ${messageId}`);
          const response = await axios.get(`${serverUrl}/api/messages/${messageId}/feedback`, { 
            headers: getAuthHeaders() 
          });
          console.log('Feedback response:', response.data);
          setExistingFeedback(response.data);
          setRating(response.data.rating);
        } catch (error) {
          // It's ok if no feedback exists yet
          console.log('Feedback fetch error:', error);
          if (error.response && error.response.status !== 404) {
            console.error("Error fetching feedback:", error);
          }
        }
      };
      
      fetchFeedback();
    }
  }, [messageId, serverUrl]);

  const handleFeedback = async (newRating) => {
    // If clicking the same rating again, remove the feedback
    if (rating === newRating) {
      try {
        setLoading(true);
        await axios.delete(`${serverUrl}/api/messages/${messageId}/feedback`, {
          headers: getAuthHeaders()
        });
        setRating(null);
        setExistingFeedback(null);
        toast({
          title: "Feedback removed",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error removing feedback:", error);
        toast({
          title: "Error removing feedback",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
      return;
    }

    // For thumbs up, just save the positive feedback
    if (newRating === 'like') {
      try {
        setLoading(true);
        await axios.post(`${serverUrl}/api/messages/${messageId}/feedback`, {
          rating: newRating
        }, {
          headers: getAuthHeaders()
        });
        setRating(newRating);
        toast({
          title: "Thank you for your feedback!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error saving feedback:", error);
        toast({
          title: "Error saving feedback",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      // For thumbs down, open the modal for more feedback
      setRating(newRating);
      onOpen();
    }
  };

  const submitDetailedFeedback = async () => {
    try {
      setLoading(true);
      
      // Prepare comment text from selected option and custom feedback
      let comment = negativeOption;
      if (negativeOption === "Something else" && customFeedback) {
        comment = customFeedback;
      } else if (customFeedback) {
        comment = `${negativeOption}. Additional feedback: ${customFeedback}`;
      }
      
      await axios.post(`${serverUrl}/api/messages/${messageId}/feedback`, {
        rating: 'dislike',
        comment
      }, {
        headers: getAuthHeaders()
      });
      
      toast({
        title: "Thank you for your feedback!",
        description: "Your feedback helps us improve.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      
      onClose();
    } catch (error) {
      console.error("Error saving detailed feedback:", error);
      toast({
        title: "Error saving feedback",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    // If canceling and no previous rating exists, reset rating
    if (!existingFeedback) {
      setRating(null);
    } else {
      setRating(existingFeedback.rating);
    }
    onClose();
  };

  return (
    <>
      <HStack spacing={2} mt={2} justify="flex-end">
        <Text fontSize="sm" color="gray.500">Was this response helpful?</Text>
        <IconButton
          icon={<Icon as={FaThumbsUp} />}
          size="sm"
          colorScheme={rating === 'like' ? 'green' : 'gray'}
          variant={rating === 'like' ? 'solid' : 'outline'}
          isLoading={loading && rating === 'like'}
          onClick={() => handleFeedback('like')}
          aria-label="Thumbs up"
        />
        <IconButton
          icon={<Icon as={FaThumbsDown} />}
          size="sm"
          colorScheme={rating === 'dislike' ? 'red' : 'gray'}
          variant={rating === 'dislike' ? 'solid' : 'outline'}
          isLoading={loading && rating === 'dislike'}
          onClick={() => handleFeedback('dislike')}
          aria-label="Thumbs down"
        />
      </HStack>

      <Modal isOpen={isOpen} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Help us improve</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text mb={4}>What was the issue with this response?</Text>
            
            <RadioGroup onChange={setNegativeOption} value={negativeOption} mb={4}>
              <Stack spacing={2}>
                {FEEDBACK_OPTIONS.map((option, index) => (
                  <Radio key={index} value={option}>
                    {option}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
            
            {(negativeOption === "Something else" || customFeedback) && (
              <Textarea
                placeholder="Please describe the issue with the response"
                value={customFeedback}
                onChange={(e) => setCustomFeedback(e.target.value)}
                mt={2}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button 
              colorScheme="blue" 
              mr={3} 
              onClick={submitDetailedFeedback}
              isLoading={loading}
              isDisabled={!negativeOption}
            >
              Submit
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MessageFeedback;
