/**
 * Application constants
 */

// Feature statistics paths for usage logging
export const FeatureStats = {
  TRANSCRIPT_VIEWS: 'transcript_views',
  AUDIO_PLAYS: 'audio_plays',
  SEARCH_USAGE: 'search_usage',
  EDIT_TITLE: 'edit_title',
  EDIT_SUMMARY: 'edit_summary',
  EDIT_TRANSCRIPTION: 'edit_transcription',
  ACTION_ITEM_APPROVE: 'action_item_approve',
  ACTION_ITEM_REJECT: 'action_item_reject',
  ACTION_ITEM_ASSIGN: 'action_item_assign',
  CHAT_MESSAGES: 'chat_messages',
  ASK_ABOUT: 'ask_about'
};

// Audio player settings
export const AudioPlayerSettings = {
  DEFAULT_BUFFER_AHEAD: 30, // seconds
  DEFAULT_CHUNK_SIZE: 1024 * 1024, // 1MB
  SKIP_FORWARD_SECONDS: 10,
  SKIP_BACKWARD_SECONDS: 5
};

// Loading states
export const LoadingStates = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error'
};

// Action item statuses
export const ActionItemStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
};

// Correspondence types
export const CorrespondenceType = {
  CALL: 'call',
  EMAIL: 'email'
}; 