/**
 * Utilities for processing and displaying transcriptions
 */

// Group transcription by speaker
export const groupTranscriptionBySpeaker = (transcription) => {
  if (!transcription || !transcription.length) return [];
  
  // If the transcription is already grouped by speaker, return it as is
  if (transcription.every(entry => entry.entries || entry.speaker)) {
    return transcription.map(entry => ({
      speaker: entry.speaker,
      entries: entry.entries || [entry]
    }));
  }
  
  // Group entries by speaker
  const groupedBySpeaker = transcription.reduce((groups, entry) => {
    const speaker = entry.speaker || 'Unknown';
    
    // Find existing group for this speaker
    const existingGroup = groups.find(group => group.speaker === speaker);
    
    if (existingGroup) {
      existingGroup.entries.push(entry);
    } else {
      groups.push({
        speaker,
        entries: [entry]
      });
    }
    
    return groups;
  }, []);
  
  return groupedBySpeaker;
};

// Format speaker name for display
export const formatSpeakerName = (speaker) => {
  if (!speaker) return 'Unknown';
  
  // If speaker is already a formatted name, return it
  if (typeof speaker === 'string' && speaker.includes(' ')) {
    return speaker;
  }
  
  // If speaker is an object with name properties
  if (typeof speaker === 'object') {
    if (speaker.firstName && speaker.lastName) {
      return `${speaker.firstName} ${speaker.lastName}`;
    }
    if (speaker.name) {
      return speaker.name;
    }
    if (speaker.emailAddress) {
      return speaker.emailAddress.name || speaker.emailAddress.address || 'Unknown';
    }
  }
  
  return speaker.toString();
};

// Filter transcription by search term
export const filterTranscriptionBySearchTerm = (groupedTranscription, searchTerm) => {
  if (!searchTerm) return groupedTranscription;
  
  const normalizedSearchTerm = searchTerm.toLowerCase();
  
  return groupedTranscription.filter(group =>
    group.entries.some(entry =>
      (entry.text?.toLowerCase() || '').includes(normalizedSearchTerm) ||
      (group.speaker?.toLowerCase() || '').includes(normalizedSearchTerm)
    )
  );
};

// Get highlighted text with search term
export const getHighlightedText = (text, searchTerm) => {
  if (!searchTerm || !text) return text;
  
  const normalizedSearchTerm = searchTerm.toLowerCase();
  const normalizedText = text.toLowerCase();
  const parts = [];
  
  let lastIndex = 0;
  let index = normalizedText.indexOf(normalizedSearchTerm, lastIndex);
  
  while (index !== -1) {
    // Add text before the match
    parts.push({
      text: text.substring(lastIndex, index),
      highlighted: false
    });
    
    // Add the matched text
    parts.push({
      text: text.substring(index, index + normalizedSearchTerm.length),
      highlighted: true
    });
    
    lastIndex = index + normalizedSearchTerm.length;
    index = normalizedText.indexOf(normalizedSearchTerm, lastIndex);
  }
  
  // Add the remaining text
  if (lastIndex < text.length) {
    parts.push({
      text: text.substring(lastIndex),
      highlighted: false
    });
  }
  
  return parts;
};

// Format title with date
export const formatTitleWithDate = (title) => {
  if (!title) return '';
  
  // Check if title already has a date
  const hasDate = /\d{1,2}\/\d{1,2}\/\d{2,4}/.test(title) || 
                 /\d{4}-\d{2}-\d{2}/.test(title);
  
  if (hasDate) return title;
  
  // Add today's date to the title
  const today = new Date();
  const formattedDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
  
  return `${title} - ${formattedDate}`;
};

// Extract timestamp from title
export const extractTimestampFromTitle = (title) => {
  if (!title) return null;
  
  // Look for date patterns in the title
  const datePattern = /(\d{1,2}\/\d{1,2}\/\d{2,4})|(\d{4}-\d{2}-\d{2})/;
  const match = title.match(datePattern);
  
  if (!match) return null;
  
  // Parse the date
  const dateStr = match[0];
  let date;
  
  if (dateStr.includes('/')) {
    // MM/DD/YYYY format
    const [month, day, year] = dateStr.split('/');
    date = new Date(
      parseInt(year.length === 2 ? `20${year}` : year),
      parseInt(month) - 1,
      parseInt(day)
    );
  } else {
    // YYYY-MM-DD format
    date = new Date(dateStr);
  }
  
  return isNaN(date.getTime()) ? null : date;
};

// Format category name
export const formatCategoryName = (categoryKey) => {
  if (!categoryKey) return '';
  
  return categoryKey
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
    .join(' ');
}; 