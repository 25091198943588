import React from 'react';
import { Heading, Text, Box, Container, Button } from '@chakra-ui/react';
import { useAuth } from '../AuthContext';

const AdminApprovalOverlay = () => {
  const { logout } = useAuth();

  return (
    <Box 
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bg="rgba(0, 0, 0, 0.7)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={9999}
    >
      <Container
        bg="white"
        p={8}
        borderRadius="lg"
        maxW="500px"
        textAlign="center"
        boxShadow="lg"
      >
        <Heading 
          as="h2" 
          size="lg" 
          color="#00417D" 
          mb={4}
        >
          Pending Admin Approval
        </Heading>
        <Text 
          fontSize="lg" 
          lineHeight="tall" 
          mb={4}
        >
          Your account is currently pending administrator approval. You&apos;ll receive an email notification once your access has been granted.
        </Text>
        <Text 
          fontSize="sm" 
          color="gray.600"
          mb={6}
        >
          For immediate assistance, please contact support at support@datadasher.ai
        </Text>
        <Button
          onClick={logout}
          size="md"
          variant="ghost"
          color="#00417D"
          _hover={{
            bg: 'blue.50',
          }}
        >
          Logout
        </Button>
      </Container>
    </Box>
  );
};

export default AdminApprovalOverlay; 