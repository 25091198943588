import React, { useState, useRef, useEffect } from 'react';
import {
  Box, VStack, HStack, Text, Heading, Input,
  Button, Divider, Avatar, Flex, Spinner,
  InputGroup, InputRightElement, Tabs, TabList,
  TabPanels, TabPanel, Tab, Icon, IconButton
} from '@chakra-ui/react';
import { ChatIcon, ArrowForwardIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { RiSparklingLine } from 'react-icons/ri';
import { useUser } from '../../../../contexts/UserContext';

// Define colors
const colors = {
  primary: "blue.500",
  secondary: "gray.600",
  accent: "blue.100"
};

/**
 * Component for chat and Q&A functionality
 */
const ChatPanel = ({
  callData,
  callId,
  callTranscription,
  handleSendMessage,
  handleAskAboutSubmit,
  questions
}) => {
  const { user } = useUser();
  const [activeTab, setActiveTab] = useState(0);
  const [chatInput, setChatInput] = useState('');
  const [askAboutInput, setAskAboutInput] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isAskAboutLoading, setIsAskAboutLoading] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [askAboutMessages, setAskAboutMessages] = useState([]);
  const [streamingAskAboutMessage, setStreamingAskAboutMessage] = useState('');
  const chatEndRef = useRef(null);
  const askAboutEndRef = useRef(null);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);

  useEffect(() => {
    if (askAboutEndRef.current) {
      askAboutEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [askAboutMessages]);

  // Initialize messages from callData
  useEffect(() => {
    if (callData?.chat?.messages) {
      setChatMessages(callData.chat.messages);
    }

    if (callData?.askAbout?.messages) {
      setAskAboutMessages(callData.askAbout.messages);
    }
  }, [callData]);

  // Handle chat input change
  const handleChatInputChange = (e) => {
    setChatInput(e.target.value);
  };

  // Handle ask about input change
  const handleAskAboutInputChange = (e) => {
    setAskAboutInput(e.target.value);
  };

  // Send chat message
  const onSendMessage = async () => {
    if (!chatInput.trim()) return;

    setIsSending(true);

    // Add user message to chat
    const userMessage = {
      id: Date.now(),
      text: chatInput,
      sender: user?.name || 'You',
      timestamp: new Date().toISOString(),
      isUser: true
    };

    setChatMessages(prev => [...prev, userMessage]);
    setChatInput('');

    try {
      // Send message to server
      const response = await handleSendMessage(chatInput);

      // Add response to chat
      if (response) {
        setChatMessages(prev => [...prev, {
          id: Date.now() + 1,
          text: response.text,
          sender: 'Assistant',
          timestamp: new Date().toISOString(),
          isUser: false
        }]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
    }
  };

  // Ask about the call
  const onAskAboutSubmit = async () => {
    if (!askAboutInput.trim()) return;

    setIsAskAboutLoading(true);
    setStreamingAskAboutMessage(''); // Clear any previous streaming message

    // Add user question to messages
    const userQuestion = {
      id: Date.now(),
      type: 'user',
      content: askAboutInput,
      timestamp: new Date().toISOString()
    };

    setAskAboutMessages(prev => [...prev, userQuestion]);
    setAskAboutInput('');

    try {
      // Get previous chat history for context
      const chatHistory = askAboutMessages.map(msg => ({
        type: msg.type,
        content: msg.content
      }));

      // Create a variable to store the complete response
      let completeResponse = '';

      // Use streaming response
      await handleAskAboutSubmit(
        askAboutInput,
        chatHistory,
        (chunk) => {
          completeResponse += chunk;
          setStreamingAskAboutMessage(prev => prev + chunk);
        }
      );

      // When streaming is complete, add the full message to the chat
      // Use the completeResponse variable instead of relying on the state
      setAskAboutMessages(prev => [
        ...prev,
        {
          id: Date.now() + 1,
          type: 'assistant',
          content: completeResponse,
          timestamp: new Date().toISOString()
        }
      ]);
      
      // Clear the streaming message after adding it to the chat
      setStreamingAskAboutMessage('');
    } catch (error) {
      console.error('Error asking about call:', error);
      // Add error message to chat
      setAskAboutMessages(prev => [
        ...prev,
        {
          id: Date.now() + 1,
          type: 'assistant',
          content: 'Sorry, I encountered an error while processing your question. Please try again.',
          timestamp: new Date().toISOString()
        }
      ]);
    } finally {
      setIsAskAboutLoading(false);
    }
  };

  // Handle key press for sending messages
  const handleKeyPress = (e, submitFn) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitFn();
    }
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      {/* Only show title and questions if there are generated questions */}
      {questions?.length > 0 && askAboutMessages.length === 0 ? (
        <VStack spacing={6} flex="1" overflowY="auto" p={2}>
          <VStack spacing={1} mt={4}>
            <Text
              fontSize="xl"
              fontWeight="bold"
              color={colors.primary}
              textAlign="center"
              lineHeight="1.2"
            >
              Ask Questions
            </Text>
            <Text
              fontSize="xl"
              fontWeight="bold"
              color={colors.primary}
              textAlign="center"
              lineHeight="1.2"
            >
              About This Call
            </Text>
          </VStack>

          <VStack align="center" spacing={6} flex="0 0 auto">
            {questions.slice(0, 3).map((question, index) => (
              <Box
                key={`preset-${index}`}
                p={6}
                bg="white"
                borderRadius="lg"
                borderWidth="1px"
                borderColor="gray.200"
                transition="all 0.2s"
                cursor="pointer"
                width="50%"
                mx="auto"
                onClick={() => {
                  setAskAboutInput(question.question);
                }}
                _hover={{
                  bg: 'gray.50',
                  borderColor: 'blue.200',
                  transform: 'translateY(-1px)',
                  boxShadow: 'md'
                }}
              >
                <HStack spacing={4}>
                  <Icon
                    as={RiSparklingLine}
                    color="blue.500"
                    boxSize={5}
                    transform="rotate(-10deg)"
                    _groupHover={{ transform: "rotate(-10deg) scale(1.1)" }}
                    transition="transform 0.2s"
                  />
                  <Text color="gray.700" fontSize="16px">{question.question}</Text>
                </HStack>
              </Box>
            ))}
          </VStack>
        </VStack>
      ) : (
        <VStack spacing={4} height="100%" flex="1" overflowY="auto" p={2}>
          {/* Chat messages will go here */}
          {askAboutMessages.map((message, index) => (
            <Box
              key={`${message.timestamp}-${index}`}
              bg={message.type === 'user' ? 'blue.50' : 'gray.50'}
              p={4}
              borderRadius="md"
              maxW="90%"
              alignSelf={message.type === 'user' ? 'flex-end' : 'flex-start'}
            >
              <Text fontWeight="bold" mb={2}>
                {message.type === 'user' ? 'You' : 'DataDasher'}
              </Text>
              <Text whiteSpace="pre-wrap">{message.content}</Text>
            </Box>
          ))}
          {streamingAskAboutMessage && (
            <Box
              bg="gray.50"
              p={4}
              borderRadius="md"
              maxW="90%"
              alignSelf="flex-start"
              key="streaming-message"
            >
              <Text fontWeight="bold" mb={2}>DataDasher</Text>
              <Text whiteSpace="pre-wrap">{streamingAskAboutMessage}</Text>
            </Box>
          )}
          {isAskAboutLoading && !streamingAskAboutMessage && (
            <Flex justify="flex-start" p={4}>
              <Spinner size="sm" mr={2} />
              <Text>Thinking...</Text>
            </Flex>
          )}
          <div ref={askAboutEndRef} />
        </VStack>
      )}

      {/* Input Area */}
      <Box mt="auto" pt={4} pb={2} borderTopWidth="1px" borderColor="gray.200">
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            placeholder="Ask about this call..."
            value={askAboutInput}
            onChange={handleAskAboutInputChange}
            onKeyDown={(e) => handleKeyPress(e, onAskAboutSubmit)}
          />
          <InputRightElement width="4.5rem">
            <IconButton
              h="1.75rem"
              size="sm"
              icon={<ArrowUpIcon />}
              colorScheme="blue"
              onClick={onAskAboutSubmit}
              isLoading={isAskAboutLoading}
            />
          </InputRightElement>
        </InputGroup>
      </Box>
    </Box>
  );
};

export default ChatPanel; 