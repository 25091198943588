import React from 'react';
import { VStack, Box, Button, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Icon, Text, HStack, Badge } from '@chakra-ui/react';
import { FaPhone, FaExternalLinkAlt } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import BlueBox from '../../../BlueBox';
import PropTypes from 'prop-types';

// Helper function to format title with date
const formatTitleWithDate = (title) => {
  if (!title) return 'Call Recording';
  
  // Check if title contains an ISO timestamp between ||
  const matches = title.match(/\|\|(.*?)\|\|/);
  if (!matches) return title;

  // Extract the parts
  const [fullMatch, isoTime] = matches;
  const baseTitle = title.replace(fullMatch, '').trim();
  
  // Format the date in user's local timezone
  const date = new Date(isoTime);
  const formattedDate = date.toLocaleString(undefined, {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });

  return `${baseTitle} on ${formattedDate}`;
};

const CallList = ({
  callHistory,
  loadingArchivedActions,
  client,
  handleActionUpdate,
  totalCalls,
  isLoadingMoreCalls,
  fetchCallHistory,
  callPage,
  showArchivedCorrespondence
}) => {
  return (
    <>
      <Accordion allowMultiple>
        {callHistory.map((item, index) => (
          <AccordionItem
            key={index}
            border="1px solid"
            borderColor={item.ActionItem?.some(action => action.aiCompletionStatus !== 'SUCCESS') ? "red.200" : "gray.200"}
            borderRadius="md"
            mb={4}
            position="relative"
            _hover={{
              borderColor: item.ActionItem?.some(action => action.aiCompletionStatus !== 'SUCCESS') ? "red.300" : "gray.300"
            }}
            transition="all 0.2s"
          >
            <AccordionButton>
              <Box flex="1">
                <HStack justify="space-between" w="100%">
                  <VStack align="start" spacing={1}>
                    <HStack>
                      <Icon
                        as={FaPhone}
                        color={item.ActionItem?.some(action => action.aiCompletionStatus !== 'SUCCESS') ? 'red.500' : 'green.500'}
                      />
                      <Text fontWeight="bold">
                        {formatTitleWithDate(item.title) || 'Call Recording'}
                      </Text>
                    </HStack>
                    <Text fontSize="sm" color="gray.600">
                      {new Date(item.startTime).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                      })}
                    </Text>
                  </VStack>
                  <HStack spacing={4}>
                    {item.ActionItem?.length > 0 && (
                      <Badge
                        colorScheme={item.ActionItem.some(action => action.aiCompletionStatus !== 'SUCCESS') ? "red" : "blue"}
                        variant={item.ActionItem.some(action => action.aiCompletionStatus !== 'SUCCESS') ? "solid" : "subtle"}
                      >
                        {item.ActionItem.filter(action => action.aiCompletionStatus === 'SUCCESS').length}
                        /{item.ActionItem.length} Actions
                      </Badge>
                    )}
                    <AccordionIcon />
                  </HStack>
                </HStack>
              </Box>
            </AccordionButton>

            <AccordionPanel pb={4}>
              <VStack align="stretch" spacing={4}>
                {item.summary && (
                  <Box>
                    <Text fontWeight="semibold" mb={2}>Summary</Text>
                    <Text>{item.summary}</Text>
                  </Box>
                )}

                <Box>
                  <VStack align="stretch" spacing={2}>
                    <BlueBox
                      actions={item.ActionItem}
                      client={client}
                      id={item.id}
                      type="call"
                      transcriptId={item.id}
                      onArchive={() => {}}
                      onActionUpdate={handleActionUpdate}
                    />
                  </VStack>
                </Box>

                <HStack justify="flex-end">
                  <Button
                    as={RouterLink}
                    to={`/transcripts/${item.id}`}
                    rightIcon={<FaExternalLinkAlt />}
                    colorScheme="blue"
                    variant="outline"
                    size="sm"
                  >
                    View Full Transcript
                  </Button>
                </HStack>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      {callHistory.length > 0 && totalCalls > callHistory.length && (
        <Box textAlign="center" mt={4}>
          <Button
            onClick={() => fetchCallHistory(callPage + 1)}
            isLoading={isLoadingMoreCalls}
            loadingText="Loading more..."
            colorScheme="blue"
            variant="outline"
          >
            Load More
          </Button>
        </Box>
      )}
    </>
  );
};

CallList.propTypes = {
  callHistory: PropTypes.array.isRequired,
  loadingArchivedActions: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  handleActionUpdate: PropTypes.func.isRequired,
  totalCalls: PropTypes.number.isRequired,
  isLoadingMoreCalls: PropTypes.bool.isRequired,
  fetchCallHistory: PropTypes.func.isRequired,
  callPage: PropTypes.number.isRequired,
  showArchivedCorrespondence: PropTypes.bool.isRequired
};

export default CallList; 