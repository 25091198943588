import axios from 'axios';
import Cookies from 'js-cookie';
import { FeatureStats } from '../enums/UsageStats';

const serverUrl = process.env.REACT_APP_API_URL;

// Helper function to get auth headers
const getAuthHeaders = () => ({
  'Authorization': `Bearer ${Cookies.get('jwtToken')}`
});

// Fetch basic correspondence metadata
export const fetchCorrespondenceMetadata = async (id) => {
  const isEmail = window.location.pathname.includes('/email/');
  const endpoint = isEmail ? 
    `${serverUrl}/api/emails/${id}/metadata` :
    `${serverUrl}/api/calls/${id}/metadata`;

  const response = await axios.get(endpoint, { 
    headers: getAuthHeaders(), 
    withCredentials: true 
  });
  
  return response.data;
};

// Fetch transcription data
export const fetchTranscription = async (id, page = 1, limit = 50) => {
  try {
    // Add a timestamp to prevent caching
    const timestamp = new Date().getTime();
    
    const response = await axios.get(
      `${serverUrl}/api/calls/${id}/transcription`,
      { 
        headers: getAuthHeaders(),
        params: { page, limit, _t: timestamp }
      }
    );
    
    // Check if the response contains the new format with grouped transcription
    if (response.data && response.data.groupedTranscription) {
      return {
        transcription: response.data.transcription || [],
        groupedTranscription: response.data.groupedTranscription || [],
        pagination: response.data.pagination || {
          total: response.data.transcription?.length || 0,
          page,
          limit,
          totalPages: Math.ceil((response.data.transcription?.length || 0) / limit)
        }
      };
    }
    
    // Handle legacy format
    if (response.data && Array.isArray(response.data)) {
      return {
        transcription: response.data,
        groupedTranscription: [], // Empty for legacy format
        pagination: {
          total: response.data.length,
          page: 1,
          limit: response.data.length,
          totalPages: 1
        }
      };
    } else if (response.data && response.data.transcription && Array.isArray(response.data.transcription)) {
      return {
        transcription: response.data.transcription,
        groupedTranscription: [], // Empty for legacy format
        pagination: {
          total: response.data.transcription.length,
          page: 1,
          limit: response.data.transcription.length,
          totalPages: 1
        }
      };
    } else {
      console.error('Unexpected transcription data format:', response.data);
      return {
        transcription: [],
        groupedTranscription: [],
        pagination: {
          total: 0,
          page: 1,
          limit,
          totalPages: 0
        }
      };
    }
  } catch (error) {
    console.error('Error fetching transcription:', error);
    return {
      transcription: [],
      groupedTranscription: [],
      pagination: {
        total: 0,
        page: 1,
        limit,
        totalPages: 0
      }
    };
  }
};

// Fetch summary data
export const fetchSummary = async (id) => {
  const response = await axios.get(
    `${serverUrl}/api/calls/${id}/summary`,
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

// Fetch audio URL
export const fetchAudioUrl = async (id) => {
  const response = await axios.get(
    `${serverUrl}/api/calls/${id}/audio-url`,
    { headers: getAuthHeaders() }
  );
  
  return response.data.audioUrl;
};

// Fetch correspondence data (call or email) - Legacy function for backward compatibility
export const fetchCorrespondence = async (id) => {
  const isEmail = window.location.pathname.includes('/email/');
  const endpoint = isEmail ? 
    `${serverUrl}/api/emails/${id}` :
    `${serverUrl}/transcripts?id=${id}`;

  const response = await axios.get(endpoint, { 
    headers: getAuthHeaders(), 
    withCredentials: true 
  });
  
  return response.data;
};

// Fetch action items for a correspondence
export const fetchActionItems = async (id) => {
  const response = await axios.get(
    `${serverUrl}/api/correspondence/${id}/action-items`,
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

export const updateEmailTitle = async (id, title) => {
  const response = await axios.post(
    `${serverUrl}/api/emails/${id}/update-title`,
    { title },
    { headers: getAuthHeaders() }
  );
};

export const updateEmailSummary = async (id, summary) => {
  const response = await axios.post(
    `${serverUrl}/api/emails/${id}/update-summary`,
    { summary },
    { headers: getAuthHeaders() }
  );
};

// Fetch questions for a call
export const fetchQuestions = async (id) => {
  try {
    const response = await axios.get(
      `${serverUrl}/api/calls/${id}/questions`,
      { headers: getAuthHeaders() }
    );

    // Log the response for debugging
    console.log('Questions API response:', response.data);
    
    // Handle different possible response formats
    if (response.data && Array.isArray(response.data)) {
      return response.data;
    } else if (response.data && response.data.questions && Array.isArray(response.data.questions)) {
      return response.data.questions;
    } else if (response.data && response.data.questions && response.data.questions.questions && 
               Array.isArray(response.data.questions.questions)) {
      return response.data.questions.questions;
    } else {
      console.error('Unexpected questions data format:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching questions:', error);
    return [];
  }
};

// Fetch insights for a call
export const fetchInsights = async (id, sourceType = 'call') => {
  try {
    const response = await axios.get(
      `${serverUrl}/api/insights`, 
      {
        params: { sourceType, sourceId: id },
        headers: getAuthHeaders()
      }
    );
    
    // Ensure we return an array of insights
    return response.data.insights || [];
  } catch (error) {
    console.error('Error fetching insights:', error);
    return [];
  }
};

// Fetch aggregate insights for a client
export const fetchAggregateInsights = async (clientId) => {
  const response = await axios.get(
    `${serverUrl}/api/clients/${clientId}/aggregate-insights`,
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

// Fetch key info for a client
export const fetchKeyInfo = async (clientId) => {
  const response = await axios.get(
    `${serverUrl}/api/clients/${clientId}/key-info`,
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

// Fetch email replies
export const fetchEmailReplies = async (emailId) => {
  const response = await axios.get(
    `${serverUrl}/api/emails/${emailId}/replies`,
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

// Update call title
export const updateCallTitle = async (id, title) => {
  const response = await axios.post(
    `${serverUrl}/api/calls/${id}/update-title`,
    { title },
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

// Update call summary
export const updateCallSummary = async (id, summary, topics) => {
  const response = await axios.post(
    `${serverUrl}/api/calls/${id}/update-summary`,
    { summary, topics },
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

// Update transcription
export const updateTranscription = async (id, transcription) => {
  const response = await axios.put(
    `${serverUrl}/update-transcript/${id}`,
    { 
      transcript: transcription,
      },
      {
        headers: getAuthHeaders()
      }
    );
  
  return response.data;
};

// Approve action item
export const approveActionItem = async (itemId, actionItemData = {}) => {
  const response = await axios.post(
    `${serverUrl}/api/actionItems/${itemId}/approve`,
    actionItemData,
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

// Reject action item
export const rejectActionItem = async (itemId) => {
  const response = await axios.post(
    `${serverUrl}/api/action-items/${itemId}/archive`,
    {},
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

// Assign action item
export const assignActionItem = async (itemId, userId) => {
  const response = await axios.post(
    `${serverUrl}/api/action-items/${itemId}/assign`,
    { userId },
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

// Update action item due date
export const updateActionItemDueDate = async (itemId, dueDate) => {
  const response = await axios.post(
    `${serverUrl}/api/action-items/${itemId}/update-due-date`,
    { dueDate },
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

// Log usage
export const logUsage = async (statPath, value = 1) => {
  await axios.post(
    `${serverUrl}/api/usage/log`,
    { statPath, value },
    { headers: getAuthHeaders() }
  );
};

// Send message in chat
export const sendChatMessage = async (correspondenceId, message) => {
  const response = await axios.post(
    `${serverUrl}/api/correspondence/${correspondenceId}/chat`,
    { message },
    { headers: getAuthHeaders() }
  );
  
  return response.data;
};

// Ask about correspondence
export const askAboutCorrespondence = async (correspondenceId, question, chatHistory = [], onChunk = null) => {
  try {
    // First, fetch the transcription data if not already provided
    let transcriptContent = [];
    
    // Determine if this is an email or call
    const isEmail = window.location.pathname.includes('/email/');
    
    if (!isEmail) {
      // For calls, fetch the transcription
      const transcriptionResponse = await fetchTranscription(correspondenceId);
      transcriptContent = transcriptionResponse.transcription || [];
      
      if (!transcriptContent.length) {
        console.error('No transcription content available for QA');
        throw new Error('No transcription content available');
      }
    } else {
      // For emails, we'll need to handle this differently
      // Currently, the /qa endpoint only supports transcripts
      // We'll need to adapt this for emails in the future
      console.warn('QA for emails is not yet supported with the /qa endpoint');
      return { text: "I'm sorry, but I can't answer questions about emails yet." };
    }
    
    // If onChunk is provided, use streaming
    if (onChunk) {
      try {
        const response = await fetch(`${serverUrl}/qa/transcript/${correspondenceId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders()
          },
          body: JSON.stringify({ 
            question, 
            transcriptContent,
            chatHistory 
          }),
        });

        if (!response.ok) {
          const errorData = await response.json().catch(() => ({ error: 'Unknown error' }));
          throw new Error(errorData.error || `Server error: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        
        let buffer = '';
        
        while (true) {
          const { value, done } = await reader.read();
          
          if (done) {
            break;
          }
          
          buffer += decoder.decode(value, { stream: true });
          
          // Process complete events from the buffer
          const lines = buffer.split('\n\n');
          buffer = lines.pop() || ''; // Keep the last incomplete chunk in the buffer
          
          for (const line of lines) {
            if (line.trim() === '') continue;
            
            // Extract the data part
            const dataMatch = line.match(/^data: (.+)$/);
            if (!dataMatch) continue;
            
            const data = dataMatch[1];
            
            if (data === '[DONE]') {
              // Stream completed
              return;
            }
            
            try {
              const parsed = JSON.parse(data);
              if (parsed.content) {
                onChunk(parsed.content);
              } else if (parsed.error) {
                throw new Error(parsed.error);
              }
            } catch (e) {
              console.error('Error parsing streaming data:', e);
            }
          }
        }
      } catch (error) {
        console.error('Error in streaming QA:', error);
        // Call onChunk with an error message so the UI can display it
        onChunk(`Error: ${error.message || 'Failed to process your question'}`);
        throw error;
      }
      
      return;
    } else {
      // Use non-streaming approach for backward compatibility
      const response = await axios.post(
        `${serverUrl}/qa/transcript/${correspondenceId}`,
        { 
          question, 
          transcriptContent,
          chatHistory 
        },
        { headers: getAuthHeaders() }
      );
      
      // Format the response to match what the UI expects
      return { text: response.data.content || response.data };
    }
  } catch (error) {
    console.error('Error asking about correspondence:', error);
    throw error;
  }
};

// Update speaker in transcription
export const updateSpeaker = async (callId, currentSpeaker, newSpeaker, groupIndex = null, applyToAll = false) => {
  try {
    console.log('Updating speaker:', { callId, currentSpeaker, newSpeaker, groupIndex, applyToAll });
    
    // Send only the necessary information to the server
    const response = await axios.put(
      `${serverUrl}/api/calls/${callId}/update-speaker`,
      { 
        currentSpeaker,
        newSpeaker: typeof newSpeaker === 'string' ? newSpeaker : newSpeaker.name,
        groupIndex,
        applyToAll
      },
      {
        headers: getAuthHeaders()
      }
    );
    
    console.log('Speaker update response:', response.data);
    
    // Log speaker name reassignment
    await logUsage(FeatureStats.SPEAKER_NAMES_REASSIGNED);
    
    return response.data;
  } catch (error) {
    console.error('Error updating speaker:', error);
    throw error;
  }
};

// Fetch CRM data for the current user
export const fetchCrmData = async () => {
  try {
    const response = await axios.get(
      `${serverUrl}/api/crm`,
      { headers: getAuthHeaders() }
    );
    
    return response.data.crm;
  } catch (error) {
    console.error('Error fetching CRM data:', error);
    return null;
  }
};