import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Card, 
  CardBody, 
  VStack, 
  Heading, 
  Stat, 
  StatLabel, 
  StatNumber,
  Text,
  HStack,
  Icon,
  Link,
  Divider,
  Button,
  Input,
  Textarea,
  Select,
  useToast,
} from '@chakra-ui/react';
import { PhoneIcon, EmailIcon, InfoIcon, WalletIcon, EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import axios from 'axios';

const defaultObjectives = [
  "Wealth Growth",
  "Preservation",
  "Retirement Planning",
  "Estate Planning",
  "Tax Optimization",
  "College Savings",
  "Business Succession",
  "Legacy Planning"
];

const defaultGoals = [
  "Retirement",
  "College Planning",
  "Home Purchase",
  "Philanthropy",
  "Business Sale",
  "Tax Sheltering",
  "Debt Reduction",
  "Emergency Fund",
  "Travel",
  "Estate Planning"
];

const OverviewPanel = ({ client }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;

  const [newTagInput, setNewTagInput] = useState('');
  const [newObjectiveInput, setNewObjectiveInput] = useState('');
  const [newGoalInput, setNewGoalInput] = useState('');

  const [existingTags, setExistingTags] = useState([]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatMoney = (amount) => {
    if (!amount) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  // Find birthdate from special dates
  const birthdate = client?.metadata?.specialDates?.find(date => date.type === 'Birthdate');
  const birthdateString = birthdate ? `${birthdate.month}/${birthdate.day}/${birthdate.year}` : null;

  const handleEditClick = () => {
    setEditedData({
      firstName: client.firstName,
      lastName: client.lastName,
      email: client.email,
      phone: client.phone,
      metadata: { ...client.metadata }
    });
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedData(null);
  };

  const handleSave = async () => {
    try {
      await axios.put(`${serverUrl}/api/clients/${client.id}`, editedData);
      toast({
        title: 'Changes saved',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsEditing(false);
      window.location.reload();
    } catch (error) {
      toast({
        title: 'Error saving changes',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('metadata.')) {
      const metadataField = name.split('.')[1];
      setEditedData(prev => ({
        ...prev,
        metadata: {
          ...prev.metadata,
          [metadataField]: value
        }
      }));
    } else {
      setEditedData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const showEditButton = !client?.crmSource;

  const renderEditableField = (label, name, value, type = "text") => {
    if (isEditing) {
      return (
        <VStack align="start" spacing={1}>
          <Text fontWeight="medium" color="gray.600">{label}</Text>
          {type === "textarea" ? (
            <Textarea
              name={name}
              value={value}
              onChange={handleChange}
              size="sm"
            />
          ) : (
            <Input
              name={name}
              value={value}
              onChange={handleChange}
              type={type}
              size="sm"
            />
          )}
        </VStack>
      );
    }
    return (
      <VStack align="start" spacing={1}>
        <Text fontWeight="medium" color="gray.600">{label}</Text>
        <Text>{value || 'N/A'}</Text>
      </VStack>
    );
  };

  const renderEditableSelect = (label, name, value, options) => {
    if (isEditing) {
      return (
        <VStack align="start" spacing={1}>
          <Text fontWeight="medium" color="gray.600">{label}</Text>
          <Select
            name={name}
            value={value || ""}
            onChange={handleChange}
            size="sm"
          >
            <option value="">Select {label}</option>
            {options.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Select>
        </VStack>
      );
    }
    return (
      <VStack align="start" spacing={1}>
        <Text fontWeight="medium" color="gray.600">{label}</Text>
        <Text>{value || 'N/A'}</Text>
      </VStack>
    );
  };

  const renderEditableArray = (label, name, value, options = [], existingTags = []) => {
    if (isEditing) {
      const inputState = {
        tags: [newTagInput, setNewTagInput],
        investmentObjectives: [newObjectiveInput, setNewObjectiveInput],
        financialGoals: [newGoalInput, setNewGoalInput]
      }[name];

      if (!inputState) return null;

      const [inputValue, setInputValue] = inputState;

      return (
        <VStack align="start" spacing={1} width="100%">
          <Text fontWeight="medium" color="gray.600">{label}</Text>
          
          {/* Dropdown for existing options */}
          <Select
            placeholder={`Select ${label}`}
            value=""
            onChange={(e) => {
              const newValue = e.target.value;
              if (newValue) {
                const currentArray = editedData.metadata[name] || [];
                if (!currentArray.includes(newValue)) {
                  setEditedData(prev => ({
                    ...prev,
                    metadata: {
                      ...prev.metadata,
                      [name]: [...currentArray, newValue]
                    }
                  }));
                }
              }
            }}
            size="sm"
          >
            {options.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Select>

          {/* Custom input field */}
          <HStack width="100%">
            <Input
              placeholder={`Add custom ${label.toLowerCase()}`}
              size="sm"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && inputValue) {
                  const currentArray = editedData.metadata[name] || [];
                  if (!currentArray.includes(inputValue)) {
                    setEditedData(prev => ({
                      ...prev,
                      metadata: {
                        ...prev.metadata,
                        [name]: [...currentArray, inputValue]
                      }
                    }));
                  }
                  setInputValue('');
                }
              }}
            />
            <Button
              size="sm"
              onClick={() => {
                if (inputValue) {
                  const currentArray = editedData.metadata[name] || [];
                  if (!currentArray.includes(inputValue)) {
                    setEditedData(prev => ({
                      ...prev,
                      metadata: {
                        ...prev.metadata,
                        [name]: [...currentArray, inputValue]
                      }
                    }));
                  }
                  setInputValue('');
                }
              }}
            >
              Add
            </Button>
          </HStack>

          {/* Display selected items */}
          <HStack spacing={2} flexWrap="wrap">
            {(editedData.metadata[name] || []).map((item, index) => (
              <Button
                key={index}
                size="sm"
                variant="outline"
                rightIcon={<CloseIcon w={2} h={2} />}
                onClick={() => {
                  setEditedData(prev => ({
                    ...prev,
                    metadata: {
                      ...prev.metadata,
                      [name]: prev.metadata[name].filter((_, i) => i !== index)
                    }
                  }));
                }}
              >
                {item}
              </Button>
            ))}
          </HStack>
        </VStack>
      );
    }

    // Display mode (not editing)
    return (
      <VStack align="start" spacing={1}>
        <Text fontWeight="medium" color="gray.600">{label}</Text>
        <HStack spacing={2} flexWrap="wrap">
          {Array.isArray(value) ? 
            value.map((item, index) => (
              <Text key={index} px={2} py={1} bg="blue.50" borderRadius="md">
                {item}
              </Text>
            ))
            :
            value?.split(',')
              .filter(item => item.trim())
              .map((item, index) => (
                <Text key={index} px={2} py={1} bg="blue.50" borderRadius="md">
                  {item.trim()}
                </Text>
              ))
          }
        </HStack>
      </VStack>
    );
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/client-tags`);
        setExistingTags(response.data.tags);
      } catch (error) {
        console.error('Error fetching tags:', error);
        toast({
          title: 'Error fetching tags',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchTags();
  }, [serverUrl, toast]);

  return (
    <VStack spacing={6} align="stretch">

      {/* Contact Information Card */}
      <Card>
        <CardBody>
          <VStack align="stretch" spacing={4}>
            <Heading size="md" color="blue.600">Contact Information</Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={6}>
              {renderEditableField("Primary Email", "email", isEditing ? editedData?.email : client?.email, "email")}
              {renderEditableField("Primary Phone", "phone", isEditing ? editedData?.phone : client?.phone, "tel")}
              {renderEditableField("Secondary Email", "metadata.emailSecondary", 
                isEditing ? editedData?.metadata?.emailSecondary : client?.metadata?.emailSecondary, "email")}
              {renderEditableField("Secondary Phone", "metadata.phoneSecondary", 
                isEditing ? editedData?.metadata?.phoneSecondary : client?.metadata?.phoneSecondary, "tel")}
              {renderEditableField("Preferred Contact", "metadata.preferredMethodOfContact", 
                isEditing ? editedData?.metadata?.preferredMethodOfContact : client?.metadata?.preferredMethodOfContact)}
              {renderEditableField("Meeting Frequency", "metadata.preferredMeetingFrequency", 
                isEditing ? editedData?.metadata?.preferredMeetingFrequency : client?.metadata?.preferredMeetingFrequency)}
            </Grid>
          </VStack>
        </CardBody>
      </Card>

      {/* Personal Information */}
      <Card>
        <CardBody>
          <VStack align="stretch" spacing={4}>
            <Heading size="md" color="blue.600">Personal Information</Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4}>
              {renderEditableField("First Name", "firstName", 
                isEditing ? editedData?.firstName : client?.firstName)}
              {renderEditableField("Last Name", "lastName", 
                isEditing ? editedData?.lastName : client?.lastName)}
              {renderEditableField("Preferred Name", "metadata.preferredName", 
                isEditing ? editedData?.metadata?.preferredName : client?.metadata?.preferredName)}
              {renderEditableField("Prefix", "metadata.prefix", 
                isEditing ? editedData?.metadata?.prefix : client?.metadata?.prefix)}
              {renderEditableField("Middle Name", "metadata.middle", 
                isEditing ? editedData?.metadata?.middle : client?.metadata?.middle)}
              {renderEditableField("Suffix", "metadata.suffix", 
                isEditing ? editedData?.metadata?.suffix : client?.metadata?.suffix)}
              {renderEditableSelect("Marital Status", "metadata.maritalStatus", 
                isEditing ? editedData?.metadata?.maritalStatus : client?.metadata?.maritalStatus,
                ["Single", "Married", "Divorced", "Widowed", "Separated"])}
              {renderEditableSelect("Gender", "metadata.gender", 
                isEditing ? editedData?.metadata?.gender : client?.metadata?.gender,
                ["Male", "Female", "Other", "Prefer not to say"])}
              {renderEditableField("Citizenship", "metadata.citizenship", 
                isEditing ? editedData?.metadata?.citizenship : client?.metadata?.citizenship)}
            </Grid>
          </VStack>
        </CardBody>
      </Card>

      {/* Special Dates */}
      <Card>
        <CardBody>
          <VStack align="stretch" spacing={4}>
            <Heading size="md" color="blue.600">Special Dates</Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4}>
              {client?.metadata?.specialDates?.map((date, index) => (
                <VStack key={index} align="start" spacing={1}>
                  <Text fontWeight="medium" color="gray.600">{date.type}</Text>
                  <Text>{`${date.month}/${date.day}/${date.year}`}</Text>
                </VStack>
              ))}
              {(!client?.metadata?.specialDates || client?.metadata?.specialDates?.length === 0) && (
                <Text color="gray.500">No special dates recorded</Text>
              )}
            </Grid>
          </VStack>
        </CardBody>
      </Card>

      {/* Professional & Financial Information */}
      <Card>
        <CardBody>
          <VStack align="stretch" spacing={4}>
            <Heading size="md" color="blue.600">Professional & Financial Information</Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4}>
              {renderEditableField("Job Title", "metadata.jobTitle", 
                isEditing ? editedData?.metadata?.jobTitle : client?.metadata?.jobTitle)}
              {renderEditableField("Company", "metadata.company", 
                isEditing ? editedData?.metadata?.company : client?.metadata?.company)}
              {renderEditableField("Annual Income", "metadata.annualIncome", 
                isEditing ? editedData?.metadata?.annualIncome : formatMoney(client?.metadata?.annualIncome), "number")}
              {renderEditableField("Net Worth", "metadata.netWorth", 
                isEditing ? editedData?.metadata?.netWorth : formatMoney(client?.metadata?.netWorth), "number")}
              {renderEditableSelect("Wealth Range", "metadata.wealthRange", 
                isEditing ? editedData?.metadata?.wealthRange : client?.metadata?.wealthRange,
                ["Mass Affluent", "High Net Worth", "Very High Net Worth", "Ultra High Net Worth"])}
            </Grid>
            {renderEditableArray("Investment Objectives", "investmentObjectives", 
              client?.metadata?.investmentObjectives, defaultObjectives)}
            {renderEditableArray("Financial Goals", "financialGoals", 
              client?.metadata?.financialGoals, defaultGoals)}
          </VStack>
        </CardBody>
      </Card>

      {/* Address Information */}
      <Card>
        <CardBody>
          <VStack align="stretch" spacing={4}>
            <Heading size="md" color="blue.600">Address</Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
              {renderEditableField("Street Line 1", "metadata.streetLine1", 
                isEditing ? editedData?.metadata?.streetLine1 : client?.metadata?.streetLine1)}
              {renderEditableField("Street Line 2", "metadata.streetLine2", 
                isEditing ? editedData?.metadata?.streetLine2 : client?.metadata?.streetLine2)}
              {renderEditableField("City", "metadata.city", 
                isEditing ? editedData?.metadata?.city : client?.metadata?.city)}
              {renderEditableField("State", "metadata.state", 
                isEditing ? editedData?.metadata?.state : client?.metadata?.state)}
              {renderEditableField("Country", "metadata.country", 
                isEditing ? editedData?.metadata?.country : client?.metadata?.country)}
            </Grid>
          </VStack>
        </CardBody>
      </Card>

      {/* Family Information */}
      <Card>
        <CardBody>
          <VStack align="stretch" spacing={4}>
            <Heading size="md" color="blue.600">Family Information</Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
              {renderEditableField("Household Role", "metadata.householdRole", 
                isEditing ? editedData?.metadata?.householdRole : client?.metadata?.householdRole)}
              {renderEditableField("Household Name", "metadata.householdName", 
                isEditing ? editedData?.metadata?.householdName : client?.metadata?.householdName)}
              {renderEditableField("Spouse Name", "metadata.spouseName", 
                isEditing ? editedData?.metadata?.spouseName : client?.metadata?.spouseName)}
            </Grid>
            {renderEditableField("Spouse Details", "metadata.spouseDetails", 
              isEditing ? editedData?.metadata?.spouseDetails : client?.metadata?.spouseDetails, "textarea")}
            {renderEditableField("Family Details", "metadata.familyDetails", 
              isEditing ? editedData?.metadata?.familyDetails : client?.metadata?.familyDetails, "textarea")}
          </VStack>
        </CardBody>
      </Card>

      {/* Background Information */}
      <Card>
        <CardBody>
          <VStack align="stretch" spacing={4}>
            <Heading size="md" color="blue.600">Background Information</Heading>
            {renderEditableField("Background", "metadata.background", 
              isEditing ? editedData?.metadata?.background : client?.metadata?.background, "textarea")}
          </VStack>
        </CardBody>
      </Card>

      {/* Tags */}
      <Card>
        <CardBody>
          <VStack align="stretch" spacing={4}>
            <Heading size="md" color="blue.600">Tags</Heading>
            {renderEditableArray("Tags", "tags", client?.metadata?.tags, [], existingTags)}
          </VStack>
        </CardBody>
      </Card>
    </VStack>
  );
};

OverviewPanel.propTypes = {
  client: PropTypes.object.isRequired
};

export default OverviewPanel; 