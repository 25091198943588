import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Heading,
  VStack,
  Button,
  Image,
  Grid,
  Tooltip,
  useToast,
  useBreakpointValue,
  HStack,
  Skeleton,
  SkeletonText
} from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import { InfoIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { motion, AnimatePresence } from 'framer-motion';

const Integrations = () => {
  const [isSalesforceConnected, setIsSalesforceConnected] = useState(false);
  const serverUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [isRedtailConnected, setIsRedtailConnected] = useState(false);
  const [isZoomConnected, setIsZoomConnected] = useState(false);
  const [isMicrosoftConnected, setIsMicrosoftConnected] = useState(false);
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);
  const [isWealthboxConnected, setIsWealthboxConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getConnections();

    // Check for toast message in query parameters
    const params = new URLSearchParams(location.search);
    const toastMessage = params.get('toast');
    if (toastMessage) {
      toast({
        title: "Notification",
        description: toastMessage,
        status: "error",
        duration: null, // Make the toast last forever
        isClosable: true,
      });
    }
  }, [location.search]);

  function getConnections() {
    setIsLoading(true);
    const token = Cookies.get('jwtToken');

    Promise.all([
      axios.get(`${serverUrl}/api/organization-connections`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
      }),
      axios.get(`${serverUrl}/api/user-connections`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
      })
    ])
    .then(([orgRes, userRes]) => {
      setIsSalesforceConnected(orgRes.data.some(crm => crm.name.toLowerCase() === 'salesforce'));
      setIsRedtailConnected(orgRes.data.some(crm => crm.name.toLowerCase() === 'redtail'));
      setIsWealthboxConnected(orgRes.data.some(crm => crm.name.toLowerCase() === 'wealthbox'));
      
      const zoomConnected = userRes.data.some(connection => connection.name.toLowerCase() === 'zoom');
      setIsZoomConnected(zoomConnected);
      const microsoftConnected = userRes.data.some(connection => connection.name.toLowerCase() === 'microsoft');
      setIsMicrosoftConnected(microsoftConnected);
      const googleConnected = userRes.data.some(connection => connection.name.toLowerCase() === 'google');
      setIsGoogleConnected(googleConnected);
    })
    .catch(err => {
      console.error("Error fetching connections", err);
      setIsSalesforceConnected(false);
      setIsRedtailConnected(false);
      setIsWealthboxConnected(false);
      setIsZoomConnected(false);
      setIsMicrosoftConnected(false);
      setIsGoogleConnected(false);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  function connectToSalesforce() {
    const token = Cookies.get('jwtToken');
    window.location.href = `${serverUrl}/salesforce/auth?token=${token}`;
  }

  function connectToRedTail() {
    navigate('/redtail/connect');
  }

  function connectToWealthbox() {
    const token = Cookies.get('jwtToken');
    window.location.href = `${serverUrl}/wealthbox/auth?token=${token}`;
  }

  function connectToOutlook() {
    window.location.href = `${serverUrl}/outlook/connect`;
  }

  function connectToZoom() {
    const token = Cookies.get('jwtToken');
  
    axios.delete(`${serverUrl}/api/zoom-oauth-credentials`, {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true
    })
    .then(response => {
      console.log('Zoom OAuth App Credentials deleted successfully:', response.data);
      // Navigate to the new URL only after successful deletion
      window.location.href = `${serverUrl}/auth/zoom?token=${token}`;
    })
    .catch(error => {
      console.error('Error deleting Zoom OAuth App Credentials:', error.response ? error.response.data : error.message);
      // Optionally, handle the error in the UI
    });
  }

  function connectToGoogle() {
    const token = Cookies.get('jwtToken');
    window.location.href = `${serverUrl}/google/connect?token=${token}`;
  }
  
  // Determine the number of columns based on screen size
  const orgGridColumns = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });
  const userGridColumns = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });

  const isAnyCrmConnected = (redtail, wealthbox, salesforce) => {
    return redtail || wealthbox || salesforce;
  };

  const isAnyUserIntegrationConnected = (microsoft, google) => {
    return false;
    // return microsoft || google;
  };

  return (
    <Box bg="white" p={8}>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Heading color={'#00417D'} fontSize={'32px'}>Integrations</Heading>
        
        {/* Organization Level Integrations */}
        <Box display="flex" alignItems="center">
          <Heading color={'#00417D'} fontSize={'24px'} mt={8}>Organization Level Integrations</Heading>
          <Tooltip 
            label="Connecting these integrations will grant DataDasher access for all users within your DataDasher organization, provided they have registered a DataDasher account. For example, if your email is john@financialadvisory.com and you are part of the Financial Advisory organization in DataDasher, then jane@financialadvisory.com, also in the same organization, will have access to the integration and be able to view clients within the integrated CRM."
            maxWidth="500px"
          >          
            <span style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '15px', verticalAlign: 'middle' }}>
              <FaInfoCircle />
            </span>
          </Tooltip>
        </Box>

        <AnimatePresence mode="wait">
          <motion.div
            key={isLoading ? 'loading' : 'loaded'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <VStack spacing={4} mt={4} align="stretch">
              {isLoading ? (
                <LoadingSkeleton itemCount={3} />
              ) : (
                <>
                  <motion.div
                    initial={{ opacity: 0, y:20 }}
                    animate={{ opacity: 1, y:0 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    <ActionBox 
                      image={<Image src="/images/Redtail.png" alt="Redtail" boxSize="100px" objectFit="contain"/>}
                      description="Redtail"
                      description2={<Text mt={1}>Automatically update CRM fields and tasks in Redtail from your email and calls.</Text>}
                      buttonText={isRedtailConnected ? "Connected" : "Connect"}
                      buttonAction={connectToRedTail}
                      isDisabled={isAnyCrmConnected(isWealthboxConnected, isSalesforceConnected)}
                      disabledTooltip={isRedtailConnected ? 
                        "You are currently connected to Redtail. Please contact support@datadasher.com if you'd like to switch to a different CRM." :
                        "You can only connect to one CRM at a time. Please contact support@datadasher.com to disconnect your current CRM before connecting to Redtail."}
                    />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, y:20 }}
                    animate={{ opacity: 1, y:0 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                  >
                    <ActionBox 
                      image={<Image src="/images/Wealthbox.png" alt="Wealthbox" boxSize="100px" objectFit="scale-down"/>}
                      description="Wealthbox"
                      description2={<Text mt={1}>Automatically update CRM fields and tasks in Wealthbox from your email and calls.</Text>}
                      buttonText={isWealthboxConnected ? "Connected" : "Connect"}
                      buttonAction={connectToWealthbox}
                      isDisabled={isAnyCrmConnected(isRedtailConnected, isSalesforceConnected)}
                      disabledTooltip={isWealthboxConnected ? 
                        "You are currently connected to Wealthbox. Please contact support@datadasher.com if you'd like to switch to a different CRM." :
                        "You can only connect to one CRM at a time. Please contact support@datadasher.com to disconnect your current CRM before connecting to Wealthbox."}
                    />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, y:20 }}
                    animate={{ opacity: 1, y:0 }}
                    transition={{ duration: 0.3, delay: 0.3 }}
                  >
                    <ActionBox 
                      image={<Image src="/images/Salesforce.png" alt="Salesforce" boxSize="100px" objectFit="contain"/>}
                      description="Salesforce"
                      description2={
                        <VStack spacing={2}>
                          <Text mt={1}>Automatically update CRM fields and tasks in Salesforce from your email and calls.</Text>
                        </VStack>
                      }
                      buttonText={isSalesforceConnected ? "Connected" : "Connect"}
                      buttonAction={connectToSalesforce}
                      isDisabled={isAnyCrmConnected(isRedtailConnected, isWealthboxConnected)}
                      disabledTooltip={isSalesforceConnected ? 
                        "You are currently connected to Salesforce. Please contact support@datadasher.com if you'd like to switch to a different CRM." :
                        "You can only connect to one CRM at a time. Please contact support@datadasher.com to disconnect your current CRM before connecting to Salesforce."}
                    />
                  </motion.div>
                </>
              )}
            </VStack>
          </motion.div>
        </AnimatePresence>

        {/* User Level Integrations */}
        <Box display="flex" alignItems="center">
          <Heading color={'#00417D'} fontSize={'24px'} mt={8}>User Level Integrations</Heading>
          <Tooltip 
            label="User Level Integrations allow individual users to connect their accounts to DataDasher. This means that each user can access and manage their own integrations independently, ensuring that their data remains private and secure. For example, if you connect your individual email or calendar, only you will have access to that information within DataDasher."
            maxWidth="500px"
          >          
            <span style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '15px', verticalAlign: 'middle' }}>
              <FaInfoCircle />
            </span>
          </Tooltip>
        </Box>

        <AnimatePresence mode="wait">
          <motion.div
            key={isLoading ? 'loading' : 'loaded'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <VStack spacing={4} mt={4} align="stretch">
              {isLoading ? (
                <LoadingSkeleton itemCount={2} />
              ) : (
                <>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.4 }}
                  >
                    <ActionBox 
                      image={
                        <Image 
                          src="/icons/Microsoft_Office_Outlook.svg" 
                          alt="Outlook" 
                          boxSize="100px" 
                          objectFit="contain"
                        />
                      }
                      description="Outlook"
                      description2={<Text mt={1}>Use emails from Outlook as an information source for your clients.</Text>}
                      buttonText={isMicrosoftConnected ? "Connected" : "Connect"}
                      buttonAction={connectToOutlook}
                      isDisabled={isAnyUserIntegrationConnected(isMicrosoftConnected, isGoogleConnected)}
                      disabledTooltip={
                        isMicrosoftConnected 
                          ? "You are currently connected to Outlook. Please contact support@datadasher.com if you'd like to switch to a different integration." 
                          : ""
                      }
                    />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.5 }}
                  >
                    <ActionBox
                      image={
                        <Image
                          src="/icons/google-calendar-logo.svg"
                          alt="Google"
                          boxSize="100px"
                          objectFit="contain"
                        />
                      }
                      description="Google"
                      description2={<Text mt={1}>Use emails from Gmail as an information source for your clients.</Text>}
                      buttonText={isGoogleConnected ? "Connected" : "Connect"}
                      buttonAction={connectToGoogle}
                      // isDisabled={isAnyUserIntegrationConnected(isMicrosoftConnected, isGoogleConnected)}
                      // disabledTooltip={
                      //   isGoogleConnected 
                      //     ? "You are currently connected to Google. Please contact support@datadasher.com if you'd like to switch to a different integration." 
                      //     : ""
                      // }
                      isDisabled={true}
                      disabledTooltip="Coming Soon!"
                    />
                  </motion.div>
                </>
              )}
            </VStack>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </Box>
  );
};

const LoadingSkeleton = ({ itemCount }) => {
  return (
    <>
      {[...Array(itemCount)].map((_, index) => (
        <Box
          key={index}
          bg="#F5F5F5"
          p={6}
          borderRadius="12px"
          border="1px"
          borderColor="gray.200"
          mb={4}
        >
          <HStack spacing={6} align="center">
            <Skeleton height="100px" width="100px" flexShrink={0} />
            <VStack flex={1} align="flex-start" spacing={2}>
              <SkeletonText noOfLines={1} skeletonHeight="6" width="40%" />
              <SkeletonText noOfLines={2} skeletonHeight="4" width="80%" />
            </VStack>
            <Box borderLeft="1px" borderColor="gray.200" pl={6}>
              <Skeleton height="50px" width="150px" />
            </Box>
          </HStack>
        </Box>
      ))}
    </>
  );
};

const ActionBox = ({ 
  image, 
  description, 
  description2, 
  buttonText, 
  buttonAction, 
  isDisabled = false,
  disabledTooltip
}) => {
  return (
    <Box 
      bg="#F5F5F5" 
      p={6}
      mb={4}
      borderRadius="12px"
      border="1px"
      borderColor="gray.200"
      boxShadow="sm"
      transition="all 0.3s ease"
      _hover={{ 
        boxShadow: 'md',
        transform: 'translateY(-2px)'
      }}
      opacity={isDisabled && buttonText !== "Connected" ? 0.7 : 1}
    >
      <HStack spacing={6} align="center">
        {/* Left side - Image */}
        <Box 
          width="150px" 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
          flexShrink={0}
        >
          {image}
        </Box>

        {/* Middle - Content */}
        <VStack 
          flex={1} 
          align="flex-start" 
          spacing={2}
        >
          <Text fontSize="2xl" fontWeight="600" color="#00417D">{description}</Text>
          <Box>{description2}</Box>
        </VStack>

        {/* Right side - Button */}
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
          pl={6}
          borderLeft="1px"
          borderColor="gray.200"
        >
          <Tooltip 
            label={isDisabled ? disabledTooltip : ""} 
            isDisabled={!isDisabled || buttonText === "Connected"}
          >
            <Button 
              onClick={buttonAction} 
              bg="#A3BBD0" 
              w="150px" 
              h="50px"
              isDisabled={isDisabled}
              _hover={{
                bg: !isDisabled && "#8FABC3"
              }}
              _disabled={{
                bg: buttonText === "Connected" ? "#A3BBD0" : "gray.300",
                cursor: "not-allowed"
              }}
            >
              <Text fontWeight={400}>{buttonText}</Text>
            </Button>
          </Tooltip>
        </Box>
      </HStack>
    </Box>
  );
};

export default Integrations;