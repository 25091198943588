/**
 * Audio utilities for handling audio playback with buffering
 */

// Class to handle audio buffering and playback
export class BufferedAudioPlayer {
  constructor(options = {}) {
    this.audioUrl = null;
    this.audioElement = null;
    this.mediaSource = null;
    this.sourceBuffer = null;
    this.isBuffering = false;
    this.bufferQueue = [];
    this.isInitialized = false;
    this.chunkSize = options.chunkSize || 1024 * 1024; // 1MB chunks by default
    this.bufferAhead = options.bufferAhead || 30; // Buffer 30 seconds ahead by default
    this.onTimeUpdate = options.onTimeUpdate || (() => {});
    this.onDurationChange = options.onDurationChange || (() => {});
    this.onPlay = options.onPlay || (() => {});
    this.onPause = options.onPause || (() => {});
    this.onBufferingStart = options.onBufferingStart || (() => {});
    this.onBufferingEnd = options.onBufferingEnd || (() => {});
    this.onError = options.onError || console.error;
  }

  // Initialize the audio player with a URL
  init(audioUrl, audioElement) {
    if (!audioUrl) return;
    
    this.audioUrl = audioUrl;
    this.audioElement = audioElement;
    
    // Check if MediaSource is supported
    if (window.MediaSource) {
      this.initMediaSource();
    } else {
      // Fallback to regular audio element if MediaSource is not supported
      this.audioElement.src = audioUrl;
      this.setupEventListeners();
      this.isInitialized = true;
    }
    
    return this;
  }
  
  // Initialize MediaSource for buffered playback
  initMediaSource() {
    this.mediaSource = new MediaSource();
    this.audioElement.src = URL.createObjectURL(this.mediaSource);
    
    this.mediaSource.addEventListener('sourceopen', () => {
      // Create source buffer when MediaSource is open
      try {
        this.sourceBuffer = this.mediaSource.addSourceBuffer('audio/mpeg');
        this.sourceBuffer.mode = 'sequence';
        
        this.sourceBuffer.addEventListener('updateend', () => {
          // Continue processing buffer queue when current update is complete
          this.processBufferQueue();
        });
        
        // Start loading initial chunk
        this.loadInitialChunk();
        this.setupEventListeners();
        this.isInitialized = true;
      } catch (error) {
        this.onError('Error initializing source buffer:', error);
        // Fallback to regular audio element
        this.audioElement.src = this.audioUrl;
      }
    });
  }
  
  // Set up event listeners for the audio element
  setupEventListeners() {
    this.audioElement.addEventListener('timeupdate', () => {
      this.onTimeUpdate(this.audioElement.currentTime, this.audioElement.duration);
      
      // If using MediaSource, check if we need to buffer more
      if (this.mediaSource && this.sourceBuffer) {
        this.checkBuffer();
      }
    });
    
    this.audioElement.addEventListener('durationchange', () => {
      this.onDurationChange(this.audioElement.duration);
    });
    
    this.audioElement.addEventListener('play', () => {
      this.onPlay();
    });
    
    this.audioElement.addEventListener('pause', () => {
      this.onPause();
    });
    
    this.audioElement.addEventListener('waiting', () => {
      this.onBufferingStart();
    });
    
    this.audioElement.addEventListener('canplay', () => {
      this.onBufferingEnd();
    });
    
    this.audioElement.addEventListener('error', (e) => {
      this.onError('Audio playback error:', e);
    });
  }
  
  // Load the initial chunk of audio
  async loadInitialChunk() {
    if (!this.sourceBuffer) return;
    
    try {
      this.onBufferingStart();
      const chunk = await this.fetchChunk(0, this.chunkSize);
      this.appendToBuffer(chunk);
    } catch (error) {
      this.onError('Error loading initial chunk:', error);
      // Fallback to regular audio element
      this.audioElement.src = this.audioUrl;
    }
  }
  
  // Check if we need to buffer more audio
  checkBuffer() {
    if (!this.sourceBuffer || this.isBuffering) return;
    
    const currentTime = this.audioElement.currentTime;
    const buffered = this.sourceBuffer.buffered;
    
    // If nothing is buffered yet, return
    if (buffered.length === 0) return;
    
    const bufferedEnd = buffered.end(buffered.length - 1);
    
    // If we're getting close to the end of the buffered content, load more
    if (bufferedEnd - currentTime < this.bufferAhead) {
      this.loadNextChunk(bufferedEnd);
    }
  }
  
  // Load the next chunk of audio
  async loadNextChunk(startByte) {
    if (this.isBuffering) return;
    
    this.isBuffering = true;
    this.onBufferingStart();
    
    try {
      const chunk = await this.fetchChunk(startByte, startByte + this.chunkSize);
      this.bufferQueue.push(chunk);
      this.processBufferQueue();
    } catch (error) {
      this.onError('Error loading next chunk:', error);
    } finally {
      this.isBuffering = false;
      this.onBufferingEnd();
    }
  }
  
  // Process the buffer queue
  processBufferQueue() {
    if (!this.sourceBuffer || this.bufferQueue.length === 0 || this.sourceBuffer.updating) {
      return;
    }
    
    const chunk = this.bufferQueue.shift();
    this.appendToBuffer(chunk);
  }
  
  // Append a chunk to the source buffer
  appendToBuffer(chunk) {
    if (!this.sourceBuffer || this.sourceBuffer.updating) {
      this.bufferQueue.push(chunk);
      return;
    }
    
    try {
      this.sourceBuffer.appendBuffer(chunk);
    } catch (error) {
      this.onError('Error appending to buffer:', error);
    }
  }
  
  // Fetch a chunk of audio
  async fetchChunk(start, end) {
    const response = await fetch(this.audioUrl, {
      headers: {
        Range: `bytes=${start}-${end}`
      }
    });
    
    return await response.arrayBuffer();
  }
  
  // Play the audio
  play() {
    if (!this.audioElement) return;
    
    this.audioElement.play().catch(error => {
      this.onError('Error playing audio:', error);
    });
  }
  
  // Pause the audio
  pause() {
    if (!this.audioElement) return;
    
    this.audioElement.pause();
  }
  
  // Seek to a specific time
  seek(time) {
    if (!this.audioElement) return;
    
    this.audioElement.currentTime = time;
  }
  
  // Get the current time
  getCurrentTime() {
    return this.audioElement ? this.audioElement.currentTime : 0;
  }
  
  // Get the duration
  getDuration() {
    return this.audioElement ? this.audioElement.duration : 0;
  }
  
  // Check if audio is playing
  isPlaying() {
    return this.audioElement ? !this.audioElement.paused : false;
  }
  
  // Destroy the player and clean up resources
  destroy() {
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement.src = '';
      this.audioElement.load();
    }
    
    if (this.mediaSource && this.mediaSource.readyState === 'open') {
      this.mediaSource.endOfStream();
    }
    
    this.audioUrl = null;
    this.audioElement = null;
    this.mediaSource = null;
    this.sourceBuffer = null;
    this.isBuffering = false;
    this.bufferQueue = [];
    this.isInitialized = false;
  }
}

// Helper function to format time in MM:SS format
export const formatTime = (seconds) => {
  if (!seconds || isNaN(seconds)) return '00:00';
  
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

// Helper function to calculate transcription duration
export const getTranscriptionDuration = (transcription) => {
  if (!transcription || !transcription.length) return 0;
  
  // Find the last entry with an end time
  const lastEntryWithEnd = [...transcription]
    .sort((a, b) => (b.end || 0) - (a.end || 0))
    [0];
    
  return lastEntryWithEnd?.end || 0;
}; 