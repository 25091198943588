import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const OrganizationClientsContext = createContext();

export const OrganizationClientsProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const serverUrl = process.env.REACT_APP_API_URL;

  // Extract fetchClients into a reusable function using useCallback
  const fetchClients = useCallback(async () => {
    const token = Cookies.get('jwtToken');
    
    if (!token) {
      setLoading(false);
      return;
    }
    
    try {
      setLoading(true);
      // Fetch clients
      const clientsResponse = await axios.get(`${serverUrl}/api/clients-in-organization`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const sortedClients = clientsResponse.data.sort((a, b) => 
        `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
      );
      setClients(sortedClients);
      
      // Fetch entities
      const entitiesResponse = await axios.get(`${serverUrl}/api/entities`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('entitiesResponse:', entitiesResponse.data);
      setEntities(entitiesResponse.data);
    } catch (error) {
      console.error('Error fetching organization data:', error);
    } finally {
      setLoading(false);
    }
  }, [serverUrl]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  return (
    <OrganizationClientsContext.Provider value={{ 
      clients, 
      entities, 
      loading, 
      setClients, 
      setEntities,
      fetchClients 
    }}>
      {children}
    </OrganizationClientsContext.Provider>
  );
};

export const useOrganizationClients = () => {
  const context = useContext(OrganizationClientsContext);
  if (context === undefined) {
    throw new Error('useOrganizationClients must be used within an OrganizationClientsProvider');
  }
  return context;
}; 