import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  Box, Button, Divider, Heading, HStack, Icon, IconButton, 
  Input, Menu, MenuButton, MenuList, MenuItem, Modal, ModalBody, ModalCloseButton, 
  ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, 
  useToast, VStack
} from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight, FaEllipsisH, FaPencilAlt, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import InputArea from './InputArea';
import FiltersSection from './FilterSection';
import ConversationSidebar from './ConversationSidebar';
import MessageDisplay from './MessageDisplay';
import { getDateRangeParams } from '../../utils/dateUtils';

const AskDatadasherPage = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [conversationId, setConversationId] = useState(null);
  const messagesEndRef = useRef(null);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const serverUrl = process.env.REACT_APP_API_URL;
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [timeRange, setTimeRange] = useState('all');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const textareaRef = useRef(null);
  const [pastConversations, setPastConversations] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedConversationForRename, setSelectedConversationForRename] = useState(null);
  const [newConversationTitle, setNewConversationTitle] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('conversationId');
    if (id) {
      setConversationId(id);
    } else {
      setIsInitialLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (conversationId) {
      const fetchConversationHistory = async (id) => {
        try {
          const response = await axios.get(`${serverUrl}/api/conversations/${id}/messages`, {
            headers: {
              Authorization: `Bearer ${Cookies.get('jwtToken')}`
            }
          });
          
          if (response.data && Array.isArray(response.data)) {
            const formattedMessages = response.data.map(msg => ({
              type: msg.role,
              content: msg.contents?.[0]?.text || msg.content || '',
              timestamp: msg.timestamp || new Date().toISOString(),
              messageId: msg.id,
              citations: msg.metadata?.ragSources?.map(source => ({
                description: `${source.sourceType}: ${source.sourceId}`,
                type: source.sourceType
              })) || []
            }));
            
            setMessages(formattedMessages);
          }
        } catch (error) {
          console.error('Error fetching conversation history:', error);
          toast({
            title: 'Error',
            description: 'Failed to load conversation history.',
            status: 'error',
            duration: 3000,
            isClosable: true
          });
        } finally {
          setIsInitialLoading(false);
        }
      };
      
      fetchConversationHistory(conversationId);
    }
  }, [conversationId, serverUrl, toast]);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const clientsResponse = await axios.get(`${serverUrl}/api/clients-in-organization`, {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        setClients(Array.isArray(clientsResponse.data) ? clientsResponse.data : []);
      } catch (error) {
        console.error('Error fetching filter options:', error);
        setClients([]);
      }
    };
    
    fetchFilterOptions();
  }, [serverUrl]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = useCallback(async () => {
    if (!inputValue.trim() || isLoading) return;

    setIsLoading(true);

    const userMessage = {
      type: 'user',
      content: inputValue,
      timestamp: new Date().toISOString()
    };
    
    setMessages(prev => [...prev, userMessage]);
    setInputValue('');

    try {
      const filterParams = {
        clientId: selectedClient || undefined,
        type: selectedType !== 'all' ? selectedType : undefined,
        ...getDateRangeParams(timeRange, customStartDate, customEndDate)
      };
      
      let currentConversationId = conversationId;
      
      if (!currentConversationId) {
        const newConversationResponse = await axios.post(`${serverUrl}/api/conversations`, 
          { 
            question: userMessage.content,
            clientId: selectedClient || undefined
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get('jwtToken')}`
            }
          }
        );
        
        currentConversationId = newConversationResponse.data.conversationId;
        setConversationId(currentConversationId);
        
        const url = new URL(window.location);
        url.searchParams.set('conversationId', currentConversationId);
        window.history.pushState({}, '', url);

        const newConversation = {
          id: currentConversationId,
          title: userMessage.content.slice(0, 50) + (userMessage.content.length > 50 ? '...' : ''),
          createdAt: new Date().toISOString()
        };
        
        setPastConversations(prevConversations => [newConversation, ...prevConversations]);
      } else {
        await axios.post(`${serverUrl}/api/conversations/${currentConversationId}/messages`, {
          content: userMessage.content,
          role: 'user'
        }, {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`
          }
        });
      }

      const response = await axios.post(`${serverUrl}/api/ask-organization`, {
        question: userMessage.content,
        conversationId: currentConversationId,
        conversationHistory: messages.slice(-7).concat(userMessage).map(msg => ({
          role: msg.type,
          content: msg.content
        })),
        ...filterParams
      }, {
        headers: {
          Authorization: `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      const assistantMessage = {
        type: 'assistant',
        content: response.data.response || response.data.answer || '', 
        timestamp: new Date().toISOString(),
        citations: response.data.citations || [],
        messageId: response.data.messageId
      };
      
      console.log('Assistant message being added:', assistantMessage);
      
      setMessages(prev => [...prev, assistantMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Error',
        description: 'Failed to get answer. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    } finally {
      setIsLoading(false);
    }
  }, [inputValue, messages, selectedClient, selectedType, timeRange, customStartDate, customEndDate, conversationId, serverUrl, toast]);

  useEffect(() => {
    const fetchPastConversations = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/conversation-history`, {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        
        if (response.data && Array.isArray(response.data)) {
          setPastConversations(response.data);
        }
      } catch (error) {
        console.error('Error fetching past conversations:', error);
        toast({
          title: 'Error',
          description: 'Failed to load conversation history.',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    };
    
    fetchPastConversations();
  }, [serverUrl, toast]);

  const handleSelectConversation = (id) => {
    navigate(`/ask-datadasher?conversationId=${id}`);
  };

  const handleNewConversation = () => {
    setConversationId(null);
    setMessages([]);
    navigate('/ask-datadasher');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleRenameConversation = async () => {
    if (!selectedConversationForRename || !newConversationTitle.trim()) return;
    
    try {
      await axios.put(`${serverUrl}/api/conversations/${selectedConversationForRename}/rename`, {
        title: newConversationTitle
      }, {
        headers: {
          Authorization: `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      
      setPastConversations(prevConversations => 
        prevConversations.map(conv => 
          conv.id === selectedConversationForRename 
            ? { ...conv, title: newConversationTitle } 
            : conv
        )
      );
      
      onClose();
      setNewConversationTitle('');
      setSelectedConversationForRename(null);
    } catch (error) {
      console.error('Error renaming conversation:', error);
      toast({
        title: 'Error',
        description: 'Failed to rename conversation.',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };
  
  const handleDeleteConversation = async (id) => {
    try {
      await axios.delete(`${serverUrl}/api/conversations/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      
      setPastConversations(prevConversations => 
        prevConversations.filter(conv => conv.id !== id)
      );
      
      if (id === conversationId) {
        handleNewConversation();
      }
    } catch (error) {
      console.error('Error deleting conversation:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete conversation.',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };
  
  const openRenameModal = (conv) => {
    setSelectedConversationForRename(conv.id);
    setNewConversationTitle(conv.title);
    onOpen();
  };

  const ConversationSidebar = ({ 
    isSidebarOpen, 
    conversationId, 
    pastConversations, 
    handleNewConversation, 
    handleSelectConversation, 
    openRenameModal, 
    handleDeleteConversation 
  }) => (
    <Box 
      w="280px" 
      h="100vh" 
      bg="gray.50" 
      borderRight="1px" 
      borderColor="gray.200"
      position="fixed"
      left={0}
      top={0}
      overflowY="auto"
      transition="transform 0.3s"
      transform={isSidebarOpen ? "translateX(0)" : "translateX(-100%)"}
      zIndex={10}
    >
      <VStack spacing={2} align="stretch" p={3}>
        <Button 
          colorScheme="blue" 
          variant="outline"
          justifyContent="center"
          mb={2}
          onClick={handleNewConversation}
        >
          New Conversation
        </Button>
        
        <Divider mb={2} />
        
        {pastConversations.length > 0 ? (
          pastConversations.map(conv => (
            <HStack key={conv.id} position="relative">
              <Button
                flex="1"
                variant="ghost"
                justifyContent="flex-start"
                py={2}
                px={3}
                borderRadius="md"
                fontWeight={conversationId === conv.id ? "bold" : "normal"}
                bg={conversationId === conv.id ? "blue.50" : "transparent"}
                onClick={() => handleSelectConversation(conv.id)}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                textAlign="left"
              >
                {conv.title || `Conversation ${new Date(conv.createdAt).toLocaleDateString()}`}
              </Button>
              
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<FaEllipsisH />}
                  variant="ghost"
                  size="sm"
                  aria-label="Conversation options"
                  onClick={(e) => e.stopPropagation()}
                />
                <MenuList zIndex={20}>
                  <MenuItem 
                    icon={<FaPencilAlt />} 
                    onClick={() => openRenameModal(conv)}
                  >
                    Rename
                  </MenuItem>
                  <MenuItem 
                    icon={<FaTrash />} 
                    color="red.500"
                    onClick={() => handleDeleteConversation(conv.id)}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          ))
        ) : (
          <Text color="gray.500" textAlign="center" py={4}>
            No past conversations
          </Text>
        )}
      </VStack>
    </Box>
  );

  const determineLink = (citation) => {
    if (citation.sourceId && citation.type) {
      if (citation.type === 'call') {
        return `/transcripts/${citation.sourceId}`;
      } else if (citation.type === 'email') {
        return `/correspondence/email/${citation.sourceId}`;
      } else if (citation.type === 'client') {
        return `/clients/${citation.sourceId}`;
      }
    }
    
    if (citation.description) {
      const match = citation.description.match(/(call|email|client):\s*([a-f0-9-]+)/i);
      if (match) {
        const [, type, id] = match;
        
        if (type.toLowerCase() === 'call') {
          return `/transcripts/${id}`;
        } else if (type.toLowerCase() === 'email') {
          return `/correspondence/email/${id}`;
        } else if (type.toLowerCase() === 'client') {
          return `/clients/${id}`;
        }
      }
    }
    
    return '#';
  };

  return (
    <Box display="flex">
      <ConversationSidebar 
        isSidebarOpen={isSidebarOpen}
        conversationId={conversationId}
        pastConversations={pastConversations}
        handleNewConversation={handleNewConversation}
        handleSelectConversation={handleSelectConversation}
        openRenameModal={openRenameModal}
        handleDeleteConversation={handleDeleteConversation}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rename Conversation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input 
              value={newConversationTitle} 
              onChange={(e) => setNewConversationTitle(e.target.value)} 
              placeholder="Enter new title"
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleRenameConversation}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
      <IconButton
        icon={isSidebarOpen ? <Icon as={FaChevronLeft} /> : <Icon as={FaChevronRight} />}
        position="fixed"
        left={isSidebarOpen ? "280px" : "0"}
        top="50%"
        transform="translateY(-50%)"
        zIndex={11}
        onClick={toggleSidebar}
        aria-label="Toggle sidebar"
        size="sm"
        variant="solid"
        colorScheme="gray"
      />
      
      <Box 
        flex="1"
        display="flex"
        justifyContent="center"
        ml={isSidebarOpen ? "280px" : "0"}
        transition="margin-left 0.3s"
      >
        <Box 
          maxW="1000px" 
          w="100%" 
          p={4}
        >
          <VStack spacing={4} align="stretch">
            <Box textAlign="center" py={4}>
              <HStack justifyContent="center" position="relative" width="100%">
                <Box position="absolute" left={0}>
                  <Button
                    leftIcon={<Icon as={FaChevronLeft} />}
                    variant="ghost"
                    colorScheme="blue"
                    onClick={() => navigate('/')}
                  >
                    Back
                  </Button>
                </Box>
                <Heading 
                  size="xl" 
                  bgGradient="linear(to-r, blue.500, blue.700)" 
                  bgClip="text" 
                  fontWeight="bold" 
                  letterSpacing="tight"
                >
                  Ask DataDasher
                </Heading>
              </HStack>
            </Box>
            <Divider />
            
            <Box 
              bg="white" 
              borderRadius="md" 
              boxShadow="sm" 
              p={4} 
              height="60vh" 
              overflowY="auto"
            >
              <MessageDisplay 
                messages={messages}
                isInitialLoading={isInitialLoading}
                messagesEndRef={messagesEndRef}
              />
            </Box>
            
            <InputArea 
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleSendMessage={handleSendMessage}
              isLoading={isLoading}
              isInitialLoading={isInitialLoading}
              textareaRef={textareaRef}
            />
            
            <FiltersSection 
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              clients={clients}
              customStartDate={customStartDate}
              setCustomStartDate={setCustomStartDate}
              customEndDate={customEndDate}
              setCustomEndDate={setCustomEndDate}
            />
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default AskDatadasherPage; 