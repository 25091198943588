import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

export const useCorrespondence = (clientId, serverUrl, client, toast) => {
  const [emailHistory, setEmailHistory] = useState([]);
  const [callHistory, setCallHistory] = useState([]);
  const [showArchivedCorrespondence, setShowArchivedCorrespondence] = useState(false);
  const [loadingArchivedActions, setLoadingArchivedActions] = useState(false);
  const [isLoadingArchived, setIsLoadingArchived] = useState(false);
  const [totalEmails, setTotalEmails] = useState(0);
  const [totalCalls, setTotalCalls] = useState(0);
  const [emailPage, setEmailPage] = useState(1);
  const [callPage, setCallPage] = useState(1);
  const [isLoadingMoreEmails, setIsLoadingMoreEmails] = useState(false);
  const [isLoadingMoreCalls, setIsLoadingMoreCalls] = useState(false);
  const [pendingEmailCount, setPendingEmailCount] = useState(0);
  const [pendingCallCount, setPendingCallCount] = useState(0);

  // Add debug logging
  useEffect(() => {
    console.log("useCorrespondence hook initialized with clientId:", clientId);
  }, [clientId]);

  const fetchEmailHistory = useCallback(async (page = 1) => {
    if (!clientId) return;
    
    try {
      setIsLoadingMoreEmails(true);
      console.log(`Fetching emails for client ${clientId}, page ${page}, showArchived: ${showArchivedCorrespondence}`);
      
      const response = await axios.get(`${serverUrl}/api/clients/${clientId}/emails`, {
        params: {
          page,
          showArchived: showArchivedCorrespondence
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      
      console.log("Email response data:", response.data);
      
      if (response.data && response.data.items) {
        if (page === 1) {
          setEmailHistory(response.data.items);
        } else {
          setEmailHistory(prev => [...prev, ...response.data.items]);
        }
        setTotalEmails(response.data.total || 0);
        setPendingEmailCount(response.data.pendingCount || 0);
        setEmailPage(page);
      } else {
        console.error("Unexpected response format:", response.data);
        toast({
          title: "Error",
          description: "Received unexpected data format from server",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching email history:", error);
      toast({
        title: "Error",
        description: "Failed to fetch email history",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingMoreEmails(false);
    }
  }, [clientId, serverUrl, showArchivedCorrespondence, toast]);

  const fetchCallHistory = useCallback(async (page = 1) => {
    if (!clientId) return;
    
    try {
      setIsLoadingMoreCalls(true);
      console.log(`Fetching calls for client ${clientId}, page ${page}, showArchived: ${showArchivedCorrespondence}`);
      
      const response = await axios.get(`${serverUrl}/api/clients/${clientId}/calls`, {
        params: {
          page,
          showArchived: showArchivedCorrespondence
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      
      console.log("Call response data:", response.data);
      
      if (response.data && response.data.items) {
        if (page === 1) {
          setCallHistory(response.data.items);
        } else {
          setCallHistory(prev => [...prev, ...response.data.items]);
        }
        setTotalCalls(response.data.total || 0);
        setPendingCallCount(response.data.pendingCount || 0);
        setCallPage(page);
      } else {
        console.error("Unexpected response format:", response.data);
        toast({
          title: "Error",
          description: "Received unexpected data format from server",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching call history:", error);
      toast({
        title: "Error",
        description: "Failed to fetch call history",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingMoreCalls(false);
    }
  }, [clientId, serverUrl, showArchivedCorrespondence, toast]);

  const handleArchiveToggle = useCallback((showArchived) => {
    setShowArchivedCorrespondence(showArchived);
    setIsLoadingArchived(true);
    setEmailPage(1);
    setCallPage(1);
  }, []);

  const handleActionUpdate = useCallback(async (id, action) => {
    try {
      setLoadingArchivedActions(prev => ({ ...prev, [id]: true }));
      
      if (action === 'archive') {
        await axios.post(`${serverUrl}/api/emails/${id}/archive`, {}, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
      } else if (action === 'unarchive') {
        await axios.post(`${serverUrl}/api/emails/${id}/unarchive`, {}, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
      }
      
      // Refresh the email list
      fetchEmailHistory(1);
      
      toast({
        title: "Success",
        description: `Email ${action === 'archive' ? 'archived' : 'unarchived'} successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error ${action}ing email:`, error);
      toast({
        title: "Error",
        description: `Failed to ${action} email`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingArchivedActions(prev => ({ ...prev, [id]: false }));
    }
  }, [serverUrl, fetchEmailHistory, toast]);

  const handleViewEmail = useCallback((emailId) => {
    window.location.href = `/correspondence/email/${emailId}`;
  }, []);

  // Fetch initial data when clientId changes or archive toggle changes
  useEffect(() => {
    if (clientId) {
      fetchEmailHistory(1);
      fetchCallHistory(1);
    }
  }, [clientId, showArchivedCorrespondence, fetchEmailHistory, fetchCallHistory]);

  // Reset loading state after data is fetched
  useEffect(() => {
    setIsLoadingArchived(false);
  }, [emailHistory, callHistory]);

  return {
    emailHistory,
    callHistory,
    showArchivedCorrespondence,
    loadingArchivedActions,
    handleArchiveToggle,
    handleActionUpdate,
    handleViewEmail,
    totalEmails,
    totalCalls,
    emailPage,
    callPage,
    isLoadingMoreEmails,
    isLoadingMoreCalls,
    pendingEmailCount,
    pendingCallCount,
    isLoadingArchived,
    fetchEmailHistory,
    fetchCallHistory
  };
}; 