import React, { useState, useEffect } from 'react';
import { 
  Box, Button, FormControl, FormLabel, HStack, Icon, Input, Menu, 
  MenuButton, MenuItem, MenuList, Checkbox, InputGroup, InputRightElement,
  Switch, Text, Spinner
} from '@chakra-ui/react';
import { FaCalendarAlt, FaChevronDown, FaHashtag, FaUser, FaSearch, FaReply } from 'react-icons/fa';
import { FixedSizeList as List } from 'react-window';

// Create a separate ClientFilterMenu component with internal state
const ClientFilterMenu = React.memo(({ 
  clients = [], 
  selectedClients = [], 
  setSelectedClients, 
  isClientsLoading = false,
  clientSearchQuery = '',
  setClientSearchQuery = () => {}
}) => {
  // Use internal state to ensure we have control
  const [internalSelectedClients, setInternalSelectedClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  
  // Sync internal state with props
  useEffect(() => {
    setInternalSelectedClients(selectedClients || []);
  }, [selectedClients]);
  
  useEffect(() => {
    setSearchQuery(clientSearchQuery || '');
  }, [clientSearchQuery]);

  // Filter clients based on search
  const filteredClients = React.useMemo(() => {
    if (!clients || !Array.isArray(clients)) return [];
    if (!searchQuery) return clients;
    
    return clients.filter(client => {
      if (!client.firstName || !client.lastName) return false;
      const fullName = `${client.firstName} ${client.lastName}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase());
    });
  }, [clients, searchQuery]);

  // Handle search input change
  const handleSearchChange = (e) => {
    const newValue = e.target.value;
    setSearchQuery(newValue);
    if (setClientSearchQuery) {
      setClientSearchQuery(newValue);
    }
  };

  // Toggle client selection with proper state handling
  const toggleClient = (clientId) => {
    console.log(`Toggle client ${clientId}`);
    console.log(`Current selected: ${JSON.stringify(internalSelectedClients)}`);
    
    const newSelectedClients = internalSelectedClients.includes(clientId)
      ? internalSelectedClients.filter(id => id !== clientId)
      : [...internalSelectedClients, clientId];
    
    console.log(`New selected: ${JSON.stringify(newSelectedClients)}`);
    
    // Update both internal state and parent
    setInternalSelectedClients(newSelectedClients);
    if (setSelectedClients) {
      setSelectedClients(newSelectedClients);
    }
  };

  // Clear all selections
  const selectAllClients = () => {
    console.log("Selecting all clients (empty array)");
    setInternalSelectedClients([]);
    if (setSelectedClients) {
      setSelectedClients([]);
    }
  };

  // Improved row renderer
  const RowRenderer = ({ index, style }) => {
    const client = filteredClients[index];
    if (!client) return null;
    
    const isSelected = internalSelectedClients.includes(client.id);
    console.log(`Client ${client.id} selected: ${isSelected}`);
    
    return (
      <MenuItem 
        key={client.id} 
        style={style}
        px={2}
        py={1.5}
        _hover={{ bg: "gray.50" }}
        borderRadius="md"
        fontSize="sm"
        color="gray.700"
        onClick={() => toggleClient(client.id)}
      >
        <Checkbox 
          isChecked={isSelected}
          colorScheme="blue"
          mr={2}
          onChange={(e) => {
            e.stopPropagation();
            toggleClient(client.id);
          }}
        >
          <Text fontSize="sm">
            {client.firstName} {client.lastName}
          </Text>
        </Checkbox>
      </MenuItem>
    );
  };

  return (
    <MenuList maxH="300px" overflowY="auto" maxW="300px" w="300px" p={2} onClick={e => e.stopPropagation()}>
      <Box mb={3}>
        <InputGroup size="md" mb={3}>
          <Input 
            placeholder="Search clients..." 
            value={searchQuery} 
            onChange={handleSearchChange}
            borderRadius="md"
            bg="white"
            px={3}
            py={2}
            fontSize="sm"
          />
          <InputRightElement>
            <Icon as={FaSearch} color="gray.400" />
          </InputRightElement>
        </InputGroup>
      </Box>
      <Box 
        borderTopWidth="1px" 
        borderColor="gray.100"
        pt={2}
      >
        <MenuItem 
          onClick={(e) => {
            e.stopPropagation();
            selectAllClients();
          }}
          px={2}
          py={2}
          bg={internalSelectedClients.length === 0 ? "blue.50" : "transparent"}
          borderRadius="md"
          fontSize="sm"
          color="gray.700"
        >
          <Checkbox 
            isChecked={internalSelectedClients.length === 0}
            onChange={(e) => {
              e.stopPropagation();
              selectAllClients();
            }}
            colorScheme="blue"
            mr={2}
          >
            <Text fontSize="sm">All Clients</Text>
          </Checkbox>
        </MenuItem>
        
        {isClientsLoading ? (
          <Spinner size="sm" m={4} />
        ) : filteredClients.length > 0 ? (
          <List
            height={200}
            itemCount={filteredClients.length}
            itemSize={35}
            width="100%"
          >
            {RowRenderer}
          </List>
        ) : (
          <Text fontSize="sm" color="gray.500" textAlign="center" py={2}>
            No clients found
          </Text>
        )}
      </Box>
    </MenuList>
  );
});

// Update the client button in FiltersSection - with internal state management
const ClientButton = React.memo(({ 
  clients = [], 
  selectedClients = [], 
  setSelectedClients = () => {}, 
  loadClients = () => {}, 
  isClientsLoading = false,
  clientSearchQuery = '',
  setClientSearchQuery = () => {}
}) => {
  // Calculate the display text for the button
  const buttonText = React.useMemo(() => {
    if (!selectedClients || selectedClients.length === 0) return 'All Clients';
    if (selectedClients.length === 1) {
      const client = clients.find(c => c && c.id === selectedClients[0]);
      return client ? `${client.firstName} ${client.lastName}` : '1 Client';
    }
    return `${selectedClients.length} Clients`;
  }, [clients, selectedClients]);

  return (
    <Menu onOpen={loadClients} closeOnSelect={false}>
      <MenuButton 
        as={Button} 
        rightIcon={<Icon as={FaChevronDown} color="gray.500" boxSize="10px" />}
        variant="ghost"
        fontWeight="normal"
        color="gray.600"
        px={2}
        py={1}
        h="32px"
        fontSize="sm"
        leftIcon={<Icon as={FaUser} color="gray.400" boxSize="12px" />}
        isLoading={isClientsLoading}
      >
        {buttonText}
      </MenuButton>
      <ClientFilterMenu
        clients={clients}
        selectedClients={selectedClients}
        setSelectedClients={setSelectedClients}
        isClientsLoading={isClientsLoading}
        clientSearchQuery={clientSearchQuery}
        setClientSearchQuery={setClientSearchQuery}
      />
    </Menu>
  );
});

// Main FilterSection component
const FilterSection = React.memo(({ 
  clients = [], 
  selectedClients,
  setSelectedClients,
  selectedClient, // for backwards compatibility 
  setSelectedClient, // for backwards compatibility
  selectedType = 'all', 
  setSelectedType = () => {}, 
  timeRange = 'all', 
  setTimeRange = () => {}, 
  customStartDate = '', 
  setCustomStartDate = () => {}, 
  customEndDate = '',
  setCustomEndDate = () => {},
  loadClients = () => {},
  isClientsLoading = false,
  clientSearchQuery = '',
  setClientSearchQuery = () => {},
  recentConversationId,
  navigate
}) => {
  // Convert between selectedClient and selectedClients formats
  const actualSelectedClients = selectedClients || (selectedClient ? [selectedClient] : []);
  
  // Function to update client selection
  const handleClientSelection = (newSelectedClients) => {
    console.log(`Setting selected clients: ${JSON.stringify(newSelectedClients)}`);
    
    // If we have setSelectedClients, use it
    if (setSelectedClients) {
      setSelectedClients(newSelectedClients);
    }
    // Otherwise fall back to setSelectedClient for backwards compatibility
    else if (setSelectedClient) {
      const newClient = newSelectedClients.length > 0 ? newSelectedClients[0] : '';
      console.log(`Setting selected client: ${newClient}`);
      setSelectedClient(newClient);
    }
  };
  
  return (
    <Box py={1}>
      <HStack spacing={3} align="center" justify="center">
        <Menu>
          <MenuButton 
            as={Button} 
            rightIcon={<Icon as={FaChevronDown} color="gray.500" boxSize="10px" />}
            variant="ghost"
            fontWeight="normal"
            color="gray.600"
            px={2}
            py={1}
            h="32px"
            fontSize="sm"
            leftIcon={<Icon as={FaCalendarAlt} color="gray.400" boxSize="12px" />}
          >
            {timeRange === 'week' ? 'Last 7 days' : 
             timeRange === 'month' ? 'Last 30 days' :
             timeRange === 'quarter' ? 'Last 90 days' :
             timeRange === 'year' ? 'Last Year' :
             timeRange === 'today' ? 'Today' :
             timeRange === 'custom' ? 'Custom Range' : 'All Time'}
          </MenuButton>
          <MenuList minW="0" w="auto">
            <MenuItem 
              onClick={() => setTimeRange('all')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              All Time
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('today')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Today
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('week')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Last 7 days
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('month')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Last 30 days
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('quarter')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Last 90 days
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('year')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Last Year
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('custom')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Custom Range
            </MenuItem>
          </MenuList>
        </Menu>
        
        <Menu>
          <MenuButton 
            as={Button} 
            rightIcon={<Icon as={FaChevronDown} color="gray.500" boxSize="10px" />}
            variant="ghost"
            fontWeight="normal"
            color="gray.600"
            px={2}
            py={1}
            h="32px"
            fontSize="sm"
            leftIcon={<Icon as={FaHashtag} color="gray.400" boxSize="12px" />}
          >
            {selectedType === 'email' ? 'Emails Only' :
             selectedType === 'call' ? 'Calls Only' :
             'Calls & Emails'}
          </MenuButton>
          <MenuList minW="0" w="auto">
            <MenuItem 
              onClick={() => setSelectedType('all')}
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Calls & Emails
            </MenuItem>
            <MenuItem 
              onClick={() => setSelectedType('email')}
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Emails Only
            </MenuItem>
            <MenuItem 
              onClick={() => setSelectedType('call')}
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Calls Only
            </MenuItem>
          </MenuList>
        </Menu>
        
        {/* Client filter */}
        <ClientButton
          clients={clients}
          selectedClients={actualSelectedClients}
          setSelectedClients={handleClientSelection}
          loadClients={loadClients}
          isClientsLoading={isClientsLoading}
          clientSearchQuery={clientSearchQuery}
          setClientSearchQuery={setClientSearchQuery}
        />
        
        {recentConversationId && navigate && (
          <Button
            leftIcon={<Icon as={FaReply} boxSize="12px" />}
            variant="ghost"
            fontWeight="normal"
            color="gray.600"
            px={2}
            py={1}
            h="32px"
            fontSize="sm"
            onClick={() => navigate(`/ask-datadasher?conversationId=${recentConversationId}`)}
          >
            History
          </Button>
        )}
      </HStack>
      
      {timeRange === 'custom' && (
        <HStack spacing={3} mt={4} justify="center" maxW="500px" mx="auto">
          <FormControl flex="1" maxW="220px">
            <FormLabel fontSize="sm" mb={1}>Start Date</FormLabel>
            <Input 
              type="date" 
              size="sm"
              value={customStartDate}
              onChange={(e) => setCustomStartDate(e.target.value)}
            />
          </FormControl>
          <FormControl flex="1" maxW="220px">
            <FormLabel fontSize="sm" mb={1}>End Date</FormLabel>
            <Input 
              type="date" 
              size="sm"
              value={customEndDate}
              onChange={(e) => setCustomEndDate(e.target.value)}
            />
          </FormControl>
        </HStack>
      )}
    </Box>
  );
});

export default FilterSection;