import React, { useEffect, useState, useCallback } from 'react';
import { Box, Heading, Switch, HStack, VStack, Text, Spinner, Select, Button, SimpleGrid, Icon, Badge, GridItem, Divider, useToast, Tooltip, Input, InputGroup, InputRightElement, Collapse, List, ListItem, Card, CardBody, Link, Checkbox, Stack, FormControl, FormLabel } from '@chakra-ui/react';
import GrayBox from '../../GrayBox';
import axios from 'axios';
import Cookies from 'js-cookie';
import { fetchEvents, EventBox, formatDate } from '../Meetings/Meetings';
import { motion, AnimatePresence } from 'framer-motion';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaPhone, FaEnvelope, FaCheckCircle, FaClock, FaSync, FaVideo, FaCalendar, FaColumns, FaExclamation, FaInfo, FaSearch, FaRobot, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import AlertBanner from './AlertBanner';
import { FeatureStats, TimeStats } from '../../../enums/UsageStats';
import AskDataDasher from './AskDatadasher';

const formatMetadata = (data) => {
  const date = new Date(
    data?.metadata?.sentDateTime || data.receivedAt || data.startTime
  ).toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const clientNames = data?.clients
    ?.map((client) => `${client.firstName} ${client.lastName}`)
    .join(", ") || '';

  const emailData = data?.correspondenceType === 'email' ? {
    emailAddress: {
      name: data?.metadata?.from?.emailAddress?.name || '',
      address: data?.metadata?.from?.emailAddress?.address || ''
    }
  } : null;

  // Handle different title formats based on correspondence type
  let title;
  if (data.correspondenceType === 'call') {
    // Use the formatTitleWithDate logic for calls
    const matches = data.title?.match(/\|\|(.*?)\|\|/);
    if (matches) {
      const [fullMatch, isoTime] = matches;
      const baseTitle = data.title.replace(fullMatch, '').trim();
      const callDate = new Date(isoTime);
      const formattedDate = callDate.toLocaleString(undefined, {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
      title = `${baseTitle} on ${formattedDate}`;
    } else {
      title = data.title || 'Untitled';
    }
  } else {
    // For emails and other types, use subject
    title = data.subject || 'Untitled';
  }
  const threadInfo = data.hasThread ? {
    hasThread: true,
    threadCount: data.threadCount,
    replies: data.metadata?.replies || []
  } : null;

  return {
    title,
    subtitle: date,
    clientNames,
    metadata: {
      ...data.metadata,
      date,
      clientNames,
      threadInfo
    },
    emailData,
    isEditable: true,
    type: data.correspondenceType || 'unknown'
  };
};

const Home = ({ scrollRef }) => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const [myFeed, setMyFeed] = useState([]);
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resyncLoading, setResyncLoading] = useState(true);
  const [sortOption, setSortOption] = useState('dateAsc');
  const [filterOption, setFilterOption] = useState(() => {
    return localStorage.getItem('filterOption') || 'emails and calls';
  });
  const [showFavorites, setShowFavorites] = useState(false);
  const toast = useToast();
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(false);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Pagination states
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [viewMode, setViewMode] = useState('combined'); // 'combined' or 'split'
  const [crm, setCRM] = useState(null);

  const [dashboardStats, setDashboardStats] = useState({
    calls: { current: 0, change: 0 },
    emails: { current: 0, change: 0 },
    pendingActions: { count: 0, topClients: [] },
    timeSaved: { current: 0, change: 0 }
  });

  const [nextCursor, setNextCursor] = useState(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const [displayedResults, setDisplayedResults] = useState([]);
  const [resultsPerPage] = useState(8);
  const [hasMore, setHasMore] = useState(false);

  const [showTodaysMeetings, setShowTodaysMeetings] = useState(true);

  useEffect(() => {
    const fetchDashboardStats = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/dashboard-stats`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        setDashboardStats(response.data);
      } catch (error) {
        console.error('Error fetching dashboard stats:', error);
      }
    };

    fetchDashboardStats();
  }, [serverUrl]);

  const fetchFeed = useCallback(async (cursor = null, append = false) => {
    if (cursor) setIsFetchingMore(true);
    else setLoading(true);

    try {
      const response = await axios.get(`${serverUrl}/api/my-feed`, {
        params: {
          favoritesOnly: showFavorites,
          cursor,
          limit: itemsPerPage,
          filterOption
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      console.log('Response:', response.data);

      const items = response.data?.items || [];
      const newNextCursor = response.data?.nextCursor || null;
      if (response.data?.crm) {
        setCRM(response.data.crm);
      } else {
        setCRM(null);
      }

      if (append) {
        setMyFeed(prevFeed => [...prevFeed, ...items]);
      } else {
        setMyFeed(items);
      }

      setNextCursor(newNextCursor);
      if (cursor) setIsFetchingMore(false);
      else setLoading(false);
    } catch (error) {
      console.error('Error fetching feed:', error);
      setLoading(false);
      setIsFetchingMore(false);
      setMyFeed([]);
      setNextCursor(null);
    }
  }, [serverUrl, showFavorites, itemsPerPage, filterOption]);

  useEffect(() => {
    fetchFeed();
  }, [fetchFeed]);

  const fetchJobs = async () => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await axios.get(`${serverUrl}/api/jobs?type=Resync&status=active`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
      console.log('Jobs:', response.data);
      setResyncLoading(response.data.length > 0);
      if (response.data.length > 0) {
        setTimeout(fetchJobs, 10000); // Poll every 10 seconds until the length is 0
      } else {
        setResyncLoading(false);
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleShowArchived = (showArchived) => {
    console.log('Show Archived Correspondences:', showArchived);
    setLoading(true);
    axios.get(`${serverUrl}/api/my-feed`, {
      params: {
        showArchived,
        favoritesOnly: showFavorites,
        page: currentPage,
        limit: itemsPerPage
      },
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
      .then(response => {
        console.log('Archived Correspondences:', response.data);
        // Ensure we have valid data
        const items = response.data?.items || [];
        const pagination = response.data?.pagination || {
          currentPage: 1,
          totalPages: 1,
          totalItems: 0,
          itemsPerPage: itemsPerPage
        };

        setMyFeed(items);
        setFeed(items);
        setTotalPages(pagination.totalPages);
        setTotalItems(pagination.totalItems);
        setCurrentPage(pagination.currentPage);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching archived items:', error);
        setLoading(false);
        // Set default states in case of error
        setMyFeed([]);
        setFeed([]);
        setTotalPages(1);
        setTotalItems(0);
        setCurrentPage(1);
      });
  };

  useEffect(() => {
    const handleScrollPosition = () => {
      const savedPosition = sessionStorage.getItem('scrollPosition');
      if (savedPosition && scrollRef.current) {
        scrollRef.current.scrollTo(0, parseInt(savedPosition, 10));
        sessionStorage.removeItem('scrollPosition'); 
      }
    };

    if (!loading) {
      handleScrollPosition();
    }
  }, [loading, scrollRef, filterOption]);

  const handleArchive = (itemId) => {
    console.log('Archive', itemId);
    // Update the state to remove the archived item
    let newFeed = myFeed.filter(item => item.id !== itemId);
    setMyFeed([...newFeed]);
  };

  // Function to split summary into sentences using new line characters
  const getListItems = (data) => {
    if (!data) return [];
    
    const summary = data?.correspondenceType === 'email' 
      ? data?.metadata?.summary 
      : data?.summary;

    if (!summary) return [];

    return summary
      .split('\n')
      .map(line => line.trim())
      .filter(line => line !== '');
  };

  const sortFeed = (feedToSort, option = sortOption) => {
    return feedToSort.sort((a, b) => {
      const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt || a.startTime);
      const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt || b.startTime);
      if (option === 'dateDesc') {
        return dateA - dateB;
      } else if (option === 'dateAsc') {
        return dateB - dateA;
      }
      // Add more sorting options if needed
      return 0;
    });
  };

  const handleSortChange = (e) => {
    const selectedOption = e.target.value;
    setSortOption(selectedOption);

    const sortedMyFeed = sortFeed([...myFeed], selectedOption);
    setMyFeed(sortedMyFeed);

    const sortedEvents = sortFeed([...events], selectedOption);
    setEvents(sortedEvents);
  };

  const handleFilterChange = (e) => {
    const selectedOption = e.target.value;
    setFilterOption(selectedOption);
    setMyFeed([]);
    setNextCursor(null);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchFeed(newPage);
  };

  const refreshFeed = useCallback(() => {
    setLoading(true);
    console.log('Refreshing feed:');
    axios.get(`${serverUrl}/api/my-feed`, {
      params: {
        page: currentPage,
        limit: itemsPerPage,
        favoritesOnly: showFavorites,
        filterOption
      },
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
    .then(response => {
      console.log('Refreshed My Feed:', response.data);
      // Ensure we have valid data
      const items = response.data?.items || [];
      const pagination = response.data?.pagination || {
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: itemsPerPage
      };

      setMyFeed(items);
      setFeed(items);
      setTotalPages(pagination.totalPages);
      setTotalItems(pagination.totalItems);
      setCurrentPage(pagination.currentPage);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error refreshing feed:', error);
      setLoading(false);
      // Set default states in case of error
      setMyFeed([]);
      setFeed([]);
      setTotalPages(1);
      setTotalItems(0);
      setCurrentPage(1);
    });
  }, [serverUrl, currentPage, itemsPerPage, showFavorites, filterOption]);

  // Updated filteredEvents to show events on the same calendar day
  const filteredEvents = events.filter(event => {
    const eventDate = new Date(event.startTime);
    const today = new Date();
    return (
      eventDate.getFullYear() === today.getFullYear() &&
      eventDate.getMonth() === today.getMonth() &&
      eventDate.getDate() === today.getDate()
    );
  });

  // Persist filterOption to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('filterOption', filterOption);
  }, [filterOption]);

  // Get current items to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFeedItems = myFeed; // No need to slice

  const loadMore = () => {
    if (nextCursor && !isFetchingMore) {
      fetchFeed(nextCursor, true);
    }
  };

  useEffect(() => {
    const logLoginTimestamp = async () => {
      try {
        const token = Cookies.get('jwtToken');
        if (!token) return;

        const newTimestamp = new Date().toISOString();
        console.log('Logging login timestamp from Home:', newTimestamp);
        
        // Log all login-related stats in parallel
        const [timestampRes, lastLoginRes, monthlyCountRes] = await Promise.all([
          // Append to login timestamps array
          axios.post(`${serverUrl}/api/usage/log`, {
            statPath: TimeStats.LOGIN_TIMESTAMPS,
            value: newTimestamp,
            isArray: true
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          }),
          // Update last login time
          axios.post(`${serverUrl}/api/usage/log`, {
            statPath: TimeStats.LAST_LOGIN,
            value: newTimestamp,
            isArray: false
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          }),
          // Increment monthly login count
          axios.post(`${serverUrl}/api/usage/log`, {
            statPath: TimeStats.MONTHLY_LOGIN_COUNT,
            value: 1,
            isArray: false
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          })
        ]);

        console.log('Login stats logged successfully:', {
          timestamps: timestampRes.data,
          lastLogin: lastLoginRes.data,
          monthlyCount: monthlyCountRes.data
        });
      } catch (error) {
        console.error('Error logging login timestamp:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
      }
    };

    // Call the logging function when component mounts
    logLoginTimestamp();
  }, []); // Empty dependency array means this runs once when component mounts

  // Fetch events when component mounts
  useEffect(() => {
    setEventsLoading(true);
    
    // Try to fetch real events first
    const fetchAndSetEvents = async () => {
      try {
        console.log('Fetching events from server:', serverUrl);
        const fetchedEvents = await fetchEvents(serverUrl, true);
        console.log('Raw fetched events:', fetchedEvents);
        
        if (fetchedEvents && fetchedEvents.length > 0) {
          console.log('Setting events state with:', fetchedEvents);
          setEvents(fetchedEvents);
        } else {
          console.log('No events were fetched or empty array returned');
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setEventsLoading(false);
      }
    };

    fetchAndSetEvents();
  }, [serverUrl]);

  // Filter events for today
  const todayEvents = events.filter(event => {
    const eventDate = new Date(event.startTime);
    const today = new Date();
    console.log('Comparing event date:', eventDate, 'with today:', today);
    const isToday = (
      eventDate.getFullYear() === today.getFullYear() &&
      eventDate.getMonth() === today.getMonth() &&
      eventDate.getDate() === today.getDate()
    );
    console.log('Is today?', isToday, 'for event:', event);
    return isToday;
  });

  console.log('Filtered today events:', todayEvents);

  return (
    <Box bg="gray.50" minH="100vh" px={8} py={4} overflowY="auto">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <AlertBanner />
        
        {/* Header Section */}
        <Box mb={8}>
          <Heading color={'#00417D'} fontSize={'32px'}>
            My Dashboard
          </Heading>
          <Text color="gray.600" mt={2}>
            {new Date().toLocaleDateString('en-US', { 
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </Text>
        </Box>

       

        {/* Statistics Section */}
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={6} mb={8}>
          <StatCard
            label="Calls This Month"
            value={dashboardStats.calls.current}
            icon={FaPhone}
            change={dashboardStats.calls.change}
          />
          <StatCard
            label="Emails Processed"
            value={dashboardStats.emails.current}
            icon={FaEnvelope}
            change={dashboardStats.emails.change}
          />
          <StatCard
            label="Time Saved"
            value={dashboardStats.timeSaved.hours}
            icon={FaClock}
            change={''}
          />
        </SimpleGrid>

        {/* Add AskDataDasher Section */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mb={8}>
          <GridItem colSpan={2}>
            <AskDataDasher />
          </GridItem>
        </SimpleGrid>

        {/* Today's Meetings Section - Now Collapsible */}
        <Box mb={8}>
          <HStack 
            spacing={2}
            cursor="pointer" 
            onClick={() => setShowTodaysMeetings(!showTodaysMeetings)}
            _hover={{ color: 'blue.500' }}
            width="fit-content"
          >
            <Heading size="md" color="#00417D">Today's Meetings</Heading>
            <Icon 
              as={showTodaysMeetings ? FaChevronUp : FaChevronDown} 
              boxSize={4} 
              color="gray.500"
              transition="transform 0.2s"
            />
          </HStack>
          <Collapse in={showTodaysMeetings}>
            <Box mt={2}>
              <TodayView 
                events={todayEvents}
                eventsLoading={eventsLoading}
                userTimeZone={userTimeZone}
              />
            </Box>
          </Collapse>
        </Box>

        {/* Processed Meetings Section Header */}
        <Box mb={4}>
          <Heading size="md" color="#00417D">Processed Emails and Meetings</Heading>
        </Box>
        {/* Controls Section */}
        <HStack
          spacing={8}
          mb={8}
          p={4}
          bg="white"
          borderRadius="xl"
          boxShadow="sm"
          align="center"
        >
          <HStack flex={1} spacing={4}>
            <Select 
              value={sortOption} 
              onChange={handleSortChange}
              w="auto"
              size="sm"
              borderRadius="md"
              _focus={{ boxShadow: 'none', borderColor: 'blue.400' }}
            >
              <option value="dateAsc">Newest First</option>
              <option value="dateDesc">Oldest First</option>
            </Select>
            <Select 
              value={filterOption} 
              onChange={handleFilterChange}
              w="auto"
              size="sm"
              borderRadius="md"
              _focus={{ boxShadow: 'none', borderColor: 'blue.400' }}
            >
              <option value="emails and calls">Emails and Meetings</option>
              <option value="email">Emails</option>
              <option value="call">Meetings</option>
            </Select>
          </HStack>

          <HStack spacing={4}>
            <Button
              size="sm"
              variant={viewMode === 'combined' ? 'solid' : 'ghost'}
              colorScheme="blue"
              onClick={() => setViewMode('combined')}
              leftIcon={<Icon as={FaColumns} />}
            >
              Combined
            </Button>
            <Button
              size="sm"
              variant={viewMode === 'split' ? 'solid' : 'ghost'}
              colorScheme="blue"
              onClick={() => setViewMode('split')}
              leftIcon={<Icon as={FaColumns} />}
            >
              Split View
            </Button>
            <Divider orientation="vertical" h="20px" />
            <HStack spacing={3}>
              <Text fontSize="sm" color="gray.600">Favorites Only</Text>
              <Switch
                isDisabled={loading}
                isChecked={showFavorites}
                onChange={(e) => {
                  setShowFavorites(e.target.checked);
                  // Reset pagination and cursor when toggling favorites
                  setNextCursor(null);
                  setMyFeed([]);
                }}
                size="sm"
                colorScheme="yellow"
              />
            </HStack>
            <Divider orientation="vertical" h="20px" />
            <HStack spacing={3}>
              <Text fontSize="sm" color="gray.600">Archived Items</Text>
              <Switch
                isDisabled={loading}
                onChange={(e) => handleShowArchived(e.target.checked)}
                size="sm"
                colorScheme="blue"
              />
            </HStack>
          </HStack>
        </HStack>
        {/* Content Section */}
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <FeedContent 
              items={myFeed}
              viewMode={viewMode}
              onArchive={handleArchive}
              scrollRef={scrollRef}
              resyncLoading={resyncLoading}
              fetchJobs={fetchJobs}
              refreshFeed={refreshFeed}
              crm={crm}
            />

            {/* Load More Button */}
            {nextCursor && (
              <Box display="flex" justifyContent="center" mt={4}>
                <Button onClick={loadMore} isLoading={isFetchingMore}>
                  Load More
                </Button>
              </Box>
            )}
          </>
        )}

        {/* Pagination */}
        {totalPages > 1 && (
          <PaginationControls
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </motion.div>
    </Box>
  );
};

// New Components
const LoadingState = () => (
  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
    {[1, 2, 3, 4].map((index) => (
      <Box 
        key={index}
        bg="white"
        p={6}
        borderRadius="xl"
        boxShadow="sm"
      >
        <SkeletonText mt='4' noOfLines={3} spacing='4' />
        <HStack mt={4} spacing={4}>
          <Skeleton height="20px" width="100px" />
          <Skeleton height="20px" width="80px" />
        </HStack>
      </Box>
    ))}
  </SimpleGrid>
);

const FeedContent = ({ items = [], viewMode, crm = null, ...props }) => {
  if (!items || items.length === 0) {
    return (
      <Box textAlign="center" py={8}>
        <Text color="gray.500">No items to display</Text>
      </Box>
    );
  }

  if (viewMode === 'split') {
    const emails = items.filter(item => item.correspondenceType === 'email');
    const calls = items.filter(item => item.correspondenceType === 'call');
    
    return (
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <VStack align="stretch" spacing={4}>
          <Text fontSize="lg" fontWeight="medium" color="gray.700">Emails</Text>
          <VStack align="stretch" spacing={4}>
            {emails.map((data, index) => (
              <FeedCard key={data.id} data={data} index={index} {...props} crm={crm} />
            ))}
            {emails.length === 0 && (
              <Text color="gray.500" textAlign="center">No emails to display</Text>
            )}
          </VStack>
        </VStack>
        <VStack align="stretch" spacing={4}>
          <Text fontSize="lg" fontWeight="medium" color="gray.700">Calls</Text>
          <VStack align="stretch" spacing={4}>
            {calls.map((data, index) => (
              <FeedCard key={data.id} data={data} index={index} {...props} crm={crm} />
            ))}
            {calls.length === 0 && (
              <Text color="gray.500" textAlign="center">No calls to display</Text>
            )}
          </VStack>
        </VStack>
      </SimpleGrid>
    );
  }

  // Combined view - single column
  return (
    <VStack spacing={4} align="stretch" w="100%" maxW="100%" mx="auto">
      {items.map((data, index) => (
        <FeedCard key={data.id} data={data} crm={crm} index={index} {...props} />
      ))}
    </VStack>
  );
};

const FeedCard = ({ data, index, crm, ...props }) => {
  const formattedData = formatMetadata(data);
  const getListItems = (data) => {
    if (!data) return [];
    
    const summary = data?.correspondenceType === 'email' 
      ? data?.metadata?.summary 
      : data?.summary;

    if (!summary) return [];

    return summary
      .split('\n')
      .map(line => line.trim())
      .filter(line => line !== '');
  };
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
    >
      <Box 
        bg="white"
        borderRadius="xl"
        boxShadow="sm"
        overflow="hidden"
        transition="all 0.2s"
        _hover={{ boxShadow: 'md' }}
      >
        <GrayBox
          {...formattedData}
          listItems={getListItems(data)}
          rows={data?.ActionItem}
          transcriptId={data?.id}
          client={data?.clients}
          type={data?.correspondenceType}
          id={data?.id}
          onArchive={() => props.onArchive(data.id)}
          scrollRef={props.scrollRef}
          errorItems={data?.ActionItem.filter(item => item.aiCompletionStatus === 'ERROR')}
          correspondence={data}
          nonClientSpeakers={data?.nonClientSpeakers}
          resyncLoading={props.resyncLoading}
          fetchJobs={props.fetchJobs}
          refreshFeed={props.refreshFeed}
          crm={crm}
          aiInsights={data?.aiInsights}
          onInsightsUpdate={(id, newInsights) => {
            const updatedFeed = props.myFeed.map(item => 
              item.id === id ? {...item, aiInsights: newInsights} : item
            );
            props.setMyFeed(updatedFeed);
          }}
        />
      </Box>
    </motion.div>
  );
};

const TodayView = ({ events, eventsLoading, userTimeZone }) => (
  <Box>
    {eventsLoading ? (
      <SimpleGrid columns={1} spacing={4}>
        {[1, 2].map((index) => (
          <Box key={index} p={4} borderWidth="1px" borderRadius="lg" bg="white">
            <SkeletonText noOfLines={2} spacing="4" />
            <HStack mt={4} spacing={4}>
              <Skeleton height="20px" width="120px" />
              <Skeleton height="20px" width="100px" />
            </HStack>
          </Box>
        ))}
      </SimpleGrid>
    ) : events.length > 0 ? (
      <SimpleGrid columns={1} spacing={4}>
        {events
          .sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
          .map((event) => (
            <EventBox
              key={event.id}
              id={event.id}
              eventId={event.id}
              subject={event.subject}
              startTime={formatDate(event.startTime, userTimeZone)}
              endTime={formatDate(event.endTime, userTimeZone)}
              webConfLink={event.webConfLink}
              location={event.location}
              audioBotJoinStatus={event.audioBotJoinStatus ?? true}
              botId={event.botId ?? null}
              eventClients={event.clients}
            />
          ))}
      </SimpleGrid>
    ) : (
      <Box textAlign="center" py={8} bg="white" borderRadius="lg">
        <Text color="gray.500">No meetings scheduled for today.</Text>
      </Box>
    )}
  </Box>
);

const PaginationControls = ({ currentPage, totalPages, onPageChange }) => (
  <HStack justify="center" mt={6} spacing={4}>
    <Button
      onClick={() => onPageChange(currentPage - 1)}
      isDisabled={currentPage === 1}
      size="sm"
      variant="outline"
    >
      Previous
    </Button>
    <Text color="gray.600">
      Page {currentPage} of {totalPages}
    </Text>
    <Button
      onClick={() => onPageChange(currentPage + 1)}
      isDisabled={currentPage === totalPages}
      size="sm"
      variant="outline"
    >
      Next
    </Button>
  </HStack>
);

const StatCard = ({ label, value, icon, change }) => (
  <Box
    bg="white"
    px={4}
    py={3.5}
    borderRadius="lg"
    boxShadow="sm"
    height="auto"
    display="flex"
    flexDirection="column"
    gap={1}
  >
    <Text 
      fontSize="sm" 
      color="gray.700"
      fontWeight="medium"
      mb={1}
    >
      {label}
    </Text>
    
    <Text 
      fontSize="2xl"
      fontWeight="bold" 
      color="#2D3748"
      lineHeight="1.2"
      mb={1}
    >
      {value}
    </Text>
  </Box>
);

const QuickActions = ({ lastSyncTime }) => {
  const [resyncLoading, setResyncLoading] = useState(false);
  const serverUrl = process.env.REACT_APP_API_URL;
  const toast = useToast();
  
  const handleResync = async () => {
    setResyncLoading(true);
    console.log('handleResync - Initiating resync process');
    try {
      console.warn('handleResync - Resyncing all clients for the organization');
      const response = await axios.post(`${serverUrl}/api/clients/resync-all`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      if (response.data.message) {
        // Log the CRM resync
        await axios.post(`${serverUrl}/api/usage/log`, {
          statPath: FeatureStats.CRM_RESYNCS,
          value: 1,
          isArray: false
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });

        toast({
          title: "Resync Initiated",
          description: "All client data resync has been initiated. This may take a few moments.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        setResyncLoading(true);
        // wait 10 seconds before fetching jobs
        setTimeout(fetchJobs, 10000);
      } else {
        throw new Error(response.data.message || "Failed to initiate resync");
      }
    } catch (error) {
      console.error("Error initiating resync:", error);
      toast({
        title: "Resync Failed",
        description: `Failed to initiate resync: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      console.log('Clients Component: handleResync - Resync process completed');
    }
  };

  const fetchJobs = async (bypassToast = false) => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await axios.get(`${serverUrl}/api/jobs?type=Resync&status=active`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
      console.log('Jobs:', response.data);
      setResyncLoading(response.data.length > 0);
      if (response.data.length > 0) {
        setTimeout(fetchJobs, 10000); // Poll every 10 seconds until the length is 0
      } else {
        setResyncLoading(false);
        if (!bypassToast) {
          toast({
            title: "Resync Completed",
            description: "All client data resync has been completed. Refresh the page to see the latest data.",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const formatDistanceToNow = (date) => {
    const now = new Date();
    const distanceInMilliseconds = now - new Date(date);
    const distanceInMinutes = Math.floor(distanceInMilliseconds / 60000);

    if (!date) {
      return 'Never';
    }

    if (distanceInMinutes < 1) {
      return 'just now';
    } else if (distanceInMinutes < 60) {
      return `${distanceInMinutes} minute${distanceInMinutes > 1 ? 's' : ''} ago`;
    } else if (distanceInMinutes < 1440) {
      const hours = Math.floor(distanceInMinutes / 60);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(distanceInMinutes / 1440);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };


  return (
    <Box
      bg="white"
      p={6}
      borderRadius="xl"
      boxShadow="sm"
      w="100%"
    >
      <Text fontSize="lg" fontWeight="medium" mb={4}>Quick Actions</Text>
      <VStack spacing={4} align="stretch">
        <Button
          height="16"
          colorScheme="blue"
          leftIcon={<Icon as={FaVideo} boxSize={5} />}
          fontSize="md"
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: 'md'
          }}
          transition="all 0.2s"
          as={RouterLink}
          to="/live-meeting"
        >
          Record Meeting
        </Button>
        
        <HStack 
          p={4} 
          bg="gray.50"
          borderRadius="lg"
          justify="space-between"
        >
          <HStack>
            <Icon as={FaSync} color={lastSyncTime ? "blue.500" : "gray.400"} />
            <VStack align="start" spacing={0}>
              <Text fontSize="sm" color="gray.600">Last CRM Sync</Text>
              {lastSyncTime ? (
                <Text fontWeight="medium">{formatDistanceToNow(new Date(lastSyncTime), { addSuffix: true })}</Text>
              ) : (
                <Text fontWeight="medium" color="gray.500">Not Synced</Text>
              )}
            </VStack>
          </HStack>
          <Button
            size="sm"
            leftIcon={<FaSync />}
            colorScheme="blue"
            variant="ghost"
            onClick={handleResync}
            isLoading={resyncLoading}
            isDisabled={!lastSyncTime}
            _disabled={{
              opacity: 0.6,
              cursor: 'not-allowed'
            }}
          >
            Sync Now
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

const UpcomingMeetings = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const serverUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setEventsLoading(true);
    fetchEvents(serverUrl, (events) => {
      const now = new Date();
      const upcoming = events
        .filter(event => new Date(event.startTime) >= now)
        .slice(0, 2);
      setUpcomingEvents(upcoming);
      setEventsLoading(false);
    });
    
    const fetchJobs = async () => {
      try {
        const token = Cookies.get('jwtToken');
        await axios.get(`${serverUrl}/api/jobs?type=Resync&status=active`, { 
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true 
        });
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };
    
    fetchJobs();
  }, [serverUrl]);


  return (
    <Box
      bg="white"
      p={6}
      borderRadius="xl"
      boxShadow="sm"
      w="100%"
    >
      <HStack justify="space-between" mb={4}>
        <Text fontSize="lg" fontWeight="medium">Upcoming Meetings</Text>
        <Button
          as={RouterLink}
          to="/meetings"
          size="sm"
          variant="ghost"
          colorScheme="blue"
        >
          View All
        </Button>
      </HStack>
      
      <VStack spacing={3} align="stretch">
        {eventsLoading ? (
          <Skeleton height="100px" borderRadius="lg" />
        ) : upcomingEvents.length > 0 ? (
          upcomingEvents.map(event => (
            <EventBox
              key={event.id}
              eventId={event.id}
              subject={event.subject}
              startTime={formatDate(event.startTime, userTimeZone)}
              endTime={formatDate(event.endTime, userTimeZone)}
              webConfLink={event.webConfLink}
              location={event.location}
              audioBotJoinStatus={event.audioBotJoinStatus ?? true}
              botId={event.botId ?? null}
            />
          ))
        ) : (
          <Text color="gray.500" fontSize="sm" textAlign="center" py={4}>
            No upcoming meetings
          </Text>
        )}
      </VStack>
    </Box>
  );
};


export default Home;