import { useState, useEffect, useCallback, useRef } from 'react';
import { useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { FeatureStats } from '../enums/UsageStats';
import { 
  fetchCorrespondence, 
  fetchCorrespondenceMetadata,
  fetchEmailContent,
  fetchSummary,
  fetchActionItems,
  fetchInsights,
  fetchAggregateInsights,
  fetchKeyInfo,
  fetchEmailReplies,
  updateEmailTitle,
  updateEmailSummary,
  approveActionItem,
  rejectActionItem,
  assignActionItem,
  updateActionItemDueDate,
  sendChatMessage,
  askAboutCorrespondence,
  fetchQuestions,
  logUsage,
  fetchCrmData
} from '../services/correspondenceService';

/**
 * Aggregates insights by category for easier display
 * @param {Array} insights - Array of insight objects
 * @returns {Object} Insights grouped by category
 */
const aggregateInsightsByCategory = (insights) => {
  if (!Array.isArray(insights)) return {};
  
  return insights.reduce((acc, insight) => {
    const category = insight.category || 'general';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(insight);
    return acc;
  }, {});
};

/**
 * Custom hook for managing email correspondence data and state
 * @returns {Object} Email correspondence state and functions
 */
const useEmailCorrespondence = () => {
  const { id } = useParams();
  const toast = useToast();
  
  // Data state
  const [emailData, setEmailData] = useState(null);
  const [actionItems, setActionItems] = useState([]);
  const [aiInsights, setAiInsights] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [summary, setSummary] = useState('');
  const [replies, setReplies] = useState([]);

  // Loading state
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingActionItems, setIsLoadingActionItems] = useState(true);
  const [isLoadingInsights, setIsLoadingInsights] = useState(true);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
  const [isLoadingReplies, setIsLoadingReplies] = useState(true);

  // Editing state
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingSummary, setIsEditingSummary] = useState(false);
  const [crm, setCrm] = useState(null);
  // Fetch email data
  const fetchData = useCallback(async () => {
    console.log('fetching data');
    console.log(id);
    if (!id) return;
    
    setIsLoading(true);
    try {
      const crmData = await fetchCrmData();
      setCrm(crmData);
      // Fetch email data
      const data = await fetchCorrespondence(id, 'email');
      setEmailData(data);
      setSummary(data.summary);
      // Fetch action items
      setIsLoadingActionItems(true);
      const actionItemsData = await fetchActionItems(id);
      setActionItems(actionItemsData);
      setIsLoadingActionItems(false);
      
      // Fetch insights
      setIsLoadingInsights(true);
      const insightsData = await fetchInsights(id);

      // Fetch replies
      const repliesData = await fetchEmailReplies(id);
      setReplies(repliesData);
      setIsLoadingReplies(false);

      setAiInsights(insightsData);
      setIsLoadingInsights(false);
      
    } catch (error) {
      console.error('Error fetching email data:', error);
      toast({
        title: 'Error fetching email data',
        description: error.message || 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [id, toast]);
  
  // Initial data fetch
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  
  // Title editing functions
  const handleEditTitle = () => setIsEditingTitle(true);
  
  const handleCancelEditTitle = () => setIsEditingTitle(false);
  
  const handleSaveTitle = async (newTitle) => {
    try {
      await updateEmailTitle(id, newTitle);
      setEmailData(prev => ({
        ...prev,
        call: {
          ...prev.call,
          title: newTitle
        }
      }));
      setIsEditingTitle(false);
      toast({
        title: 'Title updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating title:', error);
      toast({
        title: 'Error updating title',
        description: error.message || 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  // Summary editing functions
  const handleEditSummary = () => setIsEditingSummary(true);
  
  const handleCancelEditSummary = () => setIsEditingSummary(false);
  
  const handleSaveSummary = async (newSummary) => {
    try {
      await updateEmailSummary(id, newSummary);
      setEmailData(prev => ({
        ...prev,
        call: {
          ...prev.call,
          summary: newSummary
        }
      }));
      setSummary(newSummary);
      setIsEditingSummary(false);
      toast({
        title: 'Summary updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating summary:', error);
      toast({
        title: 'Error updating summary',
        description: error.message || 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  // Handle approve action item
  const handleApproveActionItem = async (item, actionItemData = {}) => {
    try {
      
      const response = await approveActionItem(item.id, actionItemData);
      
      // Update action items
      setActionItems(prev => 
        prev.map(i => i.id === item.id ? { ...i, status: 'approved' } : i)
      );
      
      toast({
        title: 'Action item approved',
        description: 'The action item has been approved.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      
      return response;
    } catch (error) {
      console.error('Error approving action item:', error);
      toast({
        title: 'Error approving action item',
        description: 'Could not approve the action item. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw error;
    } finally {
    }
  };
  
  const handleRejectActionItem = async (actionItemId) => {
    try {
      await rejectActionItem(id, actionItemId);
      setActionItems(prev => 
        prev.map(item => 
          item.id === actionItemId 
            ? { ...item, status: 'rejected' } 
            : item
        )
      );
      toast({
        title: 'Action item rejected',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error rejecting action item:', error);
      toast({
        title: 'Error rejecting action item',
        description: error.message || 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  const handleAssignActionItem = async (actionItemId, userId) => {
    try {
      await assignActionItem(id, actionItemId, userId);
      setActionItems(prev => 
        prev.map(item => 
          item.id === actionItemId 
            ? { ...item, assignedTo: userId } 
            : item
        )
      );
      toast({
        title: 'Action item assigned',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error assigning action item:', error);
      toast({
        title: 'Error assigning action item',
        description: error.message || 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  const handleDateChange = async (actionItemId, dueDate) => {
    try {
      //await updateActionItemDueDate(id, actionItemId, dueDate);
      setActionItems(prev => 
        prev.map(item => 
          item.id === actionItemId 
            ? { ...item, dueDate } 
            : item
        )
      );
      toast({
        title: 'Due date updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating due date:', error);
      toast({
        title: 'Error updating due date',
        description: error.message || 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  return {
    emailData,
    crm,
    summary,
    replies,
    actionItems,
    aiInsights,
    questions,
    isLoading,
    isLoadingActionItems,
    isLoadingInsights,
    isLoadingQuestions,
    isLoadingReplies,
    isEditingTitle,
    isEditingSummary,
    handleEditTitle,
    handleCancelEditTitle,
    handleSaveTitle,
    handleEditSummary,
    handleCancelEditSummary,
    handleSaveSummary,
    handleApproveActionItem,
    handleRejectActionItem,
    handleAssignActionItem,
    handleDateChange,
    fetchData,
    fetchCrmData
  };
};

export default useEmailCorrespondence; 