import React, { useState, useEffect } from 'react';
import { Box, Textarea, IconButton, Icon } from '@chakra-ui/react';
import { FaArrowUp } from 'react-icons/fa';

const InputArea = React.memo(({ 
  inputValue, 
  setInputValue, 
  handleSendMessage, 
  isLoading, 
  isInitialLoading,
  textareaRef 
}) => {
  const [localInputValue, setLocalInputValue] = useState(inputValue);
  
  useEffect(() => {
    setLocalInputValue(inputValue);
  }, [inputValue]);
  
  const handleInputChange = (e) => {
    setLocalInputValue(e.target.value);
  };
  
  const handleSubmit = () => {
    if (localInputValue.trim() === '') return;
    setInputValue(localInputValue);
    handleSendMessage();
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };
  
  return (
    <Box position="relative" mb={2}>
      <Textarea
        ref={textareaRef}
        placeholder="Ask anything about your organization..."
        value={localInputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        pr="50px"
        minH="100px"
        h="auto"
        borderRadius="xl"
        resize="none"
        boxShadow="sm"
        fontFamily="'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif"
        fontSize="md"
        fontWeight="normal"
        lineHeight="1.6"
        color="gray.800"
        _placeholder={{ 
          color: "gray.400",
          fontWeight: "normal"
        }}
        _focus={{
          boxShadow: "md",
          borderColor: "blue.300"
        }}
        disabled={isLoading || isInitialLoading}
      />
      {localInputValue.trim() !== "" && (
        <IconButton
          icon={<Icon as={FaArrowUp} />}
          colorScheme="blue"
          isRound
          size="md"
          position="absolute"
          bottom="10px"
          right="10px"
          onClick={handleSubmit}
          isLoading={isLoading}
          aria-label="Send"
          disabled={isInitialLoading}
        />
      )}
    </Box>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.isInitialLoading === nextProps.isInitialLoading &&
    (prevProps.inputValue === nextProps.inputValue || nextProps.inputValue === '')
  );
});

export default InputArea;