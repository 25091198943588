import React, { useState, useEffect } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Avatar,
  Text,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Input,
  useToast,
  Box,
  Checkbox,
  Flex,
  Divider,
  Badge
} from '@chakra-ui/react';
import { ChevronDownIcon, WarningIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { FaEdit, FaUser, FaUsers, FaSave } from 'react-icons/fa';
import { useOrganizationClients } from '../contexts/OrganizationClientsContext';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { formatPersonName } from '../utils/formatters';

const AssignClientButton = ({ 
  clientName, 
  onAssignClient,
  callId,
  buttonSize = "sm",
  variant = "outline",
  type = "call"
}) => {
  const [showClientSelection, setShowClientSelection] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedClientIds, setSelectedClientIds] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { clients, entities } = useOrganizationClients();
  const toast = useToast();
  const navigate = useNavigate();

  // Filter out entities with no clients
  const validEntities = entities?.filter(entity => entity.clients && entity.clients.length > 0) || [];

  // Reset selections when modal opens/closes
  useEffect(() => {
    if (showClientSelection) {
      setSelectedClientIds([]);
    }
  }, [showClientSelection]);

  const updateCallClient = async (clientId) => {
    try {
      const serverUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.put(
        `${serverUrl}/update-call-client/${callId}`,
        { clientId },
        { headers: { Authorization: `Bearer ${Cookies.get("jwtToken")}` } }
      );

      return response.data;
    } catch (error) {
      console.error('Error updating call client:', error);
      throw error;
    }
  };

  const updateCallWithMultipleClients = async (clientIds) => {
    try {
      const serverUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.put(
        `${serverUrl}/api/calls/${callId}/assign`,
        { clientIds },
        { headers: { Authorization: `Bearer ${Cookies.get("jwtToken")}` } }
      );

      return response.data;
    } catch (error) {
      console.error('Error updating call with multiple clients:', error);
      throw error;
    }
  };
  
  const toggleClientSelection = (clientId) => {
    setSelectedClientIds(prevSelected => {
      if (prevSelected.includes(clientId)) {
        return prevSelected.filter(id => id !== clientId);
      } else {
        return [...prevSelected, clientId];
      }
    });
  };

  const toggleEntitySelection = (entity) => {
    const entityClientIds = entity.clients.map(client => client.id);
    
    setSelectedClientIds(prevSelected => {
      // Check if all entity clients are already selected
      const allSelected = entityClientIds.every(id => prevSelected.includes(id));
      
      if (allSelected) {
        // Remove all entity clients
        return prevSelected.filter(id => !entityClientIds.includes(id));
      } else {
        // Add all entity clients that aren't already selected
        const newSelectedIds = [...prevSelected];
        entityClientIds.forEach(id => {
          if (!newSelectedIds.includes(id)) {
            newSelectedIds.push(id);
          }
        });
        return newSelectedIds;
      }
    });
  };

  const handleSaveSelection = async () => {
    if (selectedClientIds.length === 0) {
      toast({
        title: "No clients selected",
        description: "Please select at least one client.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      let response;
      
      if (selectedClientIds.length === 1) {
        // Use single client API for better compatibility
        response = await updateCallClient(selectedClientIds[0]);
        
        // Find the selected client object
        const selectedClient = clients.find(c => c.id === selectedClientIds[0]);
        if (selectedClient && onAssignClient) {
          onAssignClient(selectedClient);
        }
      } else {
        // Use multi-client API
        response = await updateCallWithMultipleClients(selectedClientIds);
        
        // Update with all assigned clients
        if (response?.call?.clients && response.call.clients.length > 0 && onAssignClient) {
          onAssignClient(response.call.clients);
        }
      }
      
      toast({
        title: "Clients assigned",
        description: `Successfully assigned ${selectedClientIds.length} client${selectedClientIds.length > 1 ? 's' : ''}.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      
      setShowClientSelection(false);
      setSearchQuery('');
      setSelectedClientIds([]); // Reset selections
    } catch (error) {
      console.error('Error assigning clients:', error);
      toast({
        title: "Error assigning clients",
        description: error.response?.data?.error || "There was an error assigning the clients.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isEntitySelected = (entity) => {
    const entityClientIds = entity.clients.map(client => client.id);
    return entityClientIds.every(id => selectedClientIds.includes(id));
  };

  const isEntityPartiallySelected = (entity) => {
    const entityClientIds = entity.clients.map(client => client.id);
    return entityClientIds.some(id => selectedClientIds.includes(id)) && 
           !entityClientIds.every(id => selectedClientIds.includes(id));
  };

  return (
    <>
      <HStack spacing={2}>
        {(clientName && (!Array.isArray(clientName) || clientName.length > 0)) ? (
          <Menu>
            <MenuButton
              as={Button}
              size={buttonSize}
              variant={variant}
              _hover={{ bg: 'gray.100' }}
            >
              <HStack spacing={2}>
                <Avatar
                  name={Array.isArray(clientName) ? 
                    (clientName.length > 0 ? `${clientName[0].firstName} ${clientName[0].lastName}` : 'No Client') : 
                    clientName}
                  size="xs"
                  bg="#00417D"
                  color="white"
                  fontSize="12px"
                  fontWeight="700"
                />
                <Text fontWeight="medium" noOfLines={1}>
                  {Array.isArray(clientName) 
                    ? clientName.map(client => `${client.firstName} ${client.lastName}`).join(', ')
                    : clientName}
                </Text>
                <ChevronDownIcon />
              </HStack>
            </MenuButton>
            <MenuList>
              {type === "call" && (
                <MenuItem
                  onClick={() => setShowClientSelection(true)}
                >
                  <HStack justify="flex-start" width="100%">
                    <Text>Reassign Clients</Text>
                    <Icon as={FaEdit} ml={1} fontWeight="normal" />
                  </HStack>
                </MenuItem>
              )}
              {Array.isArray(clientName) ? (
                <>
                  {clientName.map(client => (
                    <MenuItem 
                      key={client.id}
                      onClick={() => navigate(`/clients/${client.id}`)}
                    >
                      <HStack>
                        <Text>{`${client.firstName} ${client.lastName}`}</Text>
                        <Icon as={ExternalLinkIcon} />
                      </HStack>
                    </MenuItem>
                  ))}
                </>
              ) : (
                <MenuItem 
                  onClick={() => {
                    const client = clients.find(c => formatPersonName(c) === clientName);
                    if (client) {
                      navigate(`/clients/${client.id}`);
                    }
                  }}
                >
                  <HStack>
                    <Text>View Client</Text>
                    <Icon as={ExternalLinkIcon} />
                  </HStack>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        ) : (
          <Button
            leftIcon={<Icon as={WarningIcon} color="red.500" />}
            size={buttonSize}
            variant="outline"
            colorScheme="red"
            onClick={() => type === "call" && setShowClientSelection(true)}
            isDisabled={type === "email"}
          >
            Assign Client
          </Button>
        )}
      </HStack>

      <Modal 
        isOpen={showClientSelection} 
        onClose={() => {
          setShowClientSelection(false);
          setSearchQuery('');
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justify="space-between" align="center">
              <Text>Select Client or Entity</Text>
              {selectedClientIds.length > 0 && (
                <Badge colorScheme="blue" fontSize="0.8em" py={1} px={2} borderRadius="full" mr={7}>
                  {selectedClientIds.length} selected
                </Badge>
              )}
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Input
                placeholder="Search clients or entities..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <VStack align="stretch" maxH="400px" overflowY="auto" w="100%" spacing={2}>
                {/* Entities Section */}
                {validEntities.length > 0 && (
                  <Box mb={4}>
                    <Text fontWeight="bold" mb={2} color="gray.600">
                      Entities (Groups)
                    </Text>
                    {validEntities
                      .filter(entity => 
                        entity.name.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map(entity => (
                        <Button
                          key={`entity-${entity.id}`}
                          variant="ghost"
                          justifyContent="flex-start"
                          onClick={() => toggleEntitySelection(entity)}
                          py={4}
                          bg={isEntitySelected(entity) ? "blue.100" : isEntityPartiallySelected(entity) ? "blue.50" : "gray.50"}
                          _hover={{
                            bg: isEntitySelected(entity) ? "blue.200" : "blue.100"
                          }}
                          mb={1}
                          position="relative"
                          pl={10}
                        >
                          <Checkbox 
                            position="absolute"
                            left={3}
                            isChecked={isEntitySelected(entity)}
                            isIndeterminate={isEntityPartiallySelected(entity)}
                            onChange={() => toggleEntitySelection(entity)}
                            colorScheme="blue"
                            pointerEvents="none"
                          />
                          <HStack>
                            <Icon as={FaUsers} color="blue.500" />
                            <VStack align="start" spacing={0}>
                              <Text>{entity.name}</Text>
                              <Text fontSize="xs" color="gray.500">
                                {entity.clients?.length || 0} clients
                              </Text>
                            </VStack>
                          </HStack>
                        </Button>
                      ))}
                  </Box>
                )}
                
                {validEntities.length > 0 && <Divider my={2} />}
                
                {/* Individual Clients Section */}
                <Box>
                  <Text fontWeight="bold" mb={2} color="gray.600">
                    Individual Clients
                  </Text>
                  {clients
                    .filter(c => 
                      `${c.firstName} ${c.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .map(client => (
                      <Button
                        key={client.id}
                        variant="ghost"
                        justifyContent="flex-start"
                        onClick={() => toggleClientSelection(client.id)}
                        py={4}
                        bg={selectedClientIds.includes(client.id) ? "blue.50" : "white"}
                        _hover={{
                          bg: selectedClientIds.includes(client.id) ? "blue.100" : "gray.50"
                        }}
                        position="relative"
                        pl={10}
                      >
                        <Checkbox 
                          position="absolute"
                          left={3}
                          isChecked={selectedClientIds.includes(client.id)}
                          onChange={() => toggleClientSelection(client.id)}
                          colorScheme="blue"
                          pointerEvents="none"
                        />
                        <HStack>
                          <Icon as={FaUser} color="gray.400" />
                          <Text>{formatPersonName(client)}</Text>
                        </HStack>
                      </Button>
                    ))}
                </Box>
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme="blue" 
              mr={3} 
              onClick={handleSaveSelection}
              isLoading={isSubmitting}
              leftIcon={<Icon as={FaSave} />}
              isDisabled={selectedClientIds.length === 0}
            >
              Save Selection
            </Button>
            <Button variant="ghost" onClick={() => setShowClientSelection(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssignClientButton; 