import React from 'react';
import { Box, VStack, Text, Button, Spinner, Center } from '@chakra-ui/react';
import GrayBox from '../../../../components/GrayBox';
import PropTypes from 'prop-types';

const EmailList = ({
  emailHistory,
  loadingArchivedActions,
  client,
  handleActionUpdate,
  handleViewEmail,
  totalEmails,
  isLoadingMoreEmails,
  fetchEmailHistory,
  emailPage,
  pendingEmailCount,
  showArchivedCorrespondence
}) => {
  if (loadingArchivedActions) {
    return (
      <Center py={10}>
        <Spinner size="xl" color="blue.500" />
      </Center>
    );
  }

  if (!emailHistory || emailHistory.length === 0) {
    return (
      <Box textAlign="center" py={8}>
        <Text color="gray.500">No emails found</Text>
      </Box>
    );
  }

  // Add console log to debug the email data structure
  console.log("Email History in EmailList:", emailHistory);

  return (
    <VStack spacing={4} align="stretch">
      {emailHistory.map((email) => {
        // Add debug logging for each email
        console.log("Processing email:", email);
        
        // Format the email data for GrayBox
        const emailData = {
          title: email.subject || 'No Subject',
          subtitle: new Date(email.metadata?.sentDateTime || email.receivedAt).toLocaleString(),
          metadata: {
            ...email.metadata,
            date: new Date(email.metadata?.sentDateTime || email.receivedAt).toLocaleString(),
            clientNames: email.clients?.map(c => `${c.firstName} ${c.lastName}`).join(', ') || '',
            threadInfo: email.replies ? {
              hasThread: true,
              threadCount: email.replies.length,
              replies: email.replies
            } : null,
            from: email.metadata?.from
          },
          listItems: email.summary 
            ? typeof email.summary === 'string' 
              ? email.summary.split('\n').filter(item => item.trim() !== '') 
              : []
            : [],
          transcriptId: email.id,
          client: email.clients || [],
          id: email.id,
          type: "email",
          correspondence: email,
          emailData: email.metadata?.from ? {
            emailAddress: email.metadata.from.emailAddress
          } : null
        };
        
        return (
          <Box key={email.id}>
            <GrayBox
              {...emailData}
              rows={email.ActionItem || []}
              onArchive={() => handleActionUpdate(email.id, 'archive')}
              scrollRef={null}
              errorItems={[]}
              nonClientSpeakers={[]}
              handleViewEmail={() => handleViewEmail(email.id)}
            />
          </Box>
        );
      })}

      {emailHistory.length < totalEmails && (
        <Button
          onClick={() => fetchEmailHistory(emailPage + 1)}
          isLoading={isLoadingMoreEmails}
          loadingText="Loading more..."
          variant="outline"
          colorScheme="blue"
          width="100%"
          mt={4}
        >
          Load More
        </Button>
      )}
    </VStack>
  );
};

EmailList.propTypes = {
  emailHistory: PropTypes.array.isRequired,
  loadingArchivedActions: PropTypes.bool,
  client: PropTypes.object,
  handleActionUpdate: PropTypes.func.isRequired,
  handleViewEmail: PropTypes.func.isRequired,
  totalEmails: PropTypes.number.isRequired,
  isLoadingMoreEmails: PropTypes.bool.isRequired,
  fetchEmailHistory: PropTypes.func.isRequired,
  emailPage: PropTypes.number.isRequired,
  pendingEmailCount: PropTypes.number,
  showArchivedCorrespondence: PropTypes.bool.isRequired
};

// Set default props for optional props
EmailList.defaultProps = {
  loadingArchivedActions: false,
  client: {},
  pendingEmailCount: 0
};

export default EmailList; 