import React, { useState, useRef } from 'react';
import axios from 'axios';
import {
    Box,
    Text,
    VStack,
    Progress,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Select,
    useToast,
    Heading,
    InputGroup,
    InputLeftElement,
    List,
    ListItem,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useOrganizationClients } from '../../../contexts/OrganizationClientsContext';
import { FeatureStats } from '../../../enums/UsageStats';
import Cookies from 'js-cookie';
import { BlobServiceClient } from '@azure/storage-blob';
import { FaSearch } from 'react-icons/fa';

const serverUrl = process.env.REACT_APP_API_URL;
const ACCEPTED_FILE_TYPES = ['audio/mpeg', 'audio/mp4', 'audio/x-m4a', 'audio/wav', 'video/mp4'];
const ACCEPTED_FILE_EXTENSIONS = ['.mp3', '.mp4', '.m4a', '.wav'];
const MAX_FILE_SIZE_MB = 200;

function Upload() {
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [meetingName, setMeetingName] = useState('');
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [selectedClient, setSelectedClient] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const toast = useToast();
    const { clients } = useOrganizationClients();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [uploadUrl, setUploadUrl] = useState(null);
    const [blobName, setBlobName] = useState(null);
    const [clientSearchQuery, setClientSearchQuery] = useState('');
    const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
    const clientInputRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles && droppedFiles.length > 0) {
            const selectedFile = droppedFiles[0];
            const fileSizeMB = selectedFile.size / (1024 * 1024);
            console.log(`File size: ${fileSizeMB} MB`);
            if (fileSizeMB > MAX_FILE_SIZE_MB) {
                toast({
                    title: 'File Too Large',
                    description: `Please upload a file smaller than ${MAX_FILE_SIZE_MB} MB.`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            if (ACCEPTED_FILE_TYPES.includes(selectedFile.type)) {
                setFile(selectedFile);
                setShowSaveModal(true);
                e.dataTransfer.clearData();
            } else {
                toast({
                    title: 'Invalid File Type',
                    description: 'Please upload an MP3, M4A, WAV, or MP4 file.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const fileSizeMB = selectedFile.size / (1024 * 1024);
            console.log(`File size: ${fileSizeMB} MB`);
            if (fileSizeMB > MAX_FILE_SIZE_MB) {
                toast({
                    title: 'File Too Large',
                    description: `Please upload a file smaller than ${MAX_FILE_SIZE_MB} MB.`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            if (ACCEPTED_FILE_TYPES.includes(selectedFile.type)) {
                setFile(selectedFile);
                setShowSaveModal(true);
            } else {
                toast({
                    title: 'Invalid File Type',
                    description: 'Please upload an MP3, M4A, WAV, or MP4 file.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const openFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleClientChange = (e) => {
        const value = e.target.value;
        setSelectedClientId(value === 'untitled' ? undefined : value);

        const client = clients.find(client => client.id === value);
        setSelectedClient(client ? `${client.firstName} ${client.lastName}` : '');
    };

    const filteredClients = clients.filter(client => {
        const fullName = `${client.firstName} ${client.metadata?.preferredName ? client.metadata.preferredName : ''} ${client.lastName}`.toLowerCase();
        return fullName.includes(clientSearchQuery.toLowerCase());
    });

    const handleClientSearch = (e) => {
        setClientSearchQuery(e.target.value);
        if (!isClientDropdownOpen) {
            setIsClientDropdownOpen(true);
        }
    };

    const selectClient = (clientId) => {
        setSelectedClientId(clientId);
        const client = clients.find(client => client.id === clientId);
        setSelectedClient(client ? `${client.firstName} ${client.lastName}` : '');
        setIsClientDropdownOpen(false);
    };

    const handleClientInputFocus = () => {
        setIsClientDropdownOpen(true);
    };

    const handleClientInputBlur = () => {
        setTimeout(() => {
            setIsClientDropdownOpen(false);
        }, 200);
    };

    const getUploadUrl = async (fileName) => {
        try {
            const response = await axios.get(`${serverUrl}/api/get-upload-url`, {
                params: { fileName }
            });
            console.log('Upload URL response:', {
                blobName: response.data.blobName,
                hasUrl: !!response.data.sasUrl,
                blobUrl: response.data.blobUrl // This is the base URL without SAS token
            });
            return response.data;
        } catch (error) {
            console.error('Error getting upload URL:', error);
            throw error;
        }
    };

    const handleUpload = async () => {
        if (!file) return;

        setIsUploading(true);
        try {
            // Get SAS URL for upload
            console.log('Step 1: Getting SAS URL for file:', file.name);
            let sasUrlResponse;
            try {
                sasUrlResponse = await getUploadUrl(file.name);
                console.log('Successfully got SAS URL. BlobName:', sasUrlResponse.blobName);
            } catch (sasError) {
                console.error('Failed to get SAS URL:', sasError);
                console.error('SAS Error details:', sasError.response?.data);
                throw new Error('Failed to get upload URL');
            }

            const { sasUrl, blobName, blobUrl } = sasUrlResponse;

            // Step 2: Upload to Azure
            console.log('Step 2: Starting Azure upload');
            let uploadResponse;
            try {

                const uploadAxios = axios.create();

                delete uploadAxios.defaults.headers.common['Authorization'];

                console.log('Uploading file:', {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    url: sasUrl
                });

                uploadResponse = await uploadAxios.put(sasUrl, file, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': file.type,
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setUploadProgress(percentComplete);
                    },
                });
                console.log('Azure upload successful. Status:', uploadResponse.status);
            } catch (uploadError) {
                console.error('Azure upload failed:', uploadError);
                console.error('Upload error details:', uploadError.response?.data);
                throw new Error('Failed to upload to Azure');
            }

            // Step 3: Notify backend
            console.log('Step 3: Notifying backend');
            try {
                const processResponse = await axios.post(`${serverUrl}/api/process-upload`, {
                    blobName,
                    blobUrl,
                    meetingName: meetingName || `Meeting ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
                    selectedClientId: selectedClientId || '',
                    clientName: selectedClient
                });
                console.log('Backend notification successful. Status:', processResponse.data);
            } catch (processError) {
                console.error('Backend notification failed:', processError);
                console.error('Process error details:', processError.response?.data);
                throw new Error('Failed to notify backend');
            }
            
            //Step 4: Success cleanup
            console.log('Step 4: Success cleanup');
            toast({
                title: 'Upload Successful',
                description: 'Your file has been uploaded and will be processed shortly. Please check the home page after a few minutes.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            // Log uploaded meeting usage
            await axios.post(`${serverUrl}/api/usage/log`, {
                statPath: FeatureStats.MEETINGS_UPLOADED,
                value: 1
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                }
            });

            // Reset states
            setFile(null);
            setShowSaveModal(false);
            setUploadProgress(0);
            setMeetingName('');
            setSelectedClientId(null);
            setSelectedClient('');

        } catch (error) {
            toast({
                title: 'Upload Failed',
                description: 'There was an error uploading your file. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <Box bg="white" p={8} height="95vh">
            <Heading color="#00417D" pb={4}>Upload Recording</Heading>
            <VStack spacing={6} mt={10}>
                <Text fontSize="xl" textAlign="center">
                    Upload an audio or video file to generate a transcript
                </Text>
                <Text fontSize="md" color="gray.600" textAlign="center">
                    Depending on the size of the file, it will be processed & transcribed in 10-15 minutes.
                    <br />
                    Note that you will need to manually identify the speakers in the transcript.
                    <br />
                    File size limit: 200MB. 
                </Text>

                <Box
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    w="100%"
                    maxW="600px"
                    h="200px"
                    border="2px dashed"
                    borderColor="gray.300"
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="gray.50"
                    cursor="pointer"
                    transition="all 0.2s"
                    _hover={{ borderColor: "blue.400", bg: "blue.50" }}
                    onClick={openFileDialog}
                >
                    <VStack spacing={2}>
                        <Text fontSize="lg">
                            Drag & drop a file here, or click to select
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                            Supported formats: MP3, M4A, WAV, MP4
                        </Text>
                    </VStack>
                    <input
                        type="file"
                        accept={ACCEPTED_FILE_EXTENSIONS.join(',')}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                    />
                </Box>

                {isUploading && (
                    <Box w="100%" maxW="600px">
                        <Progress value={uploadProgress} size="sm" colorScheme="blue" />
                        <Text mt={2} textAlign="center">{uploadProgress}% uploaded</Text>
                    </Box>
                )}
            </VStack>

            <Modal isOpen={showSaveModal} onClose={() => setShowSaveModal(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Save Recording</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4}>
                            <Text fontSize="lg">Give your meeting a name:</Text>
                            <Input
                                placeholder={`Meeting ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
                                value={meetingName}
                                onChange={(e) => setMeetingName(e.target.value)}
                            />
                            <Text fontSize="lg">Identify Client:</Text>
                            <Box position="relative" width="100%">
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                        <FaSearch color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                        ref={clientInputRef}
                                        placeholder="Search clients..."
                                        value={clientSearchQuery}
                                        onChange={handleClientSearch}
                                        onFocus={handleClientInputFocus}
                                        onBlur={handleClientInputBlur}
                                    />
                                </InputGroup>
                                {isClientDropdownOpen && (
                                    <List
                                        position="absolute"
                                        top="100%"
                                        left="0"
                                        width="100%"
                                        maxH="200px"
                                        overflowY="auto"
                                        bg="white"
                                        borderWidth="1px"
                                        borderRadius="md"
                                        zIndex="dropdown"
                                        boxShadow="md"
                                    >
                                        <ListItem
                                            p={2}
                                            _hover={{ bg: "gray.100" }}
                                            cursor="pointer"
                                            onClick={() => selectClient(undefined)}
                                        >
                                            Untitled Client
                                        </ListItem>
                                        {filteredClients.map(client => (
                                            <ListItem
                                                key={client.id}
                                                p={2}
                                                _hover={{ bg: "gray.100" }}
                                                cursor="pointer"
                                                onClick={() => selectClient(client.id)}
                                                bg={selectedClientId === client.id ? "blue.50" : "transparent"}
                                            >
                                                {client.firstName} {client.metadata?.preferredName ? `(${client.metadata.preferredName})` : ''} {client.lastName}
                                            </ListItem>
                                        ))}
                                        {filteredClients.length === 0 && (
                                            <ListItem p={2} color="gray.500">No clients found</ListItem>
                                        )}
                                    </List>
                                )}
                            </Box>
                            {selectedClient && (
                                <Text fontSize="lg" color="blue.500" alignSelf="flex-start">
                                    Selected: {selectedClient}
                                </Text>
                            )}
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={() => setShowSaveModal(false)}>
                            Cancel
                        </Button>
                        <Button 
                            colorScheme="blue" 
                            onClick={handleUpload}
                            isLoading={isUploading}
                        >
                            Upload
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default Upload;