import axios from 'axios';
import { SimpleGrid, Flex, FormControl, FormLabel, Input, Button, useToast, Text } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const serverUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        console.log('ResetPassword component mounted');
        console.log('Token from URL:', token);
        console.log('Server URL:', serverUrl);
    }, [token, serverUrl]);

    const validatePassword = (password) => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
        return regex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form submitted');
        console.log('Making API call to:', `${serverUrl}/reset-password/${token}`);
        
        if (!validatePassword(password)) {
            setPasswordError("Password must be at least 8 characters long and include a letter, a number, and a special character.");
            return;
        }

        if (password !== confirmPassword) {
            setPasswordError("Passwords do not match.");
            return;
        }

        setIsSubmitting(true);

        try {
            await axios.post(`${serverUrl}/reset-password/${token}`, { password });
            console.log('Password reset successful');
            toast({
                title: "Success",
                description: "Your password has been reset successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            navigate('/login');
        } catch (error) {
            console.error('Password reset error:', error);
            toast({
                title: "Error",
                description: error.response?.data?.error || "An error occurred. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <SimpleGrid columns={2} spacing={0} width={"100%"} height={"100%"} data-simple className="ma-0 pa-0">
            <Flex justifyContent={"center"} alignItems={"center"}
                flexDirection={"column"} width={"100%"} height={"100%"}
            >
                <div className="h-100" style={{ maxWidth: "450px" }}>
                    <div style={{color: '#2B3674', fontSize: 36, fontWeight: '700', wordWrap: 'break-word'}}>Set New Password</div>
                    <div style={{color: '#A3AED0', marginBottom: "4px", fontSize: 16, fontWeight: '400', wordWrap: 'break-word'}}>
                        Enter your new password below.
                    </div>
                    <form onSubmit={handleSubmit}>
                        <FormControl className="mt-8" width={"100%"} isRequired={true}>
                            <FormLabel>New Password</FormLabel>
                            <Input 
                                type="password"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setPasswordError('');
                                }}
                            />
                            {passwordError && <Text color="red.500">{passwordError}</Text>}
                        </FormControl>
                        <FormControl className="mt-4" width={"100%"} isRequired={true}>
                            <FormLabel>Confirm Password</FormLabel>
                            <Input 
                                type="password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </FormControl>
                        <Button 
                            width={"100%"} 
                            className="mt-10" 
                            background={"#00417D"} 
                            color={"white"} 
                            borderRadius={"16px"} 
                            isLoading={isSubmitting}
                            type="submit"
                        >
                            Reset Password
                        </Button>
                    </form>
                </div>
            </Flex>
            <Flex>
                <Image src="/images/login_bg.jpeg" alt="LOGO JPEG" width={"100%"} height={"100%"}/>
            </Flex>
        </SimpleGrid>
    );
};

export default ResetPassword; 