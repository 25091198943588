import React from 'react';
import { VStack, HStack, Text, Switch, Heading, Box, Button, Badge } from '@chakra-ui/react';
import EmailList from './EmailList';

const EmailPanel = ({ 
  emailHistory, 
  showArchivedCorrespondence,
  isLoadingArchived,
  handleArchiveToggle,
  loadingArchivedActions,
  client,
  handleActionUpdate,
  handleViewEmail,
  totalEmails,
  isLoadingMoreEmails,
  fetchEmailHistory,
  emailPage,
  pendingEmailCount
}) => {
  return (
    <VStack spacing={4} align="stretch">
      <HStack justify="space-between" mb={4}>
        <Heading size="md">Email History</Heading>
        <HStack>
          <Text>Show Archived</Text>
          <Switch
            isChecked={showArchivedCorrespondence}
            onChange={(e) => handleArchiveToggle(e.target.checked)}
            isDisabled={isLoadingArchived}
          />
        </HStack>
      </HStack>
      
      {/* Use EmailList component to display the grouped emails */}
      <EmailList 
        emailHistory={emailHistory}
        loadingArchivedActions={loadingArchivedActions}
        client={client}
        handleActionUpdate={handleActionUpdate}
        handleViewEmail={handleViewEmail}
        totalEmails={totalEmails}
        isLoadingMoreEmails={isLoadingMoreEmails}
        fetchEmailHistory={fetchEmailHistory}
        emailPage={emailPage}
        pendingEmailCount={pendingEmailCount}
        showArchivedCorrespondence={showArchivedCorrespondence}
      />
    </VStack>
  );
};

export default EmailPanel; 