import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, VStack, HStack, Text, Icon, Button, 
  Input, InputGroup, InputRightElement, 
  Checkbox, SimpleGrid,
  FormControl, FormLabel,
  Card, CardBody, Badge, Link,
  Menu, MenuButton, MenuList, MenuItem,
  Switch, Textarea, IconButton,
  UnorderedList, ListItem,
  Spinner, Skeleton
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Link as RouterLink } from 'react-router-dom';
import { FaRobot, FaSearch, FaChevronDown, FaCalendarAlt, FaHashtag, FaUser, FaArrowUp, FaGraduationCap, FaChartLine, FaPiggyBank, FaExclamationCircle, FaReply } from 'react-icons/fa';
import { useOrganizationClients } from '../../../contexts/OrganizationClientsContext';
import MessageFeedback from '../../MessageFeedback';
import { FixedSizeList as List } from 'react-window';

const TitleBar = () => (
  <Box textAlign="center" width="100%" mb={2}>
    <HStack spacing={3} justify="center">
      <Text 
        fontSize="2xl" 
        fontWeight="bold" 
        bgGradient="linear(to-r, blue.500, blue.600)"
        bgClip="text"
        letterSpacing="tight"
      >
        Ask DataDasher
      </Text>
    </HStack>
    <Text fontSize="md" color="gray.500" mt={1}>
      Your intelligent assistant for organizational insights
    </Text>
  </Box>
);

// Create a completely isolated input component to prevent re-renders
const TextareaInput = React.memo(({ 
  initialValue = '', 
  onSubmit,
  isLoading
}) => {
  // Use local state to completely isolate typing from parent component
  const [localValue, setLocalValue] = useState(initialValue);
  
  // Update local value when initialValue changes (e.g., when it's cleared after submission)
  useEffect(() => {
    setLocalValue(initialValue);
  }, [initialValue]);
  
  const handleChange = useCallback((e) => {
    setLocalValue(e.target.value);
  }, []);
  
  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit(localValue);
    }
  }, [onSubmit, localValue]);
  
  const handleSubmit = useCallback(() => {
    onSubmit(localValue);
  }, [onSubmit, localValue]);
  
  return (
    <Box position="relative" mb={2}>
      <Textarea
        placeholder="Ask anything about your organization..."
        value={localValue}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        pr="50px"
        minH="100px"
        h="auto"
        borderRadius="xl"
        resize="none"
        boxShadow="sm"
        fontFamily="'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif"
        fontSize="md"
        fontWeight="normal"
        lineHeight="1.6"
        color="gray.800"
        _placeholder={{ 
          color: "gray.400",
          fontWeight: "normal"
        }}
        _focus={{
          boxShadow: "md",
          borderColor: "blue.300"
        }}
      />
      {localValue.trim() !== "" && (
        <IconButton
          icon={<Icon as={FaArrowUp} />}
          colorScheme="blue"
          isRound
          size="md"
          position="absolute"
          bottom="10px"
          right="10px"
          onClick={handleSubmit}
          isLoading={isLoading}
          aria-label="Ask"
        />
      )}
    </Box>
  );
});

// Create a separate ClientFilterMenu component that's properly memoized
const ClientFilterMenu = React.memo(({ 
  clients, 
  selectedClients, 
  setSelectedClients, 
  isClientsLoading,
  clientSearchQuery,
  setClientSearchQuery 
}) => {
  const filteredClients = React.useMemo(() => {
    if (!Array.isArray(clients)) return [];
    if (!clientSearchQuery) return clients;
    
    return clients.filter(client => {
      const fullName = `${client.firstName} ${client.lastName}`.toLowerCase();
      return fullName.includes(clientSearchQuery.toLowerCase());
    });
  }, [clients, clientSearchQuery]);

  const handleSearchChange = React.useCallback((e) => {
    setClientSearchQuery(e.target.value);
  }, [setClientSearchQuery]);

  const toggleClient = React.useCallback((clientId) => {
    setSelectedClients(prev => 
      prev.includes(clientId)
        ? prev.filter(id => id !== clientId)
        : [...prev, clientId]
    );
  }, [setSelectedClients]);

  const Row = ({ index, style }) => {
    const client = filteredClients[index];
    return (
      <MenuItem 
        key={client.id} 
        onClick={(e) => {
          e.stopPropagation();
          toggleClient(client.id);
        }}
        style={style}
        px={2}
        py={1.5}
        _hover={{ bg: "gray.50" }}
        borderRadius="md"
        fontSize="sm"
        color="gray.700"
      >
        <Checkbox 
          isChecked={selectedClients.includes(client.id)}
          colorScheme="blue"
          mr={2}
        >
          <Text fontSize="sm">
            {client.firstName} {client.lastName}
          </Text>
        </Checkbox>
      </MenuItem>
    );
  };

  return (
    <MenuList maxH="300px" overflowY="auto" maxW="300px" w="300px" p={2}>
      <Box mb={3}>
        <InputGroup size="md" mb={3}>
          <Input 
            placeholder="Search clients..." 
            value={clientSearchQuery} 
            onChange={handleSearchChange}
            borderRadius="md"
            bg="white"
            px={3}
            py={2}
            fontSize="sm"
          />
          <InputRightElement>
            <Icon as={FaSearch} color="gray.400" />
          </InputRightElement>
        </InputGroup>
      </Box>
      <Box 
        borderTopWidth="1px" 
        borderColor="gray.100"
        pt={2}
      >
        <MenuItem 
          onClick={(e) => {
            e.stopPropagation();
            setSelectedClients([]);
          }}
          px={2}
          py={2}
          bg={selectedClients.length === 0 ? "blue.50" : "transparent"}
          borderRadius="md"
          fontSize="sm"
          color="gray.700"
        >
          <Checkbox 
            isChecked={selectedClients.length === 0}
            onChange={() => setSelectedClients([])}
            colorScheme="blue"
            mr={2}
          >
            <Text fontSize="sm">All Clients</Text>
          </Checkbox>
        </MenuItem>
        
        {isClientsLoading ? (
          <Spinner size="sm" m={4} />
        ) : (
          <List
            height={200}
            itemCount={filteredClients.length}
            itemSize={35}
            width="100%"
          >
            {Row}
          </List>
        )}
      </Box>
    </MenuList>
  );
});

// Update the client button in FiltersSection
const ClientButton = React.memo(({ 
  clients, 
  selectedClients, 
  setSelectedClients, 
  loadClients, 
  isClientsLoading,
  clientSearchQuery,
  setClientSearchQuery
}) => {
  // Calculate the display text for the button
  const buttonText = React.useMemo(() => {
    if (selectedClients.length === 0) return 'All Clients';
    if (selectedClients.length === 1) {
      const client = clients.find(c => c.id === selectedClients[0]);
      return client ? `${client.firstName} ${client.lastName}` : '1 Client';
    }
    return `${selectedClients.length} Clients`;
  }, [clients, selectedClients]);

  return (
    <Menu onOpen={loadClients} closeOnSelect={false}>
      <MenuButton 
        as={Button} 
        rightIcon={<Icon as={FaChevronDown} color="gray.500" boxSize="10px" />}
        variant="ghost"
        fontWeight="normal"
        color="gray.600"
        px={2}
        py={1}
        h="32px"
        fontSize="sm"
        leftIcon={<Icon as={FaUser} color="gray.400" boxSize="12px" />}
        isLoading={isClientsLoading}
      >
        {buttonText}
      </MenuButton>
      <ClientFilterMenu
        clients={clients}
        selectedClients={selectedClients}
        setSelectedClients={setSelectedClients}
        isClientsLoading={isClientsLoading}
        clientSearchQuery={clientSearchQuery}
        setClientSearchQuery={setClientSearchQuery}
      />
    </Menu>
  );
});

// Update the FiltersSection to use the optimized ClientButton
const FiltersSection = React.memo(({ 
  clients, 
  selectedClients, 
  setSelectedClients, 
  selectedType, 
  setSelectedType, 
  timeRange, 
  setTimeRange, 
  customStartDate, 
  setCustomStartDate, 
  customEndDate, 
  setCustomEndDate,
  exactMatch,
  setExactMatch,
  loadClients,
  isClientsLoading,
  clientSearchQuery,
  setClientSearchQuery,
  recentConversationId,
  navigate
}) => {
  // Memoize the exact match toggle handler to prevent recreation on every render
  const handleExactMatchToggle = React.useCallback(() => {
    setExactMatch(prev => !prev);
  }, [setExactMatch]);
  
  return (
    <Box py={1}>
      <HStack spacing={3} align="center" justify="center">
        <Menu>
          <MenuButton 
            as={Button} 
            rightIcon={<Icon as={FaChevronDown} color="gray.500" boxSize="10px" />}
            variant="ghost"
            fontWeight="normal"
            color="gray.600"
            px={2}
            py={1}
            h="32px"
            fontSize="sm"
            leftIcon={<Icon as={FaCalendarAlt} color="gray.400" boxSize="12px" />}
          >
            {timeRange === 'week' ? 'Last 7 days' : 
             timeRange === 'month' ? 'Last 30 days' :
             timeRange === 'quarter' ? 'Last 90 days' :
             timeRange === 'year' ? 'Last Year' :
             timeRange === 'today' ? 'Today' :
             timeRange === 'custom' ? 'Custom Range' : 'All Time'}
          </MenuButton>
          <MenuList minW="0" w="auto">
            <MenuItem 
              onClick={() => setTimeRange('all')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              All Time
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('today')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Today
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('week')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Last 7 days
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('month')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Last 30 days
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('quarter')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Last 90 days
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('year')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Last Year
            </MenuItem>
            <MenuItem 
              onClick={() => setTimeRange('custom')} 
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Custom Range
            </MenuItem>
          </MenuList>
        </Menu>
        
        <Menu>
          <MenuButton 
            as={Button} 
            rightIcon={<Icon as={FaChevronDown} color="gray.500" boxSize="10px" />}
            variant="ghost"
            fontWeight="normal"
            color="gray.600"
            px={2}
            py={1}
            h="32px"
            fontSize="sm"
            leftIcon={<Icon as={FaHashtag} color="gray.400" boxSize="12px" />}
          >
            {selectedType === 'email' ? 'Emails Only' :
             selectedType === 'call' ? 'Calls Only' :
             'Calls & Emails'}
          </MenuButton>
          <MenuList minW="0" w="auto">
            <MenuItem 
              onClick={() => setSelectedType('all')}
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Calls & Emails
            </MenuItem>
            <MenuItem 
              onClick={() => setSelectedType('email')}
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Emails Only
            </MenuItem>
            <MenuItem 
              onClick={() => setSelectedType('call')}
              fontSize="sm" 
              color="gray.600"
              py={1}
            >
              Calls Only
            </MenuItem>
          </MenuList>
        </Menu>
        
        {/* Optimized client filter */}
        <ClientButton
          clients={clients}
          selectedClients={selectedClients}
          setSelectedClients={setSelectedClients}
          loadClients={loadClients}
          isClientsLoading={isClientsLoading}
          clientSearchQuery={clientSearchQuery}
          setClientSearchQuery={setClientSearchQuery}
        />
        
        <Button
          variant="ghost"
          fontWeight="normal"
          color="gray.600"
          px={2}
          py={1}
          h="32px"
          fontSize="sm"
          leftIcon={<Icon as={FaSearch} color="gray.400" boxSize="12px" />}
          onClick={handleExactMatchToggle}
          _hover={{ bg: "gray.50" }}
        >
          Exact
          <Switch
            colorScheme="blue"
            size="sm"
            isChecked={exactMatch}
            onChange={handleExactMatchToggle}
            ml={1}
            onClick={(e) => e.stopPropagation()}
          />
        </Button>
        
        {recentConversationId && (
          <Button
            leftIcon={<Icon as={FaReply} boxSize="12px" />}
            variant="ghost"
            fontWeight="normal"
            color="gray.600"
            px={2}
            py={1}
            h="32px"
            fontSize="sm"
            onClick={() => navigate(`/ask-datadasher?conversationId=${recentConversationId}`)}
          >
            History
          </Button>
        )}
      </HStack>
      
      {timeRange === 'custom' && (
        <HStack spacing={3} mt={4} justify="center" maxW="500px" mx="auto">
          <FormControl flex="1" maxW="220px">
            <FormLabel fontSize="sm" mb={1}>Start Date</FormLabel>
            <Input 
              type="date" 
              size="sm"
              value={customStartDate}
              onChange={(e) => setCustomStartDate(e.target.value)}
            />
          </FormControl>
          <FormControl flex="1" maxW="220px">
            <FormLabel fontSize="sm" mb={1}>End Date</FormLabel>
            <Input 
              type="date" 
              size="sm"
              value={customEndDate}
              onChange={(e) => setCustomEndDate(e.target.value)}
            />
          </FormControl>
        </HStack>
      )}
    </Box>
  );
});

// Define the answer styles as a separate object
const answerStyles = {
  fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",
  fontSize: "md",
  lineHeight: "1.6",
  color: "gray.800",
  'h1': { fontSize: '2xl', fontWeight: 'bold', mb: 4 },
  'h2': { fontSize: 'xl', fontWeight: 'bold', mb: 3 },
  'h3': { fontSize: 'lg', fontWeight: 'bold', mb: 2 },
  'p': { mb: 4 },
  'ul, ol': { mb: 4, pl: 6 },
  'li': { mb: 2 },
  'table': {
    width: '100%',
    mb: 4,
    borderCollapse: 'collapse',
    'th, td': {
      border: '1px solid',
      borderColor: 'gray.200',
      p: 2
    },
    'tr:nth-of-type(even)': {
      bg: 'gray.50'
    }
  }
};

const AIAnswer = ({ answer, navigate, conversationId }) => (
  <Card variant="outline">
    <CardBody>
      <Box
        dangerouslySetInnerHTML={{ __html: answer.response }}
        sx={answerStyles}
      />
      {answer.citations && answer.citations.length > 0 && (
        <Box mt={4} pt={4} borderTop="1px" borderColor="gray.200">
          <Text fontSize="sm" fontWeight="medium" mb={2}>Sources:</Text>
          <VStack align="stretch" spacing={2}>
            {answer.citations.map((citation, index) => (
              <HStack key={index} spacing={2}>
                <Text fontSize="sm" color="gray.500">[{index + 1}]</Text>
                {citation.link ? (
                  <Link
                    as={RouterLink}
                    to={citation.link}
                    fontSize="sm"
                    color="blue.500"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {citation.description}
                  </Link>
                ) : (
                  <Text fontSize="sm" color="gray.600">
                    {citation.description}
                  </Text>
                )}
                <Badge
                  size="sm"
                  colorScheme={
                    citation.type === 'email'
                      ? 'blue'
                      : citation.type === 'call'
                      ? 'green'
                      : citation.type === 'client'
                      ? 'purple'
                      : 'gray'
                  }
                >
                  {citation.type}
                </Badge>
              </HStack>
            ))}
          </VStack>
        </Box>
      )}

      {answer.messageId && (
        <MessageFeedback messageId={answer.messageId} />
      )}

      <Box mt={4} display="flex" justifyContent="flex-end">
        <Button
          colorScheme="blue"
          size="md"
          onClick={() => navigate(`/ask-datadasher?conversationId=${conversationId}`)}
          leftIcon={<Icon as={FaRobot} />}
        >
          Continue Conversation
        </Button>
      </Box>
    </CardBody>
  </Card>
);

// Search Results component
const SearchResults = ({ displayedResults, searchKeyword, navigate, highlightText, hasMore, loadMore }) => (
  <VStack spacing={4}>
    {displayedResults.map((result, index) => (
      <Card
        key={index}
        variant="outline"
        p={4}
        borderRadius="md"
        boxShadow="sm"
        width="100%"
        cursor="pointer"
        onClick={() => {
          if (result.typeOfInformation === 'Email') {
            navigate(`/correspondence/email/${result.textBodyId}`);
          } else {
            navigate(`/transcripts/${result.textBodyId}`);
          }
        }}
      >
        <CardBody>
          <HStack spacing={2} mb={0}>
            <Link
              as={RouterLink}
              to={`/clients/${result.clientId}`}
              onClick={(e) => e.stopPropagation()}
            >
              <Text fontWeight="bold" fontSize="lg" color="blue.500" _hover={{ textDecoration: 'underline' }}>
                {result.clientName}
              </Text>
            </Link>
            <Text fontSize="sm" color="gray.500">
              {result.typeOfInformation}
            </Text>
          </HStack>
          <Text fontSize="sm" color="gray.500" mt={1}>
            {result.callTitle || 'Untitled'}
          </Text>
          <Text
            fontSize="md"
            color="gray.700"
            mt={1}
            dangerouslySetInnerHTML={{ __html: highlightText(result.text, searchKeyword) }}
          />
        </CardBody>
      </Card>
    ))}
    
    {hasMore && (
      <Button
        onClick={loadMore}
        size="sm"
        variant="ghost"
        width="100%"
        mt={2}
        colorScheme="blue"
      >
        Load More Results
      </Button>
    )}
  </VStack>
);

// Memoize the Suggestions component to prevent unnecessary re-renders
const Suggestions = React.memo(({ setQuestion, exactMatch }) => {
  // Memoize suggestion cards based on exactMatch to prevent unnecessary recalculations
  const suggestionCards = React.useMemo(() => {
    // AI mode suggestion cards (for when exactMatch is false)
    const aiSuggestionCards = [
      {
        icon: FaPiggyBank,
        title: "401K catch up contributions",
        description: "Which of my clients mentioned this?",
        query: "Which of my clients mentioned 401K catch up contributions?"
      },
      {
        icon: FaGraduationCap,
        title: "529 educational funding",
        description: "Did any clients show interest for their kids?",
        query: "Did any of my clients mention interest in exploring 529 educational funding options for their kids?"
      },
      {
        icon: FaChartLine,
        title: "Market volatility concerns",
        description: "Did any clients mention this recently?",
        query: "Did any of my clients mention concern about recent market volatility?"
      }
    ];

    // Exact match mode suggestion cards (for when exactMatch is true)
    const exactMatchSuggestionCards = [
      {
        icon: FaPiggyBank,
        title: "retirement",
        description: "Find mentions of retirement",
        query: "retirement"
      },
      {
        icon: FaExclamationCircle,
        title: "urgent",
        description: "Find urgent matters",
        query: "urgent"
      },
      {
        icon: FaReply,
        title: "follow-up",
        description: "Find follow-up items",
        query: "follow-up"
      }
    ];

    // Choose which suggestion cards to display based on exactMatch mode
    return exactMatch ? exactMatchSuggestionCards : aiSuggestionCards;
  }, [exactMatch]);

  return (
    <Box mt={3} maxW="900px" mx="auto">
      <SimpleGrid columns={[1, 2, 3]} spacing={4}>
        {suggestionCards.map((card, index) => (
          <Box 
            key={index}
            p={3}
            borderWidth="1px"
            borderRadius="md"
            borderColor="gray.200"
            cursor="pointer"
            onClick={() => setQuestion(card.query)}
            textAlign="center"
            maxW="280px"
            mx="auto"
            w="100%"
            transition="transform 0.2s, box-shadow 0.2s"
            _hover={{ 
              borderColor: "blue.300",
              transform: "translateY(-2px)",
              boxShadow: "sm"
            }}
          >
            <Icon 
              as={card.icon} 
              boxSize={5} 
              color={index === 0 ? "blue.400" : index === 1 ? "green.400" : "red.400"} 
              mb={2}
            />
            <Text fontWeight="medium" fontSize="sm" mb={1}>
              {card.title}
            </Text>
            <Text color="gray.600" fontSize="xs">
              {card.description}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
});

// New helper function for date filtering
const getDateRangeParams = (timeRange, customStartDate, customEndDate) => {
  const dateParams = {};
  
  if (timeRange === 'custom' && customStartDate) {
    dateParams.startDate = customStartDate;
    if (customEndDate) {
      dateParams.endDate = customEndDate;
    }
  } else if (timeRange !== 'all') {
    const now = new Date();
    let startDate = new Date();
    
    switch (timeRange) {
      case 'today':
        startDate.setHours(0, 0, 0, 0);
        break;
      case 'week':
        startDate.setDate(now.getDate() - 7);
        break;
      case 'month':
        startDate.setMonth(now.getMonth() - 1);
        break;
      case 'quarter':
        startDate.setMonth(now.getMonth() - 3);
        break;
      case 'year':
        startDate.setFullYear(now.getFullYear() - 1);
        break;
      default:
        break;
    }
    
    dateParams.startDate = startDate.toISOString();
    dateParams.endDate = now.toISOString();
  }
  
  return dateParams;
};

const fetchExactMatchResults = async (question, filterParams, serverUrl) => {
  return await axios.get(`${serverUrl}/api/exact-match-search`, {
    params: { 
      query: question.trim(),
      ...filterParams
    },
    headers: {
      Authorization: `Bearer ${Cookies.get('jwtToken')}`
    }
  });
};

const fetchAIResults = async (question, filterParams, serverUrl) => {
  return await axios.post(`${serverUrl}/api/ask-organization`,
    { 
      question: question.trim(),
      ...filterParams
    },
    {
      headers: {
        Authorization: `Bearer ${Cookies.get('jwtToken')}`
      }
    }
  );
};

// Add a loading component for AI answers
const LoadingAnswer = () => (
  <Card variant="outline">
    <CardBody>
      <HStack spacing={4} mb={4}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="md"
        />
        <Text fontWeight="medium" color="gray.700">
          Analyzing your organization's data...
        </Text>
      </HStack>
      
      <Skeleton height="20px" my={2} width="90%" />
      <Skeleton height="20px" my={2} width="80%" />
      <Skeleton height="20px" my={2} width="95%" />
      <Skeleton height="20px" my={2} width="70%" />
      
      <Box mt={6}>
        <Skeleton height="15px" width="60%" mb={3} />
        <HStack>
          <Skeleton height="12px" width="100px" />
          <Skeleton height="12px" width="60px" />
        </HStack>
      </Box>
    </CardBody>
  </Card>
);

const AskDataDasher = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;
  const { clients, loading: isClientsLoading } = useOrganizationClients();
  const [question, setQuestion] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [answer, setAnswer] = useState([]);
  const [exactMatch, setExactMatch] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [clientSearchQuery, setClientSearchQuery] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [timeRange, setTimeRange] = useState('all');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [displayedResults, setDisplayedResults] = useState([]);
  const [resultsPerPage] = useState(8);
  const [hasMore, setHasMore] = useState(false);
  const [conversationId, setConversationId] = useState('');
  const [recentConversationId, setRecentConversationId] = useState(null);

  useEffect(() => {
    // Fetch the most recent conversation ID when component mounts
    const fetchRecentConversation = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/conversation-history`, {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        
        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          setRecentConversationId(response.data[0].id);
        }
      } catch (error) {
        console.error('Error fetching recent conversation:', error);
      }
    };
    
    fetchRecentConversation();
  }, [serverUrl]);

  const loadClients = () => {
    console.log('Using client context data');
  };

  // Memoize filter params to prevent unnecessary recalculations
  const filterParams = React.useMemo(() => ({
    clientIds: selectedClients.length > 0 ? selectedClients : undefined,
    type: selectedType !== 'all' ? selectedType : undefined,
    ...getDateRangeParams(timeRange, customStartDate, customEndDate)
  }), [selectedClients, selectedType, timeRange, customStartDate, customEndDate]);

  // Handler for the textarea submission
  const handleTextSubmit = useCallback((value) => {
    setQuestion(value);
    if (value.trim()) {
      // We'll call handleAsk with the new value
      handleAskWithValue(value);
    }
  }, []);
  
  // Create a version of handleAsk that takes a value parameter
  const handleAskWithValue = useCallback(async (questionValue) => {
    if (!questionValue.trim()) return;
    setIsLoading(true);

    try {
      let response;

      if (!exactMatch) {
        const conversationResponse = await axios.post(`${serverUrl}/api/conversations`, 
          { 
            question: questionValue.trim(),
            clientIds: selectedClients.length > 0 ? selectedClients : undefined
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get('jwtToken')}`
            }
          }
        );
        
        const newConversationId = conversationResponse.data.conversationId;
        setConversationId(newConversationId);
        const params = { ...filterParams, conversationId: newConversationId };
        
        response = await fetchAIResults(questionValue, params, serverUrl);
      } else {
        setConversationId('');
        response = await fetchExactMatchResults(questionValue, filterParams, serverUrl);
      }
      
      setSearchKeyword(questionValue);
      setAnswer(response.data);
      
      if (Array.isArray(response.data)) {
        setDisplayedResults(response.data.slice(0, resultsPerPage));
        setHasMore(response.data.length > resultsPerPage);
      }
    } catch (error) {
      console.error('Error asking question:', error);
      toast({
        title: 'Error',
        description: 'Failed to get answer. Please try again.',
        status: 'error',
        duration: 3000
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    exactMatch, 
    serverUrl, 
    selectedClients, 
    filterParams, 
    resultsPerPage, 
    toast
  ]);
  
  // Original handleAsk now just calls handleAskWithValue with the current question
  const handleAsk = useCallback(() => {
    handleAskWithValue(question);
  }, [handleAskWithValue, question]);

  // Function to set a suggestion as the question
  const setSuggestion = useCallback((suggestionText) => {
    setQuestion(suggestionText);
    handleAskWithValue(suggestionText);
  }, [handleAskWithValue]);

  const loadMore = () => {
    if (Array.isArray(answer)) {
      const currentLength = displayedResults.length;
      const newResults = answer.slice(currentLength, currentLength + resultsPerPage);
      setDisplayedResults([...displayedResults, ...newResults]);
      setHasMore(currentLength + resultsPerPage < answer.length);
    }
  };

  // Highlight the matched query text
  function highlightText(text, query) {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    return text.replace(regex, '<span style="background-color: yellow;">$1</span>');
  }

  return (
    <Box
      bg="white"
      p={6}
      borderRadius="xl"
      boxShadow="sm"
      transition="all 0.2s"
      _hover={{ boxShadow: 'md' }}
      position="relative"
    >
      <VStack spacing={2} align="stretch">
        <TitleBar />
        <TextareaInput 
          initialValue={question}
          onSubmit={handleTextSubmit}
          isLoading={isLoading}
        />

        <FiltersSection 
          clients={clients}
          selectedClients={selectedClients}
          setSelectedClients={setSelectedClients}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          customStartDate={customStartDate}
          setCustomStartDate={setCustomStartDate}
          customEndDate={customEndDate}
          setCustomEndDate={setCustomEndDate}
          exactMatch={exactMatch}
          setExactMatch={setExactMatch}
          loadClients={loadClients}
          isClientsLoading={isClientsLoading}
          clientSearchQuery={clientSearchQuery}
          setClientSearchQuery={setClientSearchQuery}
          recentConversationId={recentConversationId}
          navigate={navigate}
        />
  
        {Array.isArray(answer) && answer.length > 0 && (
          <Box>
            <Text color="gray.600" fontSize="sm">
              Found {answer.length} matching {answer.length === 1 ? 'result' : 'results'}
            </Text>
          </Box>
        )}

        {/* Show loading state while waiting for AI response */}
        {isLoading && !exactMatch && (
          <LoadingAnswer />
        )}

        {/* Show AI answer when available and not loading */}
        {!isLoading && answer && !Array.isArray(answer) && (
          <AIAnswer 
            answer={answer} 
            navigate={navigate}
            conversationId={conversationId}  
          />
        )}

        {/* Show exact match results */}
        {Array.isArray(answer) && displayedResults.length > 0 && (
          <SearchResults 
            displayedResults={displayedResults}
            searchKeyword={searchKeyword}
            navigate={navigate}
            highlightText={highlightText}
            hasMore={hasMore}
            loadMore={loadMore}
          />
        )}

        {/* Show no results message */}
        {Array.isArray(answer) && answer.length === 0 && searchKeyword && (
          <Box 
            p={6} 
            textAlign="center" 
            borderWidth="1px" 
            borderRadius="md" 
            borderColor="gray.200"
            bg="gray.50"
          >
            <Icon as={FaExclamationCircle} boxSize={10} color="gray.400" mb={3} />
            <Text fontSize="lg" fontWeight="medium" color="gray.700">No results found</Text>
            <Text color="gray.600" mt={2}>
              Your search for "<strong>{searchKeyword}</strong>" did not match any documents.
            </Text>
            <Text color="gray.600" mt={4}>
              Suggestions:
            </Text>
            <UnorderedList textAlign="left" maxW="400px" mx="auto" mt={2} spacing={1}>
              <ListItem>Check if your spelling is correct</ListItem>
              <ListItem>Try using different or fewer keywords</ListItem>
              <ListItem>Expand your search by removing filters</ListItem>
              <ListItem>Try switching to AI-powered search (uncheck "Exact" toggle)</ListItem>
            </UnorderedList>
          </Box>
        )}

        {/* Only show suggestions when not loading */}
        {!isLoading && (
          <Suggestions 
            setQuestion={setSuggestion}
            exactMatch={exactMatch}
          />
        )}
      </VStack>
    </Box>
  );
};

export default AskDataDasher;