import React, { useMemo } from 'react';
import { Box, Tooltip, Text, HStack, useColorModeValue } from '@chakra-ui/react';

/**
 * Component for visualizing the transcription timeline
 * Uses lightweight metadata to show speaker changes without loading full content
 */
const TranscriptionTimeline = ({ 
  transcriptionMetadata = [], 
  currentTime = 0, 
  duration = 0,
  onTimestampClick = () => {}
}) => {
  // Generate colors for different speakers
  const speakerColors = useMemo(() => {
    const colors = {};
    const colorOptions = [
      'blue.400', 'green.400', 'purple.400', 'orange.400', 
      'pink.400', 'teal.400', 'cyan.400', 'red.400'
    ];
    
    // Assign colors to unique speakers
    if (transcriptionMetadata && transcriptionMetadata.length > 0) {
      const uniqueSpeakers = [...new Set(transcriptionMetadata.map(item => item.speaker))];
      uniqueSpeakers.forEach((speaker, index) => {
        colors[speaker] = colorOptions[index % colorOptions.length];
      });
    }
    
    return colors;
  }, [transcriptionMetadata]);
  
  // Calculate segment positions based on timestamps
  const segments = useMemo(() => {
    if (!transcriptionMetadata || transcriptionMetadata.length === 0 || duration === 0) {
      return [];
    }
    
    return transcriptionMetadata.map(item => {
      const startPercent = (item.start / duration) * 100;
      const endPercent = (item.end / duration) * 100;
      const width = endPercent - startPercent;
      
      return {
        id: item.id,
        start: item.start,
        end: item.end,
        speaker: item.speaker,
        startPercent,
        endPercent,
        width: Math.max(0.5, width) // Ensure minimum width for visibility
      };
    });
  }, [transcriptionMetadata, duration]);
  
  // Calculate current position marker
  const currentPosition = useMemo(() => {
    if (duration === 0) return 0;
    return (currentTime / duration) * 100;
  }, [currentTime, duration]);
  
  // Background colors
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const markerColor = useColorModeValue('red.500', 'red.300');
  
  return (
    <Box 
      width="100%" 
      height="24px" 
      bg={bgColor} 
      borderRadius="full" 
      position="relative"
      overflow="hidden"
    >
      {/* Render segments */}
      {segments.map((segment) => (
        <Tooltip 
          key={segment.id} 
          label={`${segment.speaker}: ${new Date(segment.start * 1000).toISOString().substr(14, 5)}`}
          placement="top"
        >
          <Box
            position="absolute"
            left={`${segment.startPercent}%`}
            width={`${segment.width}%`}
            height="100%"
            bg={speakerColors[segment.speaker] || 'gray.400'}
            opacity={0.7}
            _hover={{ opacity: 1 }}
            cursor="pointer"
            onClick={() => onTimestampClick(segment.start)}
          />
        </Tooltip>
      ))}
      
      {/* Current position marker */}
      <Box
        position="absolute"
        left={`${currentPosition}%`}
        width="2px"
        height="100%"
        bg={markerColor}
        transform="translateX(-1px)"
      />
    </Box>
  );
};

export default TranscriptionTimeline; 