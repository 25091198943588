import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CorrespondenceContainer from './CorrespondenceContainer';
import EmailCorrespondenceContainer from './EmailCorrespondenceContainer';

/**
 * Main Correspondence component
 * This component determines which container to render based on the URL
 */
const Correspondence = () => {
  const location = useLocation();
  const { id } = useParams();
  
  // Check if this is an email correspondence
  const isEmail = location.pathname.includes('/correspondence/email/');
  
  // Render the appropriate container based on the URL
  return isEmail ? <EmailCorrespondenceContainer /> : <CorrespondenceContainer />;
};

export default Correspondence;