const DB_NAME = 'recordingDB';
const STORE_NAME = 'recordings';
const DB_VERSION = 1;

export const initDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve(request.result);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                // Create a compound key of userId and timestamp
                const store = db.createObjectStore(STORE_NAME, { keyPath: ['userId', 'timestamp'] });
                // Create an index for quick lookups by userId
                store.createIndex('userId', 'userId', { unique: false });
            }
        };
    });
};

export const saveRecording = async (audioBlob, name, userId) => {
    if (!userId) {
        throw new Error('User ID is required to save recording');
    }

    const db = await initDB();
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    
    const recording = {
        userId,
        timestamp: Date.now(),
        name,
        data: audioBlob
    };

    return new Promise((resolve, reject) => {
        const request = store.put(recording);
        request.onsuccess = () => resolve(recording);
        request.onerror = () => reject(request.error);
    });
};

export const getLatestRecording = async (userId) => {
    if (!userId) {
        throw new Error('User ID is required to get recording');
    }

    const db = await initDB();
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const index = store.index('userId');
    
    return new Promise((resolve, reject) => {
        const request = index.openCursor(IDBKeyRange.only(userId), 'prev');
        request.onsuccess = (event) => {
            const cursor = event.target.result;
            if (cursor) {
                resolve(cursor.value);
            } else {
                resolve(null);
            }
        };
        request.onerror = () => reject(request.error);
    });
}; 