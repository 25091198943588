import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Select,
    Text,
    Center,
    VStack,
    HStack,
    Spinner,
    Alert,
    AlertIcon,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Heading,
    Progress,
    InputGroup,
    InputLeftElement,
    List,
    ListItem,
} from '@chakra-ui/react';
import { FaUser, FaSave, FaEdit, FaInfo, FaCircle, FaSearch } from 'react-icons/fa';
import { continuousVisualizer } from 'sound-visualizer'; // Import the continuous visualizer
import { useToast } from '@chakra-ui/react';
import { useOrganizationClients } from '../../../contexts/OrganizationClientsContext';
import { FeatureStats } from '../../../enums/UsageStats';
import Cookies from 'js-cookie';
import RecordingDownloadPanel from './RecordingDownloadPanel';
import { useRecordingStorage } from '../../../hooks/useRecordingStorage';
import { useUser } from '../../../contexts/UserContext';
const serverUrl = process.env.REACT_APP_API_URL;

// Mock people data for "Identify Client" selection
const mockPeople = [
    { id: 1, name: 'Alice Johnson' },
    { id: 2, name: 'Bob Smith' },
    { id: 3, name: 'Charlie Davis' },
    // Add more mock people as needed
];

// Create a global navigation block flag
window.isNavigationBlocked = false;

// Override the useNavigate hook to check for navigation blocks
const useBlockableNavigate = () => {
    const navigate = useNavigate();
    
    return useCallback(
        (to, options) => {
            if (window.isNavigationBlocked) {
                // If navigation is blocked, dispatch a custom event that our hook can listen for
                const event = new CustomEvent('blocknavigation', { 
                    detail: { destination: to, options } 
                });
                window.dispatchEvent(event);
                return;
            }
            
            // Otherwise, proceed with normal navigation
            navigate(to, options);
        },
        [navigate]
    );
};

/**
 * Custom hook to block navigation when a condition is met
 */
function useBlockNavigation(shouldBlock) {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [navigateTo, setNavigateTo] = useState(null);
    const [navigationOptions, setNavigationOptions] = useState(null);
    
    // Store the current path for comparison
    const location = useLocation();
    const currentPath = location.pathname;
    
    // Update the global flag whenever shouldBlock changes
    useEffect(() => {
        window.isNavigationBlocked = shouldBlock;
        
        return () => {
            // Clean up when component unmounts
            window.isNavigationBlocked = false;
        };
    }, [shouldBlock]);
    
    // Listen for the custom blocknavigation event
    useEffect(() => {
        const handleBlockNavigation = (e) => {
            if (shouldBlock) {
                setNavigateTo(e.detail.destination);
                setNavigationOptions(e.detail.options);
                setShowModal(true);
                e.preventDefault();
            }
        };
        
        window.addEventListener('blocknavigation', handleBlockNavigation);
        
        return () => {
            window.removeEventListener('blocknavigation', handleBlockNavigation);
        };
    }, [shouldBlock]);
    
    // Set up navigation blocking
    useEffect(() => {
        if (!shouldBlock) return;

        // We need to track history changes manually in React Router v6
        const detectNavigation = (e) => {
            // If we're already showing the modal, don't show it again
            if (showModal) return;
            
            // Show the modal to confirm navigation
            setShowModal(true);
            
            // Push the current path back into history to prevent navigation
            window.history.pushState(null, "", currentPath);
            
            // Prevent default if it's an event
            if (e) e.preventDefault();
            
            return false;
        };
        
        // Listen for the popstate event (back/forward button)
        window.addEventListener('popstate', detectNavigation);
        
        // Also override the back button explicitly
        const handleBackButton = () => {
            if (shouldBlock) {
                setNavigateTo(null); // We don't know where back would go
                detectNavigation();
                return false;
            }
        };
        
        // This helps prevent the back button specifically
        window.history.pushState(null, "", window.location.pathname);
        window.addEventListener('popstate', handleBackButton);
        
        // Intercept link clicks for same-origin navigation
        const handleLinkClick = (e) => {
            // Only intercept if we should block navigation
            if (!shouldBlock) return;
            
            // Find closest anchor element
            let target = e.target;
            while (target && target.tagName !== 'A') {
                target = target.parentNode;
            }
            
            // If no anchor was found or it's an external link, let it proceed
            if (!target || 
                !target.href || 
                target.target === '_blank' || 
                target.href.startsWith('http') && !target.href.includes(window.location.host)) {
                return;
            }
            
            // Prevent the default navigation
            e.preventDefault();
            
            // Store where they were trying to go
            setNavigateTo(target.pathname);
            
            // Show confirmation modal
            setShowModal(true);
        };
        
        document.addEventListener('click', handleLinkClick, true);
        
        // Create our own custom history observer by patching native methods
        const originalPushState = window.history.pushState;
        const originalReplaceState = window.history.replaceState;
        
        window.history.pushState = function() {
            // If we should block and it's not a navigation we're initiating ourselves
            if (shouldBlock && arguments[2] !== currentPath) {
                setNavigateTo(arguments[2]);
                setShowModal(true);
                return;
            }
            return originalPushState.apply(this, arguments);
        };
        
        window.history.replaceState = function() {
            // If we should block and it's not a navigation we're initiating ourselves
            if (shouldBlock && arguments[2] !== currentPath) {
                setNavigateTo(arguments[2]);
                setShowModal(true);
                return;
            }
            return originalReplaceState.apply(this, arguments);
        };
        
        return () => {
            window.removeEventListener('popstate', detectNavigation);
            window.removeEventListener('popstate', handleBackButton);
            document.removeEventListener('click', handleLinkClick, true);
            window.history.pushState = originalPushState;
            window.history.replaceState = originalReplaceState;
        };
    }, [shouldBlock, currentPath, showModal]);
    
    // Handler to confirm navigation
    const confirmNavigation = useCallback(() => {
        // Temporarily unblock navigation
        window.isNavigationBlocked = false;
        
        setShowModal(false);
        if (navigateTo) {
            navigate(navigateTo, navigationOptions);
        } else {
            // If no specific destination, just go to the meetings page
            navigate('/meetings');
        }
        
        // Reset after navigation
        setNavigateTo(null);
        setNavigationOptions(null);
    }, [navigateTo, navigationOptions, navigate]);
    
    // Handler to cancel navigation
    const cancelNavigation = useCallback(() => {
        setShowModal(false);
        setNavigateTo(null);
        setNavigationOptions(null);
    }, []);
    
    return {
        showModal,
        setShowModal,
        confirmNavigation,
        cancelNavigation,
        setNavigateTo,
        setNavigationOptions,
        navigateTo,         // Export these values so they're accessible in the component
        navigationOptions   // Export these values so they're accessible in the component
    };
}

function LiveMeeting() {
    const toast = useToast();
    const { user } = useUser();
    const [permission, setPermission] = useState(null);
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [recording, setRecording] = useState(false);
    const [paused, setPaused] = useState(false); // Pause functionality
    const [timer, setTimer] = useState('00:00:00');
    const [error, setError] = useState('');
    const [isDoneClicked, setIsDoneClicked] = useState(false);
    const [meetingName, setMeetingName] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [showQuitModal, setShowQuitModal] = useState(false);
    const [showDoneModal, setShowDoneModal] = useState(false); // State for Done Modal
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false); // State for Confirm Cancel Modal
    const mediaRecorderRef = useRef(null);
    const timerRef = useRef(null);
    const audioChunksRef = useRef([]);
    const canvasRef = useRef(null); 
    const visualizerRef = useRef(null); 
    const audioStreamRef = useRef(null); 
    const [audioBlob, setAudioBlob] = useState(null);
    const { clients } = useOrganizationClients();
    const [selectedClientId, setSelectedClientId] = useState(null);
    const { lastRecordingUrl, saveRecording, clearRecording, storageError } = useRecordingStorage();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [clientSearchQuery, setClientSearchQuery] = useState('');
    const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
    const clientInputRef = useRef(null);
    // Use our blockable navigation
    const navigate = useBlockableNavigate();
    
    // Use our custom hook to block navigation during recording
    const { 
        showModal: showNavigationModal, 
        confirmNavigation: handleNavigationConfirmation, 
        cancelNavigation: handleNavigationCancellation,
        setNavigateTo,
        setNavigationOptions,
        setShowModal: setShowNavigationModal,
        navigateTo,
        navigationOptions
    } = useBlockNavigation(recording);
    
    // Custom navigation function that respects recording state
    const safeNavigate = useCallback((to) => {
        if (recording) {
            // Store destination and show confirmation modal
            setNavigateTo(to);
            setShowNavigationModal(true);
        } else {
            // Navigate directly if not recording
            navigate(to);
        }
    }, [recording, navigate, setNavigateTo, setShowNavigationModal]);
    
    // Add event listener for beforeunload to prevent browser refresh/tab closing
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (recording) {
                // Standard way to show a confirmation dialog when closing tab or refreshing
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = 'You are currently recording a meeting. If you leave now, your recording will be lost. Are you sure you want to leave?';
                // Return a string (this is shown in some older browsers)
                return 'You are currently recording a meeting. If you leave now, your recording will be lost. Are you sure you want to leave?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [recording]);

    useEffect(() => {
        // Function to request microphone permissions and initialize audio stream
        const initializeAudioStream = async () => {
            try {
                const constraints = { audio: true };
                if (selectedDevice) {
                    constraints.audio = {
                        deviceId: selectedDevice
                    };
                }
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                console.log('Microphone access granted');
                setPermission(true);
                audioStreamRef.current = stream; // Store the audio stream

                // Enumerate audio input devices
                const deviceInfos = await navigator.mediaDevices.enumerateDevices();
                const audioDevices = deviceInfos.filter(device => device.kind === 'audioinput');
                setDevices(audioDevices);
                if (audioDevices.length > 0 && !selectedDevice) {
                    setSelectedDevice(audioDevices[0].deviceId);
                }

                // Initialize and start the visualizer immediately
                if (canvasRef.current) {
                    visualizerRef.current = continuousVisualizer(stream, canvasRef.current, {
                        strokeColor: '#00417D',
                        lineWidth: 2,
                        slices: 80,
                    });
                    visualizerRef.current.start(); // Start the visualizer immediately
                    console.log('Visualizer initialized and started');
                }
            } catch (err) {
                console.error('Microphone permission denied or error:', err);
                setPermission(false);
                setError('Microphone permission is required to use this feature. Allow microphone access in your browser, then refresh this page.');
            }
        };

        initializeAudioStream();

        // Cleanup function when component unmounts
        return () => {
            if (audioStreamRef.current) {
                audioStreamRef.current.getTracks().forEach(track => track.stop());
            }
            if (visualizerRef.current) {
                visualizerRef.current.stop();
            }
        };
    }, [selectedDevice]); // Re-run if selectedDevice changes

    useEffect(() => {
        let interval = null;
        if (recording && !paused) { // Consider paused state
            interval = setInterval(() => {
                setTimer(prev => {
                    const [hours, minutes, seconds] = prev.split(':').map(Number);
                    const totalSeconds = hours * 3600 + minutes * 60 + seconds + 1;
                    const newHours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
                    const newMinutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
                    const newSeconds = String(totalSeconds % 60).padStart(2, '0');
                    return `${newHours}:${newMinutes}:${newSeconds}`;
                });
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [recording, paused]); // Include paused in dependencies

    const startRecording = async () => {
        // Log usage first

        if (audioStreamRef.current) {
            const mediaRecorder = new MediaRecorder(audioStreamRef.current);
            mediaRecorderRef.current = mediaRecorder;
            audioChunksRef.current = [];

            mediaRecorder.ondataavailable = event => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
                // Do not upload immediately; wait for "Save" action
                setAudioBlob(audioBlob); // Store the audioBlob in state
                console.log('Recording stopped. Audio blob created.');
            };

            mediaRecorder.start();
            setRecording(true);
            setPaused(false);
            setTimer('00:00:00');
            console.log('MediaRecorder started.');

            // Start the visualizer
            if (visualizerRef.current) {
                visualizerRef.current.start();
                console.log('Visualizer started');
            }
        } else {
            console.error('Audio stream is not available.');
            setError('Audio stream is not available. Please check your microphone settings.');
        }
    };

    const pauseRecording = () => { 
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.pause();
            setPaused(true);
            // Removed stopping the visualizer to keep it active
            // if (visualizerRef.current) {
            //     visualizerRef.current.stop();
            // }
        }
    };

    const resumeRecording = () => { 
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.resume();
            setPaused(false);
            // Removed starting the visualizer to prevent it from being cut off
            // if (visualizerRef.current) {
            //     visualizerRef.current.start();
            // }
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            console.log('MediaRecorder stopped.');
        }
        setRecording(false);
        setPaused(false); // Reset paused state
        // Open Done Modal instead of setting isDoneClicked
        setShowDoneModal(true);
    };

    const uploadAudio = async (audioBlob) => {
        if (!audioBlob) {
            console.error('No audio recording found.');
            setError('No audio recording found.');
            return;
        }
        
        // Save the recording locally first, but don't block on failure
        const recordingName = meetingName || `Meeting ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
        try {
            await saveRecording(audioBlob, recordingName, user.id);
        } catch (storageErr) {
            console.error('Failed to save recording locally:', storageErr);
            // Continue with upload even if local storage fails
        }

        setIsUploading(true);
        try {
            // Step 1: Get SAS URL
            console.log('Step 1: Getting SAS URL for recording');
            let sasUrlResponse;
            try {
                const response = await axios.get(`${serverUrl}/api/get-upload-url`, {
                    params: { fileName: 'recording.mp3' }
                });
                sasUrlResponse = response.data;
                console.log('Successfully got SAS URL. BlobName:', sasUrlResponse.blobName);
            } catch (sasError) {
                console.error('Failed to get SAS URL:', sasError);
                throw new Error('Failed to get upload URL');
            }

            const { sasUrl, blobName, blobUrl } = sasUrlResponse;

            // Step 2: Upload to Azure
            console.log('Step 2: Starting Azure upload');
            try {
                const uploadAxios = axios.create();
                delete uploadAxios.defaults.headers.common['Authorization'];

                const uploadResponse = await uploadAxios.put(sasUrl, audioBlob, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': 'audio/mp3',
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setUploadProgress(percentComplete);
                    },
                });
                console.log('Azure upload successful. Status:', uploadResponse.status);
            } catch (uploadError) {
                console.error('Azure upload failed:', uploadError);
                throw new Error('Failed to upload to Azure');
            }

            // Step 3: Notify backend
            console.log('Step 3: Notifying backend');
            try {
                const processResponse = await axios.post(`${serverUrl}/api/process-upload`, {
                    blobName,
                    blobUrl,
                    meetingName: meetingName || `Meeting ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
                    selectedClientId: selectedClientId || '',
                    clientName: selectedClient
                });
                console.log('Backend notification successful:', processResponse.data);
            } catch (processError) {
                console.error('Backend notification failed:', processError);
                throw new Error('Failed to notify backend');
            }

            // Step 4: Log usage and show success message
            await axios.post(`${serverUrl}/api/usage/log`, {
                statPath: FeatureStats.MEETINGS_WEB_APP,
                value: 1
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                }
            });

            toast({
                title: 'Upload Successful',
                description: 'Your meeting has been uploaded and will be processed shortly. Please check the home page after a few minutes.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            // Reset states
            setMeetingName('');
            setSelectedClientId(null);
            setSelectedClient('');
            setUploadProgress(0);

        } catch (error) {
            console.error('Upload process failed:', error);
            setError('Failed to upload audio.');
            toast({
                title: 'Upload Failed',
                description: error.message || 'There was an error processing your meeting. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUploading(false);
        }
    };

    const handleSave = () => {
        // Handle saving meeting details
        setShowDoneModal(false);
        // Check if audioBlob is available
        if (audioBlob) {
            uploadAudio(audioBlob);
        } else {
            console.error('No audio blob available to upload.');
            setError('No audio recording found.');
        }
    };

    const handleCancel = () => {
        setShowQuitModal(true);
    };

    const quitRecording = () => {
        setShowQuitModal(false);
        // Stop the media recorder if it's recording
        if (mediaRecorderRef.current && recording) {
            mediaRecorderRef.current.stop();
            console.log('MediaRecorder stopped.');
        }
        // Stop the visualizer
        // if (visualizerRef.current) {
        //     visualizerRef.current.stop();
        //     console.log('Visualizer stopped');
        // }
        // Reset state variables to initial values
        setRecording(false);
        setPaused(false);
        setIsDoneClicked(false);
        setTimer('00:00:00');
        setMeetingName('');
        setSelectedClient('');
        setError('');
    };

    const keepRecording = () => {
        setShowQuitModal(false);
    };

    const resetRecordingState = () => {
        setIsDoneClicked(false);
        setMeetingName('');
        setSelectedClient('');
        setTimer('00:00:00');
        audioChunksRef.current = [];
        // Reset visualizer if exists
        if (visualizerRef.current) {
            visualizerRef.current.reset();
            visualizerRef.current = null;
            console.log('Visualizer reset during state reset.');
        }
    };

    const handleMeetingNameChange = (e) => {
        setMeetingName(e.target.value);
    };

    const handleClientChange = (e) => {
        const value = e.target.value;
        setSelectedClientId(value === 'untitled' ? undefined : value);

        const client = clients.find(client => client.id === value);
        setSelectedClient(client ? `${client.firstName} ${client.lastName}` : '');
    };

    // Filter clients based on search query
    const filteredClients = clients.filter(client => {
        const fullName = `${client.firstName} ${client.metadata?.preferredName ? client.metadata.preferredName : ''} ${client.lastName}`.toLowerCase();
        return fullName.includes(clientSearchQuery.toLowerCase());
    });

    const handleClientSearch = (e) => {
        setClientSearchQuery(e.target.value);
        if (!isClientDropdownOpen) {
            setIsClientDropdownOpen(true);
        }
    };

    const selectClient = (clientId) => {
        setSelectedClientId(clientId);
        const client = clients.find(client => client.id === clientId);
        setSelectedClient(client ? `${client.firstName} ${client.lastName}` : '');
        setIsClientDropdownOpen(false);
    };

    const handleClientInputFocus = () => {
        setIsClientDropdownOpen(true);
    };

    const handleClientInputBlur = () => {
        // Delay closing to allow for click events on list items
        setTimeout(() => {
            setIsClientDropdownOpen(false);
        }, 200);
    };

    const handleDoneModalClose = () => {
        // Instead of closing the modal directly, open the confirm cancel modal
        setShowDoneModal(false);
        setShowConfirmCancelModal(true);
    };

    const handleKeepRecording = () => {
        // Close the confirm cancel modal and reopen the Done modal
        setShowConfirmCancelModal(false);
        setShowDoneModal(true);
    };

    const handleQuitRecording = () => {
        // Close all modals and reset states as intended previously
        setShowConfirmCancelModal(false);
        setShowDoneModal(false);
        // Reset states
        setRecording(false);
        setPaused(false);
        setIsDoneClicked(false);
        setTimer('00:00:00');
        setMeetingName('');
        setSelectedClient('');
        setError('');
        // Optionally, navigate away or reset the component
    };

    return (
        <Box bg="white" p={8} overflowY="auto" height="95vh">
            <Heading color="#00417D" pb={4}>Live Meeting</Heading>
            <Box flex="1" display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={40}>
                {error && (
                    <Alert status="error" mb={4}>
                        <AlertIcon />
                        {error}
                    </Alert>
                )}
                <Center>
                    <Text fontSize="8xl">{timer}</Text>
                </Center>
                
                {/* Audio Visualizer */}
                <Box mt={4}>
                    <canvas
                        ref={canvasRef}
                        width={600}
                        height={100}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            opacity: recording ? (paused ? 0.05 : 1) : 0, // Set opacity to 0 when not recording
                            transition: 'opacity 0.1s ease'
                        }}
                    ></canvas>
                </Box>

                {permission && (
                    <VStack spacing={4} mt={8}>
                        <Select
                            value={selectedDevice}
                            onChange={(e) => setSelectedDevice(e.target.value)}
                            width="300px"
                            placeholder="Select Microphone"
                        >
                            {devices.map(device => (
                                <option key={device.deviceId} value={device.deviceId}>
                                    {device.label || `Microphone ${device.deviceId}`}
                                </option>
                            ))}
                        </Select>
                        <Text fontSize="lg" fontWeight="bold">    
                            {recording ? (paused ? 'Paused' : 'Recording') : 'Not Recording'}
                        </Text> 
                        <Center>
                            {!recording && !isDoneClicked && (
                                <Button
                                    bg="#00417D"
                                    color="white"
                                    size="lg"
                                    borderRadius="full"
                                    onClick={startRecording} // Start Recording on button click
                                    leftIcon={<FaCircle />}
                                >
                                    Record
                                </Button>
                            )}
                            {recording && !isDoneClicked && (
                                <HStack spacing={4}>
                                    {!paused ? (
                                        <Button bg="#00417D" color="white" onClick={pauseRecording}>
                                            Pause
                                        </Button>
                                    ) : (
                                        <Button bg="#00417D" color="white" onClick={resumeRecording}>
                                            Resume
                                        </Button>
                                    )}
                                    <Button bg="#00417D" color="white" onClick={stopRecording}>
                                        Done
                                    </Button>
                                </HStack>
                            )}
                        </Center>
                    </VStack>
                )}
                {permission === null && (
                    <Center mt={8}>
                        <Spinner size="xl" />
                        <Text ml={4}>Requesting microphone permissions...</Text>
                    </Center>
                )}

                {/* Quit Recording Modal */}
                <Modal isOpen={showQuitModal} onClose={keepRecording}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Quit Recording</ModalHeader>
                        <ModalBody>
                            <Text>This recording will be deleted.</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={keepRecording}>
                                Keep Recording
                            </Button>
                            <Button colorScheme="red" onClick={quitRecording}>
                                Quit Recording
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Done Modal */}
                <Modal isOpen={showDoneModal} onClose={handleDoneModalClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Save Recording</ModalHeader>
                        <ModalBody>
                            <VStack spacing={4} width="100%" mt={4}>
                                <Text fontSize="lg">Give your meeting a name:</Text>
                                <Input
                                    placeholder={`Meeting ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
                                    value={meetingName}
                                    onChange={handleMeetingNameChange}
                                    width="300px"
                                />
                                <Text fontSize="lg">Identify Client:</Text>
                                <Box position="relative" width="300px">
                                    <InputGroup>
                                        <InputLeftElement pointerEvents="none">
                                            <FaSearch color="gray.300" />
                                        </InputLeftElement>
                                        <Input
                                            ref={clientInputRef}
                                            placeholder="Search clients..."
                                            value={clientSearchQuery}
                                            onChange={handleClientSearch}
                                            onFocus={handleClientInputFocus}
                                            onBlur={handleClientInputBlur}
                                            width="300px"
                                        />
                                    </InputGroup>
                                    {isClientDropdownOpen && (
                                        <List
                                            position="absolute"
                                            top="100%"
                                            left="0"
                                            width="100%"
                                            maxH="200px"
                                            overflowY="auto"
                                            bg="white"
                                            borderWidth="1px"
                                            borderRadius="md"
                                            zIndex="dropdown"
                                            boxShadow="md"
                                        >
                                            <ListItem
                                                p={2}
                                                _hover={{ bg: "gray.100" }}
                                                cursor="pointer"
                                                onClick={() => selectClient(undefined)}
                                            >
                                                Untitled Client
                                            </ListItem>
                                            {filteredClients.map(client => (
                                                <ListItem
                                                    key={client.id}
                                                    p={2}
                                                    _hover={{ bg: "gray.100" }}
                                                    cursor="pointer"
                                                    onClick={() => selectClient(client.id)}
                                                    bg={selectedClientId === client.id ? "blue.50" : "transparent"}
                                                >
                                                    {client.firstName} {client.metadata?.preferredName ? `(${client.metadata.preferredName})` : ''} {client.lastName}
                                                </ListItem>
                                            ))}
                                            {filteredClients.length === 0 && (
                                                <ListItem p={2} color="gray.500">No clients found</ListItem>
                                            )}
                                        </List>
                                    )}
                                </Box>
                                {selectedClient && (
                                    <Text fontSize="lg" color="blue.500" mt={1}>
                                        Selected: {selectedClient}
                                    </Text>
                                )}
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={handleDoneModalClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="blue" onClick={handleSave}>
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Confirm Cancel Modal */}
                <Modal isOpen={showConfirmCancelModal} onClose={handleKeepRecording}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Quit Recording</ModalHeader>
                        <ModalBody>
                            <Text>This recording will be deleted.</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={handleKeepRecording}>
                                Keep Recording
                            </Button>
                            <Button colorScheme="red" onClick={handleQuitRecording}>
                                Quit Recording
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Navigation Warning Modal */}
                <Modal isOpen={showNavigationModal} onClose={handleNavigationCancellation} closeOnEsc={false} closeOnOverlayClick={false}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Leave Recording?</ModalHeader>
                        <ModalBody>
                            <Text>You are currently recording a meeting. If you leave now, your recording will be lost.</Text>
                            <Text mt={2}>Are you sure you want to navigate away?</Text>
                            {/* {navigateTo && <Text fontSize="sm" color="gray.500">Destination: {navigateTo}</Text>} */}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={handleNavigationCancellation}>
                                Keep Recording
                            </Button>
                            <Button 
                                colorScheme="red" 
                                onClick={() => {
                                try {
                                    console.log("Navigation target:", navigateTo);
                                    
                                    // Stop recording before navigating
                                    if (mediaRecorderRef.current && recording) {
                                        mediaRecorderRef.current.stop();
                                    }
                                    
                                    // Reset state
                                    setRecording(false);
                                    setPaused(false);
                                    setShowNavigationModal(false);
                                    
                                    // Disable navigation blocking
                                    window.isNavigationBlocked = false;
                                    
                                    // Navigate directly (using a simpler approach)
                                    if (typeof navigateTo === 'string') {
                                        // If it's a string path, use it
                                        window.location.href = navigateTo.startsWith('/') 
                                            ? navigateTo // Use as-is if it starts with /
                                            : `/${navigateTo}`; // Add leading slash if needed
                                    } else {
                                        // Fallback to /meetings if no valid path
                                        window.location.href = '/meetings';
                                    }
                                } catch (err) {
                                    console.error("Navigation error:", err);
                                    // Ultimate fallback
                                    window.location.href = '/meetings';
                                }
                            }}>
                                Leave & Discard
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {isUploading && (
                    <Box w="100%" maxW="600px" position="absolute" bottom="20px">
                        <Progress value={uploadProgress} size="sm" colorScheme="blue" />
                        <Text mt={2} textAlign="center">{uploadProgress}% uploaded</Text>
                    </Box>
                )}
            </Box>
            <RecordingDownloadPanel
                recordingInfo={lastRecordingUrl}
                onDismiss={clearRecording}
            />
        </Box>
    );
}

export default LiveMeeting;
