import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FeatureStats } from '../../../../enums/UsageStats';

export const useChat = (id, serverUrl, toast) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loadingChat, setLoadingChat] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);

  // Load existing conversation on initial render
  useEffect(() => {
    if (id) {
      loadConversation();
    }
  }, [id]);

  const loadConversation = async () => {
    setIsLoadingHistory(true);
    try {
      const response = await axios.get(`${serverUrl}/api/clients/${id}/conversation`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      
      if (response.data.conversation) {
        setConversationId(response.data.conversation.id);
        setChatHistory(response.data.messages || []);
      }
    } catch (error) {
      console.error('Error loading conversation:', error);
      // Don't show toast for initial load - just silently create a new conversation when needed
    } finally {
      setIsLoadingHistory(false);
    }
  };

  const clearConversation = async () => {
    try {
      if (conversationId) {
        await axios.delete(`${serverUrl}/api/conversations/${conversationId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
      }
      
      setChatHistory([]);
      setConversationId(null);
    } catch (error) {
      toast({
        title: "Error clearing conversation",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAskQuestion = async () => {
    if (!inputValue.trim()) return;

    // Log the Ask About query
    await axios.post(`${serverUrl}/api/usage/log`, {
      statPath: FeatureStats.ASK_ABOUT_QUERIES,
      value: 1
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    });

    setLoadingChat(true);
    
    // Add user message to UI immediately
    const userMessage = {
      type: 'user',
      content: inputValue,
      messageId: 'temp-' + Date.now()
    };
    
    setChatHistory(prev => [...prev, userMessage]);
    setInputValue('');

    try {
      const response = await axios.post(`${serverUrl}/api/clients/${id}/ask-about`, {
        questions: [inputValue],
        answers: [],
        conversationId: conversationId
      });

      // Update with the final message data from the server
      setChatHistory(prev => {
        // Replace temporary message with actual one
        const filtered = prev.filter(msg => msg.messageId !== userMessage.messageId);
        
        // Add both messages from the response
        return [...filtered, 
          response.data.userMessage,
          response.data.assistantMessage
        ];
      });
      
      // Update conversation ID if this is a new conversation
      if (!conversationId && response.data.conversationId) {
        setConversationId(response.data.conversationId);
      }
    } catch (error) {
      // Remove the temporary message on error
      setChatHistory(prev => prev.filter(msg => msg.messageId !== userMessage.messageId));
      
      toast({
        title: "Error sending question",
        description: error.message || "Failed to get a response",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingChat(false);
    }
  };

  return {
    chatHistory,
    inputValue,
    loadingChat,
    isLoadingHistory,
    handleAskQuestion,
    setInputValue,
    clearConversation,
    conversationId
  };
}; 