import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { 
  Box, 
  VStack, 
  Text, 
  Button, 
  Portal,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  HStack,
  CloseButton
} from '@chakra-ui/react';
import { FaEdit } from 'react-icons/fa';
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import TranscriptionEntry from './TranscriptionEntry';

/**
 * Component for displaying a group of transcription entries by the same speaker
 * Implemented as a functional component with hooks
 */
const TranscriptionGroup = (props) => {
  const {
    group,
    searchTerm,
    currentTime,
    onTimestampClick,
    isEditing,
    onTextChange,
    onSpeakerChange,
    groupIndex,
    renderSpeakerName,
    onOpenSpeakerModal,
    availableSpeakers = [] // Add default empty array for available speakers
  } = props;

  // State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [speakerSearchTerm, setSpeakerSearchTerm] = useState('');
  const searchInputRef = useRef(null);

  // Focus the search input when the modal opens
  useEffect(() => {
    if (isModalOpen && searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 100);
    }
  }, [isModalOpen]);

  // Filter speakers based on search term
  const filteredSpeakers = useMemo(() => {
    if (!availableSpeakers || !availableSpeakers.length) return [];
    return availableSpeakers.filter(person =>
      person.name.toLowerCase().includes(speakerSearchTerm.toLowerCase())
    );
  }, [availableSpeakers, speakerSearchTerm]);

  // Check if any entry in this group is the current entry
  const isCurrentGroup = useMemo(() => {
    if (!group || !group.entries) return false;
    return group.entries.some(entry => 
      entry.start <= currentTime && entry.end >= currentTime
    );
  }, [group, currentTime]);
  
  // Handle speaker update
  const handleSpeakerUpdate = useCallback((person, currentSpeaker, index, replaceAll) => {
    console.log('TranscriptionGroup.handleSpeakerUpdate:', {
      person,
      currentSpeaker,
      index,
      replaceAll,
      hasOnSpeakerChange: typeof onSpeakerChange === 'function'
    });
    
    if (onSpeakerChange) {
      const speakerName = typeof person === 'object' && person.name ? person.name : person;
      onSpeakerChange(speakerName, currentSpeaker, index !== undefined ? index : groupIndex, replaceAll);
    }
    setIsModalOpen(false);
  }, [onSpeakerChange, groupIndex]);

  // Render the speaker name with proper error handling
  const renderSpeaker = useCallback(() => {
    if (!group || !group.speaker) {
      return <Text fontWeight="bold">Unknown Speaker</Text>;
    }
    
    try {
      // Add debugging for onOpenSpeakerModal
      console.log('TranscriptionGroup.renderSpeaker props:', {
        speaker: group.speaker,
        groupIndex,
        hasRenderSpeakerName: typeof renderSpeakerName === 'function',
        hasOpenSpeakerModal: typeof onOpenSpeakerModal === 'function',
        availableSpeakers: availableSpeakers?.length || 0
      });
      
      return (
        <Box position="relative" display="inline-block" data-speaker-selection="true">
          <Button
            variant="ghost"
            size="sm"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            fontWeight="bold"
            color="#00417D"
            onClick={() => setIsModalOpen(true)}
            leftIcon={<FaEdit size="12px" />}
            rightIcon={<ChevronDownIcon />}
            _hover={{
              bg: '#00417D',
              color: 'white'
            }}
            position="relative"
            width="100%"
            minWidth="120px"
            data-speaker-selection="true"
          >
            {group.speaker}
          </Button>
        </Box>
      );
    } catch (error) {
      console.error('Error rendering speaker name:', error);
      console.error('Error details:', { error, props });
    }
    
    // Fallback to simple text if renderSpeakerName fails or is not a function
    return <Text fontWeight="bold">{group.speaker}</Text>;
  }, [group, groupIndex, renderSpeakerName, onOpenSpeakerModal, availableSpeakers, setIsModalOpen]);
  
  // Use static colors instead of hooks
  const borderColor = 'blue.100';
  const bgColor = 'white';
  const hoverBg = 'gray.50';
  const colors = { primary: 'blue.500', secondary: 'blue.300' };
  
  // If group is undefined, render nothing
  if (!group) return null;
  
  return (
    <>
      <Box
        borderWidth="1px"
        borderColor={isCurrentGroup ? borderColor : 'transparent'}
        borderRadius="md"
        bg={bgColor}
        _hover={{ bg: hoverBg }}
        p={3}
        transition="all 0.2s"
        width="100%"
      >
        <Box mb={2}>
          {renderSpeaker()}
        </Box>
        
        <Box pl={4}>
          {group.entries.map((entry, entryIndex) => (
            <TranscriptionEntry
              key={`entry-${groupIndex}-${entryIndex}`}
              entry={entry}
              speaker={group.speaker}
              searchTerm={searchTerm}
              isCurrentEntry={currentTime >= entry.start && currentTime <= entry.end}
              onTimestampClick={onTimestampClick}
              isEditing={isEditing}
              onTextChange={onTextChange}
              groupIndex={groupIndex}
              entryIndex={entryIndex}
            />
          ))}
        </Box>
      </Box>

      {isModalOpen && (
        <Portal>
          <Box
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="blackAlpha.600"
            zIndex={1400}
            onClick={() => setIsModalOpen(false)}
          />
          <Box
            position="fixed"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            width="350px"
            bg="white"
            boxShadow="xl"
            borderRadius="xl"
            zIndex={1500}
            data-speaker-selection="true"
            border="1px solid"
            borderColor="gray.200"
            onClick={(e) => e.stopPropagation()}
          >
            <Flex justifyContent="space-between" alignItems="center" p={3} borderBottom="1px solid" borderColor="gray.100">
              <Text fontWeight="bold">Select Speaker: {group.speaker}</Text>
              <CloseButton onClick={() => setIsModalOpen(false)} />
            </Flex>
            
            <VStack 
              align="stretch" 
              width="100%" 
              spacing={2} 
              p={3}
            >
              <InputGroup size="md">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  ref={searchInputRef}
                  placeholder="Search speakers..."
                  value={speakerSearchTerm}
                  onChange={(e) => setSpeakerSearchTerm(e.target.value)}
                  bg="white"
                  borderWidth="2px"
                  _focus={{
                    borderColor: colors.secondary,
                    boxShadow: 'none'
                  }}
                />
              </InputGroup>
              
              {filteredSpeakers.length > 0 ? (
                <VStack 
                  align="stretch" 
                  spacing={1} 
                  maxH="300px" 
                  overflowY="auto"
                  overflowX="hidden"
                  width="100%"
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: colors.primary,
                      borderRadius: '24px',
                    },
                  }}
                >
                  {filteredSpeakers.map((person) => (
                    <Box
                      key={person.id}
                      position="relative"
                      transition="all 0.2s"
                      borderRadius="md"
                      width="100%"
                      _hover={{
                        bg: 'gray.50',
                        transform: 'translateX(2px)'
                      }}
                      data-speaker-selection="true"
                    >
                      <Flex
                        p={3}
                        align="center"
                        justify="space-between"
                        cursor="pointer"
                        onClick={() => handleSpeakerUpdate(person, group.speaker, groupIndex, false)}
                        minWidth="0"
                      >
                        <HStack spacing={3} flex="1" minWidth="0">
                          <Box
                            bg={person.type === 'client' ? 'blue.100' : 'green.100'}
                            p={2}
                            borderRadius="full"
                            color={person.type === 'client' ? 'blue.700' : 'green.700'}
                            flexShrink={0}
                          >
                            {person.type === 'client' ? '👤' : '👔'}
                          </Box>
                          <VStack align="start" spacing={0} minWidth="0">
                            <Text fontWeight="medium" noOfLines={1}>{person.name}</Text>
                            <Text fontSize="xs" color="gray.500" textTransform="capitalize">
                              {person.type}
                            </Text>
                          </VStack>
                        </HStack>
                        <Button
                          size="sm"
                          variant="ghost"
                          colorScheme={person.type === 'client' ? 'blue' : 'green'}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSpeakerUpdate(person, group.speaker, null, true);
                          }}
                          ml={2}
                          flexShrink={0}
                        >
                          Replace All
                        </Button>
                      </Flex>
                    </Box>
                  ))}
                </VStack>
              ) : (
                <Box textAlign="center" py={4}>
                  <Text color="gray.500">
                    {availableSpeakers.length === 0 
                      ? "No speakers available" 
                      : "No speakers found matching your search"}
                  </Text>
                </Box>
              )}
              
              <Button 
                size="sm" 
                variant="outline" 
                onClick={() => setIsModalOpen(false)} 
                mt={2}
                colorScheme="gray"
              >
                Cancel
              </Button>
            </VStack>
          </Box>
        </Portal>
      )}
    </>
  );
};

export default TranscriptionGroup; 