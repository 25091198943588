import axios from 'axios';

export const createGmailDraft = async (subject, content, isHtml = true) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/gmail/draft`,
      {
        subject,
        body: content,
        contentType: isHtml ? 'html' : 'text'
      },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error creating Gmail draft:', error);
    throw error;
  }
}; 