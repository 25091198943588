import React, { forwardRef, useImperativeHandle, memo, useRef, useCallback, useEffect } from 'react';
import { Box, Button, Spinner, Text, VStack, Flex } from '@chakra-ui/react';
import TranscriptionGroup from './TranscriptionGroup';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

// Memoized Group Renderer
const MemoizedTranscriptionGroup = memo(({
  group,
  searchTerm,
  currentTime,
  onTimestampClick,
  isEditing,
  onTextChange,
  onSpeakerChange,
  groupIndex,
  renderSpeakerName,
  onOpenSpeakerModal,
  availableSpeakers
}) => (
  <TranscriptionGroup
    key={`group-${groupIndex}-${group.speaker}`}
    group={group}
    searchTerm={searchTerm}
    currentTime={currentTime}
    onTimestampClick={onTimestampClick}
    isEditing={isEditing}
    onTextChange={onTextChange}
    onSpeakerChange={onSpeakerChange}
    groupIndex={groupIndex}
    renderSpeakerName={renderSpeakerName}
    onOpenSpeakerModal={onOpenSpeakerModal}
    availableSpeakers={availableSpeakers}
  />
));

/**
 * Optimized transcription list component with virtualization
 * Uses windowing to render only visible items for better performance
 */
class VirtualizedTranscriptionListClass extends React.Component {
  constructor(props) {
    super(props);
    this.loadMoreButtonRef = React.createRef();
    this.listRef = React.createRef();
    this.itemHeightCache = {};
    this.state = {
      defaultItemHeight: 150, // Default height for items
    };
    
    // Bind methods to avoid creating new functions on each render
    this.renderGroup = this.renderGroup.bind(this);
  }

  // Reset list when transcription data changes
  componentDidUpdate(prevProps) {
    // Only reset if the transcription data actually changed
    if (prevProps.groupedTranscription !== this.props.groupedTranscription) {
      if (this.listRef.current) {
        this.listRef.current.resetAfterIndex(0);
      }
      // Reset height cache when data changes
      this.itemHeightCache = {};
    }
  }

  // Expose method to scroll to a specific item
  scrollToItem = (index, align = 'smart') => {
    if (this.listRef.current) {
      this.listRef.current.scrollToItem(index, align);
    }
  }

  // Scroll to the item containing the current timestamp
  scrollToTimestamp = (timestamp) => {
    const { groupedTranscription } = this.props;
    
    // Find the group containing the timestamp
    for (let i = 0; i < groupedTranscription.length; i++) {
      const group = groupedTranscription[i];
      for (let j = 0; j < group.entries.length; j++) {
        const entry = group.entries[j];
        if (timestamp >= entry.start && timestamp <= entry.end) {
          // Found the group, scroll to it
          this.scrollToItem(i, 'center');
          return;
        }
      }
    }
  }

  // Estimate item height based on content
  getItemHeight = index => {
    const { groupedTranscription } = this.props;
    
    // Return cached height if available
    if (this.itemHeightCache[index] !== undefined) {
      return this.itemHeightCache[index];
    }
    
    // Calculate height based on content
    const group = groupedTranscription[index];
    if (!group) return this.state.defaultItemHeight;
    
    // Base height for the group container
    let height = 80; // Header + padding
    
    // Add height for each entry (estimate based on text length)
    if (group.entries && group.entries.length) {
      group.entries.forEach(entry => {
        // Calculate approximate height based on text length and width
        // Assuming average of 50 chars per line at current width
        const textLength = entry.text ? entry.text.length : 0;
        const estimatedLines = Math.ceil(textLength / 50) + (entry.text?.split('\n').length || 1) - 1;
        const entryHeight = Math.max(60, 24 * estimatedLines); // Min 60px, 24px per line
        height += entryHeight + 16; // Add padding
      });
    }
    
    // Cache the calculated height
    this.itemHeightCache[index] = height;
    return height;
  };

  renderGroup({ index, style }) {
    const {
      groupedTranscription = [],
      searchTerm = '',
      currentTime = 0,
      onTimestampClick = () => {},
      isEditing = false,
      onTextChange = () => {},
      onSpeakerChange = () => {},
      renderSpeakerName = null,
      onOpenSpeakerModal = null,
      availableSpeakers = []
    } = this.props;

    // Add debugging for onOpenSpeakerModal
    if (index === 0) {  // Only log for the first group to avoid console spam
      console.log('VirtualizedTranscriptionList.renderGroup props:', {
        hasRenderSpeakerName: typeof renderSpeakerName === 'function',
        hasOpenSpeakerModal: typeof onOpenSpeakerModal === 'function',
        onOpenSpeakerModal,
        availableSpeakers: availableSpeakers?.length || 0
      });
    }

    // Get the group at this index
    const group = groupedTranscription[index];
    
    return (
      <div style={style}>
        <MemoizedTranscriptionGroup
          group={group}
          searchTerm={searchTerm}
          currentTime={currentTime}
          onTimestampClick={onTimestampClick}
          isEditing={isEditing}
          onTextChange={onTextChange}
          onSpeakerChange={onSpeakerChange}
          groupIndex={index}
          renderSpeakerName={renderSpeakerName}
          onOpenSpeakerModal={onOpenSpeakerModal}
          availableSpeakers={availableSpeakers}
        />
      </div>
    );
  }

  // Optimize rendering by preventing unnecessary updates
  shouldComponentUpdate(nextProps, nextState) {
    // Always update if these critical props change
    if (
      this.props.groupedTranscription !== nextProps.groupedTranscription ||
      this.props.currentTime !== nextProps.currentTime ||
      this.props.isEditing !== nextProps.isEditing ||
      this.props.searchTerm !== nextProps.searchTerm ||
      this.props.isLoadingMore !== nextProps.isLoadingMore ||
      this.props.allLoaded !== nextProps.allLoaded ||
      this.state.defaultItemHeight !== nextState.defaultItemHeight
    ) {
      return true;
    }
    
    // Don't update for other prop changes
    return false;
  }

  render() {
    const {
      groupedTranscription = [],
      searchTerm = '',
      currentTime = 0,
      onTimestampClick = () => {},
      isEditing = false,
      onTextChange = () => {},
      onSpeakerChange = () => {},
      renderSpeakerName = null,
      isLoadingMore = false,
      allLoaded = false,
      totalItems = 0,
      loadedItems = 0,
      loadMoreTranscription = () => {}
    } = this.props;

    // Check if we have any transcription data
    const hasTranscription = groupedTranscription && groupedTranscription.length > 0;

    return (
      <VStack spacing={0} width="100%" align="stretch" height="100%">
        {/* Transcription groups with virtualization */}
        {hasTranscription ? (
          <Box width="100%" height="calc(100% - 20px)">
            <AutoSizer>
              {({ height, width }) => (
                <List
                  ref={this.listRef}
                  height={height}
                  width={width}
                  itemCount={groupedTranscription.length}
                  itemSize={this.getItemHeight}
                  overscanCount={10} // Increase overscan to prevent white flashes
                  estimatedItemSize={this.state.defaultItemHeight}
                  useIsScrolling={true} // Optimize rendering during scrolling
                >
                  {this.renderGroup}
                </List>
              )}
            </AutoSizer>
          </Box>
        ) : (
          <Box textAlign="center" py={4}>
            <Text color="gray.500">No transcription data available</Text>
          </Box>
        )}
        
        {/* Loading indicator */}
        {isLoadingMore && (
          <Box py={4} textAlign="center">
            <Spinner size="md" color="blue.500" />
          </Box>
        )}
        
        {/* Load more button - removed InView to prevent auto-loading */}
        {hasTranscription && !allLoaded && !isLoadingMore && (
          <Box textAlign="center" py={2}>
            <Button 
              ref={this.loadMoreButtonRef}
              mt={2} 
              size="sm" 
              colorScheme="blue" 
              variant="outline"
              onClick={loadMoreTranscription}
            >
              Load More
            </Button>
          </Box>
        )}
        
        {/* Progress indicator */}
        {hasTranscription && (
          <Box textAlign="center" height="28px" display="flex" alignItems="center" justifyContent="center">
            <Text fontSize="sm" color="gray.500" lineHeight="1" py={2}>
              Showing {loadedItems} of {totalItems} items
              {allLoaded && " (All loaded)"}
            </Text>
          </Box>
        )}
      </VStack>
    );
  }
}

// Create a forwardRef wrapper to expose the class methods to parent components
const VirtualizedTranscriptionList = forwardRef((props, ref) => {
  const classRef = React.useRef(null);
  
  // Expose the class methods to the parent component
  useImperativeHandle(ref, () => ({
    scrollToItem: (index, align) => {
      if (classRef.current) {
        classRef.current.scrollToItem(index, align);
      }
    },
    scrollToTimestamp: (timestamp) => {
      if (classRef.current) {
        classRef.current.scrollToTimestamp(timestamp);
      }
    }
  }));
  
  return <VirtualizedTranscriptionListClass ref={classRef} {...props} />;
});

VirtualizedTranscriptionList.displayName = 'VirtualizedTranscriptionList';

export default VirtualizedTranscriptionList; 