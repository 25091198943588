import React from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Heading,
} from '@chakra-ui/react';

/**
 * Component for displaying speaker statistics including duration, date, and speaking time percentages
 */
const SpeakerStatisticsComponent = ({ transcription, title, audioUrl }) => {
  // Format time (seconds) to MM:SS format
  const formatTime = (seconds) => {
    if (!seconds) return '00:00';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Calculate speaker statistics from transcript
  const calculateSpeakerStats = (transcription) => {
    console.log('Calculating stats for transcription:', transcription);
    
    if (!transcription || !transcription.length) {
      console.log('No transcription data available');
      return { speakerStats: {}, overallTotalDuration: 0 };
    }

    try {
      const speakerStats = {};
      let overallTotalDuration = 0;

      // First pass: calculate total duration
      transcription.forEach(segment => {
        if (!segment.speaker) return;

        const duration = segment.end - segment.start;
        overallTotalDuration += duration;
      });

      console.log('Overall duration:', overallTotalDuration);

      // Second pass: calculate stats per speaker
      transcription.forEach(segment => {
        if (!segment.speaker || !segment.text) return;

        const speaker = segment.speaker;
        const duration = segment.end - segment.start;
        const words = segment.text.split(/\s+/).filter(Boolean).length;

        if (!speakerStats[speaker]) {
          speakerStats[speaker] = {
            totalDuration: 0,
            totalWords: 0,
            percentageTime: 0,
            wpm: 0
          };
        }

        speakerStats[speaker].totalDuration += duration;
        speakerStats[speaker].totalWords += words;
      });

      // Calculate percentages and WPM
      for (const speaker in speakerStats) {
        const totalDuration = speakerStats[speaker].totalDuration;
        const totalWords = speakerStats[speaker].totalWords;

        speakerStats[speaker].percentageTime = overallTotalDuration > 0 
          ? Math.round((totalDuration / overallTotalDuration) * 100) 
          : 0;
        speakerStats[speaker].wpm = totalDuration > 0 
          ? Math.round((totalWords / totalDuration) * 60) 
          : 0;
      }

      console.log('Final speaker stats:', speakerStats);
      return {
        speakerStats,
        overallTotalDuration
      };
    } catch (error) {
      console.error('Error calculating speaker stats:', error);
      return { speakerStats: {}, overallTotalDuration: 0 };
    }
  };

  const { speakerStats, overallTotalDuration } = calculateSpeakerStats(transcription);

  // If no speaker stats are available, show a message
  if (Object.keys(speakerStats).length === 0) {
    return (
      <Box bg="gray.50" p={6} borderRadius="lg">
        <Heading size="md" mb={4}>Speaker Statistics</Heading>
        <Text color="gray.600">No speaker statistics available for this recording.</Text>
      </Box>
    );
  }

  return (
    <Box bg="gray.50" p={6} borderRadius="lg">
      <Heading size="md" mb={4}>Speaker Statistics</Heading>
      <VStack align="stretch" spacing={3}>
        {Object.entries(speakerStats).map(([speaker, stats]) => (
          <HStack key={speaker}>
            <Text fontWeight="medium" color="gray.600" minWidth="120px">{speaker}:</Text>
            <Text>Spoken {stats.percentageTime}% of time, {stats.wpm} WPM</Text>
          </HStack>
        ))}
      </VStack>
    </Box>
  );
};

export default SpeakerStatisticsComponent;