import React, { useState, useEffect } from 'react';
import {
  Box, VStack, HStack, Text, Heading, Button,
  Table, Thead, Tbody, Tr, Th, Td, Badge,
  Menu, MenuButton, MenuList, MenuItem, IconButton,
  Popover, PopoverTrigger, PopoverContent, PopoverBody,
  Spinner, Input, Icon, Tooltip, useToast, Collapse, useDisclosure
} from '@chakra-ui/react';
import { FaTasks, FaCheck, FaTimes, FaUserFriends, FaCalendarAlt, FaEdit, FaUser, FaSave } from 'react-icons/fa';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import GrayBox from '../../../GrayBox';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useUser } from '../../../../contexts/UserContext';
import { FeatureStats } from '../../../../enums/UsageStats';

/**
 * Component for displaying and managing action items
 */
const ActionItemsPanel = ({
  actionItems,
  callId,
  users,
  handleApproveActionItem,
  handleRejectActionItem,
  handleAssignActionItem,
  handleDateChange,
  handleToggleTasksModal,
  callData,
  isModal = false,
  crm
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const [loadingStates, setLoadingStates] = useState({});
  const [assignedUsers, setAssignedUsers] = useState({});
  const [editableDates, setEditableDates] = useState({});
  const [editableDescriptions, setEditableDescriptions] = useState({});
  const [editableNames, setEditableNames] = useState({});
  const [isEditing, setIsEditing] = useState({});
  const [isSaving, setIsSaving] = useState({});
  const [approveAllLoading, setApproveAllLoading] = useState(false);
  const [crmData, setCrmData] = useState(null);
  const [isFetchingCrm, setIsFetchingCrm] = useState(false);
  const [actionItemsState, setActionItemsState] = useState([]);
  const { user } = useUser();
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;
  
  // Debug logging
  console.log('ActionItemsPanel received actionItems:', actionItems);
  console.log('ActionItemsPanel received users:', users);
  console.log('ActionItemsPanel received crm:', crm);
  
  // Normalize status values to handle potential format mismatches
  const normalizeStatus = (status) => {
    if (!status) return 'pending';
    
    const statusLower = typeof status === 'string' ? status.toLowerCase() : '';
    if (statusLower.includes('pend')) return 'pending';
    if (statusLower.includes('approv') || statusLower.includes('success')) return 'success';
    if (statusLower.includes('reject')) return 'rejected';
    return statusLower;
  };
  
  // Initialize actionItemsState from actionItems prop
  useEffect(() => {
    if (actionItems && actionItems.length > 0) {
      console.log('actionItems prop changed, current state:', actionItemsState);
      
      // Only initialize state from props if we don't have state yet or if the items have changed significantly
      setActionItemsState(prevState => {
        // If we don't have any state yet, use the props directly
        if (!prevState || prevState.length === 0) {
          console.log('Initializing actionItemsState from props');
          return actionItems;
        }
        
        // Create a map of existing items by ID for quick lookup
        const existingItemsMap = {};
        prevState.forEach(item => {
          existingItemsMap[item.id] = item;
        });
        
        // Merge the arrays, preferring our local state for items we've already modified
        const updatedState = actionItems.map(item => {
          const existingItem = existingItemsMap[item.id];
          
          // If we already have this item in our state and it's been modified locally, keep our version
          if (existingItem) {
            // If this item has our local update flag, preserve it
            if (existingItem._locallyUpdated) {
              console.log(`Preserving locally updated state for item ${item.id}:`, existingItem);
              return existingItem;
            }
            
            const localStatus = normalizeStatus(existingItem.status);
            const localAiStatus = normalizeStatus(existingItem.aiCompletionStatus);
            
            // If our local item shows approved or rejected, preserve that state
            if (localStatus === 'success' || localStatus === 'rejected' ||
                localAiStatus === 'success' || localAiStatus === 'rejected') {
              console.log(`Preserving local state for item ${item.id} with status:`, localStatus, localAiStatus);
              return existingItem;
            }
          }
          
          // Otherwise use the item from props
          return item;
        });
        
        console.log('Updated state after merging with props:', updatedState);
        return updatedState;
      });
    }
  }, [actionItems]);
  
  // Use CRM data passed from props if available, otherwise fetch it
  useEffect(() => {
    if (crm) {
      console.log('Using CRM data from props:', crm);
      setCrmData(crm);
      return;
    }
    
    const fetchCrmData = async () => {
      if (users && users.length > 0) {
        console.log('Using provided users data:', users);
        return;
      }
      
      try {
        setIsFetchingCrm(true);
        console.log('Fetching CRM data from API...');
        const response = await axios.get(`${serverUrl}/api/my-feed`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        
        console.log('API response:', response.data);
        
        if (response.data && response.data.crm) {
          console.log('Fetched CRM data structure:', response.data.crm);
          setCrmData(response.data.crm);
        } else if (response.data && response.data.items && response.data.items.length > 0) {
          // Try to extract CRM data from items if available
          console.log('Trying to extract CRM data from items');
          setCrmData(response.data);
        } else {
          // If we couldn't get CRM data from my-feed, try a direct approach
          console.log('Trying to fetch CRM users directly');
          try {
            const orgResponse = await axios.get(`${serverUrl}/api/organization/current`, {
              headers: {
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
              }
            });
            
            if (orgResponse.data && orgResponse.data.crms && orgResponse.data.crms.length > 0) {
              console.log('Fetched organization CRM data:', orgResponse.data.crms[0]);
              setCrmData({ crmUsers: orgResponse.data.crms[0].crmUsers || [] });
            }
          } catch (orgError) {
            console.error('Error fetching organization data:', orgError);
          }
        }
      } catch (error) {
        console.error('Error fetching CRM data:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch CRM users. Some features may be limited.',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      } finally {
        setIsFetchingCrm(false);
      }
    };
    
    fetchCrmData();
  }, [serverUrl, users, toast, crm]);
  
  // Initialize assigned users and dates from actionItems
  useEffect(() => {
    if (actionItems && actionItems.length > 0) {
      const initialAssignedUsers = {};
      const initialDates = {};
      const initialDescriptions = {};
      const initialNames = {};
      
      actionItems.forEach(item => {
        // Initialize assigned users if available
        if (item.assignedTo) {
          const assignedUser = {
            id: item.assignedTo.id || item.assignedTo,
            name: item.assignedToName || ''
          };
          
          // Try to find the user name if not provided
          if (!assignedUser.name) {
            // First try from the users prop
            if (users && users.length > 0) {
              const foundUser = users.find(u => u.id === (item.assignedTo.id || item.assignedTo));
              if (foundUser) {
                assignedUser.name = foundUser.name;
              }
            } 
            // Then try from the fetched CRM data
            else if (crmData && crmData.crmUsers && crmData.crmUsers.length > 0) {
              const foundUser = crmData.crmUsers.find(u => u.id === (item.assignedTo.id || item.assignedTo));
              if (foundUser) {
                assignedUser.name = foundUser.name;
              }
            }
            // Finally try from the user context
            else if (user?.organization?.crms && user.organization.crms.length > 0) {
              const crmUsers = user.organization.crms[0]?.crmUsers || [];
              const foundUser = crmUsers.find(u => u.id === (item.assignedTo.id || item.assignedTo));
              if (foundUser) {
                assignedUser.name = foundUser.name;
              }
            }
          }
          
          initialAssignedUsers[item.id] = assignedUser;
          console.log(`Initialized assigned user for item ${item.id}:`, assignedUser);
        }
        
        // Initialize dates if available
        if (item.dueDate) {
          // Format date to YYYY-MM-DD for input element
          const date = new Date(item.dueDate);
          const formattedDate = date.toISOString().split('T')[0];
          initialDates[item.id] = formattedDate;
        }
        
        // Initialize descriptions if available
        if (item.functionParameters?.newValue?.description) {
          initialDescriptions[item.id] = item.functionParameters.newValue.description;
        }
        
        // Initialize names if available
        if (item.description) {
          initialNames[item.id] = item.description;
        }
      });
      
      setAssignedUsers(initialAssignedUsers);
      setEditableDates(initialDates);
      setEditableDescriptions(initialDescriptions);
      setEditableNames(initialNames);
    }
  }, [actionItems, users, crmData, user]);
  
  // Handle approve action with loading state
  const onApprove = async (item) => {
    setLoadingStates(prev => ({ ...prev, [item.id]: true }));
    try {
      // Get the due date - if not set, default to 7 days from now
      const dueDate = editableDates[item.id] 
        ? new Date(editableDates[item.id]).toISOString()
        : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(); // 7 days from now
      console.log('assignedUsers:', assignedUsers);
      console.log('item:', item);
      // Prepare the action item data
      const actionItemData = {
        assignedTo: assignedUsers[item.id]?.id,
        dueDate: dueDate,
        description: editableDescriptions[item.id] || item.description,
        functionParameters: {
          ...item.functionParameters,
          newValue: {
            ...item.functionParameters?.newValue,
            description: editableDescriptions[item.id] || item.functionParameters?.newValue?.description
          }
        }
      };
      
      await handleApproveActionItem(item, actionItemData);
      
      // Update the local state of the item to reflect it's now approved
      const updatedItem = {
        ...item,
        status: 'success',
        aiCompletionStatus: 'success',
        assignedTo: assignedUsers[item.id]?.id,
        assignedToName: assignedUsers[item.id]?.name,
        dueDate: dueDate,
        // Add a timestamp to ensure we know this item has been locally updated
        _locallyUpdated: Date.now(),
        _locallyUpdatedStatus: 'success'
      };
      
      console.log('Updating local state with approved item:', updatedItem);
      
      // Update actionItemsState with the updated item
      setActionItemsState(prevState => {
        const newState = prevState.map(ai => ai.id === item.id ? updatedItem : ai);
        console.log('New actionItemsState after approval:', newState);
        return newState;
      });
      
    } catch (error) {
      console.error('Error approving action item:', error);
      toast({
        title: "Error",
        description: "Failed to approve the action item. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingStates(prev => ({ ...prev, [item.id]: false }));
    }
  };
  
  // Handle reject action with loading state
  const onReject = async (item) => {
    setLoadingStates(prev => ({ ...prev, [item.id]: true }));
    try {
      await handleRejectActionItem(item);
      
      // Update the local state of the item to reflect it's now rejected
      const updatedItem = {
        ...item,
        status: 'rejected',
        aiCompletionStatus: 'rejected',
        // Add a timestamp to ensure we know this item has been locally updated
        _locallyUpdated: Date.now(),
        _locallyUpdatedStatus: 'rejected'
      };
      
      console.log('Updating local state with rejected item:', updatedItem);
      
      // Update actionItemsState with the updated item
      setActionItemsState(prevState => {
        const newState = prevState.map(ai => ai.id === item.id ? updatedItem : ai);
        console.log('New actionItemsState after rejection:', newState);
        return newState;
      });
      
      toast({
        title: "Action Item Rejected",
        description: "The action item has been successfully rejected.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error rejecting action item:', error);
      toast({
        title: "Error",
        description: "Failed to reject the action item. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingStates(prev => ({ ...prev, [item.id]: false }));
    }
  };
  
  // Filter pending action items with normalized status from local state
  const pendingItems = actionItemsState.filter(item => 
    normalizeStatus(item.status) === 'pending' || 
    normalizeStatus(item.aiCompletionStatus) === 'pending' ||
    normalizeStatus(item.status) === 'error' || 
    normalizeStatus(item.aiCompletionStatus) === 'error'
  );
  
  // Handle assign action
  const handleAssign = (itemId, user) => {
    console.log(`Assigning user to item ${itemId}:`, user);
    setAssignedUsers(prev => ({
      ...prev,
      [itemId]: {
        id: user.id,
        name: user.name
      }
    }));
  };

  // Handle text edit
  const handleTextEdit = (itemId, field, value) => {
    if (field === 'name') {
      setEditableNames(prev => ({
        ...prev,
        [itemId]: value
      }));
    } else if (field === 'description') {
      setEditableDescriptions(prev => ({
        ...prev,
        [itemId]: value
      }));
    }
  };

  // Toggle edit mode
  const onEditToggle = async (field, itemId) => {
    const newIsEditing = !isEditing[field];
    setIsEditing(prev => ({
      ...prev,
      [field]: newIsEditing
    }));

    // Only log when finishing an edit
    if (!newIsEditing) {
      if (field.includes('name')) {
        // Log action item name edit
        try {
          await axios.post(`${serverUrl}/api/usage/log`, {
            statPath: FeatureStats.TRANSCRIPTION_TITLES_EDITED,
            value: 1
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
          });
        } catch (error) {
          console.error('Error logging name edit:', error);
        }
      } else if (field.includes('description')) {
        // Log action item description edit
        try {
          await axios.post(`${serverUrl}/api/usage/log`, {
            statPath: FeatureStats.ACTION_ITEMS_EDITED,
            value: 1
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
          });
        } catch (error) {
          console.error('Error logging description edit:', error);
        }
      }
    }
  };

  // Handle approve all action items
  const handleApproveAll = async () => {
    try {
      setApproveAllLoading(true);
      
      const newLoadingStates = {};
      pendingItems.forEach(item => {
        newLoadingStates[item.id] = true;
      });
      setLoadingStates(newLoadingStates);

      // Make all API calls in parallel
      await Promise.all(
        pendingItems.map(item => {
          // Get the due date - if not set, default to 7 days from now
          const dueDate = editableDates[item.id] 
            ? new Date(editableDates[item.id]).toISOString()
            : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(); // 7 days from now

          const actionItemData = {
            assignedTo: assignedUsers[item.id]?.id,
            dueDate: dueDate,
            description: editableDescriptions[item.id] || item.description,
            functionParameters: {
              ...item.functionParameters,
              newValue: {
                ...item.functionParameters?.newValue,
                description: editableDescriptions[item.id] || item.functionParameters?.newValue?.description
              }
            }
          };
          
          return handleApproveActionItem(item, actionItemData);
        })
      );

      // Update all pending items to approved state
      setActionItemsState(prevState => {
        const newState = prevState.map(item => {
          if (pendingItems.some(pi => pi.id === item.id)) {
            // This is a pending item that was just approved
            return {
              ...item,
              status: 'success',
              aiCompletionStatus: 'success',
              assignedTo: assignedUsers[item.id]?.id,
              assignedToName: assignedUsers[item.id]?.name,
              dueDate: editableDates[item.id] 
                ? new Date(editableDates[item.id]).toISOString()
                : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
              // Add local update flag
              _locallyUpdated: Date.now(),
              _locallyUpdatedStatus: 'success'
            };
          }
          return item;
        });
        
        console.log('New actionItemsState after approving all:', newState);
        return newState;
      });

      // Log multiple approvals at once
      try {
        await axios.post(`${serverUrl}/api/usage/log`, {
          statPath: FeatureStats.ACTION_ITEMS_APPROVED,
          value: pendingItems.length
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
      } catch (error) {
        console.error('Error logging multiple approvals:', error);
      }

      toast({
        title: "All Actions Approved",
        description: "All pending action items have been successfully approved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error approving all action items:', error);
      toast({
        title: "Error",
        description: "Failed to approve all action items. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setApproveAllLoading(false);
      setLoadingStates({});
    }
  };

  // Get user CRMs from various sources in order of priority
  const getUserCrms = () => {
    console.log('Getting user CRMs with users:', users);
    console.log('crmData:', crmData);
    console.log('crm prop:', crm);
    console.log('user context:', user);
    
    // 0. Use the crm prop if available (highest priority)
    if (crm && crm.crmUsers && crm.crmUsers.length > 0) {
      console.log('Using crm prop crmUsers for dropdown:', crm.crmUsers);
      return crm.crmUsers;
    }
    
    // 1. Use the users prop if available
    if (users && users.length > 0) {
      console.log('Using users prop for dropdown:', users);
      const usersWithNames = users.map(user => ({
        id: user.id,
        name: user.name ? user.name : user.firstName + ' ' + user.lastName
      }));
      return usersWithNames;
    }
    
    // 2. Use the fetched CRM data if available
    if (crmData) {
      // Handle different possible structures
      if (Array.isArray(crmData.crmUsers)) {
        console.log('Using crmData.crmUsers for dropdown:', crmData.crmUsers);
        return crmData.crmUsers;
      } else if (crmData.crmUsers) {
        console.log('Using crmData.crmUsers (non-array) for dropdown:', [crmData.crmUsers]);
        return [crmData.crmUsers];
      } else if (crmData.crm && crmData.crm.crmUsers) {
        console.log('Using crmData.crm.crmUsers for dropdown:', crmData.crm.crmUsers);
        return crmData.crm.crmUsers;
      } else if (Array.isArray(crmData)) {
        console.log('Using crmData array for dropdown:', crmData);
        return crmData;
      } else if (crmData.items && crmData.items.length > 0) {
        // Try to extract CRM users from the first item that has them
        for (const item of crmData.items) {
          if (item.crm && item.crm.crmUsers && item.crm.crmUsers.length > 0) {
            console.log('Using crmData.items[].crm.crmUsers for dropdown:', item.crm.crmUsers);
            return item.crm.crmUsers;
          }
        }
      }
    }
    
    // 3. Use the user context as a fallback
    if (user?.organization?.crms && user.organization.crms.length > 0) {
      const crmUsers = user.organization.crms[0]?.crmUsers || [];
      console.log('Using user context crmUsers for dropdown:', crmUsers);
      return crmUsers;
    }
    
    // 4. Create a fallback user if we couldn't find any
    if (user) {
      console.log('Creating fallback user from current user');
      return [{
        id: user.id,
        name: user.name || user.email || 'Current User'
      }];
    }
    
    // 5. Return empty array if no users found
    console.log('No users found for dropdown, returning empty array');
    return [];
  };
  
  const userCrms = getUserCrms();
  
  // Check if all pending items have assignees
  const canApproveAll = pendingItems.every(item => assignedUsers[item.id]);
  
  // Check if we have valid users to display
  const hasValidUsers = userCrms && userCrms.length > 0;
  
  // Check if we have a CRM
  const hasCrm = hasValidUsers || (user?.organization?.crms && user.organization.crms.length > 0);

  // Handle save action item
  const handleSaveActionItem = async (item) => {
    try {
      setIsSaving(prev => ({ ...prev, [item.id]: true }));

      // Prepare the data to update
      const updateData = {
        name: editableNames[item.id] || item.description,
        description: editableDescriptions[item.id] || item.functionParameters?.newValue?.description || '',
        functionParameters: {
          ...item.functionParameters,
          newValue: {
            ...item.functionParameters?.newValue,
            name: editableNames[item.id] || item.description,
            description: editableDescriptions[item.id] || item.functionParameters?.newValue?.description || ''
          }
        }
      };

      // Make the API call to update the action item
      const response = await axios.put(
        `${serverUrl}/api/action-items/${item.id}`,
        updateData,
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );

      if (response.status === 200) {
        // Update the local state to reflect the changes
        const updatedItem = {
          ...item,
          description: updateData.name,
          functionParameters: {
            ...item.functionParameters,
            newValue: {
              ...item.functionParameters?.newValue,
              name: updateData.name,
              description: updateData.description
            }
          },
          // Add local update flag for editing
          _locallyEdited: Date.now()
        };
        
        console.log('Updating item after save:', updatedItem);
        
        // Update the actionItemsState with the updated item
        setActionItemsState(prevState => {
          const newState = prevState.map(ai => ai.id === item.id ? updatedItem : ai);
          console.log('New actionItemsState after save:', newState);
          return newState;
        });
        
        setIsEditing(prev => ({
          ...prev,
          [`${item.id}-name`]: false,
          [`${item.id}-description`]: false
        }));

        toast({
          title: "Action Item Updated",
          description: "The action item has been successfully updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Log action item edit
        await axios.post(`${serverUrl}/api/usage/log`, {
          statPath: FeatureStats.ACTION_ITEMS_EDITED,
          value: 1
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
      }
    } catch (error) {
      console.error('Error updating action item:', error);
      toast({
        title: "Error",
        description: "Failed to update the action item. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSaving(prev => ({ ...prev, [item.id]: false }));
    }
  };

  return (
    <VStack spacing={4} align="stretch" height="100%" overflowY="auto" p={2}>
      {/* Action Items Section */}
      <Box>
        {/* Advisor To-Dos Section */}
              
          <Collapse in={isOpen} animateOpacity>
            <VStack 
              spacing={2} 
              align="stretch"
              bg="white"
              borderRadius="md"
              p={4}
              borderWidth="1px"
              borderColor="gray.100"
              transition="box-shadow 0.2s ease-in-out"
              boxShadow="sm"
              _hover={{ boxShadow: 'sm' }}
              mt={2}
            >
              {actionItemsState && actionItemsState.length > 0 ? (
                <>
                  {actionItemsState.filter(item => !item.archived).map((item, index) => (
                    <Box 
                      key={item.id || index}
                      borderWidth="1px"
                      borderRadius="md"
                      borderColor={normalizeStatus(item.status) === 'pending' || normalizeStatus(item.aiCompletionStatus) === 'pending' ? 'blue.100' : 'gray.100'}
                      bg={normalizeStatus(item.status) === 'pending' || normalizeStatus(item.aiCompletionStatus) === 'pending' ? 'gray.50' : 'white'}
                      p={3}
                    >
                      <VStack align="stretch" spacing={3}>
                        {/* Title and Actions Row */}
                        <HStack justify="space-between" align="flex-start">
                          <VStack align="start" spacing={1} flex={1}>
                            {isEditing[`${item.id}-name`] ? (
                              <Input
                                size="sm"
                                value={editableNames[item.id] || item.description}
                                onChange={(e) => handleTextEdit(item.id, 'name', e.target.value)}
                                onBlur={() => {}}
                                autoFocus
                              />
                            ) : (
                              <HStack alignItems="center" spacing={2}>
                                <Text 
                                  fontSize="sm"
                                  fontWeight="medium"
                                  color="gray.700"
                                >
                                  {item.description}
                                </Text>
                                {(normalizeStatus(item.aiCompletionStatus) === 'success') && (
                                  <Badge colorScheme="green" variant="solid" fontSize="2xs" px={2} py={0.5}>
                                    Sent to CRM
                                  </Badge>
                                )}
                                {assignedUsers[item.id]?.name && (normalizeStatus(item.aiCompletionStatus) === 'success') && (
                                  <Badge colorScheme="blue" variant="outline" fontSize="2xs" px={2} py={0.5}>
                                    {assignedUsers[item.id].name}
                                  </Badge>
                                )}
                              </HStack>
                            )}
                            
                            {isEditing[`${item.id}-description`] ? (
                              <Input
                                size="sm"
                                value={editableDescriptions[item.id] || item.functionParameters?.newValue?.description || ''}
                                onChange={(e) => handleTextEdit(item.id, 'description', e.target.value)}
                                onBlur={() => {}}
                                placeholder="Add description..."
                                autoFocus
                              />
                            ) : (
                              item.functionParameters?.newValue?.description && (
                                <Text fontSize="xs" color="gray.600">
                                  {item.functionParameters.newValue.description}
                                </Text>
                              )
                            )}
                          </VStack>
                          {/* Only show action buttons for non-approved items */}
                          {(normalizeStatus(item.aiCompletionStatus) !== 'success') && (
                            <HStack spacing={2}>
                                  <Tooltip
                                    label={
                                      !assignedUsers[item.id] && hasCrm
                                        ? "Please assign this action to someone"
                                        : "Approve this action"
                                    }
                                    hasArrow
                                  >
                                    <Button
                                      size="sm"
                                      colorScheme="green"
                                      variant="ghost"
                                      leftIcon={<CheckIcon />}
                                      onClick={() => onApprove(item)}
                                      isDisabled={(!assignedUsers[item.id] || loadingStates[item.id]) && hasCrm}
                                      isLoading={loadingStates[item.id]}
                                    >
                                      Create in CRM
                                    </Button>
                                  </Tooltip>
                                  <IconButton
                                    size="sm"
                                    icon={<CloseIcon />}
                                    colorScheme="red"
                                    variant="ghost"
                                    onClick={() => onReject(item)}
                                    isDisabled={loadingStates[item.id]}
                                    isLoading={loadingStates[item.id]}
                                    aria-label="Reject"
                                  />
                              
                              <IconButton
                                size="sm"
                                icon={<EditIcon />}
                                variant="ghost"
                                onClick={() => {
                                  onEditToggle(`${item.id}-name`, item.id);
                                  onEditToggle(`${item.id}-description`, item.id);
                                }}
                                aria-label="Edit details"
                              />
                              
                              {/* Add Save Button */}
                              {(isEditing[`${item.id}-name`] || isEditing[`${item.id}-description`]) && (
                                <IconButton
                                  size="sm"
                                  icon={<Icon as={FaSave} />}
                                  variant="ghost"
                                  colorScheme="green"
                                  onClick={() => handleSaveActionItem(item)}
                                  isLoading={isSaving[item.id]}
                                  aria-label="Save changes"
                                />
                              )}
                            </HStack>
                          )}
                        </HStack>

                        {/* Assignment and Due Date Row - only for non-approved items */}
                        {((normalizeStatus(item.aiCompletionStatus) === 'pending' || 
                           normalizeStatus(item.aiCompletionStatus) === 'error') && hasCrm) && (
                          <HStack spacing={4} align="center">
                            <Menu>
                              <MenuButton
                                as={Button}
                                size="sm"
                                variant="outline"
                                rightIcon={<ChevronDownIcon />}
                                colorScheme="blue"
                                isLoading={isFetchingCrm}
                              >
                                {assignedUsers[item.id]?.name ? assignedUsers[item.id].name : 'Assign To'}
                              </MenuButton>
                              <MenuList>
                                {hasValidUsers ? (
                                  userCrms.map((user) => (
                                    <MenuItem 
                                      key={user.id}
                                      onClick={() => handleAssign(item.id, {
                                        id: user.id,
                                        name: user.name
                                      })}
                                    >
                                      {user.name}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem isDisabled>No users available</MenuItem>
                                )}
                              </MenuList>
                            </Menu>
                            
                            <Input
                              type="date"
                              size="sm"
                              value={editableDates[item.id] || ''}
                              onChange={(e) => {
                                const newDate = e.target.value;
                                setEditableDates(prev => ({
                                  ...prev,
                                  [item.id]: newDate
                                }));
                              }}
                              min={new Date().toISOString().split('T')[0]}
                              placeholder="Due date"
                              w="auto"
                              borderColor="gray.300"
                              _hover={{ borderColor: "gray.400" }}
                              _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
                            />
                          </HStack>
                        )}
                      </VStack>
                    </Box>
                  ))}
                  
                  {/* Approve All Button */}
                  {pendingItems.length > 1 && (
                    <HStack justify="flex-end" mt={2}>
                      <Tooltip
                        label={
                          !canApproveAll && hasCrm
                            ? "Please assign all pending actions to someone"
                            : "Approve all pending actions"
                        }
                        hasArrow
                      >
                        <Button
                          size="sm"
                          colorScheme="blue"
                          variant="solid"
                          rightIcon={<CheckIcon />}
                          onClick={handleApproveAll}
                          isDisabled={(!canApproveAll && hasCrm) || approveAllLoading}
                          isLoading={approveAllLoading}
                        >
                          Approve All
                        </Button>
                      </Tooltip>
                    </HStack>
                  )}
                </>
              ) : (
                <VStack py={4} color="gray.600">
                  <Icon as={FaTasks} boxSize={6} />
                  <Text>No advisor to-dos available</Text>
                </VStack>
              )}
            </VStack>
          </Collapse>
        </Box>

        {/* Client To-Dos Section */}
        {callData?.call?.clientTodos?.length > 0 && (
          <Box mb={4}>
            <Heading size="md" mb={4}>Client To-Dos</Heading>
            <VStack align="stretch" spacing={3}>
              {callData.call.clientTodos.map((todo, idx) => (
                <Box
                  key={idx}
                  p={4}
                  borderRadius="md"
                  bg="gray.50"
                  borderWidth="1px"
                  borderColor="gray.200"
                  _hover={{ bg: 'gray.100' }}
                  transition="all 0.2s"
                >
                  <HStack justify="space-between">
                    <Text color="gray.700">{todo}</Text>
                    <Badge colorScheme="blue">Pending</Badge>
                  </HStack>
                </Box>
              ))}
            </VStack>
          </Box>
        )}
    </VStack>
  );
};

export default ActionItemsPanel; 