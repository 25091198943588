import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Home from './components/pages/Home/Home';
import Clients from './components/pages/Clients/Clients';
import Meetings from './components/pages/Meetings/Meetings';
import Settings from './components/pages/Settings/Settings';
import Integrations from './components/pages/Integrations/Integrations';
import Correspondence from './components/pages/Clients/Correspondence';
import LiveMeeting from './components/pages/Meetings/LiveMeeting';
import LiveMeetingTest from './components/pages/Meetings/LiveMeetingTest';
import Login from './components/pages/Login';
import Signup from './components/pages/Signup';
import Salesforce from './components/pages/Salesforce';
import RedtailConnect from './components/pages/RedtailConnect/RedtailConnect';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import './App.css';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import FollowUpPage from './components/FollowUpPage';
import './styles/quill.css';
import { UserProvider, useUser } from './contexts/UserContext';
import { OrganizationClientsProvider } from './contexts/OrganizationClientsContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { OrganizationUsersProvider } from './contexts/OrganizationUsersContext';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword';
import Upload from './components/pages/Meetings/Upload';
import ClientPage from './components/pages/Clients/ClientPage';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FeatureStats, TimeStats } from './enums/UsageStats';
import AdminApprovalOverlay from './components/AdminApprovalOverlay';
import AddClient from './components/pages/Clients/AddClient';
import Checkout from './components/pages/Checkout/Checkout';
import CheckoutSimple from './components/pages/Checkout/CheckoutSimple';
import EditClient from './components/pages/Clients/EditClient';
import AskDatadasherPage from './pages/AskDatadasher/AskDatadasherPage';

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const isAuthRoute = location.pathname === '/login' || 
                     location.pathname === '/signup' || 
                     location.pathname === '/forgot-password' || 
                     location.pathname.includes('/reset-password/');
  
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/checkout" element={<CheckoutSimple />} />
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <UserProvider>
              <OrganizationClientsProvider>
                <OrganizationUsersProvider>
                  <NotificationProvider>
                    <MainLayout />
                  </NotificationProvider>
                </OrganizationUsersProvider>
              </OrganizationClientsProvider>
            </UserProvider>
          </PrivateRoute>
        }
      />
      <Route path="/ask-datadasher" element={<AskDatadasherPage />} />
    </Routes>
  );
}

function MainLayout() {
  const scrollBoxRef = useRef(null);
  const location = useLocation();
  const { user, loading } = useUser();
  const isAuthRoute = location.pathname === '/login' || 
                     location.pathname === '/signup' || 
                     location.pathname === '/forgot-password' || 
                     location.pathname.includes('/forgot-password/');
  const isHomePage = location.pathname === '/';
  const serverUrl = process.env.REACT_APP_API_URL;
  const lastTimeRef = useRef(Date.now());
  const currentPageRef = useRef(null);
  const intervalRef = useRef(null);

  // Log time spent on pages
  useEffect(() => {
    const logPageTime = async (path, timeSpent) => {
      try {
        let statPath;
        switch (path) {
          case '/':
            statPath = TimeStats.PAGE_HOME;
            break;
          case '/clients':
            statPath = TimeStats.PAGE_CLIENTS;
            break;
          case '/meetings':
            statPath = TimeStats.PAGE_MEETINGS;
            break;
          case '/settings':
            statPath = TimeStats.PAGE_SETTINGS;
            break;
          default:
            if (path.includes('/transcripts/')) {
              statPath = TimeStats.PAGE_TRANSCRIPTS;
            }
            break;
        }
        
        // Only log if we have accumulated at least 1 second
        if (timeSpent > 0) {
          if (statPath) {
            await axios.post(`${serverUrl}/api/usage/log`, {
              statPath,
              value: timeSpent // Log in seconds
            }, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
              }
            });
          }

          // Always log total platform time
          await axios.post(`${serverUrl}/api/usage/log`, {
            statPath: TimeStats.PLATFORM_TOTAL,
            value: timeSpent // Log in seconds
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
          });
        }
      } catch (error) {
        console.error('Error logging page time:', error);
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        // User left the page - log the time spent so far
        const timeSpent = Math.floor((Date.now() - lastTimeRef.current) / 1000);
        if (currentPageRef.current) {
          logPageTime(currentPageRef.current, timeSpent);
        }
        // Clear the interval when page is hidden
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        // Update the last time reference
        lastTimeRef.current = Date.now();
      } else {
        // User returned to the page - start a new interval
        lastTimeRef.current = Date.now();
        startLoggingInterval();
      }
    };

    const startLoggingInterval = () => {
      // Clear any existing interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      // Set up new interval to log every 10 seconds
      intervalRef.current = setInterval(() => {
        const timeSpent = 10; // Log 10 seconds each interval
        if (currentPageRef.current) {
          logPageTime(currentPageRef.current, timeSpent);
        }
        // Update the last time reference
        lastTimeRef.current = Date.now();
      }, 10000); // Run every 10 seconds
    };

    // Set up visibility change listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // When location changes, log the time spent on the previous page
    if (currentPageRef.current && currentPageRef.current !== location.pathname) {
      const timeSpent = Math.floor((Date.now() - lastTimeRef.current) / 1000);
      if (timeSpent > 0) {
        logPageTime(currentPageRef.current, timeSpent);
      }
    }

    // Update current page and reset timer
    currentPageRef.current = location.pathname;
    lastTimeRef.current = Date.now();

    // Start logging interval
    startLoggingInterval();

    // Cleanup
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      // Log final time before unmounting
      const timeSpent = Math.floor((Date.now() - lastTimeRef.current) / 1000);
      if (currentPageRef.current && timeSpent > 0) {
        logPageTime(currentPageRef.current, timeSpent);
      }
    };
  }, [serverUrl, location.pathname]);

  const showAdminApprovalOverlay = user && user.subscriptionActivated === null && user.role !== 'admin';

  return (
    <Flex className="app" height="100vh" overflow="hidden">
      {!isAuthRoute && <Sidebar />}
      <Box
        position="relative"
        ref={scrollBoxRef}
        flex="1"
        width="100%"
        bg={isHomePage ? "#00417D" : "transparent"}
        pt={0}
        pl={0}
        pr={0}
        overflowY="auto"
        transition="all 0.3s ease"
      >
        {loading ? (
          <Flex 
            height="100%" 
            width="100%" 
            justifyContent="center" 
            alignItems="center"
          >
            <Spinner 
              size="xl" 
              color={isHomePage ? "white" : "#00417D"}
              thickness="4px"
            />
          </Flex>
        ) : (
          <>
            {showAdminApprovalOverlay && (
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                zIndex={9999}
              >
                <AdminApprovalOverlay />
              </Box>
            )}
            <Routes>
              <Route path="/" element={<Home scrollRef={scrollBoxRef} />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/clients/:id" element={<ClientPage />} />
              <Route path="/meetings" element={<Meetings />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/correspondence" element={<Correspondence />} />
              <Route path="/salesforce" element={<Salesforce />} />
              <Route path="/transcripts/:id" element={<Correspondence />} />
              <Route path="/correspondence/email/:id" element={<Correspondence />} />
              <Route path="/redtail/connect" element={<RedtailConnect />} />
              <Route path="/live-meeting" element={<LiveMeeting />} />
              <Route path="/live-meeting-test" element={<LiveMeetingTest />} />
              <Route path="/follow-up/:type/:id" element={<FollowUpPage />} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/clients/new" element={<AddClient />} />
              <Route path="/clients/:id/edit" element={<EditClient />} />
              <Route path="/ask-datadasher" element={<AskDatadasherPage />} />
            </Routes>
          </>
        )}
      </Box>
    </Flex>
  );
}

export default App;