import React from 'react';
import { Heading, Text, Box, Container, Button, Link } from '@chakra-ui/react';
import { useAuth } from '../AuthContext';

/**
 * Overlay component that blocks access to the application for users without beta access
 * Only displays in non-production environments
 */
const BetaAccessOverlay = ({ user }) => {
  const { logout } = useAuth();
  
  // Don't show overlay if we're in production or user has beta access
  if (!user || process.env.NODE_ENV === 'production' || user?.betaAccess) {
    return null;
  }

  return (
    <Box 
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bg="rgba(0, 0, 0, 0.7)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={9999}
    >
      <Container
        bg="white"
        p={8}
        borderRadius="lg"
        maxW="500px"
        textAlign="center"
        boxShadow="lg"
      >
        <Heading 
          as="h2" 
          size="lg" 
          color="#00417D" 
          mb={4}
        >
          Beta Access Required
        </Heading>
        <Text 
          fontSize="lg" 
          lineHeight="tall" 
          mb={4}
        >
          This application is currently in beta. You need beta access to use this application.
        </Text>
        <Text 
          fontSize="sm" 
          color="gray.600"
          mb={6}
        >
          For access, please contact <Link href="mailto:support@datadasher.ai" color="blue.500">support@datadasher.ai</Link>
        </Text>
        <Button
          onClick={logout}
          size="md"
          variant="ghost"
          color="#00417D"
          _hover={{
            bg: 'blue.50',
          }}
        >
          Logout
        </Button>
      </Container>
    </Box>
  );
};

export default BetaAccessOverlay; 