import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  Box, VStack, HStack, IconButton, Flex, Text,
  Input, Button, Heading, Divider, useColorModeValue, InputGroup, InputLeftElement,
  Menu, MenuButton, MenuList, MenuItem, Avatar, Icon, Popover, PopoverTrigger, PopoverContent, PopoverBody,
  useToast, Spinner
} from '@chakra-ui/react';
import {
  ChevronLeftIcon, ChevronRightIcon, SearchIcon, ChevronDownIcon,
  ArrowBackIcon, TimeIcon, WarningIcon
} from '@chakra-ui/icons';
import { FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { BsStopwatch } from 'react-icons/bs';
import axios from 'axios';
import Cookies from 'js-cookie';

import AudioPlayer from '../../../AudioPlayer';
import VirtualizedTranscriptionList from '../../../VirtualizedTranscriptionList';
import TranscriptionTimeline from '../../../TranscriptionTimeline';
import useSpeakerSelection from '../../../SpeakerSelection';
import AssignClientModal from '../../../AssignClientModal';
import { filterTranscriptionBySearchTerm } from '../../../../utils/transcriptionUtils';
import { useOrganizationUsers } from '../../../../contexts/OrganizationUsersContext';
import { useOrganizationClients } from '../../../../contexts/OrganizationClientsContext';
import { formatTime } from '../../../../utils/audioUtils';
import { formatPersonName } from '../../../../utils/formatters';
import AssignClientButton from '../../../../components/AssignClientButton';

// Add this utility function for debouncing
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// Add this throttle function at the top with the debounce function
const throttle = (func, limit) => {
  let inThrottle;
  return function(...args) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
};

/**
 * Component for displaying transcription and audio player
 */
const TranscriptionPanel = ({
  callData,
  callId,
  callTitle,
  callAudioUrl,
  callTranscription,
  groupedTranscription,
  isEditingTranscription,
  handleEditTranscription,
  handleCancelEditTranscription,
  handleSaveTranscription,
  handleTranscriptionChange,
  handleSpeakerUpdate,
  isLeftExpanded,
  setIsLeftExpanded,
  isRightExpanded = false,
  setIsRightExpanded,
  // Audio state from parent
  isPlaying,
  currentTime,
  duration,
  progress,
  isBuffering,
  handlePlayPause,
  handleSkip,
  handleSeek,
  jumpToTimestamp,
  audioRef,
  // Pagination props
  currentPage,
  totalPages,
  totalItems,
  isLoadingMoreTranscription,
  loadMoreTranscription,
  allTranscriptionLoaded
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [filteredTranscription, setFilteredTranscription] = useState(groupedTranscription);
  const [isSearching, setIsSearching] = useState(false);
  const [isClientAssignModalOpen, setIsClientAssignModalOpen] = useState(false);
  const [assignedClients, setAssignedClients] = useState(() => {
    // Initialize from callData.clients array if available
    if (callData?.call?.clients && callData.call.clients.length > 0) {
      return callData.call.clients;
    }
    return [];
  });
  const transcriptionRef = useRef(null);
  const virtualizedListRef = useRef(null);
  const { users } = useOrganizationUsers();
  const { clients } = useOrganizationClients();
  const toast = useToast();

  // Update assignedClients when callData changes
  useEffect(() => {
    if (callData?.call?.clients && callData.call.clients.length > 0) {
      setAssignedClients(callData.call.clients);
    } else {
      setAssignedClients([]);
    }
  }, [callData]);

  // Calculate transcription duration
  const transcriptionDuration = callData?.call?.duration || 0;
  console.log('transcriptionDuration', transcriptionDuration);

  // Colors for styling
  const colors = {
    primary: '#00417D',
    secondary: 'blue.300'
  };

  // Format title with date
  const formatTitleWithDate = (title) => {
    if (!title) return 'Untitled Call';
    
    // Check if title contains an ISO timestamp between ||
    const matches = title.match(/\|\|(.*?)\|\|/);
    if (!matches) return title;

    // Extract the parts
    const [fullMatch, isoTime] = matches;
    const baseTitle = title.replace(fullMatch, '').trim();
    
    // Format the date in user's local timezone
    const date = new Date(isoTime);
    const formattedDate = date.toLocaleString(undefined, {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });

    return `${baseTitle} on ${formattedDate}`;
  };

  // Fix: Create a memoized props object for SpeakerSelection instead of calling the component
  const speakerSelectionProps = useMemo(() => ({
    users: users || [],
    clients: clients || [],
    colors,
    handleSpeakerUpdate: (person, oldSpeaker, groupIndex, replaceAll) => {
      console.log('Speaker update requested:', { person, oldSpeaker, groupIndex, replaceAll });
      // Call the handleSpeakerUpdate function from props with the new speaker name
      handleSpeakerUpdate(person.name || person, oldSpeaker, groupIndex, replaceAll);
    }
  }), [users, clients, colors, handleSpeakerUpdate]);
  
  // Use the renderSpeakerName function from SpeakerSelection component
  const { renderSpeakerName, openSpeakerModal } = useSpeakerSelection(speakerSelectionProps);
  
  // Create a memoized list of available speakers for our own modal
  const availableSpeakers = useMemo(() => {
    const formattedUsers = (users || []).map(user => ({
      id: user.id,
      name: formatPersonName(user),
      type: 'user'
    }));

    const formattedClients = (clients || []).map(client => ({
      id: client.id,
      name: formatPersonName(client),
      type: 'client'
    }));

    return [...formattedUsers, ...formattedClients]
      .filter(person => person.name)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [users, clients]);
  
  // Add debugging for openSpeakerModal
  console.log('TranscriptionPanel openSpeakerModal:', {
    isFunction: typeof openSpeakerModal === 'function',
    openSpeakerModal,
    availableSpeakers: availableSpeakers.length
  });
  
  // Create a wrapped version of openSpeakerModal with debugging
  const debugOpenSpeakerModal = useCallback((speaker, groupIndex) => {
    console.log('debugOpenSpeakerModal called with:', { speaker, groupIndex });
    if (typeof openSpeakerModal === 'function') {
      openSpeakerModal(speaker, groupIndex);
    } else {
      console.error('openSpeakerModal is not a function:', openSpeakerModal);
    }
  }, [openSpeakerModal]);

  // Create debounced search function
  const debouncedSearch = useCallback(
    debounce(value => {
      setDebouncedSearchTerm(value);
    }, 300),
    []
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsSearching(value.length > 0);
    debouncedSearch(value);
  };

  // Apply search filter when debounced search term changes
  useEffect(() => {
    if (!debouncedSearchTerm) {
      setFilteredTranscription(groupedTranscription);
      setIsSearching(false);
      return;
    }

    setIsSearching(true);
    const filtered = filterTranscriptionBySearchTerm(groupedTranscription, debouncedSearchTerm);
    setFilteredTranscription(filtered);
    setIsSearching(false);
  }, [debouncedSearchTerm, groupedTranscription]);

  // Update filtered transcription when grouped transcription changes
  useEffect(() => {
    if (!searchTerm) {
      setFilteredTranscription(groupedTranscription);
    } else {
      const filtered = filterTranscriptionBySearchTerm(groupedTranscription, searchTerm);
      setFilteredTranscription(filtered);
    }
  }, [groupedTranscription]);

  // Toggle expanded view
  const toggleExpanded = () => {
    setIsLeftExpanded(!isLeftExpanded);
  };

  // Handle transcription text change
  const handleTextChange = (groupIndex, entryIndex, newText) => {
    handleTranscriptionChange(groupIndex, entryIndex, newText);
  };

  // Handle client selection from modal
  const handleClientSelect = async (selectedClients) => {
    try {
      // Update the local state with all selected clients
      setAssignedClients(Array.isArray(selectedClients) ? selectedClients : [selectedClients]);

      // Update the callData object to reflect the new clients
      if (callData && callData.call) {
        callData.call.clients = Array.isArray(selectedClients) ? selectedClients : [selectedClients];
      }

    } catch (error) {
      console.error('Error in handleClientSelect:', error);
    }
  };

  // Replace the previous useEffect for scrolling with this one
  useEffect(() => {
    // Create a throttled scroll function to prevent too many scroll operations
    const throttledScroll = throttle((time) => {
      if (virtualizedListRef.current && filteredTranscription.length > 0) {
        virtualizedListRef.current.scrollToTimestamp(time);
      }
    }, 500); // Throttle to once every 500ms
    
    // Only scroll if playing and not manually scrolling
    if (isPlaying && currentTime > 0) {
      throttledScroll(currentTime);
    }
  }, [isPlaying, currentTime, filteredTranscription.length]);

  return (
    <Box
      gridColumn={isLeftExpanded ? "1 / -1" : "1 / 2"}
      bg="white"
      position="relative"
      transition="width 0.1s ease"
      overflow="hidden"
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <VStack spacing={0} h="100%">
        {/* Header with title and expand button */}
        <HStack w="100%" justifyContent="space-between">
          <VStack spacing={0} width="100%">
            {/* Title Section */}
            <Box
              w="100%"
              bg="gray.50"
              borderBottomWidth="1px"
              borderColor="gray.200"
            >
              <VStack spacing={2} p={4}>
                <HStack justify="space-between" width="100%">
                  <HStack spacing={3}>
                    <IconButton
                      aria-label="Go back"
                      icon={<ArrowBackIcon />}
                      onClick={() => window.history.back()}
                      variant="ghost"
                      color="gray.600"
                      _hover={{ bg: "gray.100" }}
                    />
                    <Heading size="md" color={colors.primary}>
                      {formatTitleWithDate(callData?.call?.title)}
                    </Heading>
                  </HStack>
                  <IconButton
                    icon={isLeftExpanded ? <MdFullscreenExit /> : <MdFullscreen />}
                    onClick={() => {
                      setIsLeftExpanded(!isLeftExpanded);
                      if (setIsRightExpanded && !isLeftExpanded) {
                        setIsRightExpanded(false);
                      }
                    }}
                    aria-label="Toggle fullscreen"
                  />
                </HStack>
                
                <HStack spacing={6} pl={12} color="gray.600" width="100%">
                  {/* Update AssignClientButton to use assignedClients */}
                  <HStack spacing={2}>
                    <AssignClientButton
                      clientName={assignedClients.length > 0 ? assignedClients : ''}
                      onAssignClient={handleClientSelect}
                      callId={callId}
                    />
                  </HStack>

                  <HStack spacing={2}>
                    <Icon as={TimeIcon} />
                    <Text>
                      {callData?.call?.title ?
                        (() => {
                          // First try to parse date from title format "Meeting M/D/YYYY HH:MM AM/PM"
                          const titleMatch = callData.call.title.match(/Meeting (\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2} [AP]M)/);
                          if (titleMatch) {
                            const date = new Date(titleMatch[1]);
                            return date.toLocaleString(undefined, {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            });
                          }

                          // Then try timestamp between || markers
                          const markerMatches = callData.call.title.match(/\|\|(.*?)\|\|/);
                          if (markerMatches) {
                            const date = new Date(markerMatches[1]);
                            return date.toLocaleString(undefined, {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            });
                          }

                          // Finally fallback to startTime
                          if (callData.call.startTime) {
                            return new Date(callData.call.startTime).toLocaleString(undefined, {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            });
                          }
                          return 'Time not available';
                        })()
                        : 'Time not available'
                      }
                    </Text>
                  </HStack>
                </HStack>
                
                {/* Add TranscriptionTimeline component */}
                {callData?.call?.transcriptionMetadata && (
                  <Box width="100%" mt={2}>
                    <TranscriptionTimeline 
                      transcriptionMetadata={callData.call.transcriptionMetadata}
                      currentTime={currentTime}
                      duration={transcriptionDuration}
                      onTimestampClick={jumpToTimestamp}
                    />
                  </Box>
                )}
              </VStack>
            </Box>
          </VStack>
        </HStack>

        <Box p={4} w="100%">
          {/* Search bar */}
          <Flex w="100%">
            <InputGroup flex={1}>
              <InputLeftElement pointerEvents="none">
                {isSearching ? <Spinner size="sm" color="blue.500" /> : <SearchIcon color="gray.300" />}
              </InputLeftElement>
              <Input
                placeholder={`Search transcript`}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </InputGroup>
            {/* Add Edit Controls Hidden for now*/}
            {/*
            {!isEditingTranscription ? (
              <IconButton
                aria-label="Edit Transcription"
                icon={<FaEdit />}
                onClick={handleEditTranscription}
                variant="ghost"
                colorScheme="blue"
                _hover={{ bg: "blue.50", transform: "scale(1.05)" }}
                transition="background-color 0.3s ease, transform 0.2s ease"
              />
            ) : (
              <>
                <IconButton
                  aria-label="Save Transcription"
                  icon={<FaSave />}
                  onClick={handleSaveTranscription}
                  colorScheme="green"
                  variant="ghost"
                />
                <IconButton
                  aria-label="Cancel Transcription Edit"
                  icon={<FaTimes />}
                  onClick={handleCancelEditTranscription}
                  colorScheme="red"
                  variant="ghost"
                />
              </>
            )}
            */}
          </Flex>
        </Box>

        {/* Transcription content with virtualized list */}
        <Box
          w="100%"
          flex="1"
          overflowY="auto"
          ref={transcriptionRef}
          px={2}
          css={{
            // Add CSS to improve scrolling performance
            '&::-webkit-scrollbar': {
              width: '8px',
              borderRadius: '8px',
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '8px',
            },
            // Add scroll momentum for better UX
            '-webkit-overflow-scrolling': 'touch',
            // Improve rendering performance
            'will-change': 'transform',
            'transform': 'translateZ(0)',
            'backface-visibility': 'hidden'
          }}
        >
          <VirtualizedTranscriptionList
            ref={virtualizedListRef}
            groupedTranscription={filteredTranscription}
            searchTerm={searchTerm}
            currentTime={currentTime}
            onTimestampClick={jumpToTimestamp}
            isEditing={isEditingTranscription}
            onTextChange={handleTextChange}
            onSpeakerChange={(newSpeaker, currentSpeaker, groupIndex) => {
              console.log('onSpeakerChange called with:', { newSpeaker, currentSpeaker, groupIndex });
              handleSpeakerUpdate(newSpeaker, currentSpeaker, groupIndex);
            }}
            renderSpeakerName={renderSpeakerName}
            onOpenSpeakerModal={debugOpenSpeakerModal}
            loadMoreTranscription={loadMoreTranscription}
            isLoadingMore={isLoadingMoreTranscription}
            allLoaded={allTranscriptionLoaded}
            totalItems={totalItems}
            loadedItems={filteredTranscription.reduce((count, group) => count + (group.entries?.length || 0), 0)}
            availableSpeakers={availableSpeakers}
          />
        </Box>
      </VStack>

      {/* Add AssignClientModal */}
      <AssignClientModal
        isClientAssignModalOpen={isClientAssignModalOpen}
        setIsClientAssignModalOpen={setIsClientAssignModalOpen}
        clients={clients}
        setClientName={(name) => {
          // Find the client by formatted name
          const selectedClient = clients.find(client => formatPersonName(client) === name);
          if (selectedClient) {
            handleClientSelect(selectedClient);
          }
        }}
      />
    </Box>
  );
};

export default TranscriptionPanel; 