import { useState, useEffect } from 'react';
import { saveRecording, getLatestRecording } from '../utils/indexedDB';
import { useUser } from '../contexts/UserContext';

export const useRecordingStorage = () => {
    const [lastRecordingUrl, setLastRecordingUrl] = useState(null);
    const [storageError, setStorageError] = useState(null);
    const { user } = useUser();

    useEffect(() => {
        const loadLatestRecording = async () => {
            if (!user?.id) return;
            
            try {
                const recording = await getLatestRecording(user.id);
                if (recording) {
                    const url = URL.createObjectURL(recording.data);
                    setLastRecordingUrl({
                        url,
                        name: recording.name,
                        timestamp: recording.timestamp,
                        sizeMB: recording.data.size / (1024 * 1024)
                    });
                }
            } catch (error) {
                console.error('Error loading latest recording:', error);
                setStorageError('Failed to load recording from storage');
                // Don't throw, just log and continue
            }
        };

        loadLatestRecording();
    }, [user?.id]);

    const saveRecordingToStorage = async (audioBlob, name) => {
        if (!user?.id) return;
        
        try {
            await saveRecording(audioBlob, name, user.id);
            const url = URL.createObjectURL(audioBlob);
            setLastRecordingUrl({
                url,
                name,
                timestamp: Date.now(),
                sizeMB: audioBlob.size / (1024 * 1024)
            });
            setStorageError(null);
        } catch (error) {
            console.error('Error saving recording:', error);
            setStorageError('Failed to save recording to storage');
            // Don't throw, just log and continue
        }
    };

    const clearRecording = () => {
        try {
            if (lastRecordingUrl?.url) {
                URL.revokeObjectURL(lastRecordingUrl.url);
            }
            setLastRecordingUrl(null);
            setStorageError(null);
        } catch (error) {
            console.error('Error clearing recording:', error);
            // Don't throw, just log and continue
        }
    };

    return {
        lastRecordingUrl,
        storageError,
        saveRecording: saveRecordingToStorage,
        clearRecording
    };
}; 